const CardListSkeleton = () => {
    return (
        <>
        {Array(5).fill(0).map((_, index) => (
            <div
                key={index}
                role='status'
                className='w-full animate-pulse my-2 px-2'
            >
                <div className='w-full h-[4em] bg-HTKBorder rounded-[5px] dark:bg-HTKBorder'></div>
            </div>
        ))}
        </>
    )
}

export default CardListSkeleton