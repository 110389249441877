import { RFC } from "../../../types/propTypes";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { exerciseGroupIdSelector, setExerciseGroup } from "../../../features/Training/Workouts/exerciseGroupSlice";
import { Exercise, GroupExercise } from "../../../types/stateTypes";
import { ExerciseCard, HTKButton, HTKSectionTitleBlock, TitleCard } from "../../atoms/atoms";
import { CardListSkeleton } from "../molecules";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { addNewExerciseGroup, workoutIdSelector } from "../../../features/Training/Workouts/workoutSlice";
import { useAddGroupExercisesMutation } from "../../../services/WorkoutService";
import { useEffect } from "react";

type WorkoutExercisesListProps = {
	isLoading: boolean;
	exerciseGroups: GroupExercise[] | undefined;
};

const WorkoutExercisesList: RFC<WorkoutExercisesListProps> = ({ isLoading, exerciseGroups }) => {
	const workoutId = useHTKSelector(workoutIdSelector);
	const groupId = useHTKSelector(exerciseGroupIdSelector);
	const dispatch = useHTKDispatch();

	const [addGroupExercises] = useAddGroupExercisesMutation();

	const handleClickCard = (group: GroupExercise) => {
		dispatch(setExerciseGroup(group));
	};

	const addExerciseWorkout = async (exercises: Exercise[]) => {
		try {
			const data = await addGroupExercises({
				workoutId,
				exercises,
				presentExercises: exerciseGroups,
			}).unwrap();
			if (!data) throw Error;
			dispatch(addNewExerciseGroup(exercises));
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to add exercises",
					body: "Something went wrong. Please try again later.",
				})
			);
		} finally {
			dispatch(closeModal());
		}
	};

	const renderExerciseGroups = () => {
		if (isLoading) return <CardListSkeleton />;
		return (
			<div className="flex flex-col h-screen w-full overflow-y-auto overflow-x-hidden pb-[15em]">
				{!exerciseGroups?.length ? (
					<div className="w-full text-center mt-4 font-Title text-lg">No Exercise</div>
				) : (
					exerciseGroups.map((group, index) => {
						return (
							<TitleCard key={index} isSelected={groupId === group.id} onClick={() => handleClickCard(group)}>
								<ExerciseCard index={index} exerciseGroup={group} />
							</TitleCard>
						);
					})
				)}

				<HTKButton
					text="Add Exercise"
					className="main-detail-button mx-5 my-6"
					onSubmit={() =>
						dispatch(
							openModal({
								modalType: MODAL_TYPES.ADD_EXERCISES,
								title: "ADD EXERCISES TO WORKOUT",
								addItemFn: (exercises) => addExerciseWorkout(exercises),
							})
						)
					}
				/>
			</div>
		);
	};

	return (
		<div className="border-r-[1px] border-solid border-HTKBorder">
			<HTKSectionTitleBlock title="EXERCISES" />
			{renderExerciseGroups()}
		</div>
	);
};

export default WorkoutExercisesList;
