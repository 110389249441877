import { HTKLoading } from '../atoms'

const CardLoading = () => {
  return (
    <div
      className='felx justify-center items-center text-center mx-auto w-full p-5 mt-8'
    >
      <HTKLoading shape='bars'/>
      <p className='mt-4 text-HTKDarkGrey font-bold'>
        ... Loading
      </p>
    </div>
  )
}

export default CardLoading