import { RFC } from "../../types/propTypes";
import { useHTKDispatch, useHTKSelector } from "../../app/hooks";
import { CardError, CardLogo, HTKAsyncAvatar, TitleCard } from "../../components/atoms/atoms";
import { User } from "../../types/stateTypes";
import { selectUser, userIdSelector } from "./userSlice";
import { usersSelector } from "./usersSlice";
import { domAnimation, LazyMotion } from "framer-motion";

type UserTitlesProps = {
	searchTab: string | undefined;
	allLoading?: boolean;
	payingLoading?: boolean;
	trialLoading?: boolean;
	allError?: boolean;
	payingError?: boolean;
	trialError?: boolean;
};

export const UserTitles: RFC<UserTitlesProps> = ({ searchTab, allLoading, payingLoading, trialLoading, allError, payingError, trialError }) => {
	const userId = useHTKSelector(userIdSelector);
	const usersState = useHTKSelector(usersSelector);
	const dispatch = useHTKDispatch();

	const handleClickCard = (item: User) => {
		dispatch(selectUser({ id: item.id, user: item }));
	};

	if ((searchTab === "ALL USERS" && allLoading) || (searchTab === "PAYING USERS" && payingLoading) || (searchTab === "TRIAL USERS" && trialLoading)) {
		return <CardLogo />;
	}
	if ((searchTab === "ALL USERS" && allError) || (searchTab === "PAYING USERS" && payingError) || (searchTab === "TRIAL USERS" && trialError)) {
		return <CardError />;
	}
	if (!usersState.length) return <div className="w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7">No User</div>;

	return (
		<LazyMotion features={domAnimation} key="user-card">
			{usersState.map((user, index) => (
				<TitleCard key={index} isSelected={userId === user.id} onClick={() => handleClickCard(user)}>
					<div className="flex items-center justify-between">
						<div className="flex items-center justify-center min-w-[50px] h-[50px]">
							<HTKAsyncAvatar fileName={user.avatar ?? ""} width={"w-10"} height={"h-10"} />
						</div>
						<div className="w-full pl-3 truncate">
							<p className="font-Title uppercase font-bold text-lg truncate">
								{user.firstName}&nbsp;{user.lastName}
							</p>
							<div className="flex items-center text-sm">
								{user.userSubscription?.hasFreeAccess && <p className="font-medium text-HTKGreen mr-2">Free Access</p>}
								{user.isPaying ? <p className="font-medium text-HTKGreen mr-2">Paying User</p> : user.trialUser ? <p className="font-medium mr-2">Trial User</p> : null}
								{user.isRestricted ? <p className="font-medium text-HTKRed">Restricted User</p> : null}
							</div>
						</div>
					</div>
				</TitleCard>
			))}
		</LazyMotion>
	);
};
