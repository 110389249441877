const searchIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "16"} 
            height={height ?? "16"} 
            viewBox="0 0 16 16"
        >
        <path id="Path_921" data-name="Path 921" d="M0,0H16V16H0Z" fill="none"/>
        <path id="Path_922" data-name="Path 922" d="M11.333,10.333h-.527l-.187-.18a4.34,4.34,0,1,0-.467.467l.18.187v.527l3.333,3.327.993-.993Zm-4,0a3,3,0,1,1,3-3A3,3,0,0,1,7.333,10.333Z" transform="translate(-1 -1)"/>
        </svg>
      </div>
    )
  }
  
  export default searchIcon