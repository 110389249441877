import { RFC } from '../../../types/propTypes'

type HTKPrefixTextInputProps = {
    title:        string
    unit?:        string
    value?:       string
    placeholder?: string
    className?:   string
    onChange:     (text: string) => void
    error?: string
}

const HTKPrefixTextInput:RFC<HTKPrefixTextInputProps> = ({
    title,
    unit,
    value,
    placeholder,
    className,
    onChange,
    error
}) => {
    return (
        <div
            className='w-full flex items-center bg-HTKMiddleGrey
            drop-shadow-sm my-2 rounded-container px-2 py-1
            border-[1px] border-HTKBorder border-solid'
        >
            <div
                className={`${className} flex items-center justify-between
                font-semibold text-sm font-Title`}
            >
                <p>{title}</p>
                {unit && (<p>({unit})</p>)}
            </div>
            <input
                type='text'
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                className='w-full outline-none bg-HTKMiddleGrey
                py-1 font-medium'
            />
            <p className='ml-1 mt-1 text-xs text-red-600'>{error}</p>
        </div>
    )
}

export default HTKPrefixTextInput