import { RFC } from '../../../types/propTypes'
import { HTKButton } from '../atoms'

type HTKDetailUserBlockProps = {
    title:        string
    isRestricted: boolean | undefined
    onClick:      () => void
    onAccessClick : () => void
    hasFreeAccess :boolean
}

const HTKDetailUserBlock:RFC<HTKDetailUserBlockProps> = ({
    title,
    isRestricted,
    onClick,
    onAccessClick,
    hasFreeAccess 
}) => {

    return (
        <div
            className='w-full flex items-center justify-between px-3 py-2
        border-b-[1px] border-HTKBorder border-solid transition-all duration-200'
        >
            <div className='flex font-Title font-semibold text-HTKBlack/90 text-lg'>
                {title}
            </div>
            <div className='flex flex-row '>
            <HTKButton
                    text={hasFreeAccess ? 'Remove Free Access':'Give Free Access'}
                    onSubmit={onAccessClick}
                    className={`ml-3 ${ hasFreeAccess? 'main-detail-button':'unrestrict-button'}`}
                />
            {!isRestricted ? (
            <div className='flex items-center'>
                <HTKButton
                    text='Restrict User'
                    onSubmit={onClick}
                    className='ml-3 main-detail-button'
                />
            </div>
            ) : (
            <div className='flex items-center'>
                <HTKButton
                    text='Unrestrict User'
                    onSubmit={onClick}
                    className='ml-3 unrestrict-button'
                />
            </div>
            )}
            </div>
        </div>
    )
}

export default HTKDetailUserBlock