const addIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "22.627"} 
            height={height ?? "22.627"} 
            viewBox="0 0 22.627 22.627"
            onClick={onClick}
        >
            <g transform="translate(0 11.314) rotate(-45)">
                <path id="Path_1045" data-name="Path 1045" d="M0,0H16V16H0Z" fill="none"/>
                <path id="Path_1046" data-name="Path 1046" d="M8.333.839,7.494,0,4.167,3.327.839,0,0,.839,3.327,4.167,0,7.494l.839.839L4.167,5.006,7.494,8.333l.839-.839L5.006,4.167Z" transform="translate(3.833 3.833)"/>
            </g>
        </svg>
      </div>
    )
  }
  
  export default addIcon