import { HTKApi } from "../app/api";
import {
  AddProgramWeekArgs,
  CreateProgramArgs,
  EditRecommendationArgs,
  PaginationPayload,
  PaginationPayloadWithActiveStatus,
  RemoveDailyWorkout,
  SwapDailyWorkout,
  UpdateProgramArgs,
  UpdateProgramWeekContentArgs,
} from "../types/serviceTypes";
import { Day, Program, Week } from "../types/stateTypes";
import { programActiveFilter } from "../utils/filterListFormatter";

export const programApi = HTKApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrograms: builder.query<Program[], PaginationPayloadWithActiveStatus>({
      query: ({ search, take, skip, isActive }) => ({
        url: "/programs/search/admin",
        params: {
          search,
          take,
          skip,
          orderBy: "desc",
          isActivate: programActiveFilter(isActive),
        },
      }),
      providesTags: ["Program"],
    }),

    getProgramList: builder.query<Program[], PaginationPayload>({
      query: ({ search, take, skip }) => ({
        url: "/programs/search/admin",
        params: {
          search,
          take,
          skip,
          orderBy: "desc",
        },
      }),
      providesTags: ["Program"],
    }),

    getProgram: builder.query<Program, string>({
      query: (programId) => `/programs/${programId}/single`,
      providesTags: ["Program"],
    }),
    createProgram: builder.mutation<Program, CreateProgramArgs>({
      query: ({
        internalTitle,
        title,
        programPlannedFor,
        ProgramSkillRequired,
        description,
        image,
      }) => ({
        url: "/programs",
        method: "POST",
        body: {
          internalTitle,
          userFacingTitle: title,
          image,
        },
      }),
      invalidatesTags: ["Program"],
    }),
    updateProgram: builder.mutation<Program, UpdateProgramArgs>({
      query: ({
        id,
        internalTitle,
        programPlannedFor,
        ProgramSkillRequired,
        description,
        image,
        isActiveOnApp,
        userFacingTitle,
      }) => ({
        url: `/programs/${id}`,
        method: "PUT",
        body: {
          internalTitle,
          userFacingTitle,
          image,
        },
      }),
      invalidatesTags: ["Program"],
    }),
    deleteProgram: builder.mutation<Program, string>({
      query: (programId) => ({
        url: `/programs/${programId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Program"],
    }),
    editProgramRecommendation: builder.mutation<
      Program,
      EditRecommendationArgs
    >({
      query: ({ programId, recommendation }) => ({
        url: `/programs/${programId}/recommendation`,
        method: "PUT",
        body: {
          recommendation: recommendation.map(
            (recommendation) => recommendation.title
          ),
        },
      }),
      invalidatesTags: ["Program"],
    }),
    addWeek: builder.mutation<Week, AddProgramWeekArgs>({
      query: ({ programId, weekIndex, weekName }) => ({
        url: `/weeks/programs/${programId}`,
        method: "POST",
        body: {
          weekIndex,
          weekName,
        },
      }),
      invalidatesTags: ["Program"],
    }),
    deleteWeek: builder.mutation<Week, { weekId: string }>({
      query: ({ weekId }) => ({
        url: `/weeks/${weekId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Program"],
    }),
    updateProgramWeekContents: builder.mutation<
      Week,
      UpdateProgramWeekContentArgs
    >({
      query: ({ dayId, dayWorkouts }) => {
        const newWorkoutIds = dayWorkouts.map((dayWorkout) => {
          return {
            id: dayWorkout.workoutId,
            index: dayWorkout.index,
          };
        });

        console.log("newWorkoutIds", newWorkoutIds);

        return {
          url: `/weeks/days/${dayId}/add-workouts`,
          method: "POST",
          body: {
            workoutIds: newWorkoutIds,
          },
        };
      },
      invalidatesTags: ["Program"],
    }),
    removeDailyWorkout: builder.mutation<Day, RemoveDailyWorkout>({
      query: ({ dayId, workoutId }) => ({
        url: `/weeks/days/${dayId}/workouts/${workoutId}/remove`,
        method: "PUT",
      }),
      invalidatesTags: ["Program"],
    }),
    swapDailyWorkout: builder.mutation<Day, SwapDailyWorkout>({
      query: ({ dayId, workoutId, dayWorkout }) => {
        const newWorkoutId = [
          {
            id: dayWorkout.workoutId,
          },
        ];

        return {
          url: `/weeks/days/${dayId}/workouts/${workoutId}/swap`,
          method: "PUT",
          body: {
            workoutIds: newWorkoutId,
          },
        };
      },
      invalidatesTags: ["Program"],
    }),
  }),
});

export const {
  useLazyGetProgramListQuery,
  useDeleteWeekMutation,
  useLazyGetProgramsQuery,
  useGetProgramQuery,
  useCreateProgramMutation,
  useUpdateProgramMutation,
  useDeleteProgramMutation,
  useEditProgramRecommendationMutation,
  useAddWeekMutation,
  useUpdateProgramWeekContentsMutation,
  useRemoveDailyWorkoutMutation,
  useSwapDailyWorkoutMutation,
} = programApi;
