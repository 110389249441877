import { useEffect } from 'react'
import { clearWorkout, storeWorkout, workoutIdSelector, workoutSelector } from '../../../features/Training/Workouts/workoutSlice'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { useDeleteWorkoutMutation, useGetWorkoutQuery } from '../../../services/WorkoutService'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { HTKDetailTitleblock } from '../../atoms/atoms'
import { closeModal, openModal } from '../../../features/Cores/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { ExerciseDetailField, WorkoutExercisesList } from '../../molecules/molecules'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { deleteWorkoutWithId } from '../../../features/Training/Workouts/workoutsSlice'


const WorkoutDetail = () => {
  const workoutId = useHTKSelector(workoutIdSelector)
  const { id, title, exerciseGroups } = useHTKSelector(workoutSelector)
  const dispatch = useHTKDispatch()
  
  const { data: workoutData, isLoading: workoutLoading, isFetching: workoutFetching } = useGetWorkoutQuery(workoutId ?? skipToken)
  const [ deleteWorkout ] = useDeleteWorkoutMutation()

  useEffect(() => {
    workoutData && dispatch(storeWorkout({ id: workoutData.id, workout: workoutData }))
  },[workoutData])

  const removeWorkout = async (workoutId: string) => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting this workout' }))
      const [ data ] = await Promise.all([
        deleteWorkout(workoutId).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(clearWorkout())
      dispatch(deleteWorkoutWithId(workoutId))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'WORKOUT DELETED',
        body: 'You have successfully deleted the workout!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete workout',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <div className='flex flex-col mx-3'>
      <p className='min-h-[2.5em] text-2xl font-bold'></p>
      <div className='w-[70vw] bg-HTKWhite htk-container'>
        <HTKDetailTitleblock 
          title='WORKOUT'
          subTitle={title}
          mainBtnTitle='Workout Settings'
          onMainClick={() => dispatch(openModal({
            modalType: MODAL_TYPES.EDIT_WORKOUT
          }))}
          subBtnTitle='Delete Workout'
          onSubClick={() => dispatch(openModal({
            modalType: MODAL_TYPES.DELETE,
            deleteId: id,
            deleteFn: removeWorkout
          }))}
        />
        <div className='w-full h-screen grid grid-flow-col grid-cols-2cols'>
          <WorkoutExercisesList 
            isLoading={workoutLoading || workoutFetching}
            exerciseGroups={exerciseGroups}
          />
          <ExerciseDetailField 
            isLoading={workoutLoading || workoutFetching}
          />
        </div>
      </div>
    </div>
  )
}

export default WorkoutDetail