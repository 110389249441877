import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { ArrowLeftIcon } from "../../../assets/icons/icons";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import { clearProgram, programIdSelector, programSelector, storeProgram } from "../../../features/Training/Programs/programSlice";
import { deleteProgramWithId } from "../../../features/Training/Programs/programsSlice";
import { useDeleteProgramMutation, useDeleteWeekMutation, useGetProgramQuery } from "../../../services/ProgramService";
import asyncTimeout from "../../../utils/asyncTimeout";
import { HTKDetailTitleblock } from "../../atoms/atoms";
import { ProgramWeekDetailField, ProgramWeeksList } from "../../molecules/molecules";
import { clearWeek } from "../../../features/Training/Programs/weekSlice";
import { weekSelector } from "../../../features/Training/Programs/weekSlice";

const ProgramDetail = () => {
	const programId = useHTKSelector(programIdSelector);
	const { id, title, isActiveOnApp, week, internalTitle } = useHTKSelector(programSelector);
	const { weekName, weekIndex, id: weekId, day } = useHTKSelector(weekSelector);
	const dispatch = useHTKDispatch();

	const { data: programData, isLoading: programLoading, isFetching: programFetching } = useGetProgramQuery(programId ?? skipToken);
	const [deleteProgram] = useDeleteProgramMutation();

	useEffect(() => {
		programData && dispatch(storeProgram({ id: programData.id, program: programData }));
	}, [programData]);

	const removeProgram = async (programId: string) => {
		try {
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Deleting this program" }));
			const [data] = await Promise.all([deleteProgram(programId).unwrap(), asyncTimeout(2000)]);
			if (!data) throw Error;
			dispatch(clearProgram());
			dispatch(deleteProgramWithId(programId));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "PROGRAM DELETED",
					body: "You have successfully deleted the program!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to delete program",
					body: "Something went wrong. Please try again later.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	return (
		<div className="w-[90vw] mt-[1.5em] bg-HTKWhite htk-container">
			<HTKDetailTitleblock
				title="PROGRAM"
				subTitle={internalTitle}
				mainBtnTitle="Program Settings"
				onMainClick={() =>
					dispatch(
						openModal({
							modalType: MODAL_TYPES.EDIT_PROGRAM,
						})
					)
				}
				subBtnTitle="Delete Program"
				onSubClick={() =>
					dispatch(
						openModal({
							modalType: MODAL_TYPES.DELETE,
							deleteId: programId,
							deleteFn: removeProgram,
						})
					)
				}
				extraBtnTitle={weekName ? `Delete Week` : undefined}
				leftIcon={
					<ArrowLeftIcon
						className="mr-2 cursor-pointer"
						onClick={() => {
							dispatch(clearWeek());
							dispatch(clearProgram());
						}}
					/>
				}
			/>
			<div className="w-full h-screen grid grid-flow-col grid-cols-Big2Cols">
				<ProgramWeeksList isLoading={programLoading || programFetching} programId={id} programWeeks={week} />
				<ProgramWeekDetailField />
			</div>
		</div>
	);
};

export default ProgramDetail;
