import { RFC } from "../../../types/propTypes";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { programsSelector } from "./programsSlice";
import { programIdSelector, selectProgram } from "./programSlice";
import { Program } from "../../../types/stateTypes";
import { domAnimation, LazyMotion } from "framer-motion";
import { CardError, CardLogo, TitleCard } from "../../../components/atoms/atoms";
import moment from "moment";

type ProgramTitlesProps = {
	isLoading: boolean;
	isError: boolean;
};

export const ProgramTitles: RFC<ProgramTitlesProps> = ({ isLoading, isError }) => {
	const programIdState = useHTKSelector(programIdSelector);
	const programsState = useHTKSelector(programsSelector);
	const dispatch = useHTKDispatch();

	const handleClickCard = (item: Program) => {
		dispatch(selectProgram({ id: item.id, program: item }));
	};

	if (isLoading) return <CardLogo />;
	if (isError) return <CardError />;
	if (!programsState?.length) return <div className="w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7">No Program</div>;

	return (
		<LazyMotion features={domAnimation} key="program-card">
			{programsState.map((program, index) => (
				<TitleCard key={index} isSelected={programIdState === program.id} onClick={() => handleClickCard(program)}>
					<div className="flex items-center">
						<div className="w-full pl-3 truncate">
							<p className="font-Title uppercase font-bold text-lg truncate">{program.internalTitle}</p>
							<div className="flex items-center text-sm">
								<p className="font-bold w-[110px]">{program.week?.length} Week Program</p>
								<div className="pl-2 flex items-center text-sm">
									<p className="font-medium">Created</p>
									<p className="pl-2 text-inherit/80">{moment(program.createdAt).format("DD MMM YYYY")}</p>
								</div>
							</div>
						</div>
					</div>
				</TitleCard>
			))}
		</LazyMotion>
	);
};
