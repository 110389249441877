import { Dialog } from "@headlessui/react";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { CloseIcon } from "../../../assets/icons/icons";
import { HTKButton, HTKTabListInput, HTKTextArea, HTKTextInput, HTKTitleBlock } from "../../atoms/atoms";
import { updateWorkoutDescription, updateWorkoutInternalTitle, updateWorkoutRotation, updateWorkoutTitle, updateWorkoutType, workoutSelector } from "../../../features/Training/Workouts/workoutSlice";
import { workoutTypes } from "../../../assets/data/arrays";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import { useUpdateWorkoutMutation } from "../../../services/WorkoutService";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { updateWorkoutList } from "../../../features/Training/Workouts/workoutsSlice";
import HTKTabInput from "../../atoms/inputField/HTKTabInput";

const EditWorkoutModal = () => {
	const { id, internalTitle, title, type, circuitRotations, description } = useHTKSelector(workoutSelector);
	const dispatch = useHTKDispatch();

	const [updateWorkout] = useUpdateWorkoutMutation();

	const saveWorkout = async () => {
		try {
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Updating Workout" }));
			const [data] = await Promise.all([
				updateWorkout({
					id,
					internalTitle,
					title,
					type,
					circuitRotations,
					description,
				}).unwrap(),
				asyncTimeout(2000),
			]);
			if (!data) throw Error;
			dispatch(updateWorkoutList(data));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "WORKOUT UPDATED",
					body: "You have successfully updated the workout!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to update workout",
					body: "Please check your input details and try again.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	return (
		<>
			<div className="min-w-[600px] max-w-[650px] py-3 px-6">
				<Dialog.Title className="flex items-center justify-between">
					<div>
						<p className="font-Title font-extrabold text-xl">EDIT WORKOUT</p>
						<p className="text-HTKBlack/80">Let’s update a workout!</p>
					</div>
					<CloseIcon width={"30"} height={"30"} className="cursor-pointer" onClick={() => dispatch(closeModal())} />
				</Dialog.Title>
				<hr className="htk-border my-2" />
				<div className="max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]">
					<HTKTitleBlock title="INTERNAL WORKOUT TITLE" description="The internal workout title allows you to title this workout anything you would like. This title is not shown to users on the front end of the HTK Application." isRequired={!internalTitle} />
					<HTKTextInput name="internal-title" value={internalTitle} placeholder="Internal Workout Title" onChange={(text) => dispatch(updateWorkoutInternalTitle(text))} />
					<HTKTitleBlock title="USER-FACING WORKOUT TITLE" description="This is the workout title a user will see inside of the HTK application." isRequired={!title} />
					<HTKTextInput name="title" value={title} placeholder="Workout Title" onChange={(text) => dispatch(updateWorkoutTitle(text))} />
					<HTKTabInput title={"WORKOUT TYPE"} description={"Pick what workout type best describes this workout. Select from Circuit or Strength."} list={workoutTypes} selectedItem={type} onSelect={(text) => dispatch(updateWorkoutType(text))} className={"py-1"} />
					<div
						className="border-solid border-HTKBorder border-[1px] rounded-[3px]
            px-3 py-3 flex justify-between items-center mb-3"
					>
						<div>
							<p>Circuit Rotations</p>
							<p className="text-HTKBlack/80 text-sm">Specify how may rotations a user needs to complete in this workout.</p>
						</div>
						<div
							className="flex items-center justify-end bg-HTKMiddleGrey rounded-[3px]
              border-HTKBorder border-solid border-[1px] px-3 py-2 text-HTKBlack 
              shadow-sm drop-shadow-sm"
						>
							<input type="number" value={circuitRotations?.toString() !== "NaN" ? circuitRotations?.toString() : "0"} onChange={(e) => dispatch(updateWorkoutRotation(parseInt(e.target.value)))} className="max-w-[1.5em] outline-none bg-HTKMiddleGrey font-medium" />
							<p>Rotations</p>
						</div>
					</div>
					<HTKTitleBlock title="WORKOUT DESCRIPTION" description="Provide some extra details about this workout. This allows the user to better understand what they will be completing." isRequired={!description} />
					<HTKTextArea text={description ?? ""} onChange={(text) => dispatch(updateWorkoutDescription(text))} />
				</div>
			</div>
			<div className="sticky bottom-0 w-full h-12 flex items-center px-6">
				<HTKButton text="Update Workout" isLoading={false} disabled={false} className="create-item-button" onSubmit={saveWorkout} />
				<HTKButton text="Cancel" className="cancel-item-button" onSubmit={() => dispatch(closeModal())} />
			</div>
		</>
	);
};

export default EditWorkoutModal;
