import { RFC } from "../../../types/propTypes";
import { useHTKDispatch } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { AddNewIcon } from "../../../assets/icons/icons";
import { openModal } from "../../../features/Cores/modalSlice";
import { HTKCategoryButton } from "../../atoms/atoms";

type AnnouncementCourseFieldProps = {
	courseTitle: string;
	title: string;
	onDelete: () => void;
};

const AnnouncementCourseField: RFC<AnnouncementCourseFieldProps> = ({ courseTitle, title, onDelete }) => {
	const dispatch = useHTKDispatch();

	return (
		<div className="w-full flex items-center my-2">
			<AddNewIcon
				className="w-[30px] h-[30px] flex items-center justify-center
                rounded-[3px] border-[2px] border-HTKBlack border-solid cursor-pointer
                drop-shadow-md hover:shadow-md transition-all duration-200"
				onClick={() =>
					dispatch(
						openModal({
							modalType: MODAL_TYPES.ADD_COURSE_TO_ANNOUNCEMENT,
							title: title ?? undefined,
						})
					)
				}
			/>
			<div className="ml-1 flex flex-wrap gap-1">{!courseTitle ? <div className="text-HTKBlack/80 ml-2">No Course Selected</div> : <HTKCategoryButton text={"Course Selected"} onClick={() => onDelete()} />}</div>
		</div>
	);
};

export default AnnouncementCourseField;
