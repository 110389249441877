import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialRecipeCategoriesState } from '../../../assets/data/initialState'
import { RecipeCategory } from '../../../types/stateTypes'

export type RecipeCategorySliceType = {
    categories: RecipeCategory[]
}

export const recipeCategorySlice = createSlice({
    name: 'recipeCategory',
    initialState: InitialRecipeCategoriesState,
    reducers: {
        clearRecipeCategories: (state) => {
            state.categories = []
        },
        setInitialRecipeCategories: (state, action:PayloadAction<RecipeCategorySliceType>) => {
            state.categories = action.payload.categories
        },
        setRecipeCategories: (state, action:PayloadAction<RecipeCategorySliceType>) => {
            const { categories: storedCategories } = state
            const { categories: newCategories } = action.payload
            const categoryIds = storedCategories.map(category => category.id)
            const filteredDuplicates = newCategories.filter(category => !categoryIds.includes(category.id))
            filteredDuplicates.map(category => state.categories.push(category))
        },
        addBrandNewCategory: (state, action:PayloadAction<RecipeCategory>) => {
            state.categories.unshift(action.payload)
        }
    }
})

export const recipeCategoriesSelector = (state: RootState) => state.recipeCategories.categories
export const {
    clearRecipeCategories,
    setInitialRecipeCategories,
    setRecipeCategories,
    addBrandNewCategory,
} = recipeCategorySlice.actions
export default recipeCategorySlice.reducer