import { useEffect } from 'react'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { 
  clearExercise, exerciseIdSelector , exerciseSelector, removeExerciseCategory, 
  selectExercise, 
  storeExercise, updateExerciseDescription, updateExerciseImage, updateExerciseTitle, updateExerciseVideo 
} from '../../../features/Training/Exercises/exerciseSlice'
import { useCreateExerciseMutation, useDeleteExerciseMutation, useGetExerciseQuery, useUpdateExerciseMutation } from '../../../services/ExerciseService'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { closeModal, openModal } from '../../../features/Cores/modalSlice'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import asyncTimeout from '../../../utils/asyncTimeout'
import { HTKDetailTitleblock, HTKTextArea, HTKTextInput, HTKTitleBlock, HTKUploadInput } from '../../atoms/atoms'
import { ExerciseCategoryField, ImageSkeleton, InputSkeleton, TextAreaSkeleton } from '../../molecules/molecules'
import { addExercise, deleteExerciseWithId } from '../../../features/Training/Exercises/exercisesSlice'

const ExerciseDetail = () => {
  const exerciseId = useHTKSelector(exerciseIdSelector)
  const { id, title, video, exerciseCategory, description, isActiveOnApp, image } = useHTKSelector(exerciseSelector)
  const dispatch = useHTKDispatch()

  const { data: exerciseData, isLoading: exerciseLoading, isFetching: exerciseFetching } = useGetExerciseQuery(exerciseId ?? skipToken)
  const [ createExercise ] = useCreateExerciseMutation()
  const [ updateExercise ] = useUpdateExerciseMutation()
  const [ deleteExercise ] = useDeleteExerciseMutation()

  useEffect(() => {
    exerciseData && dispatch(storeExercise({ id: exerciseData.id, exercise: exerciseData }))
  },[exerciseData])

  const duplicateExercise = async () => {
    if (!title || !exerciseCategory || !description) throw Error
    try {
      dispatch(openOverlay({ text: 'Duplicating Exercise' }))
      const [ data ] = await Promise.all([
        createExercise({
          title, video, exerciseCategory, description, image,
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(addExercise(data))
      dispatch(selectExercise({ id: data.id, exercise: data }))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'EXERCISE DUPLICATED',
        body: 'You have successfully duplicated an exercise!'
      }))
    } catch (error) {
      console.log("error",error)
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to duplicate exercise',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const saveExercise = async () => {
    try {
      dispatch(openOverlay({ text: 'Updating Exercise ' }))
      const [ data ] = await Promise.all([
        updateExercise({
          id, title, video, exerciseCategory, 
          description, isActiveOnApp, image,
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'EXERCISE UPDATED',
        body: 'You have successfully updated the exercise!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to update exercise',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const removeExercise = async (exerciseId: string) => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting this Exercise' }))
      const [ data ] = await Promise.all([
        deleteExercise(exerciseId).unwrap(),
        asyncTimeout(1000)
      ])
      if (!data) throw Error
      dispatch(clearExercise())
      dispatch(deleteExerciseWithId(exerciseId))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'EXERCISE DELETED',
        body: 'You have successfully deleted the exercise!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete exercise',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <div className='flex flex-col mx-3'>
      <p className='min-h-[2.5em] text-2xl font-bold'></p>
      <div className='w-[33em] bg-HTKWhite htk-container'>

        <HTKDetailTitleblock 
          title='VIEW EXERCISE'
          mainBtnTitle='Delete Exercise'
          onMainClick={() => dispatch(openModal({
            modalType: MODAL_TYPES.DELETE,
            deleteId: id,
            deleteFn: removeExercise
          }))}
          subBtnTitle='Save'
          onSubClick={saveExercise}
          onDuplicate={duplicateExercise}
        />

        <div className='py-3 px-2 h-screen overflow-y-auto overflow-x-hidden pb-[15em]'>
          <HTKTitleBlock 
            title='EXERCISE TITLE'
            description='Give this exercise an accurate title to what is involved.'
            isRequired={!title}
          />
          {(exerciseLoading || exerciseFetching) ? (<InputSkeleton />) : (
            <HTKTextInput 
              name='title'
              value={title}
              placeholder='Title'
              onChange={(text) => dispatch(updateExerciseTitle(text))}
            />
          )}
          <HTKTitleBlock 
            title='EXERCISE VIDEO'
            description='Upload a video of this exercise. This video will be shown to users during a workout and exercise library.'
            className='mt-6'
            isRequired={!video}
          />
          {(exerciseLoading || exerciseFetching) ? (<ImageSkeleton />) : (
            <HTKUploadInput 
              type='video'
              source={video ?? ''}
              onUpload={(video) => dispatch(updateExerciseVideo(video ?? ''))}
            />
          )} 
          <HTKTitleBlock
              title='EXERCISE IMAGE'
              description='Upload an image for this exercise’s thumbnail image. This image will be shown to users during a workout and viewing in the exercise library.'
              isRequired={!image}
          />
          {(exerciseLoading || exerciseFetching) ? (<ImageSkeleton />) : (
            <HTKUploadInput
              type='image'
              source={image ?? ''}
              onUpload={(image) => dispatch(updateExerciseImage(image ?? ''))}
          />
          )}
          <HTKTitleBlock 
            title='EXERCISE CATEGORY'
            description='Add this exercise to a category. This allows user to find exercises by category.'
            className='mt-6'
            isRequired={!exerciseCategory?.length}
          />
          {(exerciseLoading || exerciseFetching) ? (<InputSkeleton />) : (
            <ExerciseCategoryField 
              categories={exerciseCategory}
              title='UPDATE_EXERCISE'
              onDelete={(categoryId) => dispatch(removeExerciseCategory(categoryId))}
            />
          )}
          <HTKTitleBlock 
            title='EXERCISE DESCRIPTION'
            description='Add an exercise description. This description will be shown to user when they video this exercise in the exercise library.'
            className='mt-6'
            isRequired={(!description || description === '<p><br></p>') ? true : false}
          />
          {(exerciseLoading || exerciseFetching) ? (<TextAreaSkeleton />) : (
            <HTKTextArea 
              text={description ?? ''}
              onChange={(text) => dispatch(updateExerciseDescription(text))}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ExerciseDetail