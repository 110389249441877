import { RFC } from '../../../types/propTypes'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import '../../../assets/styles/quill.css'

type HTKTextAreaProps = {
    text:         string
    onChange:     (text: string) => void
}

const HTKTextArea:RFC<HTKTextAreaProps> = ({
    text,
    onChange,
}) => {

    const modules = {
        toolbar: [
        [ 'italic', 'bold' ],
        ],
    }

    return (
        <ReactQuill 
            value={text}
            onChange={(text) => onChange(text)}
            modules={modules}
        />
    )
}

export default HTKTextArea