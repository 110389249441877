import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store' 
import { InitialUserState } from '../../assets/data/initialState'
import { UserData } from '../../types/serviceTypes'

export type UserSliceType = {
    id:   string
    user: UserData
}

export const userSlice = createSlice({
    name: 'user',
    initialState: InitialUserState,
    reducers: {
        selectUser: (state, action:PayloadAction<UserSliceType>) => {
            state.id = action.payload.id
        },
        storeUser: (state, action:PayloadAction<UserSliceType>) => {
            state.user = action.payload.user
        },
    }
})

export const userIdSelector = (state: RootState) => state.user.id
export const userSelector = (state: RootState) => state.user.user
export const {
    selectUser,
    storeUser,
} = userSlice.actions
export default userSlice.reducer