import { RFC } from '../../../types/propTypes'
import { Exercise, ExerciseToGroupExercise } from '../../../types/stateTypes'
import { BinIcon, SupersetIcon, SwapIcon } from '../../../assets/icons/icons'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { closeModal, openModal } from '../../../features/Cores/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { addSuperSetExercise, clearExerciseGroup, exerciseGroupIdSelector, swapExercise } from '../../../features/Training/Workouts/exerciseGroupSlice'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import { useDeleteWorkoutGroupMutation } from '../../../services/WorkoutService'
import asyncTimeout from '../../../utils/asyncTimeout'

type ExerciseTitleBlockProps = {
    isSingleExercise?: boolean
    isCircuitWorkout?: boolean
    groupExercises:    ExerciseToGroupExercise[]
}

const ExerciseTitleBlock:RFC<ExerciseTitleBlockProps> = ({ 
    isSingleExercise,
    isCircuitWorkout,
    groupExercises,
}) => {
    const exerciseGroupIdState = useHTKSelector(exerciseGroupIdSelector)
    const dispatch = useHTKDispatch()

    const [ deleteExerciseGroup ] = useDeleteWorkoutGroupMutation()
    
    const addExercisesToGroup = (exercises: Exercise[]) => {
        dispatch(addSuperSetExercise(exercises))
        dispatch(closeModal())
    }

    const swapGroupExercise = (exercise: Exercise) => {
        dispatch(swapExercise(exercise))
        dispatch(closeModal())
    }

    const removeExerciseGroup = async (exerciseGroupId: string) => {
        try {
            dispatch(closeModal())
            dispatch(openOverlay({ text: 'Deleting this exercise group' }))
            const [ data ] = await Promise.all([
                deleteExerciseGroup(exerciseGroupId).unwrap(),
                asyncTimeout(1000)
            ])
            if (!data) throw Error
            dispatch(clearExerciseGroup())
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'EXERCISE GROUP DELETED',
                body: 'You have successfully deleted the exercise group!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to delete exercise group',
                body: 'Something went wrong. Please try again later.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }

    const allTitles = groupExercises.map(group => group.exercise.title)
    const formattedTitles = allTitles.join(' & ')
    return (
        <div className='flex items-center justify-between px-3 py-2'>
            <div className='max-w-[500px] truncate'>
                <p className='text-sm text-HTKBlack/80'>
                Exercise
                </p>
                <p className='uppercase text-2xl font-Title font-semibold truncate'>
                {formattedTitles}
                </p>
            </div>
            <div className='w-[20%] flex flex-wrap gap-2'>
                {!isCircuitWorkout && (
                    <SupersetIcon 
                    width={'28'} 
                    height={'28'}  
                    className={'cursor-pointer'}
                    onClick={() => dispatch(openModal({
                        modalType: MODAL_TYPES.ADD_SUPERSET,
                        title: 'SUPERSET EXERCISE',
                        addItemFn: (exercises) => addExercisesToGroup(exercises)
                    }))}
                    />
                )}
                {isSingleExercise && (
                    <SwapIcon 
                    width={'28'} 
                    height={'28'}  
                    className={'cursor-pointer'}
                    onClick={() => dispatch(openModal({
                        modalType: MODAL_TYPES.SWAP_EXERCISE,
                        addItemFn: (exercises) => swapGroupExercise(exercises) 
                    }))}
                    />
                )}
                <BinIcon 
                width={'28'} 
                height={'28'}  
                className={'cursor-pointer'}
                onClick={() => dispatch(openModal({
                    modalType: MODAL_TYPES.DELETE,
                    deleteId: exerciseGroupIdState,
                    deleteFn: removeExerciseGroup
                }))}
                />
            </div>
        </div>
    )
}

export default ExerciseTitleBlock