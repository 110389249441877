import { useState } from "react";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import {
  programSelector,
  updateProgramDescription,
  updateProgramImage,
  updateProgramInternalTitle,
  updateProgramProgramPlannedFor,
  updateProgramSkillRequired,
  updateProgramTitle,
} from "../../../features/Training/Programs/programSlice";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/Cores/overlaySlice";
import { useUpdateProgramMutation } from "../../../services/ProgramService";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { Dialog } from "@headlessui/react";
import { CloseIcon } from "../../../assets/icons/icons";
import {
  HTKButton,
  HTKTabListInput,
  HTKTextArea,
  HTKTextInput,
  HTKTitleBlock,
  HTKUploadInput,
} from "../../atoms/atoms";
import {
  coursePlannedForTypes,
  programPlannedForTypes,
  programSkillRequiredTypes,
} from "../../../assets/data/arrays";
import {
  courseSelector,
  storeCourse,
  updateCourseFullDescription,
  updateCourseImage,
  updateCourseInternalTitle,
  updateCoursePlannedFor,
  updateCourseProgramLength,
  updateCourseSkillRequired,
  updateCourseSnippetDescription,
  updateCourseUserFacingTitle,
} from "../../../features/Training/Courses/courseSlice";
import { CourseUpdateArgs } from "../../../types/CourseTypes";
import { useUpdateCourseMutation } from "../../../services/CourseService";
import { updateCourseInfo } from "../../../features/Training/Courses/coursesSlice";

const EditCourseModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const course = useHTKSelector(courseSelector);
  const dispatch = useHTKDispatch();
  const [updateCourse, { isLoading: isCourseUpdating }] =
    useUpdateCourseMutation();

  const updateCourseDetails = async () => {
    if (!course) return;
    if (
      !course.internalTitle ||
      !course.userFacingTitle ||
      !course.snippetDescription ||
      !course.fullDescription ||
      !course.image ||
      !course.skillRequired ||
      !course.courseProgramLength ||
      !course.coursePlannedFor
    )
      return;
    const updateCourseData: CourseUpdateArgs = {
      id: course.id,
      internalTitle: course.internalTitle,
      userFacingTitle: course.userFacingTitle,
      snippetDescription: course.snippetDescription,
      fullDescription: course.fullDescription,
      image: course.image,
      skillRequired: course.skillRequired,
      courseProgramLength: course.courseProgramLength,
      isActive: course.isActive,
      coursePlannedFor: course.coursePlannedFor?.map((coursePlan) => {
        return { title: coursePlan.title };
      }),
    };
    try {
      setIsLoading(true);
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Updating this course" }));
      const [data] = await Promise.all([
        updateCourse(updateCourseData).unwrap(),
        asyncTimeout(2000),
      ]);
      if (!data) throw Error;
      dispatch(updateCourseInfo(data));
      dispatch(storeCourse({ id: data.id, course: data }));
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "COURSE UPDATED",
          body: "You have successfully updated the Course!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to update course",
          body: "Something went wrong. Please try again later.",
        })
      );
    } finally {
      setIsLoading(false);
      dispatch(closeOverlay());
    }
  };

  return (
    <>
      <div className="min-w-[600px] max-w-[650px] py-3 px-6">
        <Dialog.Title className="flex items-center justify-between">
          <div>
            <p className="font-Title font-extrabold text-xl">EDIT COURSE</p>
            <p className="text-HTKBlack/80">
              Make any adjustment you need to this course.
            </p>
          </div>
          <CloseIcon
            width={"30"}
            height={"30"}
            className="cursor-pointer"
            onClick={() => dispatch(closeModal())}
          />
        </Dialog.Title>
        <hr className="htk-border my-2" />
        <div className="max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]">
          <HTKTitleBlock
            title="INTERNAL COURSE TITLE"
            description="The Internal Course title allows you to title this Course anything you would like. This title is not shown to users on the front end of the HTK Application."
            isRequired={!course.internalTitle}
          />
          <HTKTextInput
            name="internal-title"
            value={course.internalTitle}
            placeholder="Internal Program Title"
            onChange={(text) => dispatch(updateCourseInternalTitle(text))}
          />
          <HTKTitleBlock
            title="USER FACING COURSE TITLE"
            description="This is the Course title a user will see inside of the HTK application."
            isRequired={!course.userFacingTitle}
          />
          <HTKTextInput
            name="title"
            value={course.userFacingTitle}
            placeholder="Program Title"
            onChange={(text) => dispatch(updateCourseUserFacingTitle(text))}
          />
          <HTKTabListInput
            title={"COURSE PLANNED FOR"}
            description={
              "Select what workout type best describes this Course. Select from Gym, Home, Specialised or Other."
            }
            list={coursePlannedForTypes}
            selectedItem={course.coursePlannedFor?.map((coursePlannedFor) => {
              return coursePlannedFor.title;
            })}
            onSelect={(text) => {
              dispatch(updateCoursePlannedFor(text));
            }}
            className={"py-1"}
          />

          <HTKTitleBlock
            title="PROGRAM SKILL REQUIRED"
            description="Select the level of fitness expertise a user will need to complete this program."
            isRequired={!course.skillRequired}
          />
          <HTKTextInput
            name="title"
            value={course.skillRequired}
            placeholder="Program Skill Required"
            onChange={(text) => dispatch(updateCourseSkillRequired(text))}
          />
          <HTKTitleBlock
            title="COURSE PROGRAM LENGTH"
            description="Enter how many weeks each program inside of this course goes for in weeks."
            isRequired={!course.courseProgramLength}
          />
          <HTKTextInput
            name="title"
            value={course.courseProgramLength}
            placeholder="Course Program Length"
            onChange={(text) => dispatch(updateCourseProgramLength(text))}
          />
          <HTKTitleBlock
            title="UPLOAD IMAGE"
            description={`Upload and image for this Course's thumbnail.`}
            isRequired={!course.image}
          />
          <HTKUploadInput
            type="image"
            source={course.image ?? ""}
            onUpload={(image) => dispatch(updateCourseImage(image ?? ""))}
          />
          <HTKTitleBlock
            title="COURSE SNIPPET DESCRIPTION"
            description="Give a small description of this Course's contents. This description will be displayed on each thumbnail."
            isRequired={!course.snippetDescription}
          />
          <HTKTextArea
            text={course.snippetDescription ?? ""}
            onChange={(text) => dispatch(updateCourseSnippetDescription(text))}
          />
          <div className="mt-8">
            <HTKTitleBlock
              title="COURSE FULL DESCRIPTION"
              description="Give a description of this Course's contents. This description will be shown to users when viewing a courses details."
              isRequired={!course.fullDescription}
            />

            <HTKTextArea
              text={course.fullDescription ?? ""}
              onChange={(text) => dispatch(updateCourseFullDescription(text))}
            />
          </div>
        </div>
        <div className="sticky bottom-0 w-full h-12 flex items-center">
          <HTKButton
            text="Update Course"
            isLoading={isLoading}
            disabled={isLoading}
            className="create-item-button"
            onSubmit={updateCourseDetails}
          />
          <HTKButton
            text="Cancel"
            className="cancel-item-button"
            onSubmit={() => dispatch(closeModal())}
          />
        </div>
      </div>
    </>
  );
};

export default EditCourseModal;
