import { RFC } from '../../../types/propTypes'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { newExerciseGroupSelector } from '../../../features/Training/Workouts/exerciseGroupSlice'
import { SingleExerciseField, SuperSetExerciseField } from '../molecules'
import { HTKSectionTitleBlock } from '../../atoms/atoms'
import { useUpdateExerciseDetailsMutation } from '../../../services/WorkoutService'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { openModal } from '../../../features/Cores/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'

type ExerciseDetailFieldProps = {
  isLoading: boolean
}

const ExerciseDetailField:RFC<ExerciseDetailFieldProps> = ({ isLoading }) => {

  const newExerciseGroupState = useHTKSelector(newExerciseGroupSelector)
  const { exerciseInExerciseGroups: newExerciseGroup, execution, executionDescription } = newExerciseGroupState
  const dispatch = useHTKDispatch()
  const [ updateExerciseDetails ] = useUpdateExerciseDetailsMutation()

  const updateExerciseDetailsInfo = async () => {
    try {
      dispatch(openOverlay({ text: 'Updating Exercise Details' }))
      const [ data ] = await Promise.all([
        updateExerciseDetails({ 
          groupExerciseId: newExerciseGroupState.id, 
          groupExercise: newExerciseGroupState 
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'EXERCISE DETAIL UPDATED',
        body: 'You have successfully updated the exercise details!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to update exercise details',
        body: 'Please check your input details and try again.'
      }))
      console.log('Error while updating the workout', JSON.stringify(error, null, 3))
    } finally {
      dispatch(closeOverlay())
    }
  }

  if (!newExerciseGroup.length) return (
    <div>
      <div className='w-full bg-HTKLightGrey border-b-[1px] border-solid border-HTKBorder'>
        <p className='px-3 py-1 font-Title font-semibold text-sm text-HTKBlack/80'>
          EXERCISE DETAILS
        </p>
      </div>
      <div className='w-full h-[80vh] flex items-center justify-center bg-HTKWhite htk-container'>
        <p className='text-HTKBlack/80 font-Title text-lg'>
          Select an exercise to see details
        </p>
      </div>
    </div>
  )

  if (newExerciseGroup.length === 1) return (
    <div>
      <HTKSectionTitleBlock 
        title='EXERCISE DETAILS' 
        btnTitle='Save Details'
        btnFn={updateExerciseDetailsInfo}  
      />
      <SingleExerciseField 
        isLoading={isLoading}
        execution={execution}
        executionDescription={executionDescription}
        exerciseGroupDetails={newExerciseGroup}
      />
    </div>
  )
  else return (
    <div>
      <HTKSectionTitleBlock 
        title='EXERCISE DETAILS' 
        btnTitle='Save Details'
        btnFn={updateExerciseDetailsInfo}  
      />
      <SuperSetExerciseField 
        isLoading={isLoading}
        execution={execution}
        executionDescription={executionDescription}
        exerciseGroupDetails={newExerciseGroup}
      />
    </div>
  )
}

export default ExerciseDetailField