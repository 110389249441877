import { RFC } from "../../../types/propTypes";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { Workout } from "../../../types/stateTypes";
import { CardError, CardLogo, TitleCard } from "../../../components/atoms/atoms";
import { domAnimation, LazyMotion } from "framer-motion";
import moment from "moment";
import { courseIdSelector, selectCourse } from "./courseSlice";
import { clearCourses, coursesSelector } from "./coursesSlice";
import { Course } from "../../../types/CourseTypes";

type WorkoutTitlesProps = {
	isLoading: boolean;
	isError: boolean;
};

export const CourseTitles: RFC<WorkoutTitlesProps> = ({ isLoading, isError }) => {
	const courseId = useHTKSelector(courseIdSelector);
	const courseState = useHTKSelector(coursesSelector);
	const dispatch = useHTKDispatch();

	const handleClickCard = (item: Course) => {
		dispatch(selectCourse({ id: item.id, course: item }));
	};

	if (isLoading) return <CardLogo />;
	if (isError) return <CardError />;
	if (!courseState.courses.length) return <div className="w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7">No Courses</div>;

	return (
		<LazyMotion features={domAnimation} key="workout-card">
			{courseState.courses.map((course, index) => (
				<TitleCard key={index} isSelected={courseId === course.id} onClick={() => handleClickCard(course)}>
					<div className="w-full truncate">
						<p className="font-Title uppercase font-bold text-lg truncate">{course.internalTitle}</p>
						<div className="flex items-center text-sm">
							<p className={`w-16 font-bold mr-2 text-[15px] text-${course.isActive ? `HTKGreen` : `HTKRed`}`}>{course.isActive ? `Active` : `In-Active`}</p>
							<p className="font-semibold text-inherit/80">Created</p>
							<p className="pl-2 text-inherit/80">{moment(course.createdAt).format("DD MMM YYYY")}</p>
						</div>
					</div>
				</TitleCard>
			))}
		</LazyMotion>
	);
};
