import { useHTKSelector } from "../../app/hooks";
import { HTKEmptyDetailBox } from "../../components/atoms/atoms";
import CourseDetail from "../../components/organisms/details/CourseDetail";
import CoursesList from "../../components/organisms/searchList/CoursesList";
import { courseIdSelector } from "../../features/Training/Courses/courseSlice";
import { programIdSelector } from "../../features/Training/Programs/programSlice";

const Courses = () => {
	const programId = useHTKSelector(programIdSelector);
	const courseId = useHTKSelector(courseIdSelector);

	return (
		<div className="w-full h-full flex justify-center">
			<CoursesList />
			{courseId ? <CourseDetail /> : <HTKEmptyDetailBox title="course" />}
		</div>
	);
};

export default Courses;
