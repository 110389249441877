import { useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { debounce } from 'lodash'
import { useHTKDispatch } from '../../../app/hooks'
import { userSearchTabs } from '../../../assets/data/arrays'
import { DASHBOARD_TYPES, MODAL_TYPES } from '../../../assets/data/enums'
import { HTKSearchbar, HTKSearchTabs, HTKSearchTitleBlock } from '../../atoms/atoms'
import InfiniteScrollLists from './InfiniteScrollLists'
import { openModal } from '../../../features/Cores/modalSlice'

const UserList = () => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
  const [ searchTab, setSearchTab ] = useState('ALL USERS')
  const dispatch = useHTKDispatch()

  return (
    <div className='flex flex-col'>
      <p className='min-h-[2.5em] pb-1 flex items-end font-Title text-2xl font-bold'>
        APPLICATION USERS
      </p>
      <div className='w-[20em] bg-HTKWhite htk-container'>
        <HTKSearchTitleBlock
          title='ALL USERS'
          onDownload={() => dispatch(openModal({
            modalType: MODAL_TYPES.CONVERT_CSV
          }))}
        />
        <HTKSearchbar onChange={onChangeText} />
        <HTKSearchTabs
          tabs={userSearchTabs}
          selectedTab={searchTab}
          onClick={(tab) => setSearchTab(tab)}
        />
        <div className='w-full my-1'>
          <AnimatePresence>
            <InfiniteScrollLists
              key='infinite_scroll'
              page={DASHBOARD_TYPES.USERS}
              searchText={searchText}
              searchTab={searchTab}
              pb='pb-[10em]'
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

export default UserList