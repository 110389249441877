import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialArticleState } from '../../../assets/data/initialState'
import { ArticleData, NewArticleData } from '../../../types/serviceTypes'

export type ArticleSliceType = {
    id:      string
    article: ArticleData
}

export type NewArticleSliceType = {
    newArticle: NewArticleData
}

export const articleSlice = createSlice({
    name: 'article',
    initialState: InitialArticleState,
    reducers: {
        // Select Existing Article
        selectArticle: (state, action:PayloadAction<ArticleSliceType>) => {
            state.id = action.payload.id
        },
        storeArticle: (state, action:PayloadAction<ArticleSliceType>) => {
            state.article = action.payload.article
        },
        updateArticleTitle: (state, action:PayloadAction<string>) => {
            state.article.title = action.payload
        },
        updateArticleImage: (state, action:PayloadAction<string>) => {
            state.article.image = action.payload
        },
        updateArticleCategory: (state, action:PayloadAction<string>) => {
            state.article.category = action.payload
        },
        removeArticleCategory: (state) => {
            state.article.category = ''
        },
        updateArticleBody: (state, action:PayloadAction<string>) => {
            state.article.body = action.payload
        },
        updateArticleActiveStatus: (state, action:PayloadAction<boolean>) => {
          state.article.isActiveOnApp = action.payload  
        },
        clearArticle: (state) => {
            state.id      = ''
            state.article = { id: '' }
        },

        // Add New Article
        clearNewArticle: (state) => {
            state.newArticle = {
                title:    '',
                image:    '',
                category: '',
                body:     ''
            }
        },
        addNewArticleTitle: (state, action:PayloadAction<string>) => {
            state.newArticle.title = action.payload
        },
        addNewArticleImage: (state, action:PayloadAction<string>) => {
            state.newArticle.image = action.payload
        },
        addNewArticleCategory: (state, action: PayloadAction<string>) => {
            state.newArticle.category = action.payload
        },
        removeNewArticleCategory: (state) => {
            state.newArticle.category = ''
        },
        addNewArticleBody: (state, action:PayloadAction<string>) => {
            state.newArticle.body = action.payload
        },
    }
})

export const articleIdSelector  = (state: RootState) => state.article.id
export const articleSelector    = (state: RootState) => state.article.article
export const newArticleSelector = (state: RootState) => state.article.newArticle
export const {
    selectArticle,
    storeArticle,
    updateArticleTitle,
    updateArticleImage,
    updateArticleCategory,
    removeArticleCategory,
    updateArticleBody,
    updateArticleActiveStatus,
    clearArticle,

    clearNewArticle,
    addNewArticleTitle,
    addNewArticleImage,
    addNewArticleCategory,
    removeNewArticleCategory,
    addNewArticleBody,
} = articleSlice.actions
export default articleSlice.reducer