import { useState } from 'react'
import { InputChangeEventHandler, RFC } from '../../../types/propTypes'
import { useLazyGetPresignedUploadUrlQuery } from '../../../services/FileService'
import axios from 'axios'
import { HTKAsyncImage, HTKAsyncVideo, HTKButton } from '../atoms'
import { InstallIcon, TrainingIcon } from '../../../assets/icons/icons'

type HTKUploadInputProps = {
  type: 'image' | 'video'
  source: string
  onUpload: (file: string | undefined) => void
  error?: string
}

const HTKUploadInput: RFC<HTKUploadInputProps> = ({
  type,
  source,
  onUpload,
  error
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [loaded, setLoaded] = useState(0)
  const [isUploadError, setIsUploadError] = useState(false)
  const [getPresignedUpload] = useLazyGetPresignedUploadUrlQuery()

  const handleUpload: InputChangeEventHandler = async ({ target }) => {
    try {
      onUpload('')
      setIsUploadError(false)
      setIsUploading(true)
      const files = target.files
      if (!files) return
      const file = files[0]

      const { data } = await getPresignedUpload({
        imageKey: file.name
      })
      if (!data) return

      const uploading = await axios.put(data.url, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: e => {
          setLoaded(parseInt(((e.loaded * 100) / (e.total ?? 0)).toFixed()))
          if (e.loaded === e.total) setIsUploading(false)
        }
      })
      if (uploading.status !== 200) throw Error
      onUpload(data.imageKey)
    } catch (error) {
      setIsUploadError(true)
    } finally {
      setIsUploading(false)
    }
  }

  const renderThumbnail = () => {
    if (!source) {
      return (
        <div
          className={`rounded-lg shadow-md
          ${type === 'image'
              ? 'w-[9em] aspect-square'
              : 'w-[12em] aspect-video'}`}
        >
          {loaded > 0 && loaded < 100 ? (
            <div
              className='flex justify-center items-center h-full
              rounded-lg transition-all duration-200 cursor-no-drop
              font-Title font-bold '
            >
              {loaded}%
            </div>
          ) : (
            <TrainingIcon
              className='flex justify-center items-center h-full
              bg-HTKMiddleGrey rounded-lg hover:bg-HTKLightBlack
              cursor-pointer transition-all duration-200'
              width={'30'}
              height={'30'}
            />
          )}
        </div>
      )
    } else {
      return (
        <div
          className={`rounded-lg shadow-md
          ${type === 'image'
              ? 'w-[9em] aspect-square'
              : 'w-[12em] aspect-video'}`}
        >
          {type === 'image' && (
            <HTKAsyncImage fileName={source} />
          )}
          {type === 'video' && (
            <HTKAsyncVideo fileName={source} />
          )}
        </div>
      )
    }
  }

  return (
    <>
      <div className='w-full flex items-center my-2'>
        {renderThumbnail()}
        <div className='flex items-center relative ml-3'>
          <input
            type='file'
            accept={`${type}/*`}
            className='absolute inset-0 opacity-0 z-50 cursor-pointer file:cursor-pointer'
            onChange={handleUpload}
            disabled={isUploading}
          />
          <HTKButton
            text='Upload'
            className='presigned-url-button'
            icon={<InstallIcon width={'24'} height={'24'} className='fill-HTKWhite' />}
            onSubmit={() => { }}
          />
          {error && <p className='ml-1 mt-1 text-xs text-red-600'  >{error}</p>}        </div>
      </div>
      {isUploadError && (
        <div className='ml-2 text-HTKRed/80'>
          Failed to upload the file
        </div>
      )}
    </>
  )
}

export default HTKUploadInput