import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { useCreateAnnouncementMutation } from "../../../services/AnnouncementService";
import { addNewAnnouncementBody, addNewAnnouncementImage, addNewAnnouncementLink, addNewAnnouncementTitle, newAnnouncementSelector, removeNewAnnouncementProgram, selectAnnouncement } from "../../../features/Announcement/announcementSlice";
import { addAnnouncement } from "../../../features/Announcement/announcementsSlice";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import { Dialog } from "@headlessui/react";
import { CloseIcon } from "../../../assets/icons/icons";
import asyncTimeout from "../../../utils/asyncTimeout";
import { HTKButton, HTKTextArea, HTKTextInput, HTKTitleBlock, HTKUploadInput } from "../../atoms/atoms";
import AnnouncementCourseField from "../Fields/AnnouncementCourseField";
import { useState } from "react";

const CreateAnnouncementModal = () => {
	const { title, link, image, body, courseId } = useHTKSelector(newAnnouncementSelector);
	const dispatch = useHTKDispatch();
	const isCreateButtonDisabled = !title || !image || !body || !link || !courseId;

	const [createAnnouncement] = useCreateAnnouncementMutation();

	const createNewAnnouncement = async () => {
		try {
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Creating New Announcement" }));
			const [data] = await Promise.all([createAnnouncement({ title, link, image, body, courseId }).unwrap(), asyncTimeout(2000)]);
			if (!data) throw Error;
			dispatch(addAnnouncement(data));
			dispatch(selectAnnouncement({ id: data.id, announcement: data }));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "ANNOUNCEMENT CREATED",
					body: "You have successfully created a new announcement!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to create announcement",
					body: "Please check your input details and try again.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	return (
		<>
			<div className="min-w-[500px] max-w-[600px] py-3 px-6">
				<Dialog.Title className="flex items-center justify-between">
					<div>
						<p className="font-Title font-extrabold text-xl">NEW ANNOUNCEMENT</p>
						<p className="text-HTKBlack/80">Let’s create a new announcement.</p>
					</div>
					<CloseIcon width={"30"} height={"30"} className="cursor-pointer" onClick={() => dispatch(closeModal())} />
				</Dialog.Title>
				<hr className="htk-border my-2" />
				<div className="max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]">
					<HTKTitleBlock title="ANNOUNCEMENT TITLE" description="Give your announcement a unique title. This will be the main title shown to users on the app." isRequired={!title} />
					<HTKTextInput name="title" value={title} placeholder="Title" onChange={(text) => dispatch(addNewAnnouncementTitle(text))} />
					<HTKTitleBlock title="ANNOUNCEMENT LINK" description="Add a link to this announcement that users can click on and follow through." className="mt-6" isRequired={!link} />
					<HTKTextInput name="link" value={link} placeholder="Link" onChange={(text) => dispatch(addNewAnnouncementLink(text))} />
					<HTKTitleBlock title="ANNOUNCEMENT PROGRAM" description="Add a program to this announcement." className="mt-6" isRequired={!title} />
					<AnnouncementCourseField courseTitle={courseId} title="CREATE_ANNOUNCEMENT" onDelete={() => dispatch(removeNewAnnouncementProgram())} />
					<HTKTitleBlock title="ANNOUNCEMENT IMAGE" description="Add an image to this announcement." className="mt-6" isRequired={!image} />
					<HTKUploadInput type="image" source={image ?? ""} onUpload={(image) => dispatch(addNewAnnouncementImage(image ?? ""))} />
					<HTKTitleBlock title="ANNOUNCEMENT CONTENT" description="This is where the main content of the announcement is placed in." className="mt-6" isRequired={!body} />
					<HTKTextArea text={body ?? ""} onChange={(text) => dispatch(addNewAnnouncementBody(text))} />
				</div>
			</div>
			<div className="sticky bottom-0 w-full h-12 flex items-center px-6">
				<HTKButton text="Create Announcement" isLoading={false} disabled={isCreateButtonDisabled} className={isCreateButtonDisabled ? `disabled-item-button` : `create-item-button`} onSubmit={createNewAnnouncement} />
				<HTKButton text="Cancel" className="cancel-item-button" onSubmit={() => dispatch(closeModal())} />
			</div>
		</>
	);
};

export default CreateAnnouncementModal;
