import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { EXERCISE_TYPES } from "../../../assets/data/enums";
import { InitialExerciseGroupState } from "../../../assets/data/initialState";
import { Exercise, GroupExercise, Set } from "../../../types/stateTypes";
import { v1 as uuid } from "uuid";

export type ExerciseGroupSliceType = {
	exerciseGroup: GroupExercise;
	newExerciseGroup: GroupExercise;
};

export type UpdateExerciseType = {
	id: string;
	type: EXERCISE_TYPES | undefined;
};

export type UpdateExerciseSetType = {
	repetitions?: string;
	oneRmWeight?: number;
	set: Set;
};

export const exerciseGroupSlice = createSlice({
	name: "exerciseGroup",
	initialState: InitialExerciseGroupState,
	reducers: {
		clearExerciseGroup: (state) => {
			state.exerciseGroup = {
				createdAt: new Date(),
				execution: "",
				executionDescription: "",
				exerciseInExerciseGroups: [],
				id: "",
				updatedAt: new Date(),
				workoutId: "",
				index: 0,
			};
			state.newExerciseGroup = {
				createdAt: new Date(),
				execution: "",
				executionDescription: "",
				exerciseInExerciseGroups: [],
				id: "",
				updatedAt: new Date(),
				workoutId: "",
				index: 0,
			};
		},
		setExerciseGroup: (state, action: PayloadAction<GroupExercise>) => {
			const sortedGroupExercise = action.payload.exerciseInExerciseGroups.map((exercise, index) => {
				return {
					...exercise,
					sets: exercise.sets.slice().sort((a, b) => a.index - b.index),
				};
			});
			const newGroupExerciseObj = {
				...action.payload,
				exerciseInExerciseGroups: sortedGroupExercise,
			};
			state.exerciseGroup = newGroupExerciseObj;
			state.newExerciseGroup = newGroupExerciseObj;
		},
		addSuperSetExercise: (state, action: PayloadAction<Exercise[]>) => {
			action.payload.forEach((exercise) => {
				state.newExerciseGroup.exerciseInExerciseGroups.push({
					createdAt: new Date(),
					exercise: exercise,
					exerciseId: exercise.id,
					groupExerciseId: state.newExerciseGroup.id,
					id: uuid(),
					sets: [],
					type: EXERCISE_TYPES.CIRCUIT,
					updatedAt: new Date(),
				});
			});
		},
		removeSuperSetWithId: (state, action: PayloadAction<string>) => {
			const filteredExerciseGroup = state.newExerciseGroup.exerciseInExerciseGroups.filter((exercise) => exercise.id !== action.payload);
			state.newExerciseGroup.exerciseInExerciseGroups = filteredExerciseGroup;
		},
		swapExercise: (state, action: PayloadAction<Exercise>) => {
			const storedExerciseInExerciseGroup = state.newExerciseGroup.exerciseInExerciseGroups[0];
			const newExerciseInExerciseGroupObj = {
				...storedExerciseInExerciseGroup,
				updatedAt: new Date(),
				exerciseId: action.payload.id,
				exercise: action.payload,
			};
			const newExerciseGroupObj = {
				...state.newExerciseGroup,
				exerciseInExerciseGroups: [newExerciseInExerciseGroupObj],
			};
			state.newExerciseGroup = newExerciseGroupObj;
		},

		// New Exercise Group
		updateNewExerciseType: (state, action: PayloadAction<UpdateExerciseType>) => {
			const originalStyle = state.exerciseGroup.exerciseInExerciseGroups.find((group) => {
				if (group.id === action.payload.id && action.payload.type !== undefined) return group;
			});

			state.newExerciseGroup.exerciseInExerciseGroups.forEach((group, index) => {
				if (group.id === action.payload.id && action.payload.type !== undefined) {
					state.newExerciseGroup.exerciseInExerciseGroups[index].type = action.payload.type;
					if (action.payload.type === originalStyle?.type) {
						state.newExerciseGroup.exerciseInExerciseGroups[index].sets = state.exerciseGroup.exerciseInExerciseGroups[index].sets;
					} else {
						state.newExerciseGroup.exerciseInExerciseGroups[index].sets = [];
					}
				}
			});
		},
		updateNewExerciseExecutions: (state, action: PayloadAction<string>) => {
			state.newExerciseGroup.execution = action.payload;
		},
		updateNewExerciseExecutionDescription: (state, action: PayloadAction<string>) => {
			state.newExerciseGroup.executionDescription = action.payload;
		},
		addNewExerciseSet: (state, action: PayloadAction<number>) => {
			const setId = uuid();
			const setIndex = state.newExerciseGroup.exerciseInExerciseGroups[action.payload].sets.length + 1;

			state.newExerciseGroup.exerciseInExerciseGroups[action.payload].sets.push({
				id: setId,
				createdAt: new Date(),
				updatedAt: new Date(),
				index: setIndex,
				title: `S${setIndex}`,
				repetitions: "0",
				oneRmWeight: 0,
				exerciseInGroupExerciseId: state.newExerciseGroup.exerciseInExerciseGroups[action.payload].id,
			});
		},
		deleteExerciseSet: (state, action: PayloadAction<Set>) => {
			state.newExerciseGroup.exerciseInExerciseGroups.forEach((exercise, exercise_index) => {
				const filteredExerciseSets = exercise.sets.filter((set) => set.id !== action.payload.id);
				const fixedSetIndex = filteredExerciseSets.map((set, set_index) => {
					return {
						...set,
						index: set_index + 1,
						title: `S${set_index + 1}`,
					};
				});
				state.newExerciseGroup.exerciseInExerciseGroups[exercise_index].sets = fixedSetIndex;
			});
		},
		updateExerciseRepetitions: (state, action: PayloadAction<UpdateExerciseSetType>) => {
			state.newExerciseGroup.exerciseInExerciseGroups.forEach((exercise, exercise_index) => {
				exercise.sets.forEach((set, set_index) => {
					if (set.id === action.payload.set.id) {
						state.newExerciseGroup.exerciseInExerciseGroups[exercise_index].sets[set_index].repetitions = action.payload.repetitions ?? "";
					}
				});
			});
		},
		updateExerciseOneRmWeight: (state, action: PayloadAction<UpdateExerciseSetType>) => {
			state.newExerciseGroup.exerciseInExerciseGroups.forEach((exercise, exercise_index) => {
				exercise.sets.forEach((set, set_index) => {
					if (set.id === action.payload.set.id) {
						state.newExerciseGroup.exerciseInExerciseGroups[exercise_index].sets[set_index].oneRmWeight = action.payload.oneRmWeight ?? 0;
					}
				});
			});
		},
	},
});
export const exerciseGroupIdSelector = (state: RootState) => state.exerciseGroup.exerciseGroup.id;
export const exerciseGroupSelector = (state: RootState) => state.exerciseGroup.exerciseGroup;
export const newExerciseGroupSelector = (state: RootState) => state.exerciseGroup.newExerciseGroup;
export const {
	clearExerciseGroup,
	setExerciseGroup,
	addSuperSetExercise,
	removeSuperSetWithId,
	swapExercise,

	updateNewExerciseType,
	updateNewExerciseExecutions,
	updateNewExerciseExecutionDescription,
	addNewExerciseSet,
	deleteExerciseSet,
	updateExerciseRepetitions,
	updateExerciseOneRmWeight,
} = exerciseGroupSlice.actions;
export default exerciseGroupSlice.reducer;
