import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { InitialModalState } from "../../assets/data/initialState";
import { Modal } from "../../types/propTypes";

type ModalPayload = Omit<Modal, "isDisplayed">;

export const modalSlice = createSlice({
  name: "modal",
  initialState: InitialModalState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalPayload>) => {
      state.isDisplayed = true;
      state.modalType = action.payload.modalType;
      state.title = action.payload.title;
      state.body = action.payload.body;
      state.deleteId = action.payload.deleteId;
      state.dayId = action.payload.dayId;
      state.deleteFn = action.payload.deleteFn;
      state.addItemFn = action.payload.addItemFn;
      state.day = action.payload.day;
    },
    closeModal: (state) => {
      state.isDisplayed = false;
    },
  },
});

export const modalSelector = (state: RootState) => state.modal;
export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
