import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialAnnouncementsState } from '../../assets/data/initialState'
import { Announcement } from '../../types/stateTypes'

export type AnnouncementsSliceType = {
    annoucements: Announcement[]
}

export const announcementsSlice = createSlice({
    name: 'announcements',
    initialState: InitialAnnouncementsState,
    reducers: {
        clearAnnouncements: (state) => {
            state.annoucements = []
        },
        setInitialAnnouncements: (state, action:PayloadAction<AnnouncementsSliceType>) => {
            state.annoucements = action.payload.annoucements
        },
        setAnnouncements: (state, action:PayloadAction<AnnouncementsSliceType>) => {
            const { annoucements: storedAnnouncements } = state
            const { annoucements: newAnnouncements } = action.payload
            const announcementIds = storedAnnouncements.map(announcement => announcement.id)
            const filteredDuplicates = newAnnouncements.filter(announcement => !announcementIds.includes(announcement.id))
            filteredDuplicates.map(announcement => state.annoucements.push(announcement))
        },
        addAnnouncement: (state, action:PayloadAction<Announcement>) => {
            state.annoucements.unshift(action.payload)
        },
        updateAnnouncementInfo: (state, action:PayloadAction<Announcement>) => {
            const filteredAnnouncements = state.annoucements.filter(announcement => announcement.id !== action.payload.id)
            state.annoucements = [ action.payload, ...filteredAnnouncements ]
        },
        updateAnnouncementActiveStatus: (state, action:PayloadAction<Announcement>) => {
            const filteredAnnouncements = state.annoucements.filter(announcement => announcement.id !== action.payload.id)
            const deactivatedAnnouncementsObj = filteredAnnouncements.map(announcement => {
                return {
                    ...announcement,
                    isActiveOnApp: false
                }
            })
            state.annoucements = [ action.payload, ...deactivatedAnnouncementsObj ]
        },
        deleteAnnouncementWithId: (state, action:PayloadAction<string>) => {
            const filteredAnnouncements = state.annoucements.filter(announcement => announcement.id !== action.payload)
            state.annoucements = filteredAnnouncements
        }
    }
})

export const announcementsSelector = (state: RootState) => state.announcements.annoucements
export const {
    clearAnnouncements,
    setInitialAnnouncements,
    setAnnouncements,
    addAnnouncement,
    updateAnnouncementInfo,
    updateAnnouncementActiveStatus,
    deleteAnnouncementWithId
} = announcementsSlice.actions
export default announcementsSlice.reducer