import { RFC } from '../../../types/propTypes'
import { Tab } from '@headlessui/react'

type HTKMeasurementTabsProps = {
  selectedMesurement: string
  setMeasurementTab:  (tab: string) => void
}

const HTKMeasurementTabs:RFC<HTKMeasurementTabsProps> = ({
  selectedMesurement,
  setMeasurementTab,
}) => {
  
  return (
    <div className='my-4'>
      <Tab.Group>
        <Tab.List className='flex border-solid border-[1px] border-HTKBorder rounded-[4px]'>
          <Tab
            className={`w-full flex flex-col items-center justify-center hover:bg-HTKBorder
            ${selectedMesurement === 'Metric'
              ? 'bg-HTKBlack text-HTKWhite hover:bg-HTKBlack' 
              : 'bg-HTKMiddleGrey'} outline-none
            transition-all duration-300 py-1 rounded-l-[4px]`}
            onClick={() => setMeasurementTab('Metric')}
          >
            <p className='font-Title font-bold text-sm'>
              METRIC
            </p>
            <p className='text-xs text-HTKGreen font-medium'>
              Kilograms, Kilojoules
            </p>
          </Tab>
          <Tab
            className={`w-full flex flex-col items-center justify-center hover:bg-HTKBorder
            ${selectedMesurement === 'Imperial'
              ? 'bg-HTKBlack text-HTKWhite hover:bg-HTKBlack' 
              : 'bg-HTKMiddleGrey'} outline-none
            transition-all duration-300 py-1 rounded-r-[4px]`}
            onClick={() => setMeasurementTab('Imperial')}
          >
            <p className='font-Title font-bold text-sm'>
              IMPERIAL
            </p>
            <p className='text-xs text-HTKGreen font-medium'>
              Pounds, Calories
            </p>
          </Tab>
        </Tab.List>
      </Tab.Group>
    </div>
  )
}

export default HTKMeasurementTabs