import { RFC } from '../../../types/propTypes'
import { HTKButton } from '../atoms'

type HTKSectionTitleBlockProps = {
    title:     string
    btnTitle?: string
    btnFn?:    () => void
}

const HTKSectionTitleBlock:RFC<HTKSectionTitleBlockProps> = ({
    title,
    btnTitle,
    btnFn
}) => {
    return (
        <div 
            className='w-full bg-HTKLightGrey border-b-[1px] border-solid border-HTKBorder
            flex justify-between items-center'
        >
            <p className='px-3 py-1 font-Title font-semibold text-sm text-HTKBlack/80'>
                { title }
            </p>
            {btnTitle && btnFn ? (
                <HTKButton 
                    text={btnTitle}
                    className='save-details'
                    onSubmit={btnFn}
                />
            ) : null}
        </div>
    )
}

export default HTKSectionTitleBlock