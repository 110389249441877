import { RFC } from "../../../types/propTypes";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { useRemoveDailyWorkoutMutation } from "../../../services/ProgramService";
import { ERROR_MESSAGES, MODAL_TYPES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES, WORKOUT_TYPES } from "../../../assets/data/enums";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BinIcon, SwapIcon } from "../../../assets/icons/icons";
import { openModal } from "../../../features/Cores/modalSlice";
import { closeSnackbar, openSnackbar } from "../../../features/Cores/snackbarSlice";
import { removeDailyWorkout } from "../../../features/Training/Programs/weekSlice";
import { Program } from "../../../types/CourseTypes";
import moment from "moment";
import { TitleCard } from "../../atoms/atoms";
import { BiChevronDown } from "react-icons/bi";
import { BiChevronUp } from "react-icons/bi";
import ChevronUpIcon from "../../../assets/icons/svg/chevronUpIcon";
import ChevronDownIcon from "../../../assets/icons/svg/chevronDownIcon";
import { useRemoveSingleProgramFromCourseMutation } from "../../../services/CourseService";
import { courseProgramSelector } from "../../../features/Training/Courses/courseSlice";

type ProgramPopoverProps = {
	program: Program;
	programLength: string | undefined;
	isActive: boolean | undefined;
	onPress: (program: Program) => void;
	onDeletePress: (program: Program) => void;
	onUpPress: (program: Program) => void;
	onDownPress: (program: Program) => void;
	hideUpButton: boolean;
	hideDownButton: boolean;
};

const ProgramPopover: RFC<ProgramPopoverProps> = ({ program, programLength, isActive, onPress, onDeletePress, onUpPress, onDownPress, hideDownButton, hideUpButton }) => {
	const dispatch = useHTKDispatch();

	return (
		<Popover className="relative">
			{({ open, close }) => (
				<>
					<Popover.Button
						className={`  w-[100%] items-start justify-start cursor-pointer truncate outline-none 
                                     hover:bg-HTKMiddleGrey transition-all duration-200
                                     ${open ? "bg-HTKDarkGrey hover:bg-HTKDarkGrey" : "bg-HTKWhite hover:bg-HTKMiddleGrey"}`}
					>
						<TitleCard isSelected={false} onClick={() => onPress(program)} className="">
							<div className="w-full truncate">
								<p className="font-Title uppercase font-bold text-lg truncate text-start ">{program.internalTitle}</p>
								<div className="flex items-center text-sm">
									<p className="font-semibold text-inherit/80 mr-5">{programLength} Program</p>
									<p className="font-semibold text-inherit/80">Created</p>
									<p className="pl-2 text-inherit/80">{moment(program.createdAt).format("DD MMM YYYY")}</p>
								</div>
							</div>
						</TitleCard>
					</Popover.Button>
					<Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
						<Popover.Panel
							className="w-fit flex justify-between items-center mt-1
                        bg-HTKBorder 
                        rounded-[10px] px-2 py-1 mx-auto shadow-sm drop-shadow-sm before:box-corner"
						>
							{!hideUpButton && (
								<ChevronUpIcon
									width={"30"}
									height={"30"}
									className="cursor-pointer"
									onClick={() => {
										onUpPress(program);
										close();
									}}
								/>
							)}
							{!hideDownButton && (
								<ChevronDownIcon
									width={"30"}
									height={"30"}
									className="cursor-pointer"
									onClick={() => {
										onDownPress(program);
										close();
									}}
								/>
							)}
							<BinIcon
								width={"30"}
								height={"30"}
								className="cursor-pointer"
								onClick={() => {
									close();
									onDeletePress(program);
								}}
							/>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};

export default ProgramPopover;
