import { HTKApi } from "../app/api";
import { AddWorkoutExerciseArgs, AddWorkoutExerciseData, CreateWorkoutArgs, PaginationPayload, PaginationPayloadWithType, UpdateExerciseDetailArgs, UpdateWorkoutArgs } from "../types/serviceTypes";
import { GroupExercise, Workout } from "../types/stateTypes";
import { workoutTypeFilter } from "../utils/filterListFormatter";

export const workoutApi = HTKApi.injectEndpoints({
	endpoints: (builder) => ({
		getProgramWorkouts: builder.query<Workout[], PaginationPayload>({
			query: ({ search, skip, take }) => ({
				url: "/workouts/search",
				params: { search, skip, take },
			}),
			providesTags: ["Program"],
		}),
		getWorkouts: builder.query<Workout[], PaginationPayloadWithType>({
			query: ({ search, skip, take, type }) => ({
				url: `/workouts/search/type`,
				params: { search, skip, take, type: workoutTypeFilter(type) },
			}),
			providesTags: ["Workout"],
		}),
		getWorkout: builder.query<Workout, string>({
			query: (workoutId) => `/workouts/${workoutId}`,
			providesTags: ["Workout"],
		}),
		createWorkout: builder.mutation<Workout, CreateWorkoutArgs>({
			query: ({ internalTitle, title, type, circuitRotations, description }) => ({
				url: "/workouts",
				method: "POST",
				body: {
					internalTitle,
					title,
					type,
					circuitRotations,
					description,
				},
			}),
			invalidatesTags: ["Workout"],
		}),
		updateWorkout: builder.mutation<Workout, UpdateWorkoutArgs>({
			query: ({ id, internalTitle, title, type, circuitRotations, description }) => ({
				url: `/workouts/${id}`,
				method: "PUT",
				body: {
					internalTitle,
					title,
					type,
					circuitRotations,
					description,
				},
			}),
			invalidatesTags: ["Workout"],
		}),
		updateExerciseDetails: builder.mutation<GroupExercise, UpdateExerciseDetailArgs>({
			query: ({ groupExerciseId, groupExercise }) => {
				const groupExercises = groupExercise.exerciseInExerciseGroups.map((exercise) => {
					const newSets = exercise.sets.map((set) => {
						return {
							index: set.index,
							title: set.title,
							repetitions: set.repetitions,
							oneRmWeight: set.oneRmWeight,
						};
					});
					return {
						id: exercise.exerciseId,
						type: exercise.type,
						set: newSets,
					};
				});
				const newGroupExerciseObj = {
					execution: groupExercise.execution,
					executionDescription: groupExercise.executionDescription,
					exerciseToExerciseGroup: [
						{
							exerciseIds: groupExercises,
						},
					],
				};
				return {
					url: `/workouts/exerciseGroups/${groupExerciseId}/update-group-exercise`,
					method: "PUT",
					body: { ...newGroupExerciseObj },
				};
			},
			invalidatesTags: ["Workout"],
		}),
		addGroupExercises: builder.mutation<AddWorkoutExerciseData, AddWorkoutExerciseArgs>({
			query: ({ workoutId, exercises, presentExercises }) => {
				const indexValue = presentExercises?.length ? presentExercises[presentExercises.length - 1].index + 1 : 0;
				const newExerciseObj = exercises.map((exercise, index) => {
					return {
						id: exercise.id,
						type: "Circuit",
						execution: "Exercise Execution",
						executionDescription: "Exercise Execution Description",
						index: indexValue + index,
					};
				});
				return {
					url: `/workouts/${workoutId}/add-group-exercise`,
					method: "POST",
					body: {
						exerciseIds: newExerciseObj,
					},
				};
			},
			invalidatesTags: ["Workout"],
		}),
		deleteWorkout: builder.mutation<Workout, string>({
			query: (workoutId) => ({
				url: `/workouts/${workoutId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Workout"],
		}),
		deleteWorkoutGroup: builder.mutation<GroupExercise, string>({
			query: (groupExerciseId) => ({
				url: `/workouts/exerciseGroups/${groupExerciseId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Workout"],
		}),
	}),
});

export const { useLazyGetProgramWorkoutsQuery, useLazyGetWorkoutsQuery, useGetWorkoutQuery, useCreateWorkoutMutation, useUpdateWorkoutMutation, useUpdateExerciseDetailsMutation, useAddGroupExercisesMutation, useDeleteWorkoutMutation, useDeleteWorkoutGroupMutation } = workoutApi;
