import { HTKApi } from '../app/api'
import { PaginationPayload, UpdateRecipeArgs, UpdateCategory, CreateRecipeArgs } from '../types/serviceTypes'
import { Recipe, RecipeCategory } from '../types/stateTypes'

export const recipeApi = HTKApi.injectEndpoints({
    endpoints: (builder) => ({
        getRecipes: builder.query<Recipe[], PaginationPayload>({
            query: ({ search, skip, take }) => ({
                url: '/recipes/search/admin',
                params: { search, skip, take }
            }),
            providesTags: ['Recipe']
        }),
        getRecipe: builder.query<Recipe, string>({
            query: (recipeId) => `/recipes/${recipeId}`,
            providesTags: ['Recipe']
        }),
        getRecipeCategories: builder.query<RecipeCategory[], PaginationPayload>({
            query: ({ search, skip, take }) => ({
                url: '/recipes/categories/admin',
                params: { search, skip, take }
            }),
            providesTags: ['RecipeCategory']
        }),
        createRecipeCategory: builder.mutation<RecipeCategory, string>({
            query: (title) => ({
                url: '/recipes/categories/create',
                method: 'POST',
                body: { title }
            })
        }),
        updateRecipeCategory: builder.mutation<RecipeCategory, UpdateCategory>({
            query: ({ categoryId, title }) => ({
                url: `/recipes/categories/${categoryId}/admin`,
                method: 'POST',
                body: { title }
            }),
            invalidatesTags: ['RecipeCategory']
        }),
        deleteRecipeCategory: builder.mutation<RecipeCategory, string>({
            query: (categoryId) => ({
                url: `/recipes/categories/${categoryId}/admin`,
                method: 'DELETE',
            }),
            invalidatesTags: ['RecipeCategory']
        }),
        createRecipe: builder.mutation<Recipe, CreateRecipeArgs>({
            query: ({
                title, image, carbs, fats, protein, description,
                recipeCategory, intakeLevel, recipeIngredient, recipeStep
            }) => {
                const recipeCategories = recipeCategory?.map(category => category.title)
                const recipeIngredients = recipeIngredient.map(ingredient => {
                    return {
                        name:   ingredient.name,
                        amount: ingredient.amount,
                        index : ingredient.index
                    }
                })
                const recipeSteps = recipeStep.map((step, index) => {
                    return {
                        title:       step.title,
                        description: step.description,
                        stepNumber:  index
                    }
                })
                return ({
                    url: '/recipes',
                    method: 'POST',
                    body: {
                        title, image, intakeLevel, 
                        carbs, fats, protein, description, 
                        isActiveOnApp: false,
                        recipeCategories,
                        recipeIngredients, 
                        recipeSteps,
                    }
                })
            },
            invalidatesTags: ['Recipe']
        }),
        updateRecipe: builder.mutation<Recipe, UpdateRecipeArgs>({
            query: ({
                id, title, image, carbs, fats, protein, description, isActiveOnApp,
                recipeCategory, intakeLevel, recipeIngredient, recipeStep
            }) => {
                const recipeCategories = recipeCategory?.map(category => category.title)
                const recipeIngredients = recipeIngredient?.map(ingredient => {
                    return {
                        name:   ingredient.name,
                        amount: ingredient.amount,
                        index : ingredient.index
                    }
                })
                const recipeSteps = recipeStep?.map((step, index) => {
                    return {
                        title:       step.title,
                        description: step.description,
                        stepNumber:  index
                    }
                })
                return ({
                    url: `/recipes/${id}`,
                    method: 'PUT',
                    body: {
                        title, image, intakeLevel, carbs, fats, 
                        protein, description, isActiveOnApp,
                        recipeCategories,
                        recipeIngredients,
                        recipeSteps,
                    }
                })
            },
            invalidatesTags: ['Recipe']
        }),
        deleteRecipe: builder.mutation<Recipe, string>({
            query: (recipeId) => ({
                url: `/recipes/${recipeId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Recipe']
        })
    })
})

export const {
    useLazyGetRecipesQuery,
    useGetRecipeQuery,
    useLazyGetRecipeCategoriesQuery,
    useCreateRecipeCategoryMutation,
    useUpdateRecipeCategoryMutation,
    useDeleteRecipeCategoryMutation,
    useCreateRecipeMutation,
    useUpdateRecipeMutation,
    useDeleteRecipeMutation,
} = recipeApi