import { RFC } from "../../../types/propTypes";
import { useHTKDispatch } from "../../../app/hooks";
import { exerciseTypes } from "../../../assets/data/arrays";
import { removeSuperSetWithId, updateNewExerciseExecutionDescription, updateNewExerciseExecutions, updateNewExerciseType } from "../../../features/Training/Workouts/exerciseGroupSlice";
import { ExerciseToGroupExercise } from "../../../types/stateTypes";
import { exerciseNumberConverter, exerciseTypeConverter, exerciseTypeReverseConverter } from "../../../utils/filterListFormatter";
import { HTKButton, HTKDetailSectionTitleBlock, HTKTabListInput, HTKTextArea, HTKTextInput } from "../../atoms/atoms";
import { DetailSkeleton, ExerciseSetField, ExerciseTitleBlock } from "../molecules";
import HTKTabInput from "../../atoms/inputField/HTKTabInput";

type SuperSetExerciseFieldProps = {
	isLoading: boolean;
	execution: string;
	executionDescription: string;
	exerciseGroupDetails: ExerciseToGroupExercise[];
};

const SuperSetExerciseField: RFC<SuperSetExerciseFieldProps> = ({ isLoading, exerciseGroupDetails, execution, executionDescription }) => {
	const dispatch = useHTKDispatch();

	if (isLoading) return <DetailSkeleton />;

	return (
		<div className="w-full h-screen overflow-y-auto overflow-x-hidden pb-[15em]">
			<ExerciseTitleBlock groupExercises={exerciseGroupDetails} />
			<div className="w-full px-3 py-2">
				<HTKDetailSectionTitleBlock title="EXERCISE EXECUTIONS" description="Outline what the user needs to complete in this exercise. Include, work time, rest, and timing styles." />
				<HTKTextInput name="execution" placeholder="Exercise Executions" value={execution} onChange={(text) => dispatch(updateNewExerciseExecutions(text))} />
				<HTKDetailSectionTitleBlock title="EXECUTION DESCRIPTION" description="Provide some extra details about this exercise. This will help the user know how they need to execute this movement correctly." />
				<HTKTextArea text={executionDescription} onChange={(text) => dispatch(updateNewExerciseExecutionDescription(text))} />
				{exerciseGroupDetails.map((group, index) => (
					<div key={index} className="w-full mt-8">
						<div
							className="w-full flex items-center bg-HTKGrey
                            px-2 py-1 justify-between mb-4"
						>
							<div className="flex items-center">
								<p className="font-Title font-semibold">EXERCISE {exerciseNumberConverter(index + 1)}</p>
								<p className="ml-3 text-HTKWhite uppercase font-Title font-semibold">{group.exercise.title}</p>
							</div>
							<HTKButton text="REMOVE SUPERSET" className="remove-superset" onSubmit={() => dispatch(removeSuperSetWithId(group.id))} />
						</div>
						<HTKTabInput
							title={"EXERCISE STYLE"}
							description={"Outline what the user needs to complete in this exercise. Include, work time, rest, and timing styles."}
							list={exerciseTypes}
							selectedItem={exerciseTypeConverter(group.type)}
							onSelect={(type) =>
								dispatch(
									updateNewExerciseType({
										id: group.id,
										type: exerciseTypeReverseConverter(type),
									})
								)
							}
							className={"px-3"}
						/>
						{group.type !== "Circuit" ? <ExerciseSetField setIndex={index} exerciseGroup={group} /> : null}
					</div>
				))}
			</div>
		</div>
	);
};

export default SuperSetExerciseField;
