import { HTKApi } from "../app/api";
import { LoginArgs, LoginData, UpdateAdminArgs } from "../types/serviceTypes";
import { AdminDetails } from "../types/stateTypes";

export const adminApi = HTKApi.injectEndpoints({
	endpoints: (builder) => ({
		adminLogin: builder.mutation<LoginData, LoginArgs>({
			query: ({ email, password }) => ({
				url: "/admin/login",
				method: "POST",
				body: {
					email,
					password,
				},
			}),
			invalidatesTags: ["Admin"],
		}),
		adminMe: builder.query<LoginData, void>({
			query: () => "/admin/me",
			providesTags: ["Admin"],
		}),
		updateAdminUnitOfMeasurement: builder.mutation<AdminDetails, UpdateAdminArgs>({
			query: ({ adminId, unitOfMeasurement }) => ({
				url: `/admin/${adminId}`,
				method: "PUT",
				body: {
					unitOfMeasurement,
				},
			}),
			invalidatesTags: ["Admin"],
		}),
	}),
});

export const { useAdminLoginMutation, useLazyAdminMeQuery, useUpdateAdminUnitOfMeasurementMutation } = adminApi;
