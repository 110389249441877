import { IoTrashBin } from "react-icons/io5";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { AddIcon, BinIcon } from "../../../assets/icons/icons";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { weekSelector } from "../../../features/Training/Programs/weekSlice";
import { HTKSectionTitleBlock } from "../../atoms/atoms";
import { WorkoutPopover } from "../molecules";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/Cores/overlaySlice";
import { useDeleteWeekMutation } from "../../../services/ProgramService";
import asyncTimeout from "../../../utils/asyncTimeout";

const ProgramWeekDetailField = () => {
  const weekState = useHTKSelector(weekSelector);
  const dispatch = useHTKDispatch();
  const [deleteWeek] = useDeleteWeekMutation();

  if (weekState.id === "")
    return (
      <div
        className="w-full h-[80%] flex items-center justify-center
    text-HTKBlack/80 font-Title text-lg"
      >
        Select a week to see details
      </div>
    );

  async function deleteSelectedWeek() {
    try {
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Deleting this week" }));
      const [data] = await Promise.all([
        deleteWeek({ weekId: weekState.id }).unwrap(),
        asyncTimeout(2000),
      ]);
      if (!data) throw Error;
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "WEEK DELETED",
          body: "You have successfully deleted the week!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to delete week",
          body: "Something went wrong. Please try again later.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  }

  return (
    <div>
      <div
        className="w-full bg-HTKLightGrey border-b-[1px] border-solid border-HTKBorder
            flex justify-between items-center"
      >
        <p className="px-3 py-1 font-Title font-semibold text-sm text-HTKBlack/80">
          {"PROGRAM WEEK CONTENTS"}
        </p>
        <div>
          <BinIcon onClick={deleteSelectedWeek} />
        </div>
      </div>
      <div className="h-full grid grid-cols-7">
        {weekState.day.map((day, index) => {
          return (
            <div
              key={index}
              className="relative border-[1px] border-solid border-HTKBorder"
            >
              <div
                className="w-full text-center uppercase font-semibold
                font-Title py-2 border-b-[2px] border-HTKBorder border-solid"
              >
                {day.dayName}
              </div>
              <div className="w-full h-full overflow-y-auto overflow-x-hidden">
                {day.dayWorkouts.map((dayWorkout, index) => (
                  <WorkoutPopover
                    key={index}
                    title={dayWorkout.workout.internalTitle}
                    type={dayWorkout.workout.type}
                    dayId={day.id}
                    workoutId={dayWorkout.workout.id}
                  />
                ))}
              </div>
              <div
                className="w-full sticky bottom-20
                flex items-center justify-center py-2"
              >
                <AddIcon
                  width={"34"}
                  height={"34"}
                  className="w-[34px] h-[34px] bg-HTKGreen rounded-[3px]
                  fill-HTKWhite cursor-pointer"
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: MODAL_TYPES.ADD_WORKOUT,
                        dayId: day.id,
                        day: day,
                      })
                    )
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgramWeekDetailField;
