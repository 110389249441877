import { RFC } from '../../../types/propTypes'

type HTKDetailSectionTitleBlockProps = {
    title:       string
    description: string
}

const HTKDetailSectionTitleBlock:RFC<HTKDetailSectionTitleBlockProps> = ({
    title,
    description
}) => {
    return (
        <>
            <p className='font-Title text-sm font-bold'>
                {title}
            </p>
            <p className='text-sm'>
                {description}
            </p>
        </>
    )
}

export default HTKDetailSectionTitleBlock