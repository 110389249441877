const successIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '60'} 
            height={height ?? '60'} 
            viewBox="0 0 60 60"
        >
            <g id="verified_black_24dp_1_" data-name="verified_black_24dp (1)" transform="translate(-0.265 -0.265)">
                <g id="Group_1351" data-name="Group 1351">
                <rect id="Rectangle_815" data-name="Rectangle 815" width="60" height="60" transform="translate(0.265 0.265)" fill="none"/>
                </g>
                <g id="Group_1353" data-name="Group 1353" transform="translate(2.522 3.783)">
                <g id="Group_1352" data-name="Group 1352">
                    <path id="Path_945" data-name="Path 945" d="M41.062,18.784l.2-2.144.454-4.918-4.792-1.084-2.119-.479L33.7,8.292,31.2,4.055,26.686,5.972l-1.992.857L22.7,5.972,18.187,4.03l-2.5,4.237-1.11,1.866-2.119.479L7.67,11.7l.454,4.893.2,2.144L6.913,20.373,3.66,24.106l3.253,3.707,1.412,1.639-.2,2.144L7.67,36.539l4.792,1.084,2.119.479,1.11,1.866,2.5,4.212,4.489-1.942,2.018-.832,1.992.857,4.489,1.942,2.5-4.237,1.11-1.866,2.119-.479,4.792-1.084-.454-4.918-.2-2.144,1.412-1.639L45.7,24.131l-3.253-3.707ZM19.877,36.035l-9.584-9.609,3.733-3.733,5.851,5.876,14.754-14.8L38.363,17.5Z" transform="translate(3.049 2.351)" fill="#FFFFFF" opacity="0.3"/>
                    <path id="Path_946" data-name="Path 946" d="M56.485,27.956,50.331,20.92l.857-9.306-9.1-2.068L37.318,1.5,28.743,5.182,20.168,1.5,15.4,9.545,6.3,11.588l.857,9.332L1,27.956l6.154,7.037L6.3,44.324l9.1,2.068,4.767,8.071,8.575-3.707,8.575,3.682,4.767-8.045,9.1-2.068-.857-9.306Zm-9.962,3.733-1.412,1.639.2,2.144.454,4.918-4.792,1.084-2.119.479-1.11,1.866-2.5,4.237L30.76,46.115l-2.018-.857-1.992.857-4.489,1.942-2.5-4.212-1.11-1.866L16.536,41.5l-4.792-1.084.454-4.943.2-2.144-1.412-1.639L7.734,27.982l3.253-3.733L12.4,22.61l-.227-2.169-.454-4.893,4.792-1.084,2.119-.479,1.11-1.866,2.5-4.237,4.489,1.942,2.018.857,1.992-.857,4.489-1.942,2.5,4.237,1.11,1.866,2.119.479,4.792,1.084-.454,4.918-.2,2.144L46.5,24.249l3.253,3.707Z" transform="translate(-1 -1.5)" fill="#129B00"/>
                    <path id="Path_947" data-name="Path 947" d="M15.874,22.7l-5.851-5.876L6.29,20.581l9.584,9.609L34.386,11.628,30.653,7.87Z" transform="translate(7.052 8.195)" fill="#129B00"/>
                </g>
                </g>
            </g>
        </svg>
      </div>
    )
  }
  
  export default successIcon