const ChevronDownIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
	return (
		<div className={className}>
			<svg xmlns="http://www.w3.org/2000/svg" width={width ?? "24"} height={height ?? "24"} viewBox="0 0 24 24" onClick={onClick}>
				<g id="chevron_right_black_24dp" transform="translate(24) rotate(90)">
					<path id="Path_892" data-name="Path 892" d="M0,0H24V24H0Z" fill="none" />
					<path id="Path_893" data-name="Path 893" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" />
				</g>
			</svg>
		</div>
	);
};

export default ChevronDownIcon;
