import { useGetActiveAnnouncementQuery } from '../../../services/AnnouncementService'
import { HTKAsyncImage, HTKLoading, HTKSearchTitleBlock } from '../../atoms/atoms'
import { motion } from 'framer-motion'
import moment from 'moment'

const ActiveAnnouncement = () => {

    const { data, isLoading, isError } = useGetActiveAnnouncementQuery()

    const cardAnimations = {
        initial:    { x: -150, opacity: 0, scale: 0 },
        animate:    { x: 0, opacity: 1, scale: 1 },
        exit:       { x: 150, opacity: 0 },
        transition: { stiffness: 80 }
    }

    const renderActiveAnnouncement = () => {
        if (isLoading) return (
            <div className='h-[4em] flex items-center justify-center'>
                <HTKLoading shape='circle'/>
            </div>
        )
        if (isError) return (
            <div className='h-[4em] flex items-center justify-center text-HTKRed text-center'>
                Something went wrong.<br/>
                Please try again later.
            </div>
        )
        if (!data) return (
            <div className='h-[4em] flex items-center justify-center text-sm px-20 text-center text-HTKBlack/80'>
                Set an announcement to ‘Display On App’ to have it appear to users.
            </div>
        )
        else return (
            <motion.div
                key='title-card'
                {...cardAnimations}
                className='flex flex-col p-2 border-HTKBorder border-b-[1px] 
                border-solid cursor-pointer transition-all duration-300 shadow-sm
              text-HTKWhite bg-HTKGrey hover:bg-HTKGrey'
            >
                <div className='flex items-center justify-between'>
                    <div className='min-w-[50px] h-[50px]'>
                        <HTKAsyncImage fileName={data.image} />
                    </div>
                    <div className='w-full pl-3'>
                        <p className='font-Title uppercase font-bold line-clamp-2'>
                            {data.title}
                        </p>
                        <div className='flex items-center text-sm'>
                            <p className='font-medium text-HTKGreen'>
                                Active On App
                            </p>
                            <p className='pl-2 text-inherit/80'>
                                {moment(data.createdAt).format('DD MMM YYYY')}
                            </p>
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    }

    return (
        <div className='w-[25em] bg-HTKWhite htk-container mb-3'>
            <HTKSearchTitleBlock
                title='ACTIVE ANNOUNCEMENT'
            />
            { renderActiveAnnouncement() }
        </div>
    )
}

export default ActiveAnnouncement