import { RFC } from '../../../types/propTypes'
import { AddIcon, BinIcon } from '../../../assets/icons/icons'
import { RecipeStep } from '../../../types/stateTypes'
import { HTKButton, HTKTextInput } from '../../atoms/atoms'
import { ValidationError } from 'yup'

type RecipeStepsFieldProps = {
  steps: RecipeStep[] | undefined
  onChangeTitle: (
    stepId: string,
    title:  string
  ) => void
  onChangeDescription: (
    stepId:      string,
    description: string
  ) => void
  onDelete: (stepId: string) => void
  onAdd:    () => void
  stepsErrors?: ValidationError[] | undefined
}

const RecipeStepsField:RFC<RecipeStepsFieldProps> = ({
  steps,
  onChangeTitle,
  onChangeDescription,
  onDelete,
  onAdd,
  stepsErrors
}) => {


  function getValidationErrorMessage(index: number) {
    if(!stepsErrors) return
    const stepsError = stepsErrors.find(error => error.path?.includes(`[${index}].description`));
    return stepsError ? stepsError.message : '';
  }


  return (
    <div className='w-full'>
      {steps?.map((step, index) => (
        <div
          key={index}
          className='flex items-start justify-between gap-1'
        >
          <div className='mt-3 px-4 font-semibold text-xl font-Title'>
            {index + 1}
          </div>
          <div className='w-full'>

            <textarea

              name='stepDescription'
              value={step.description}
              className='min-h-[5em] mt-3 w-full border-solid border-HTKBorder border-[1px]
              rounded-[3px] p-2 bg-HTKMiddleGrey font-medium outline-none'
              onChange={(e) => onChangeDescription(step.id, e.target.value)}

            />
                  <p className='ml-1 text-xs text-red-600'>{getValidationErrorMessage(index)}</p>
          </div>
          <BinIcon
            className='mt-3 px-5 cursor-pointer'
            width={'30'}
            height={'30'}
            onClick={() => onDelete(step.id)}
          />
        </div>
      ))}
      <HTKButton
        text='Add Step'
        icon={<AddIcon className='fill-HTKWhite' />}
        className='main-detail-button mx-auto my-3'
        onSubmit={() => onAdd()}
      />
    </div>
  )
}

export default RecipeStepsField