import { useEffect, useMemo, useState } from "react";
import { domAnimation, LazyMotion } from "framer-motion";
import { debounce } from "lodash";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import {
  closeModal,
  modalSelector,
  openModal,
} from "../../../features/Cores/modalSlice";
import {
  clearProgramWorkouts,
  programWorkoutsSelector,
  setInitialProgramWorkouts,
  setProgramWorkouts,
} from "../../../features/Training/Programs/programWorkoutsSlice";
import { useLazyGetProgramWorkoutsQuery } from "../../../services/WorkoutService";
import { DayWorkout, Workout } from "../../../types/stateTypes";
import {
  CardError,
  CardLoading,
  CardLogo,
  HTKButton,
  HTKSearchbar,
} from "../../atoms/atoms";
import { AddIcon, CloseIcon } from "../../../assets/icons/icons";
import { Dialog } from "@headlessui/react";
import InfiniteScroll from "react-infinite-scroller";
import { addNewProgramWorkouts } from "../../../features/Training/Programs/weekSlice";
import { useUpdateProgramWeekContentsMutation } from "../../../services/ProgramService";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/Cores/overlaySlice";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";

const AddWorkoutModal = () => {
  const [searchText, setSearchText] = useState("");
  const onChangeText = useMemo(
    () => debounce(setSearchText, 500),
    [setSearchText]
  );
  const take = 10;
  const [skip, setSkip] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedWorkouts, setSelectedWorkouts] = useState<Workout[]>([]);

  const programWorkoutsState = useHTKSelector(programWorkoutsSelector);
  const { dayId, day } = useHTKSelector(modalSelector);
  const dispatch = useHTKDispatch();

  const [getWorkouts, { isLoading, isError }] =
    useLazyGetProgramWorkoutsQuery();
  const [addNewWorkouts] = useUpdateProgramWeekContentsMutation();

  useEffect(() => {
    setSkip(0);
    loadMoreItems(10, 0);
  }, [searchText]);

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreItems(take, skip);
  };

  const loadMoreItems = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true);
    try {
      const items = await getWorkouts({
        take,
        skip,
        search: searchText,
      }).unwrap();

      if (!items.length && skip === 0) dispatch(clearProgramWorkouts());
      if (!items || !items.length) {
        setHasMore(false);
      } else {
        if (skip === 0) {
          dispatch(setInitialProgramWorkouts({ workouts: items }));
          setHasMore(true);
          setSkip(10);
        } else if (items.length < take) {
          dispatch(setProgramWorkouts({ workouts: items }));
          setHasMore(false);
        } else {
          dispatch(setProgramWorkouts({ workouts: items }));
          setHasMore(true);
          setSkip(skip + 10);
        }
      }

      setTimeout(() => {
        setIsLoadMore(false);
      }, 1000);
    } catch (error) {
      console.log({ error });
    }
  };

  const addWorkouts = async () => {
    try {
      if (!dayId) throw Error;
      dispatch(closeModal());
      const existingDayWorkouts = day?.dayWorkouts ?? [];
      dispatch(openOverlay({ text: "Adding Workouts" }));
      const dayWorkouts: DayWorkout[] = existingDayWorkouts
        .concat(
          selectedWorkouts.map((workout) => ({
            dayId,
            workoutId: workout.id,
            workout: workout,
          }))
        )
        .map((workout, index) => {
          return {
            ...workout,
            index,
          };
        });

      const [data] = await Promise.all([
        addNewWorkouts({ dayId, dayWorkouts: dayWorkouts }).unwrap(),
        asyncTimeout(1000),
      ]);
      if (!data) throw Error;
      dispatch(addNewProgramWorkouts({ dayId, dayWorkouts: dayWorkouts }));
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "WORKOUTS ADDED",
          body: "You have successfully added workouts!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to add workouts",
          body: "Something went wrong. Please try again later.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  const renderProgramWorkoutTitles = () => {
    if (isLoading) return <CardLogo />;
    if (isError || !dayId) return <CardError />;
    if (!programWorkoutsState.length)
      return (
        <div className="w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7">
          No Workout
        </div>
      );

    return (
      <LazyMotion features={domAnimation} key="workout-card">
        <div className="grid grid-cols-2 gap-x-[0.5em] px-2">
          {programWorkoutsState.map((workout, index) => {
            const isSelected = selectedWorkouts.find(
              (selected) => selected.id === workout.id
            );
            return (
              <div
                key={index}
                className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2"
                onClick={() =>
                  !isSelected &&
                  setSelectedWorkouts((prev) => [...prev, workout])
                }
              >
                <p
                  className={`${
                    isSelected?.id !== workout.id
                      ? "bg-HTKMiddleGrey text-HTKBlack"
                      : "bg-HTKBlack text-HTKWhite"
                  } w-full px-2 py-1
                  border-solid border-[1px] border-HTKBorder rounded-l-[3px]
                  font-semibold font-Title`}
                >
                  {workout.internalTitle}
                </p>
                <AddIcon
                  width={"30"}
                  height={"30"}
                  className={`${
                    !isSelected
                      ? "bg-HTKMiddleGrey fill-HTKBlack"
                      : "bg-HTKBlack fill-HTKWhite"
                  } py-[1px]
                  border-solid border-[1px] border-HTKBorder rounded-r-[3px]`}
                />
              </div>
            );
          })}
        </div>
      </LazyMotion>
    );
  };

  return (
    <div
      className={`transition-all duration-300 relative
      ${selectedWorkouts.length ? "w-[800px] pt-3" : "w-[500px] py-3"}`}
    >
      <Dialog.Title className="flex items-center justify-between px-6">
        <div>
          <p className="font-Title font-extrabold text-xl">
            ADD WORKOUT TO PROGRAM
          </p>
          <p className="text-HTKBlack/80">
            Select the workouts you would like to add to this program.
          </p>
        </div>
        <CloseIcon
          width={"30"}
          height={"30"}
          className="cursor-pointer"
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className="htk-border my-2" />
      <div
        className={`grid transition-all duration-200
        ${selectedWorkouts.length ? "grid-cols-500-300-cols" : "grid-cols-1"}`}
      >
        {/* All Workout Search */}
        <div className="w-full px-4 border-solid border-r-[1px] border-HTKBorder">
          <p className="font-Title font-semibold px-2">WORKOUTS</p>
          <HTKSearchbar onChange={onChangeText} />
          <div className="max-h-[25em] overflow-y-auto overflow-x-hidden pb-[1em]">
            <InfiniteScroll
              loadMore={fetchMoreData}
              hasMore={hasMore}
              useWindow={false}
              loader={<CardLoading key={0} />}
              threshold={150}
            >
              {renderProgramWorkoutTitles()}
            </InfiniteScroll>
          </div>
        </div>

        {/* Selected Workouts */}
        {selectedWorkouts.length ? (
          <div className="w-full px-4">
            <p className="font-Title font-semibold">SELECTED WORKOUTS</p>
            <div className="max-w-[90%]">
              {selectedWorkouts.map((workout, index) => (
                <div
                  key={index}
                  className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2"
                >
                  <p
                    className="w-full px-2 py-1 font-semibold font-Title bg-HTKRed
                    text-HTKWhite border-solid border-[1px] border-HTKBorder rounded-l-[3px]"
                  >
                    {workout.internalTitle}
                  </p>
                  <CloseIcon
                    width={"24"}
                    height={"24"}
                    className="py-[4px] border-solid border-[1px] border-HTKBorder
                    rounded-r-[3px] bg-HTKRed fill-HTKWhite hover:bg-HTKWhite
                    hover:fill-HTKRed hover:border-HTKRed transition-all duration-300"
                    onClick={() => {
                      const filteredCategories = selectedWorkouts.filter(
                        (selectedWorkout) => selectedWorkout.id !== workout.id
                      );
                      setSelectedWorkouts(filteredCategories);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {selectedWorkouts.length ? (
        <div className="sticky bottom-0 h-[4em] flex items-center mx-5">
          <HTKButton
            text={"Add Workouts To Program"}
            className="create-item-button"
            onSubmit={addWorkouts}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AddWorkoutModal;
