import { Dialog } from '@headlessui/react'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { CloseIcon } from '../../../assets/icons/icons'
import { closeModal, openModal } from '../../../features/Cores/modalSlice'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import { addNewArticleBody, addNewArticleImage, addNewArticleTitle, newArticleSelector, removeNewArticleCategory, selectArticle } from '../../../features/Library/Articles/articleSlice'
import { addArticle } from '../../../features/Library/Articles/articlesSlice'
import { useCreateArticleMutation } from '../../../services/ArticleService'
import asyncTimeout from '../../../utils/asyncTimeout'
import { HTKButton, HTKTextArea, HTKTextInput, HTKTitleBlock, HTKUploadInput } from '../../atoms/atoms'
import { ArticleCategoryField } from '../molecules'

const CreateArticleModal = () => {
  const { title, image, category, body } = useHTKSelector(newArticleSelector)
  const dispatch = useHTKDispatch()
  const isCreateButtonDisabled = !title || !image || !category || !body
  const [ createArticle ] = useCreateArticleMutation()

  const createNewArticle = async () => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Creating New Article' }))
      const [data] = await Promise.all([
        createArticle({
          title, image, category, body
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(addArticle(data))
      dispatch(selectArticle({ id: data.id, article: data }))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'ARTICLE CREATED',
        body: 'You have successfully created a new article!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to create article',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <>
      <div
        className='min-w-[500px] max-w-[600px] py-3 px-6'
      >
        <Dialog.Title className='flex items-center justify-between'>
          <div>
            <p className='font-Title font-extrabold text-xl'>
              NEW ARTICLE
            </p>
            <p className='text-HTKBlack/80'>
              Let’s create a new article.
            </p>
          </div>
          <CloseIcon
            width={'30'}
            height={'30'}
            className='cursor-pointer'
            onClick={() => dispatch(closeModal())}
          />
        </Dialog.Title>
        <hr className='htk-border my-2' />
        <div className='max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]'>
          <HTKTitleBlock
            title='ARTICLE TITLE'
            description='The article title will be displayed to users in the HTK app as the main headline for this article.'
            isRequired={!title}
          />
          <HTKTextInput
            name='title'
            value={title}
            placeholder='Title'
            onChange={(text) => dispatch(addNewArticleTitle(text))}
          />
          <HTKTitleBlock
            title='ARTICLE IMAGE'
            description='Upload an image that relates to this articles contents.'
            className='mt-6'
            isRequired={!image}
          />
          <HTKUploadInput
            type='image'
            source={image ?? ''}
            onUpload={(image) => dispatch(addNewArticleImage(image ?? ''))}
          />
          <HTKTitleBlock
            title='ARTICLE CATEGORY'
            description='Add a category to this Article. This allows users to find an article by it’s allocated category.'
            className='mt-6'
            isRequired={!category?.length}
          />
          <ArticleCategoryField
            category={category}
            title='CREATE_ARTICLE'
            onDelete={() => dispatch(removeNewArticleCategory())}
          />
          <HTKTitleBlock
            title='ARTICLE CONTENTS'
            description='Place in the contents of this article.'
            className='mt-6'
            isRequired={(!body || body === '<p><br></p>') ? true : false}
          />
          <HTKTextArea
            text={body ?? ''}
            onChange={(text) => dispatch(addNewArticleBody(text))}
          />
        </div>
      </div>
      <div className='sticky bottom-0 w-full h-12 flex items-center px-6'>
        <HTKButton
          text='Create Article'
          isLoading={false}
          disabled={isCreateButtonDisabled}
          className={isCreateButtonDisabled ? `disabled-item-button`:'create-item-button'}
          onSubmit={createNewArticle}
        />
        <HTKButton
          text='Cancel'
          className='cancel-item-button'
          onSubmit={() => dispatch(closeModal())}
        />
      </div>
    </>
  )
}

export default CreateArticleModal