import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { closeModal, openModal } from '../../../features/Cores/modalSlice'
import { selectUser, userSelector } from '../../../features/User/userSlice'
import { Dialog } from '@headlessui/react'
import { HTKAsyncAvatar, HTKButton } from '../../atoms/atoms'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { useRestrictUserMutation, useUnrestrictUserMutation } from '../../../services/UserService'
import asyncTimeout from '../../../utils/asyncTimeout'
import { updateUser } from '../../../features/User/usersSlice'


const RestrictUserModal = () => {
    const { id, avatar, firstName, lastName, isPaying, trialUser, isRestricted } = useHTKSelector(userSelector)
    const dispatch = useHTKDispatch()

    const [ restrictingUser ] = useRestrictUserMutation()
    const [ unrestrictingUser ] = useUnrestrictUserMutation()

    const restrictUser = async () => {
        try {
            dispatch(closeModal())
            dispatch(openOverlay({ text: `${isRestricted ? 'Unrestricting' : 'Restricting'} User`}))
            const [ data ] = await Promise.all([
                isRestricted 
                ? unrestrictingUser(id).unwrap()
                : restrictingUser(id).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data) throw Error
            dispatch(selectUser({ id: data.id, user: data }))
            dispatch(updateUser(data))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: `USER ${isRestricted ? 'UNRESTRICTED' : 'RESTRICTED'}`,
                body: `You have successfully ${isRestricted ? 'unrestricted' : 'restricted'} this user!`
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: `Failed to ${isRestricted ? 'unrestrict' : 'restrict'} user`,
                body: 'Something went wrong. Please try again later.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }

    return (
        <div className='w-[500px] py-3 px-6'>
            <Dialog.Title className='flex items-center justify-between'>
                <span className='font-Title font-extrabold text-xl'>
                    {isRestricted ? 'UNRESTRICT USER' : 'RESTRICT USER'}
                </span>
            </Dialog.Title>
            <hr className='htk-border my-2' />
            <div>
                <p className='text-HTKBlack/80'>
                    By restricting a user, they will no longer be able to access their Hard To Kill account, until Unrestricted. This action will apply regardless of whether the user is a paying user or not. Be sure you want to invoke this action.
                </p>
                <div 
                    className='flex items-center rounded-[4px] bg-HTKMiddleGrey
                    px-3 py-4 my-2'
                >
                    <HTKAsyncAvatar 
                        fileName={avatar ?? ''}
                        width={'w-14'}
                        height={'h-14'}
                    />
                    <div className='ml-3'>
                        <p className='font-Title font-bold text-lg'>
                            {firstName}&nbsp;{lastName}
                        </p>
                        {isPaying ? (
                            <p className='text-HTKGreen font-medium'>
                                Paying User
                            </p>
                        ) : null}
                        {(!isPaying && trialUser) ? (
                            <p className='text-HTKBlack/60 font-medium'>
                                Trial User
                            </p>
                        ) : null}
                        {(!isPaying && !trialUser) ? (
                            <p className='text-HTKBlack/60 font-medium'>
                                No Subscription
                            </p>
                        ) : null}
                    </div>
                </div>
                <div className='w-full flex items-center mt-3'>
                    {!isRestricted ? (
                        <HTKButton
                            text='Restrict This User'
                            className='main-detail-button'
                            onSubmit={restrictUser}
                        />
                    ) : (
                        <HTKButton
                            text='Unrestrict User'
                            className='unrestrict-button'
                            onSubmit={restrictUser}
                        />
                    )}
                    <HTKButton
                        text='Go Back'
                        className='delete-goback-button'
                        onSubmit={() => dispatch(closeModal())}
                    />
                </div>
            </div>
        </div>
    )
}

export default RestrictUserModal