import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { InitialAnnouncementProgramsState } from "../../assets/data/initialState";
import { Program } from "../../types/stateTypes";
import { Course } from "../../types/CourseTypes";

export type AnnouncementCoursesSliceType = {
	courses: Course[];
};

export const announcementCourseSlice = createSlice({
	name: "announcementPrograms",
	initialState: InitialAnnouncementProgramsState,
	reducers: {
		clearAnnouncementCourses: (state) => {
			state.courses = [];
		},
		setInitialAnnouncementCourses: (state, action: PayloadAction<AnnouncementCoursesSliceType>) => {
			state.courses = action.payload.courses;
		},
		setAnnouncementCourses: (state, action: PayloadAction<AnnouncementCoursesSliceType>) => {
			const { courses: storedAnnouncementCourses } = state;
			const { courses: newAnnouncementCourses } = action.payload;
			const announcementCourseIds = storedAnnouncementCourses.map((course: Course) => course.id);
			const filteredDuplicates = newAnnouncementCourses.filter((course) => !announcementCourseIds.includes(course.id));
			filteredDuplicates.map((course) => state.courses.push(course));
		},
	},
});

export const announcementCourseSelector = (state: RootState) => state.announcementPrograms.courses;
export const { clearAnnouncementCourses, setInitialAnnouncementCourses, setAnnouncementCourses } = announcementCourseSlice.actions;
export default announcementCourseSlice.reducer;
