import { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useHTKDispatch } from '../../../app/hooks'
import { HTKSearchbar, HTKSearchTitleBlock } from '../../atoms/atoms'
import { clearNewRecipe } from '../../../features/Library/Recipes/recipeSlice'
import { openModal } from '../../../features/Cores/modalSlice'
import { DASHBOARD_TYPES, MODAL_TYPES } from '../../../assets/data/enums'
import { AnimatePresence } from 'framer-motion'
import InfiniteScrollLists from './InfiniteScrollLists'

const RecipesList = () => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
  const dispatch = useHTKDispatch()
  
  return (
    <div className='flex flex-col'>
      <p className='min-h-[2.5em] pb-1 flex items-end font-Title text-2xl font-bold'>
        RECIPES
      </p>
      <div className='w-[20em] bg-HTKWhite htk-container'>
        <HTKSearchTitleBlock
          title='ALL RECIPES'
          btnTitle='New'
          onClick={() => {
            dispatch(clearNewRecipe())
            dispatch(openModal({
              modalType: MODAL_TYPES.CREATE_RECIPE
            }))
          }}
        />
        <HTKSearchbar onChange={onChangeText} />
        <div className='w-full my-1'>
          <AnimatePresence>
            <InfiniteScrollLists
              key='infinite_scroll'
              page={DASHBOARD_TYPES.RECIPES}
              searchText={searchText}
              pb='pb-[10em]'
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

export default RecipesList