import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { useLazyGetExercisesQuery } from "../../../services/ExerciseService";
import {
  clearExercises,
  setExercises,
  setInitialExercises,
  workoutExercisesSelector,
} from "../../../features/Training/Workouts/workoutExercisesSlice";
import { Dialog } from "@headlessui/react";
import { AddIcon, CloseIcon } from "../../../assets/icons/icons";
import { closeModal, modalSelector } from "../../../features/Cores/modalSlice";
import {
  CardError,
  CardLoading,
  CardLogo,
  HTKButton,
  HTKSearchbar,
} from "../../atoms/atoms";
import InfiniteScroll from "react-infinite-scroller";
import { domAnimation, LazyMotion } from "framer-motion";
import { Exercise } from "../../../types/stateTypes";

const EditWorkoutExerciseModal = () => {
  const [searchText, setSearchText] = useState("");
  const onChangeText = useMemo(
    () => debounce(setSearchText, 500),
    [setSearchText]
  );
  const take = 10;
  const [skip, setSkip] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedExercises, setSelectedExercises] = useState<Exercise[]>([]);

  const workoutExercisesState = useHTKSelector(workoutExercisesSelector);
  const { title, addItemFn } = useHTKSelector(modalSelector);
  const dispatch = useHTKDispatch();

  const [getExercises, { isLoading, isError }] = useLazyGetExercisesQuery();

  const renderTitles = (title: string | undefined) => {
    switch (title) {
      case "ADD EXERCISES TO WORKOUT":
        return "Select what exercises you would like to add to this workout.";
      case "SUPERSET EXERCISE":
        return "Select what exercise you would like to super set this exercise with.";
      default:
        return "";
    }
  };

  const renderButtonText = (title: string | undefined) => {
    switch (title) {
      case "ADD EXERCISES TO WORKOUT":
        return "Add Exercises To Workout";
      case "SUPERSET EXERCISE":
        return "Superset Exercise";
      default:
        return "";
    }
  };

  useEffect(() => {
    setSkip(0);
    loadMoreItems(10, 0);
  }, [searchText]);

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreItems(take, skip);
  };

  const loadMoreItems = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true);
    try {
      const items = await getExercises({
        take,
        skip,
        search: searchText,
        isActive: undefined,
      }).unwrap();

      if (!items?.length && skip === 0) dispatch(clearExercises());
      if (!items || !items.length) {
        setHasMore(false);
      } else {
        if (skip === 0) {
          dispatch(setInitialExercises({ exercises: items }));
          setHasMore(true);
          setSkip(10);
        } else if (items.length < take) {
          dispatch(setExercises({ exercises: items }));
          setHasMore(false);
        } else {
          dispatch(setExercises({ exercises: items }));
          setHasMore(true);
          setSkip(skip + 10);
        }
      }

      setTimeout(() => {
        setIsLoadMore(false);
      }, 1000);
    } catch (error) {}
  };

  const renderExerciseTitles = () => {
    if (isLoading) return <CardLogo />;
    if (isError) return <CardError />;
    if (!workoutExercisesState.length)
      return (
        <div className="w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7">
          No Exercise
        </div>
      );

    return (
      <LazyMotion features={domAnimation} key="exercise-card">
        <div className="grid grid-cols-2 gap-x-[0.5em] px-2">
          {workoutExercisesState.map((exercise, index) => {
            const isSelected = selectedExercises.find(
              (selected) => selected.id === exercise.id
            );
            return (
              <div
                key={index}
                className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2"
                onClick={() =>
                  !isSelected &&
                  setSelectedExercises((prev) => [...prev, exercise])
                }
              >
                <p
                  className={`${
                    isSelected?.id !== exercise.id
                      ? "bg-HTKMiddleGrey text-HTKBlack"
                      : "bg-HTKBlack text-HTKWhite"
                  } w-full px-2 py-1
                                border-solid border-[1px] border-HTKBorder rounded-l-[3px]
                                font-semibold font-Title`}
                >
                  {exercise.title}
                </p>
                <AddIcon
                  width={"30"}
                  height={"30"}
                  className={`${
                    !isSelected
                      ? "bg-HTKMiddleGrey fill-HTKBlack"
                      : "bg-HTKBlack fill-HTKWhite"
                  } py-[1px]
                                border-solid border-[1px] border-HTKBorder rounded-r-[3px]`}
                />
              </div>
            );
          })}
        </div>
      </LazyMotion>
    );
  };

  return (
    <div
      className={`transition-all duration-300 relative
            ${selectedExercises.length ? "w-[800px] pt-3" : "w-[500px] py-3"}`}
    >
      <Dialog.Title className="flex items-center justify-between px-6">
        <div>
          <p className="font-Title font-extrabold text-xl">{title}</p>
          <p className="text-HTKBlack/80">{renderTitles(title)}</p>
        </div>
        <CloseIcon
          width={"30"}
          height={"30"}
          className="cursor-pointer"
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className="htk-border my-2" />
      <div
        className={`grid transition-all duration-200 
                ${
                  selectedExercises.length
                    ? "grid-cols-500-300-cols"
                    : "grid-cols-1"
                }`}
      >
        {/* All Exercise Search */}
        <div className="w-full px-4 border-solid border-r-[1px] border-HTKBorder">
          <p className="font-Title font-semibold px-2">EXERCISES</p>
          <HTKSearchbar onChange={onChangeText} />
          <div className="max-h-[25em] overflow-y-auto overflow-x-hidden pb-[1em]">
            <InfiniteScroll
              loadMore={fetchMoreData}
              hasMore={hasMore}
              useWindow={false}
              loader={<CardLoading key={0} />}
              threshold={150}
            >
              {renderExerciseTitles()}
            </InfiniteScroll>
          </div>
        </div>

        {/* Selected Exercises */}
        {selectedExercises.length ? (
          <div className="w-full px-4">
            <p className="font-Title font-semibold">SELECTED EXERCISES</p>
            <div className="max-w-[90%]">
              {selectedExercises.map((exercise, index) => (
                <div
                  key={index}
                  className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2"
                >
                  <p
                    className="w-full px-2 py-1 font-semibold font-Title bg-HTKRed 
                                text-HTKWhite border-solid border-[1px] border-HTKBorder rounded-l-[3px]"
                  >
                    {exercise.title}
                  </p>
                  <CloseIcon
                    width={"24"}
                    height={"24"}
                    className="py-[4px] border-solid border-[1px] border-HTKBorder 
                                rounded-r-[3px] bg-HTKRed fill-HTKWhite hover:bg-HTKWhite
                                hover:fill-HTKRed hover:border-HTKRed transition-all duration-300"
                    onClick={() => {
                      const filteredCategories = selectedExercises.filter(
                        (selectedCategory) =>
                          selectedCategory.id !== exercise.id
                      );
                      setSelectedExercises(filteredCategories);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {selectedExercises.length && addItemFn ? (
        <div className="sticky bottom-0 h-[4em] flex items-center mx-5">
          <HTKButton
            text={renderButtonText(title)}
            className="create-item-button"
            onSubmit={() => addItemFn(selectedExercises)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default EditWorkoutExerciseModal;
