import { HTKApi } from '../app/api'
import { CreateExerciseArgs, PaginationPayloadWithActiveStatus, PaginationPayload, UpdateCategory, UpdateExerciseArgs } from '../types/serviceTypes'
import { Exercise, ExerciseCategory } from '../types/stateTypes'
import { exerciseActiveFilter } from '../utils/filterListFormatter'

export const exerciseApi = HTKApi.injectEndpoints({
    endpoints: (builder) => ({
        getExercises: builder.query<Exercise[], PaginationPayloadWithActiveStatus>({
            query: ({ search, skip, take, isActive }) => ({
                url: '/exercises/search-by-active-status/admin',
                params: { search, skip, take, isActive: exerciseActiveFilter(isActive) }
            }),
            providesTags: ['Exercise']
        }),
        getExercise: builder.query<Exercise, string>({
            query: (exerciseId) => `/exercises/${exerciseId}`,
            providesTags: ['Exercise']
        }),
        getExerciseCategories: builder.query<ExerciseCategory[], PaginationPayload>({
            query: ({ search, skip, take }) => ({
                url: '/exercises/categories/admin',
                params: { search, skip, take }
            }),
            providesTags: ['ExerciseCategory']
        }),
        createExerciseCategory: builder.mutation<ExerciseCategory, string>({
            query: (title) => ({
                url: '/exercises/categories/create',
                method: 'POST',
                body: { title }
            }),
            invalidatesTags: ['ExerciseCategory']
        }),
        updateExerciseCategory: builder.mutation<ExerciseCategory, UpdateCategory>({
            query: ({ categoryId, title }) => ({
                url: `/exercises/categories/${categoryId}/admin`,
                method: 'POST',
                body: { title }
            }),
            invalidatesTags: ['ExerciseCategory']
        }),
        deleteExerciseCategory: builder.mutation<ExerciseCategory, string>({
            query: (categoryId) => ({
                url: `/exercises/categories/${categoryId}/admin`,
                method: 'DELETE',
            })
        }),
        createExercise: builder.mutation<Exercise, CreateExerciseArgs>({
            query: ({ title, video, exerciseCategory, description, image }) => {
                const exerciseCategoryTitles = exerciseCategory.map(category => category.title)
                return ({
                    url: '/exercises',
                    method: 'POST',
                    body: {
                        title,
                        video,
                        exerciseCategories: exerciseCategoryTitles,
                        isActiveOnApp: false,
                        description,
                        image,
                    }
                })
            },
            invalidatesTags: ['Exercise']
        }),
        updateExercise: builder.mutation<Exercise, UpdateExerciseArgs>({
            query: ({ id, title, video, exerciseCategory, isActiveOnApp, description, image }) => {
                const exerciseCategoryTitles = exerciseCategory?.map(category => category.title)
                return ({
                    url: `/exercises/${id}`,
                    method: 'PUT',
                    body: {
                        title,
                        video,
                        exerciseCategories: exerciseCategoryTitles,
                        isActiveOnApp,
                        description,
                        image,
                    }
                })
            },
            invalidatesTags: ['Exercise']
        }),
        deleteExercise: builder.mutation<Exercise, string>({
            query: (exerciseId) => ({
                url: `/exercises/${exerciseId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Exercise']
        })
    })
})

export const {
    useLazyGetExercisesQuery,
    useGetExerciseQuery,
    useLazyGetExerciseCategoriesQuery,
    useCreateExerciseCategoryMutation,
    useUpdateExerciseCategoryMutation,
    useDeleteExerciseCategoryMutation,
    useCreateExerciseMutation,
    useUpdateExerciseMutation,
    useDeleteExerciseMutation,
} = exerciseApi