import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { Dialog } from "@headlessui/react";
import { CloseIcon } from "../../../assets/icons/icons";
import { HTKButton, HTKTabListInput, HTKTextArea, HTKTextInput, HTKTitleBlock, HTKUploadInput } from "../../atoms/atoms";
import { addNewWorkoutDescription, addNewWorkoutInternalTitle, addNewWorkoutRotation, addNewWorkoutTitle, addNewWorkoutType, newWorkoutSelector, selectWorkout } from "../../../features/Training/Workouts/workoutSlice";
import { coursePlannedForTypes, workoutTypes } from "../../../assets/data/arrays";
import { useState } from "react";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import { useCreateWorkoutMutation } from "../../../services/WorkoutService";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { addWorkout } from "../../../features/Training/Workouts/workoutsSlice";
import { clearExerciseGroup } from "../../../features/Training/Workouts/exerciseGroupSlice";
import { addNewCourseFullDescription, addNewCourseImage, addNewCourseInternalTitle, addNewCoursePlannedFor, addNewCourseProgramLength, addNewCourseSkillRequired, addNewCourseSnippetDescription, addNewCourseUserFacingTitle, newCourseSelector, selectCourse } from "../../../features/Training/Courses/courseSlice";
import { CourseCreate } from "../../../types/CourseTypes";
import { useCreateCourseMutation } from "../../../services/CourseService";
import { addcourse, clearCourses } from "../../../features/Training/Courses/coursesSlice";

const CreateCourseModal = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { internalTitle, userFacingTitle, coursePlannedFor, skillRequired, courseProgramLength, snippetDescription, fullDescription, image } = useHTKSelector(newCourseSelector);
	const dispatch = useHTKDispatch();

	const [createCourse] = useCreateCourseMutation();

	const createNewCourse = async () => {
		const courseCreate: CourseCreate = {
			internalTitle,
			userFacingTitle,
			coursePlannedFor,
			courseProgramLength,
			fullDescription,
			image,
			skillRequired,
			snippetDescription,
		};
		try {
			setIsLoading(true);
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Creating New Course" }));
			const [data] = await Promise.all([createCourse(courseCreate).unwrap(), asyncTimeout(2000)]);
			if (!data) throw Error;
			dispatch(addcourse(data));
			dispatch(selectCourse({ id: data.id, course: data }));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "COURSE CREATED",
					body: "You have successfully created a new course!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to create course",
					body: "Please check your input details and try again.",
				})
			);
		} finally {
			setIsLoading(false);
			dispatch(closeOverlay());
		}
	};

	return (
		<>
			<div className="min-w-[600px] max-w-[650px] py-3 px-6">
				<Dialog.Title className="flex items-center justify-between">
					<div>
						<p className="font-Title font-extrabold text-xl">NEW COURSE</p>
						<p className="text-HTKBlack/80">Let’s create a new HTK Course! Courses are a series of programs which a user can complete.</p>
					</div>
					<CloseIcon width={"30"} height={"30"} className="cursor-pointer" onClick={() => dispatch(closeModal())} />
				</Dialog.Title>
				<hr className="htk-border my-2" />
				<div className="max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]">
					<HTKTitleBlock title="INTERNAL COURSE TITLE" description="The Internal Course Title allows you to title this Course anything you would like, without being seen by public users. This title is not shown to users on the front-end HTK Application." isRequired={!internalTitle} />
					<HTKTextInput name="internal-title" value={internalTitle} placeholder="Internal Course Title" onChange={(text) => dispatch(addNewCourseInternalTitle(text))} />
					<HTKTitleBlock title="USER-FACING COURSE TITLE" description="This is the Course Title a user will see inside of the HTK application." isRequired={!userFacingTitle} />
					<HTKTextInput name="title" value={userFacingTitle} placeholder="Course Title" onChange={(text) => dispatch(addNewCourseUserFacingTitle(text))} />
					<HTKTabListInput
						title={"COURSE PLANNED FOR"}
						description={"Select what workout type best describes this Course. Select from Gym, Home, Specialised or Other."}
						list={coursePlannedForTypes}
						selectedItem={coursePlannedFor?.map((coursePlannedFor) => {
							return coursePlannedFor.title;
						})}
						onSelect={(text) => dispatch(addNewCoursePlannedFor({ title: text }))}
						className={"py-1"}
					/>

					<HTKTitleBlock title="PROGRAM SKILL REQUIRED" description="Select the level of fitness expertise a user will need to complete this program." isRequired={!skillRequired} />
					<HTKTextInput name="title" value={skillRequired} placeholder="Program Skill Required" onChange={(text) => dispatch(addNewCourseSkillRequired(text))} />
					<HTKTitleBlock title="COURSE PROGRAM LENGTH" description="Enter how many weeks each program inside of this course goes for in weeks." isRequired={!courseProgramLength} />
					<HTKTextInput name="title" value={courseProgramLength} placeholder="Course Program Length" onChange={(text) => dispatch(addNewCourseProgramLength(text))} />
					<HTKTitleBlock title="UPLOAD IMAGE" description={`Upload and image for this Course's thumbnail.`} isRequired={!image} />
					<HTKUploadInput type="image" source={image ?? ""} onUpload={(image) => dispatch(addNewCourseImage(image ?? ""))} />
					<HTKTitleBlock title="COURSE SNIPPET DESCRIPTION" description="Give a small description of this Course's contents. This description will be displayed on each thumbnail." isRequired={!snippetDescription} />
					<HTKTextArea text={snippetDescription ?? ""} onChange={(text) => dispatch(addNewCourseSnippetDescription(text))} />
					<div className="mt-8">
						<HTKTitleBlock title="COURSE FULL DESCRIPTION" description="Give a description of this Course's contents. This description will be shown to users when viewing a courses details." isRequired={!fullDescription} />

						<HTKTextArea text={fullDescription ?? ""} onChange={(text) => dispatch(addNewCourseFullDescription(text))} />
					</div>
				</div>
			</div>
			<div className="sticky bottom-0 w-full h-12 flex items-center px-6">
				<HTKButton text="Create Course" isLoading={isLoading} disabled={isLoading} className="create-item-button" onSubmit={() => createNewCourse()} />
				<HTKButton text="Cancel" className="cancel-item-button" onSubmit={() => dispatch(closeModal())} />
			</div>
		</>
	);
};

export default CreateCourseModal;
