import { useHTKSelector } from '../../app/hooks'
import { ProgramDetail, ProgramsList } from '../../components/organisms/organisms'
import { programIdSelector } from '../../features/Training/Programs/programSlice'

const Programs = () => {
  const programId = useHTKSelector(programIdSelector)

  return (
    <div className='w-full h-full flex justify-center'>
      {!programId ? (
        <ProgramsList />
      ) : (
        <ProgramDetail />
      )}
    </div>
  )
}

export default Programs