import { RFC } from '../../../types/propTypes'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { exerciseIdSelector, selectExercise, updateExerciseVideo } from './exerciseSlice'
import { exercisesSelector } from './exercisesSlice'
import { Exercise } from '../../../types/stateTypes'
import { CardError, CardLogo, HTKAsyncImage, TitleCard } from '../../../components/atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import moment from 'moment'

type ExerciseTitlesProps = {
    isLoading:   boolean
    isError:     boolean
}

export const ExerciseTitles:RFC<ExerciseTitlesProps> = ({
    isLoading,
    isError,
}) => {
    const exerciseId = useHTKSelector(exerciseIdSelector)
    const exercisesState = useHTKSelector(exercisesSelector)
    const dispatch = useHTKDispatch()

    const handleClickCard = (item: Exercise) => {
        dispatch(updateExerciseVideo(''))
        dispatch(selectExercise({ id: item.id, exercise: item }))
    }

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!exercisesState.exercises.length) return (
        <div className='w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7'>
            No Exercise
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='exercise-card'>
        {exercisesState.exercises.map((exercise, index) => (
            <TitleCard
                key={index}
                isSelected={exerciseId === exercise.id}
                onClick={() => handleClickCard(exercise)}
            >
                <div className='flex items-center justify-between'>
                    <div className='min-w-[50px] h-[50px]'>
                        <HTKAsyncImage fileName={exercise.image}/>
                    </div>
                    <div className='w-full pl-3 truncate'>
                        <p className='font-Title uppercase font-bold text-lg truncate'>
                            {exercise.title}
                        </p>
                        <div className='flex items-center text-sm'>
                            <p className='font-medium'>
                                Created
                            </p>
                            <p className='pl-2 text-inherit/80'>
                                {moment(exercise.createdAt).format('DD MMM YYYY')}
                            </p>
                        </div>
                    </div>
                </div>
            </TitleCard>
        ))}
        </LazyMotion>
    )
}