import { RFC } from '../../../types/propTypes'
import { AddIcon, BinIcon } from '../../../assets/icons/icons'
import { RecipeIngredient } from '../../../types/stateTypes'
import { HTKButton, HTKPrefixTextInput } from '../../atoms/atoms'
import { ValidationError } from 'yup'

type RecipeIngredientsFieldProps = {
    ingredients: RecipeIngredient[] | undefined
    onChangeName: (
        ingredientId: string,
        name: string
    ) => void
    onChangeAmount: (
        ingredientId: string,
        amount: string
    ) => void
    onDelete: (ingredientId: string) => void
    onAdd: () => void
    ingredientsErrors?: ValidationError[] | undefined
}
const RecipeIngredientsField: RFC<RecipeIngredientsFieldProps> = ({
    ingredients,
    onChangeName,
    onChangeAmount,
    onDelete,
    onAdd,
    ingredientsErrors


}) => {

    function getValidationErrorMessage(field: "name" | "amount", index: number) {
        if(!ingredientsErrors) return
        const nameError = ingredientsErrors.find(error => error.path?.includes(`[${index}].${field}`));
        return nameError ? nameError.message : '';
      }

    return (
        <div className='w-full'>
            {ingredients?.map((ingredient, index) => (
                <div
                    key={index}
                    className='flex items-center justify-between gap-1'
                >
                    <div className='min-w-[28px] px-2 font-semibold text-lg font-Title'>
                        {index + 1}
                    </div>
                    <HTKPrefixTextInput
                        title='Name'
                        value={ingredient.name}
                        placeholder='Name'
                        className='w-[70px]'
                        onChange={(name) => onChangeName(ingredient.id, name)}
                        error={getValidationErrorMessage('name', index)}
                    />
                    <HTKPrefixTextInput
                        title='Amount'
                        value={ingredient.amount.toString()}
                        placeholder='Amount'
                        className='mr-3 w-[90px]'
                        onChange={(amount) => onChangeAmount(ingredient.id, amount)}
                        error={getValidationErrorMessage('amount', index)}
                    />
                    <BinIcon
                        className='px-1 cursor-pointer'
                        onClick={() => onDelete(ingredient.id)}
                    />
                </div>
            ))}
            <HTKButton
                text='Add Ingredient'
                icon={<AddIcon className='fill-HTKWhite' />}
                className='main-detail-button mx-auto my-3'
                onSubmit={() => onAdd()}
            />
        </div>
    )
}

export default RecipeIngredientsField