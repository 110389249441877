import { useEffect, useState } from "react";
import { RFC } from "../../../types/propTypes";
import { DASHBOARD_TYPES } from "../../../assets/data/enums";
import { useHTKDispatch } from "../../../app/hooks";
// Service Types
import { Announcement, Article, Exercise, Program, Recipe, User, Workout } from "../../../types/stateTypes";
// Services
import { useLazyGetAnnouncementsQuery } from "../../../services/AnnouncementService";
import { useLazyGetProgramsQuery } from "../../../services/ProgramService";
import { useLazyGetWorkoutsQuery } from "../../../services/WorkoutService";
import { useLazyGetExercisesQuery } from "../../../services/ExerciseService";
import { useLazyGetRecipesQuery } from "../../../services/RecipeService";
import { useLazyGetArticlesQuery } from "../../../services/ArticleService";
import { useLazyGetAllUsersQuery, useLazyGetPayingUsersQuery, useLazyGetTrialUsersQuery } from "../../../services/UserService";
// Dispatch
import { clearAnnouncements, setInitialAnnouncements, setAnnouncements } from "../../../features/Announcement/announcementsSlice";
import { clearPrograms, setInitialPrograms, setPrograms } from "../../../features/Training/Programs/programsSlice";
import { clearWorkouts, setInitialWorkouts, setWorkouts } from "../../../features/Training/Workouts/workoutsSlice";
import { clearExercises, setInitialExercises, setExercises } from "../../../features/Training/Exercises/exercisesSlice";
import { clearRecipes, setInitialRecipes, setRecipes } from "../../../features/Library/Recipes/recipesSlice";
import { clearArticles, setInitialArticles, setArticles } from "../../../features/Library/Articles/articlesSlice";
import { clearUsers, setInitialUsers, setUsers } from "../../../features/User/usersSlice";
// Cards
import { AnnouncementTitles } from "../../../features/Announcement/AnnouncementTitles";
import { ProgramTitles } from "../../../features/Training/Programs/ProgramTitles";
import { WorkoutTitles } from "../../../features/Training/Workouts/WorkoutTitles";
import { ExerciseTitles } from "../../../features/Training/Exercises/ExerciseTitles";
import { RecipeTitles } from "../../../features/Library/Recipes/RecipeTitles";
import { ArticleTitles } from "../../../features/Library/Articles/ArticleTitles";
import { UserTitles } from "../../../features/User/UserTitles";
// Components
import InfiniteScroll from "react-infinite-scroller";
import { CardLoading } from "../../atoms/atoms";
import { useLazyGetCoursesQuery } from "../../../services/CourseService";
import { clearCourses, setCourses, setInitialCourses } from "../../../features/Training/Courses/coursesSlice";
import { Course } from "../../../types/CourseTypes";
import { CourseTitles } from "../../../features/Training/Courses/CourseTitles";

type InfiniteScrollListsProps = {
	page: DASHBOARD_TYPES;
	searchText: string;
	searchTab?: string;
	pb: string;
};

const InfiniteScrollLists: RFC<InfiniteScrollListsProps> = ({ page, searchText, searchTab, pb }) => {
	const take = 10;
	const [skip, setSkip] = useState(0);
	const [isLoadMore, setIsLoadMore] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const dispatch = useHTKDispatch();

	// Get All Items APIs
	const [getAnnouncements, { isLoading: announcementsLoading, isError: announcementsError }] = useLazyGetAnnouncementsQuery();
	const [getPrograms, { isLoading: programsLoading, isError: programsError }] = useLazyGetProgramsQuery();
	const [getWorkouts, { isLoading: workoutsLoading, isError: workoutsError }] = useLazyGetWorkoutsQuery();
	const [getExercises, { isLoading: exercisesLoading, isError: exercisesError }] = useLazyGetExercisesQuery();
	const [getRecipes, { isLoading: recipesLoading, isError: recipesError }] = useLazyGetRecipesQuery();
	const [getArticles, { isLoading: articlesLoading, isError: articlesError }] = useLazyGetArticlesQuery();
	const [getAllUsers, { isLoading: allUsersLoading, isError: allUsersError }] = useLazyGetAllUsersQuery();
	const [getPayingUsers, { isLoading: payingUsersLoading, isError: payingUsersErrors }] = useLazyGetPayingUsersQuery();
	const [getTrialUsers, { isLoading: trailUsersLoading, isError: trailUsersErrors }] = useLazyGetTrialUsersQuery();
	const [getCourses, { isLoading: areCoursesLoading, isError: isCoursesError }] = useLazyGetCoursesQuery();

	useEffect(() => {
		setSkip(0);
		loadMoreItems(10, 0);
	}, [searchText, searchTab]);

	const fetchMoreData = () => {
		if (!isLoadMore) loadMoreItems(take, skip);
	};

	const loadMoreItems = async (take: number, skip: number) => {
		if (!isLoadMore) setIsLoadMore(true);
		try {
			const items = await switchService(take, skip, searchText, searchTab);

			if (!items?.length && skip === 0) clearList();
			if (!items || !items.length) {
				setHasMore(false);
			} else {
				if (skip === 0) {
					initialiseList(items);
					setHasMore(true);
					setSkip(10);
				} else if (items.length < take) {
					setItems(items);
					setHasMore(false);
				} else {
					setItems(items);
					setHasMore(true);
					setSkip(skip + 10);
				}
			}

			setTimeout(() => {
				setIsLoadMore(false);
			}, 1000);
		} catch (error) {
			console.log({ error });
		}
	};

	const switchService = (take: number, skip: number, searchText: string, searchTab?: string) => {
		const lazyLoadObj = {
			take,
			skip,
			search: searchText,
		};
		switch (page) {
			case DASHBOARD_TYPES.ANNOUNCEMENTS:
				return getAnnouncements(lazyLoadObj).unwrap();
			case DASHBOARD_TYPES.PROGRAMS:
				return getPrograms({ ...lazyLoadObj, isActive: searchTab }).unwrap();
			case DASHBOARD_TYPES.WORKOUTS:
				return getWorkouts({ ...lazyLoadObj, type: searchTab }).unwrap();
			case DASHBOARD_TYPES.EXERCISES:
				return getExercises({ ...lazyLoadObj, isActive: searchTab }).unwrap();
			case DASHBOARD_TYPES.RECIPES:
				return getRecipes(lazyLoadObj).unwrap();
			case DASHBOARD_TYPES.ARTICLES:
				return getArticles(lazyLoadObj).unwrap();
			case DASHBOARD_TYPES.USERS: {
				if (searchTab === "PAYING USERS") {
					return getPayingUsers(lazyLoadObj).unwrap();
				}
				if (searchTab === "TRIAL USERS") {
					return getTrialUsers(lazyLoadObj).unwrap();
				} else {
					return getAllUsers(lazyLoadObj).unwrap();
				}
			}
			case DASHBOARD_TYPES.COURSES:
				return getCourses(lazyLoadObj).unwrap();
			default:
				return;
		}
	};

	const clearList = () => {
		switch (page) {
			case DASHBOARD_TYPES.ANNOUNCEMENTS:
				return dispatch(clearAnnouncements());
			case DASHBOARD_TYPES.PROGRAMS:
				return dispatch(clearPrograms());
			case DASHBOARD_TYPES.WORKOUTS:
				return dispatch(clearWorkouts());
			case DASHBOARD_TYPES.EXERCISES:
				return dispatch(clearExercises());
			case DASHBOARD_TYPES.RECIPES:
				return dispatch(clearRecipes());
			case DASHBOARD_TYPES.ARTICLES:
				return dispatch(clearArticles());
			case DASHBOARD_TYPES.USERS:
				return dispatch(clearUsers());
			case DASHBOARD_TYPES.COURSES:
				return dispatch(clearCourses());
			default:
				return;
		}
	};

	const initialiseList = (items: any) => {
		switch (page) {
			case DASHBOARD_TYPES.ANNOUNCEMENTS:
				return dispatch(setInitialAnnouncements({ annoucements: items as Announcement[] }));
			case DASHBOARD_TYPES.PROGRAMS:
				return dispatch(setInitialPrograms({ programs: items as Program[] }));
			case DASHBOARD_TYPES.WORKOUTS:
				return dispatch(setInitialWorkouts({ workouts: items as Workout[] }));
			case DASHBOARD_TYPES.EXERCISES:
				return dispatch(setInitialExercises({ exercises: items as Exercise[] }));
			case DASHBOARD_TYPES.RECIPES:
				return dispatch(setInitialRecipes({ recipes: items as Recipe[] }));
			case DASHBOARD_TYPES.ARTICLES:
				return dispatch(setInitialArticles({ articles: items as Article[] }));
			case DASHBOARD_TYPES.USERS:
				return dispatch(setInitialUsers({ users: items as User[] }));
			case DASHBOARD_TYPES.COURSES:
				return dispatch(setInitialCourses({ courses: items as Course[] }));
			default:
				return;
		}
	};

	const setItems = (items: any) => {
		switch (page) {
			case DASHBOARD_TYPES.ANNOUNCEMENTS:
				return dispatch(setAnnouncements({ annoucements: items as Announcement[] }));
			case DASHBOARD_TYPES.PROGRAMS:
				return dispatch(setPrograms({ programs: items as Program[] }));
			case DASHBOARD_TYPES.WORKOUTS:
				return dispatch(setWorkouts({ workouts: items as Workout[] }));
			case DASHBOARD_TYPES.EXERCISES:
				return dispatch(setExercises({ exercises: items as Exercise[] }));
			case DASHBOARD_TYPES.RECIPES:
				return dispatch(setRecipes({ recipes: items as Recipe[] }));
			case DASHBOARD_TYPES.ARTICLES:
				return dispatch(setArticles({ articles: items as Article[] }));
			case DASHBOARD_TYPES.USERS:
				return dispatch(setUsers({ users: items as User[] }));
			case DASHBOARD_TYPES.COURSES:
				return dispatch(setCourses({ courses: items as Course[] }));
			default:
				return;
		}
	};

	const renderTitleCards = () => {
		switch (page) {
			case DASHBOARD_TYPES.ANNOUNCEMENTS:
				return <AnnouncementTitles isLoading={announcementsLoading} isError={announcementsError} />;
			case DASHBOARD_TYPES.PROGRAMS:
				return <ProgramTitles isLoading={programsLoading} isError={programsError} />;
			case DASHBOARD_TYPES.WORKOUTS:
				return <WorkoutTitles isLoading={workoutsLoading} isError={workoutsError} />;
			case DASHBOARD_TYPES.EXERCISES:
				return <ExerciseTitles isLoading={exercisesLoading} isError={exercisesError} />;
			case DASHBOARD_TYPES.RECIPES:
				return <RecipeTitles isLoading={recipesLoading} isError={recipesError} />;
			case DASHBOARD_TYPES.ARTICLES:
				return <ArticleTitles isLoading={articlesLoading} isError={articlesError} />;
			case DASHBOARD_TYPES.USERS:
				return <UserTitles searchTab={searchTab} allLoading={allUsersLoading} payingLoading={payingUsersLoading} trialLoading={trailUsersLoading} allError={allUsersError} payingError={payingUsersErrors} trialError={trailUsersErrors} />;
			case DASHBOARD_TYPES.COURSES:
				return <CourseTitles isError={isCoursesError} isLoading={areCoursesLoading} />;
			default:
				return <div></div>;
		}
	};

	return (
		<div className={`w-full h-[85vh] overflow-y-auto overflow-x-hidden ${pb}`}>
			<InfiniteScroll loadMore={fetchMoreData} hasMore={hasMore} useWindow={false} loader={<CardLoading key={0} />} threshold={150}>
				{renderTitleCards()}
			</InfiniteScroll>
		</div>
	);
};

export default InfiniteScrollLists;
