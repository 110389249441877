import { RFC } from '../../../types/propTypes'
import { useHTKDispatch } from '../../../app/hooks'
import { useRemoveDailyWorkoutMutation } from '../../../services/ProgramService'
import { ERROR_MESSAGES, MODAL_TYPES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES, WORKOUT_TYPES } from '../../../assets/data/enums'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { BinIcon, SwapIcon } from '../../../assets/icons/icons'
import { openModal } from '../../../features/Cores/modalSlice'
import { closeSnackbar, openSnackbar } from '../../../features/Cores/snackbarSlice'
import { removeDailyWorkout } from '../../../features/Training/Programs/weekSlice'

type WorkoutPopoverProps = {
    title:     string
    type:      WORKOUT_TYPES
    dayId:     string
    workoutId: string
}

const WorkoutPopover:RFC<WorkoutPopoverProps> = ({
    title,
    type,
    dayId,
    workoutId,
}) => {
    const dispatch = useHTKDispatch()

    const [ deleteWorkout ] = useRemoveDailyWorkoutMutation()

    const removeDayWorkout = async () => {
        try {
            const day = await deleteWorkout({
                dayId,
                workoutId
            })
            if (!day) throw Error
            dispatch(removeDailyWorkout({ dayId, workoutId }))
        } catch (error) {
            dispatch(openSnackbar({
                snackbarType: SNACKBAR_TYPES.ERROR,
                snackbarMessage: ERROR_MESSAGES.REMOVE_WORKOUT,
                snackbarTimeout: 10000,
                snackbarBtnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                snackbarBtnFunc: () => dispatch(closeSnackbar())
            }))
        }
    }

    return (
        <Popover className='relative'>
            {({ open }) => (
            <>
                <Popover.Button
                    className={`w-full flex flex-col justify-start cursor-pointer py-1 px-2
                    border-b-[1px] border-solid border-HTKBorder truncate outline-none
                    hover:bg-HTKMiddleGrey transition-all duration-200
                    ${open ? 'bg-HTKDarkGrey hover:bg-HTKDarkGrey' : 'bg-HTKWhite hover:bg-HTKMiddleGrey'}`}
                >
                    <p 
                        className={`w-full truncate text-start text-xs font-Title uppercase font-bold
                        ${open ? 'text-HTKWhite' : 'text-HTKBlack'}`}
                    >
                        {title}
                    </p>
                    <p className={`text-[10px]
                    ${open ? 'text-HTKWhite' : 'text-HTKBlack/80'}`}>
                        {type}
                    </p>
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-200'
                    enterFrom='opacity-0 translate-y-1'
                    enterTo='opacity-100 translate-y-0'
                    leave='transition ease-in duration-150'
                    leaveFrom='opacity-100 translate-y-0'
                    leaveTo='opacity-0 translate-y-1'
                >
                    <Popover.Panel
                        className='w-[5.5em] flex justify-between items-center mt-1
                        bg-HTKBorder 
                        rounded-[10px] px-2 py-1 mx-auto shadow-sm drop-shadow-sm before:box-corner'
                    >
                        <BinIcon 
                            width={'30'}
                            height={'30'}
                            className='cursor-pointer'
                            onClick={removeDayWorkout}
                        />
                        <SwapIcon 
                            width={'30'}
                            height={'30'}
                            className='cursor-pointer'
                            onClick={() => dispatch(openModal({
                                modalType: MODAL_TYPES.SWAP_WORKOUT,
                                dayId: dayId,
                                deleteId: workoutId
                            }))}
                        />
                    </Popover.Panel>
                </Transition>
            </>
            )}
        </Popover>
    )
}

export default WorkoutPopover