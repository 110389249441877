const binIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? '24'} 
                height={height ?? '24'} 
                viewBox="0 0 24 24"
                onClick={onClick}
            >
                <path id="Path_940" data-name="Path 940" d="M0,0H24V24H0Z" fill="none" />
                <path id="Path_941" data-name="Path 941" d="M16,9V19H8V9h8M14.5,3h-5l-1,1H5V6H19V4H15.5ZM18,7H6V19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2Z" />
            </svg>
        </div>
    )
}

export default binIcon