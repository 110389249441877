import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialAdminState } from '../../assets/data/initialState'
import { AdminDetails } from '../../types/stateTypes'

export type AdminSliceType = {
    admin: AdminDetails
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState: InitialAdminState,
    reducers: {
        clearAdmin: (state) => {
            state.admin = {
                createdAt:         new Date(),
                email:             '',
                firstName:         '',
                id:                '',
                lastName:          '',
                unitOfMeasurement: 'Metric',
                mobile:            '',
                password:          '',
                updatedAt:         new Date(),
            }
        },
        storeAdmin: (state, action:PayloadAction<AdminSliceType>) => {
            state.admin = action.payload.admin
        },
        updateMeasurement: (state, action:PayloadAction<string>) => {
            state.admin.unitOfMeasurement = action.payload
        }
    }
})

export const adminSelector       = (state: RootState) => state.admin.admin
export const measurementSelector = (state: RootState) => state.admin.admin.unitOfMeasurement
export const {
    clearAdmin,
    storeAdmin,
    updateMeasurement,
} = adminSlice.actions
export default adminSlice.reducer