export const convertUnitNameForCalAndKJ = (measurement: string) => {
    switch (measurement) {
        case 'Metric':   return 'kJ'
        case 'Imperial': return 'kcal'
        default:         return 'kJ'
    }
}

export const convertUnitNameForOzAndG = (measurement: string) => {
    switch (measurement) {
        case 'Metric':   return 'g'
        case 'Imperial': return 'oz'
        default:         return 'g'
    }
}

export const convertCalToKJ = (measurement: string, value: number | undefined) => {
    if (!value) return
    switch (measurement) {
        case 'Metric':
            return value.toString()
        case 'Imperial': {
            const convertedValue = (value / 4.184).toFixed(0)
            return convertedValue
        }
        default:
            return '0'
    }
}

export const onChangeKcalToKJConverter = (kcal: string) => {
    const intakeKcal = (parseInt(kcal) * 4.184).toFixed(0)
    return parseInt(intakeKcal)
}

export const convertOzToG = (measurement: string, value: number | undefined) => {
    if (!value) return
    switch (measurement) {
        case 'Metric': 
            return value.toString()
        case 'Imperial':   {
            const convertedValue = (value / 28.35).toFixed(0)
            return convertedValue
        }
        default: 
            return '0'
    }
}

export const onChangeOzToGConverter = (value: string) => {
    const intValue = parseInt(value)
    const convertedValue = (intValue * 28.35).toFixed(0)
    return parseInt(convertedValue)
}