import { RFC } from '../../types/propTypes'
import { useNavigate } from 'react-router-dom'
import { navbarTabs } from '../../assets/data/arrays'
import { HTKBlackLogo } from '../../assets/images/images'
import { LogoutIcon } from '../../assets/icons/icons'
import { MeasurementPopover } from '../molecules/molecules'

type NavbarProps = {
    page: 
        'Announcements' | 
        'Training' | 
        'Library' | 
        'User'
}

const Navbar:RFC<NavbarProps> = ({ page }) => {
    const navigate = useNavigate()

    const renderNavbarIcon = () => {
        return (
            <>
                {navbarTabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`
                            h-full w-[5em] flex items-center justify-center
                            navbar-icon-box cursor-pointer border-HTKBorder border-solid
                            hover:bg-HTKMiddleGrey transition-all duration-300
                            ${index === 0 && 'border-l-[1px]'}
                            ${navbarTabs.length - 1 === index ? 'border-r-[1px]' : 'border-r-[0.5px]'}
                            ${page === tab.page 
                                ? 'bg-HTKRed/20 fill-HTKRed hover:bg-HTKRed/20' 
                                : 'bg-HTKWhite fill-HTKBlack/40'
                            }`
                        }
                        onClick={() => navigate(tab.link)}
                    >
                        <tab.icon 
                            width='30'
                            height='30'
                        />
                    </div>
                ))}
            </>
        )
    }

    const handleLogout = () => {
        localStorage.removeItem('HTKAdminToken')
        navigate('/login')
    }

    return (
        <div 
            className='w-screen h-[3.3em] bg-HTKWhite flex justify-between 
            items-center border-b-[1px] border-solid border-HTKBorder'
        >
            <div className='ml-6'>
                <img 
                    src={HTKBlackLogo} 
                    alt='navbar' 
                    className='w-auto h-auto max-h-[100px] max-w-[70px]'
                />
            </div>
            <div className='w-full h-full relative flex flex-row justify-center items-center'>
                { renderNavbarIcon() }
            </div>
            <MeasurementPopover />
            <div 
                className='flex items-center font-bold px-5 h-full 
                cursor-pointer border-l-[1px] border-solid border-HTKBorder
                hover:bg-HTKMiddleGrey transition-all duration-300'
                onClick={handleLogout}
            >
                <LogoutIcon className='ml-2'/>
            </div>
        </div>
    )
}

export default Navbar