const AvatarSkeleton = () => {
  return (
    <div
        role='status'
        className='w-full animate-pulse my-2'
    >
        <div 
            className='h-[50px] w-[50px] bg-HTKBorder 
            rounded-[50%] dark:bg-HTKBorder'
        ></div>
    </div>
  )
}

export default AvatarSkeleton