import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { DayWorkout, Workout } from "../../../types/stateTypes";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import {
  clearProgramWorkouts,
  programWorkoutsSelector,
  setInitialProgramWorkouts,
  setProgramWorkouts,
} from "../../../features/Training/Programs/programWorkoutsSlice";
import {
  closeModal,
  modalSelector,
  openModal,
} from "../../../features/Cores/modalSlice";
import { useLazyGetProgramWorkoutsQuery } from "../../../services/WorkoutService";
import { useSwapDailyWorkoutMutation } from "../../../services/ProgramService";
import { domAnimation, LazyMotion } from "framer-motion";
import { AddIcon, CloseIcon } from "../../../assets/icons/icons";
import { Dialog } from "@headlessui/react";
import {
  CardError,
  CardLoading,
  CardLogo,
  HTKButton,
  HTKSearchbar,
} from "../../atoms/atoms";
import InfiniteScroll from "react-infinite-scroller";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/Cores/overlaySlice";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { swapDailyWorkout } from "../../../features/Training/Programs/weekSlice";

const SwapWorkoutModal = () => {
  const [searchText, setSearchText] = useState("");
  const onChangeText = useMemo(
    () => debounce(setSearchText, 500),
    [setSearchText]
  );
  const take = 10;
  const [skip, setSkip] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedWorkout, setSelectedWorkout] = useState<Workout>();
  const [swappedWorkout, setSwappedWorkout] = useState<Workout>();

  const programWorkoutsState = useHTKSelector(programWorkoutsSelector);
  const { dayId, deleteId } = useHTKSelector(modalSelector);
  const dispatch = useHTKDispatch();

  const [getWorkouts, { isLoading, isError }] =
    useLazyGetProgramWorkoutsQuery();
  const [swapWorkout] = useSwapDailyWorkoutMutation();

  useEffect(() => {
    programWorkoutsState.forEach((workout) => {
      if (workout.id === deleteId) {
        setSwappedWorkout(workout);
      }
    });
  }, [deleteId]);

  useEffect(() => {
    setSkip(0);
    loadMoreItems(10, 0);
  }, [searchText]);

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreItems(take, skip);
  };

  const loadMoreItems = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true);
    try {
      const items = await getWorkouts({
        take,
        skip,
        search: searchText,
      }).unwrap();

      if (!items.length && skip === 0) dispatch(clearProgramWorkouts());
      if (!items || !items.length) {
        setHasMore(false);
      } else {
        if (skip === 0) {
          dispatch(setInitialProgramWorkouts({ workouts: items }));
          setHasMore(true);
          setSkip(10);
        } else if (items.length < take) {
          dispatch(setProgramWorkouts({ workouts: items }));
          setHasMore(false);
        } else {
          dispatch(setProgramWorkouts({ workouts: items }));
          setHasMore(true);
          setSkip(skip + 10);
        }
      }

      setTimeout(() => {
        setIsLoadMore(false);
      }, 1000);
    } catch (error) {
      console.log({ error });
    }
  };

  const changeDailyWorkout = async () => {
    try {
      if (!dayId || !deleteId || !selectedWorkout) throw Error;
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Swapping Workout" }));
      const [data] = await Promise.all([
        swapWorkout({
          dayId,
          workoutId: deleteId,
          dayWorkout: {
            workout: selectedWorkout,
            workoutId: selectedWorkout.id,
            dayId: dayId,
          },
        }).unwrap(),
        asyncTimeout(1000),
      ]);
      if (!data) throw Error;
      dispatch(
        swapDailyWorkout({
          dayId,
          workoutId: deleteId,
          dayWorkout: {
            workout: selectedWorkout,
            workoutId: selectedWorkout.id,
            dayId: dayId,
          },
        })
      );
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "WORKOUT SWAPPED",
          body: "You have successfully swapped workouts!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to swap workouts",
          body: "Something went wrong. Please try again later.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  const renderProgramWorkoutTitles = () => {
    if (isLoading) return <CardLogo />;
    if (isError || !dayId) return <CardError />;
    if (!programWorkoutsState.length)
      return (
        <div className="w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7">
          No Workout
        </div>
      );

    return (
      <LazyMotion features={domAnimation} key="workout-card">
        <div className="grid grid-cols-2 gap-x-[0.5em] px-2">
          {programWorkoutsState.map((workout, index) => {
            const isInitialWorkout = swappedWorkout?.id === workout.id;
            const isSelected = selectedWorkout?.id === workout.id;
            return (
              <div
                key={index}
                className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2"
                onClick={() => {
                  if (isInitialWorkout) return;
                  else setSelectedWorkout(workout);
                }}
              >
                <p
                  className={`${
                    isInitialWorkout
                      ? "bg-HTKBlack text-HTKWhite"
                      : isSelected
                      ? "bg-HTKRed text-HTKWhite"
                      : "bg-HTKMiddleGrey text-HTKBlack"
                  }
                                        w-full border-solid border-[1px] border-HTKBorder
                                        px-2 py-1 rounded-l-[3px] font-semibold font-Title`}
                >
                  {workout.internalTitle}
                </p>
                <AddIcon
                  width={"30"}
                  height={"30"}
                  className={`${
                    isInitialWorkout
                      ? "bg-HTKBlack fill-HTKWhite"
                      : isSelected
                      ? "bg-HTKRed fill-HTKWhite"
                      : "bg-HTKMiddleGrey fill-HTKBlack"
                  }
                                        py-[1px] border-solid border-[1px]
                                        border-HTKBorder rounded-r-[3px]`}
                />
              </div>
            );
          })}
        </div>
      </LazyMotion>
    );
  };

  return (
    <div
      className={`transition-all duration-300 relative
            ${swappedWorkout ? "w-[800px] pt-3" : "w-[500px] py-3"}`}
    >
      <Dialog.Title className="flex items-center justify-between px-6">
        <div>
          <p className="font-Title font-extrabold text-xl">
            SWAP DAILY WORKOUT
          </p>
          <p className="text-HTKBlack/80">
            Select a workout you would like to swap.
          </p>
        </div>
        <CloseIcon
          width={"30"}
          height={"30"}
          className="cursor-pointer"
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className="htk-border my-2" />
      <div
        className={`grid transition-all duration-200
                ${swappedWorkout ? "grid-cols-500-300-cols" : "grid-cols-1"}
                ${!selectedWorkout && "pb-[2em]"}`}
      >
        {/* All Workout Search */}
        <div className="w-full px-4 border-solid border-r-[1px] border-HTKBorder">
          <p className="font-Title font-semibold px-2">WORKOUTS</p>
          <HTKSearchbar onChange={onChangeText} />
          <div className="max-h-[25em] overflow-y-auto overflow-x-hidden pb-[1em]">
            <InfiniteScroll
              loadMore={fetchMoreData}
              hasMore={hasMore}
              useWindow={false}
              loader={<CardLoading key={0} />}
              threshold={150}
            >
              {renderProgramWorkoutTitles()}
            </InfiniteScroll>
          </div>
        </div>

        <div>
          {/* Swapped Workouts */}
          {swappedWorkout ? (
            <div className="w-full px-4">
              <p className="font-Title font-semibold">SWAPPED WORKOUT</p>
              <div className="max-w-[90%]">
                <div className="w-full flex items-center justify-between drop-shadow-md my-2">
                  <p
                    className="w-full px-2 py-1 font-semibold font-Title bg-HTKBlack
                                    text-HTKWhite border-solid border-[1px] border-HTKBorder rounded-l-[3px]"
                  >
                    {swappedWorkout.internalTitle}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {/* Selected Workouts */}
          {selectedWorkout ? (
            <div className="w-full px-4 mt-[2em]">
              <p className="font-Title font-semibold">SELECTED WORKOUT</p>
              <div className="max-w-[90%]">
                <div className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2">
                  <p
                    className="w-full px-2 py-1 font-semibold font-Title bg-HTKRed
                                    text-HTKWhite border-solid border-[1px] border-HTKBorder rounded-l-[3px]"
                  >
                    {selectedWorkout.internalTitle}
                  </p>
                  <CloseIcon
                    width={"24"}
                    height={"24"}
                    className="py-[4px] border-solid border-[1px] border-HTKBorder
                                    rounded-r-[3px] bg-HTKRed fill-HTKWhite hover:bg-HTKWhite
                                    hover:fill-HTKRed hover:border-HTKRed transition-all duration-300"
                    onClick={() => setSelectedWorkout(undefined)}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {selectedWorkout ? (
        <div className="sticky bottom-0 h-[4em] flex items-center mx-5">
          <HTKButton
            text={"Swap Workout"}
            className="create-item-button"
            onSubmit={changeDailyWorkout}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SwapWorkoutModal;
