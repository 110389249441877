import { HTKApi } from '../app/api'
import { FileDownloadArgs, FileUploadArgs, FileUploadData } from '../types/serviceTypes'

export const fileApi = HTKApi.injectEndpoints({
    endpoints: (builder) => (({
        getPresignedUploadUrl: builder.query<FileUploadData, FileUploadArgs>({
            query: ({ imageKey }) => ({
                url: '/image-upload/upload-presigned-url',
                method: 'POST',
                body: { imageKey }
            })
        }),
        getPresignedDownloadURL: builder.query<string, FileDownloadArgs>({
            query: ({imageKey}) => ({
                url: '/image-upload/download-presigned-url',
                method: 'POST',
                body: { imageKey },
                responseHandler: (response) => response.text(),
            }),
        })
    }))
})

export const {
    useLazyGetPresignedUploadUrlQuery,
    useGetPresignedDownloadURLQuery,
    useLazyGetPresignedDownloadURLQuery,
} = fileApi