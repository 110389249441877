import { RFC } from '../../types/propTypes'
import { useHTKDispatch, useHTKSelector } from '../../app/hooks'
import { Announcement } from '../../types/stateTypes'
import { announcementIdSelector, selectAnnouncement, updateAnnouncementImage } from './announcementSlice'
import { announcementsSelector } from './announcementsSlice'
import { domAnimation, LazyMotion } from 'framer-motion'
import { CardError, CardLogo, HTKAsyncImage, TitleCard } from '../../components/atoms/atoms'
import moment from 'moment'

type AnnouncementTitlesProps = {
  isLoading: boolean
  isError:   boolean
}

export const AnnouncementTitles:RFC<AnnouncementTitlesProps> = ({
  isLoading,
  isError,
}) => {
  const announcementId = useHTKSelector(announcementIdSelector)
  const announcementsState = useHTKSelector(announcementsSelector)
  const dispatch = useHTKDispatch()

  const handleClickCard = (item: Announcement) => {
    dispatch(updateAnnouncementImage(''))
    dispatch(selectAnnouncement({ id: item.id, announcement: item }))
  }

  if (isLoading) return <CardLogo />
  if (isError)   return <CardError />
  if (!announcementsState.length) return (
    <div className='w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7'>
      No Announcement
    </div>
  )

  return (
    <LazyMotion features={domAnimation} key='announcement-card'>
      {announcementsState.map((announcement, index) => (
        <TitleCard
          key={index}
          isSelected={announcementId === announcement.id}
          onClick={() => handleClickCard(announcement)}
        >
          <div className='flex items-center justify-between'>
            <div className='min-w-[50px] h-[50px]'>
              <HTKAsyncImage fileName={announcement.image} />
            </div>
            <div className='w-full pl-3'>
              <p className='font-Title uppercase font-bold line-clamp-2'>
                {announcement.title}
              </p>
              <div className='flex items-center text-sm'>
                {announcement.isActiveOnApp ? (
                <p className='font-medium text-HTKGreen'>
                  Active On App
                </p>
                ) : (
                <p className='font-medium'>
                  Created
                </p>
                )}
                <p className='pl-2 text-inherit/80'>
                  {moment(announcement.createdAt).format('DD MMM YYYY')}
                </p>
              </div>
            </div>
          </div>
        </TitleCard>
      ))}
    </LazyMotion>
  )
}