const ImageSkeleton = () => {
    return (
        <div
            role='status'
            className='w-full animate-pulse my-2'
        >
            <div className='aspect-video w-[14em] bg-HTKBorder rounded-[5px] dark:bg-HTKBorder'></div>
        </div>
    )
}

export default ImageSkeleton