import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { closeModal, modalSelector } from '../../../features/Cores/modalSlice'
import { clearExercises, setExercises, setInitialExercises, workoutExercisesSelector } from '../../../features/Training/Workouts/workoutExercisesSlice'
import { Exercise } from '../../../types/stateTypes'
import { useLazyGetExercisesQuery } from '../../../services/ExerciseService'
import { CardError, CardLoading, CardLogo, HTKButton, HTKSearchbar } from '../../atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import { AddIcon, CloseIcon } from '../../../assets/icons/icons'
import { Dialog } from '@headlessui/react'
import InfiniteScroll from 'react-infinite-scroller'
import { exerciseGroupSelector } from '../../../features/Training/Workouts/exerciseGroupSlice'

const SwapExercise = () => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
  const take = 10
  const [ skip, setSkip ] = useState(0)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const [ selectedExercise, setSelectedExercise ] = useState<Exercise>()
  const [ swappedExercise, setSwappedExercise ] = useState<Exercise>()

  const workoutExercisesState = useHTKSelector(workoutExercisesSelector)
  const { exerciseInExerciseGroups } = useHTKSelector(exerciseGroupSelector)
  const { addItemFn } = useHTKSelector(modalSelector)
  const dispatch = useHTKDispatch()

  const [ getExercises, { isLoading, isError } ] = useLazyGetExercisesQuery()

  useEffect(() => {
    const singleExercise = exerciseInExerciseGroups[0]
    workoutExercisesState.forEach(exercise => {
      if (exercise.id === singleExercise.exerciseId) {
        setSwappedExercise(exercise)
      }
    })
  },[exerciseInExerciseGroups, workoutExercisesState])

  useEffect(() => {
    setSkip(0)
    loadMoreItems(10, 0)
  }, [searchText])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreItems(take, skip)
  }

  const loadMoreItems = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const items = await getExercises({ take, skip, search: searchText, isActive: undefined }).unwrap()
  
      if (!items.length && skip === 0) dispatch(clearExercises())
      if (!items || !items.length) {
        setHasMore(false)
      } else {
        if (skip === 0) {
          dispatch(setInitialExercises({ exercises: items }))
          setHasMore(true)
          setSkip(10)
        } else if (items.length < take) {
          dispatch(setExercises({ exercises: items }))
          setHasMore(false)
        } else {
          dispatch(setExercises({ exercises: items }))
          setHasMore(true)
          setSkip(skip + 10)
        }
      }
  
      setTimeout(() => {
        setIsLoadMore(false)
      }, 1000)
    } catch (error) {
      console.log({error})
    }
  }

  const renderWorkoutExerciseTitles = () => {
    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!workoutExercisesState.length) return (
      <div className='w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7'>
        No Exercise
      </div>
    )

    return (
      <LazyMotion
        features={domAnimation}
        key='exercise-card'
      >
        <div className='grid grid-cols-2 gap-x-[0.5em] px-2'>
          {workoutExercisesState.map((exercise, index) => {
            const isInitialWorkout = swappedExercise?.id === exercise.id
            const isSelected = selectedExercise?.id === exercise.id
            return (
              <div
                key={index}
                className='w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2'
                onClick={() => {
                  if (isInitialWorkout) return
                  else setSelectedExercise(exercise)
                }}
              >
                <p
                  className={`${isInitialWorkout
                    ? 'bg-HTKBlack text-HTKWhite'
                    : isSelected
                      ? 'bg-HTKRed text-HTKWhite'
                      : 'bg-HTKMiddleGrey text-HTKBlack'} 
                    w-full border-solid border-[1px] border-HTKBorder 
                    px-2 py-1 rounded-l-[3px] font-semibold font-Title`
                  }
                >
                  {exercise.title}
                </p>
                <AddIcon
                  width={'30'}
                  height={'30'}
                  className={`${isInitialWorkout
                    ? 'bg-HTKBlack fill-HTKWhite'
                    : isSelected
                      ? 'bg-HTKRed fill-HTKWhite'
                      : 'bg-HTKMiddleGrey fill-HTKBlack'} 
                    py-[1px] border-solid border-[1px] 
                    border-HTKBorder rounded-r-[3px]`
                  }
                />
              </div>
            )
          })}
        </div>
      </LazyMotion>
    )
  }

  return (
    <div
      className={`transition-all duration-300 relative
      ${swappedExercise ? 'w-[800px] pt-3' : 'w-[500px] py-3'}`}
    >
      <Dialog.Title className='flex items-center justify-between px-6'>
        <div>
          <p className='font-Title font-extrabold text-xl'>
            SWAP EXERCISE
          </p>
          <p className='text-HTKBlack/80'>
            Select an exercise you would like to swap.
          </p>
        </div>
        <CloseIcon
          width={'30'}
          height={'30'}
          className='cursor-pointer'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='htk-border my-2' />
      <div
        className={`grid transition-all duration-200 
        ${swappedExercise ? 'grid-cols-500-300-cols' : 'grid-cols-1'}
        ${!selectedExercise && 'pb-[2em]'}`}
      >
        {/* All Exercise Search */}
        <div className='w-full px-4 border-solid border-r-[1px] border-HTKBorder'>
          <p className='font-Title font-semibold px-2'>
            EXERCISES
          </p>
          <HTKSearchbar onChange={onChangeText} />
          <div className='max-h-[25em] overflow-y-auto overflow-x-hidden pb-[1em]'>
            <InfiniteScroll
              loadMore={fetchMoreData}
              hasMore={hasMore}
              useWindow={false}
              loader={<CardLoading key={0} />}
              threshold={150}
            >
              {renderWorkoutExerciseTitles()}
            </InfiniteScroll>
          </div>
        </div>

        <div>
          {/* Swapped Exercises */}
          {swappedExercise ? (
            <div className='w-full px-4'>
              <p className='font-Title font-semibold'>
                SWAPPED EXERCISE
              </p>
              <div className='max-w-[90%]'>
                <div
                  className='w-full flex items-center justify-between drop-shadow-md my-2'
                >
                  <p
                    className='w-full px-2 py-1 font-semibold font-Title bg-HTKBlack 
                  text-HTKWhite border-solid border-[1px] border-HTKBorder rounded-l-[3px]'
                  >
                    {swappedExercise.title}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {/* Selected Exercise */}
          {selectedExercise ? (
            <div className='w-full px-4 mt-[2em]'>
              <p className='font-Title font-semibold'>
                SELECTED EXERCISE
              </p>
              <div className='max-w-[90%]'>
                <div
                  className='w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2'
                >
                  <p
                    className='w-full px-2 py-1 font-semibold font-Title bg-HTKRed 
                  text-HTKWhite border-solid border-[1px] border-HTKBorder rounded-l-[3px]'
                  >
                    {selectedExercise.title}
                  </p>
                  <CloseIcon
                    width={'24'}
                    height={'24'}
                    className='py-[4px] border-solid border-[1px] border-HTKBorder 
                    rounded-r-[3px] bg-HTKRed fill-HTKWhite hover:bg-HTKWhite
                    hover:fill-HTKRed hover:border-HTKRed transition-all duration-300'
                    onClick={() => setSelectedExercise(undefined)}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {(selectedExercise && addItemFn) ? (
        <div className='sticky bottom-0 h-[4em] flex items-center mx-5'>
          <HTKButton
            text={'Swap Exercise'}
            className='create-item-button'
            onSubmit={() => addItemFn(selectedExercise)}
          />
        </div>
      ) : null}
    </div>
  )
}

export default SwapExercise