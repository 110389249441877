import { RFC } from '../../../types/propTypes'
import { GroupExercise } from '../../../types/stateTypes'
import { exerciseTypeConverter } from '../../../utils/filterListFormatter'

type ExerciseCardProps = {
    index:         number
    exerciseGroup: GroupExercise
}

const ExerciseCard:RFC<ExerciseCardProps> = ({
    index,
    exerciseGroup,
}) => {
    const allTitles = exerciseGroup.exerciseInExerciseGroups.map(group => group.exercise.title)
    const allTypes  = exerciseGroup.exerciseInExerciseGroups.map(group => exerciseTypeConverter(group.type))
    const formattedTitles = allTitles.join(' & ')
    const formattedTypes  = allTypes.join(', ')
    
    return (
        <div className='h-max flex'>
            <p
                className='h-full w-7 flex items-center justify-center 
                text-center my-auto font-Title text-xl font-bold'
            >
                {(index ?? 0) + 1}
            </p>
            <div className='w-full truncate pl-2'>
                <p className='font-Title uppercase font-bold text-lg truncate'>
                    {!exerciseGroup.exerciseInExerciseGroups.length 
                        ? 'New Exercise' 
                        : formattedTitles
                    }
                </p>
                <div className='flex items-center text-sm'>
                    {(allTypes && allTypes.length >= 2) ? (
                        <p className='font-black mr-2 text-[15px]'>
                            Superset
                        </p>
                    ) : null}
                    {!exerciseGroup.exerciseInExerciseGroups.length ? 'Add New Exercise' : (
                    <>
                        <p className='font-bold mr-2 text-[15px] truncate'>
                            {formattedTypes}
                        </p>
                        <p className='pl-2 text-inherit/80 truncate'>
                            {exerciseGroup.execution}
                        </p>
                    </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ExerciseCard