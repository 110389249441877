import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { closeModal, modalSelector, openModal } from "../../../features/Cores/modalSlice";
import { addNewAnnouncementProgram, announcementSelector, newAnnouncementSelector, updateAnnouncementProgram } from "../../../features/Announcement/announcementSlice";
import { Dialog } from "@headlessui/react";
import { domAnimation, LazyMotion } from "framer-motion";
import { AddIcon, CloseIcon } from "../../../assets/icons/icons";

import { CardError, CardLoading, CardLogo, HTKButton, HTKSearchbar } from "../../atoms/atoms";
import InfiniteScroll from "react-infinite-scroller";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { announcementCourseSelector, clearAnnouncementCourses, setAnnouncementCourses, setInitialAnnouncementCourses } from "../../../features/Announcement/announcementCourseSlice";
import { useLazyGetCoursesQuery } from "../../../services/CourseService";

const AddCourseToAnnouncementModal = () => {
	const [searchText, setSearchText] = useState("");
	const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText]);
	const take = 10;
	const [skip, setSkip] = useState(0);
	const [isLoadMore, setIsLoadMore] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const { title } = useHTKSelector(modalSelector);
	const { courseId: announcementCourseId } = useHTKSelector(announcementSelector);
	const { courseId: newAnnouncementCourseId } = useHTKSelector(newAnnouncementSelector);
	const announcementProgramsState = useHTKSelector(announcementCourseSelector);
	const [selectedCourse, setSelectedCourse] = useState(title === "CREATE_ANNOUNCEMENT" ? newAnnouncementCourseId ?? "" : announcementCourseId ?? "");
	const dispatch = useHTKDispatch();

	const [getCourses, { isLoading, isError }] = useLazyGetCoursesQuery();

	useEffect(() => {
		setSkip(0);
		loadMoreItems(10, 0);
	}, [searchText]);

	const fetchMoreData = () => {
		if (!isLoadMore) loadMoreItems(take, skip);
	};

	const loadMoreItems = async (take: number, skip: number) => {
		if (!isLoadMore) setIsLoadMore(true);
		try {
			const items = await getCourses({
				take,
				skip,
				search: searchText,
			}).unwrap();

			if (!items.length && skip === 0) dispatch(clearAnnouncementCourses());
			if (!items || !items.length) {
				setHasMore(false);
			} else {
				if (skip === 0) {
					dispatch(setInitialAnnouncementCourses({ courses: items }));
					setHasMore(true);
					setSkip(10);
				} else if (items.length < take) {
					dispatch(setAnnouncementCourses({ courses: items }));
					setHasMore(false);
				} else {
					dispatch(setAnnouncementCourses({ courses: items }));
					setHasMore(true);
					setSkip(skip + 10);
				}
			}

			setTimeout(() => {
				setIsLoadMore(false);
			}, 1000);
		} catch (error) {
			console.log({ error });
		}
	};

	const addProgram = () => {
		switch (title) {
			case "CREATE_ANNOUNCEMENT": {
				dispatch(addNewAnnouncementProgram(selectedCourse));
				dispatch(
					openModal({
						modalType: MODAL_TYPES.ANNOUNCEMENT,
					})
				);
				return;
			}
			case "UPDATE_ANNOUNCEMENT": {
				dispatch(updateAnnouncementProgram(selectedCourse));
				dispatch(closeModal());
				return;
			}
			default:
				return;
		}
	};

	const cancelSelectProgram = () => {
		switch (title) {
			case "CREATE_ANNOUNCEMENT": {
				dispatch(
					openModal({
						modalType: MODAL_TYPES.ANNOUNCEMENT,
					})
				);
				return;
			}
			case "UPDATE_ANNOUNCEMENT": {
				dispatch(closeModal());
				return;
			}
			default:
				return;
		}
	};

	const renderAnnouncementProgramTitles = () => {
		if (isLoading) return <CardLogo />;
		if (isError) return <CardError />;
		if (!announcementProgramsState.length) return <div className="w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7">No Active Program</div>;

		return (
			<LazyMotion features={domAnimation} key="workout-card">
				<div className="grid grid-cols-2 gap-x-[0.5em] px-2">
					{announcementProgramsState.map((program, index) => {
						const isSelected = program.id === selectedCourse;
						return (
							<div key={index} className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2" onClick={() => !isSelected && setSelectedCourse(program.id)}>
								<p
									className={`${!isSelected ? "bg-HTKMiddleGrey text-HTKBlack" : "bg-HTKBlack text-HTKWhite"} w-full px-2 py-1
                                        border-solid border-[1px] border-HTKBorder rounded-l-[3px]
                                        font-semibold font-Title`}
								>
									{program.internalTitle}
								</p>
								<AddIcon
									width={"30"}
									height={"30"}
									className={`${!isSelected ? "bg-HTKMiddleGrey fill-HTKBlack" : "bg-HTKBlack fill-HTKWhite"} py-[1px]
                                        border-solid border-[1px] border-HTKBorder rounded-r-[3px]`}
								/>
							</div>
						);
					})}
				</div>
			</LazyMotion>
		);
	};

	return (
		<>
			<div className="py-3 px-6 transition-all duration-300 relative w-[600px]">
				<Dialog.Title className="flex items-center justify-between">
					<div>
						<p className="font-Title font-extrabold text-xl">ADD COURSE TO ANNOUNCEMENT</p>
						<p className="text-HTKBlack/80">Select what course you would like to add to this announcement.</p>
					</div>
					<CloseIcon width={"30"} height={"30"} className="cursor-pointer" onClick={() => dispatch(closeModal())} />
				</Dialog.Title>
				<hr className="htk-border my-2" />
				<div className="grid transition-all duration-200 grid-cols-1 w-[90%] mx-auto">
					<div className="w-full px-4 max-h-[28em]">
						<div className="font-Title font-bold">Courses</div>
						<HTKSearchbar onChange={onChangeText} />
						<div className="max-h-[25em] overflow-y-auto overflow-x-hidden pb-[1em]">
							<InfiniteScroll loadMore={fetchMoreData} hasMore={hasMore} useWindow={false} loader={<CardLoading key={0} />} threshold={150}>
								{renderAnnouncementProgramTitles()}
							</InfiniteScroll>
						</div>
					</div>
				</div>
			</div>
			<div className="sticky bottom-0 w-full flex items-center h-16 pl-6 pr-16">
				<div className="flex items-center">
					<HTKButton text="Add Course" className="create-item-button" onSubmit={addProgram} />
					<HTKButton text="Cancel" className="cancel-item-button" onSubmit={cancelSelectProgram} />
				</div>
			</div>
		</>
	);
};

export default AddCourseToAnnouncementModal;
