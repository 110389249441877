import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { closeModal, modalSelector } from '../../../features/Cores/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { Dialog } from '@headlessui/react'
import {
  AddArticleCategoryModal, AddExerciseCategoryModal, AddCourseToAnnouncementModal, AddRecipeCategoryModal,
  AddWorkoutModal, ConvertCSVModal, CreateAnnouncementModal, CreateArticleModal,
  CreateExerciseModal, CreateProgramModal, CreateRecipeModal, CreateWorkoutModal,
  DeleteModal, EditProgramModal, EditWorkoutExerciseModal, EditWorkoutModal, FailModal,
  ProgramRecommendationModal, RestrictUserModal, SuccessModal, SwapExerciseModal, SwapWorkoutModal
} from '../../molecules/molecules'
import CreateCourseModal from '../../molecules/Modals/CreateCourseModal'
import AddProgramToCourseModal from '../../molecules/Modals/AddProgramToCourseModal'
import EditCourseModal from '../../molecules/Modals/EditCourseModal'
import AccessToUserModal from '../../molecules/Modals/AccessToUserModal'

const HTKModal = () => {
  const modalState = useHTKSelector(modalSelector)
  const dispatch = useHTKDispatch()

  const renderModal = () => {
    switch (modalState.modalType) {
      case MODAL_TYPES.ANNOUNCEMENT:                           return <CreateAnnouncementModal />
      case MODAL_TYPES.CREATE_COURSE:                          return <CreateCourseModal />
      case MODAL_TYPES.ADD_COURSE_TO_ANNOUNCEMENT:             return <AddCourseToAnnouncementModal />
      case MODAL_TYPES.CREATE_PROGRAM:                         return <CreateProgramModal />
      case MODAL_TYPES.EDIT_PROGRAM:                           return <EditProgramModal />
      case MODAL_TYPES.EDIT_COURSE :                           return <EditCourseModal/>
      case MODAL_TYPES.RECOMMENDATION:                         return <ProgramRecommendationModal />
      case MODAL_TYPES.ADD_WORKOUT:                            return <AddWorkoutModal />
      case MODAL_TYPES.SWAP_WORKOUT:                           return <SwapWorkoutModal />
      case MODAL_TYPES.CREATE_WORKOUT:                         return <CreateWorkoutModal />
      case MODAL_TYPES.EDIT_WORKOUT:                           return <EditWorkoutModal />
      case MODAL_TYPES.ADD_EXERCISES:                          return <EditWorkoutExerciseModal />
      case MODAL_TYPES.ADD_SUPERSET:                           return <EditWorkoutExerciseModal />
      case MODAL_TYPES.SWAP_EXERCISE:                          return <SwapExerciseModal />
      case MODAL_TYPES.CREATE_EXERCISE:                        return <CreateExerciseModal />
      case MODAL_TYPES.EXERCISE_CATEGORY:                      return <AddExerciseCategoryModal />
      case MODAL_TYPES.CREATE_RECIPE:                          return <CreateRecipeModal />
      case MODAL_TYPES.RECIPE_CATEGORY:                        return <AddRecipeCategoryModal />
      case MODAL_TYPES.CREATE_ARTICLE:                         return <CreateArticleModal />
      case MODAL_TYPES.ARTICLE_CATEGORY:                       return <AddArticleCategoryModal />
      case MODAL_TYPES.RESTRICT_USER:                          return <RestrictUserModal />
      case MODAL_TYPES.ACCESS_TO_USER:                          return <AccessToUserModal />
      case MODAL_TYPES.CONVERT_CSV:                            return <ConvertCSVModal />
      case MODAL_TYPES.SUCCESS:                                return <SuccessModal />
      case MODAL_TYPES.FAIL:                                   return <FailModal />
      case MODAL_TYPES.DELETE:                                 return <DeleteModal />
      case MODAL_TYPES.ADD_PROGRAM_TO_COURSE:                  return <AddProgramToCourseModal/>
      default:                                                 return null
    }
  }

  return (
    <Dialog
        open={modalState.isDisplayed}
        onClose={() => dispatch(closeModal())}
        className='fixed inset-0 z-50 overflow-y-auto flex justify-center items-center'
    >
        <Dialog.Overlay className='fixed inset-0 bg-HTKBlack/40'/>
        <Dialog.Panel className='relative bg-white rounded-[3px]'>
            { renderModal() }
        </Dialog.Panel>
    </Dialog>
  )
}

export default HTKModal