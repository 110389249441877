import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialProgramsState } from '../../../assets/data/initialState'
import { Program } from '../../../types/stateTypes'

export type ProgramsSliceType = {
    programs: Program[]
}

export const programsSlice = createSlice({
    name: 'programs',
    initialState: InitialProgramsState,
    reducers: {
        clearPrograms: (state) => {
            state.programs = []
        },
        setInitialPrograms: (state, action:PayloadAction<ProgramsSliceType>) => {
            state.programs = action.payload.programs
        },
        setPrograms: (state, action:PayloadAction<ProgramsSliceType>) => {
            const { programs: storedPrograms } = state
            const { programs: newPrograms } = action.payload
            const programIds = storedPrograms.map(program => program.id)
            const filteredDuplicates = newPrograms.filter(program => !programIds.includes(program.id))
            filteredDuplicates.map(program => state.programs.push(program))
        },
        addProgram: (state, action:PayloadAction<Program>) => {
            state.programs.unshift(action.payload)
        },
        deleteProgramWithId: (state, action:PayloadAction<string>) => {
            const filteredPrograms = state.programs.filter(program => program.id !== action.payload)
            state.programs = filteredPrograms
        }
    }
})

export const programsSelector = (state: RootState) => state.programs.programs
export const {
    clearPrograms,
    setInitialPrograms,
    setPrograms,
    addProgram,
    deleteProgramWithId,
} = programsSlice.actions
export default programsSlice.reducer