const trainingIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "24"} 
            height={height ?? "24"} 
            viewBox="0 0 24 24"
        >
            <g id="fitness_center_black_24dp">
                <path id="Path_977" data-name="Path 977" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_978" data-name="Path 978" d="M20.57,14.86,22,13.43,20.57,12,17,15.57,8.43,7,12,3.43,10.57,2,9.14,3.43,7.71,2,5.57,4.14,4.14,2.71,2.71,4.14,4.14,5.57,2,7.71,3.43,9.14,2,10.57,3.43,12,7,8.43,15.57,17,12,20.57,13.43,22l1.43-1.43L16.29,22l2.14-2.14,1.43,1.43,1.43-1.43-1.43-1.43L22,16.29l-1.43-1.43Z" transform="translate(0 0)"/>
            </g>
        </svg>
      </div>
    )
  }
  
  export default trainingIcon