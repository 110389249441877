import { RFC } from '../../../types/propTypes'
import { useHTKDispatch } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { AddNewIcon } from '../../../assets/icons/icons'
import { openModal } from '../../../features/Cores/modalSlice'
import { HTKCategoryButton } from '../../atoms/atoms'

type ArticleCategoryFieldProps = {
    category: string | undefined
    title:    string
    onDelete: () => void
}

const ArticleCategoryField:RFC<ArticleCategoryFieldProps> = ({
    category,
    title,
    onDelete,
}) => {
    const dispatch = useHTKDispatch()

    return (
        <div className='w-full flex items-center my-2'>
            <AddNewIcon
                className='w-[30px] h-[30px] flex items-center justify-center
                rounded-[3px] border-[2px] border-HTKBlack border-solid cursor-pointer
                drop-shadow-md hover:shadow-md transition-all duration-200'
                onClick={() => dispatch(openModal({
                    modalType: MODAL_TYPES.ARTICLE_CATEGORY,
                    title: title ?? undefined
                }))}
            />
            <div className='ml-1 flex flex-wrap gap-1'>
                {!category ? (
                    <div className='text-HTKBlack/80 ml-2'>
                        No Article Category
                    </div>
                ) : (
                    <HTKCategoryButton
                        text={category}
                        onClick={() => onDelete()}
                    />
                )}
            </div>
        </div>
    )
}

export default ArticleCategoryField