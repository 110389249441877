import { useHTKDispatch } from '../../../app/hooks'
import { BinIcon } from '../../../assets/icons/icons'
import { deleteExerciseSet, updateExerciseRepetitions } from '../../../features/Training/Workouts/exerciseGroupSlice'
import { RFC } from '../../../types/propTypes'
import { Set } from '../../../types/stateTypes'

type HTKSingleColInputProps = {
    sets:     Set[]
    colTitle: string
}

const HTKSingleColInput:RFC<HTKSingleColInputProps> = ({ 
    sets,
    colTitle,
}) => {
  const dispatch = useHTKDispatch()

  return (
    <div className='w-full'>
      {sets.length ? (
        <p className='w-full font-semibold text-center'>
          {colTitle}
        </p>
      ) : null}
      {sets.map((set, index) => (
        <div
          key={index}
          className='flex justify-between w-full rounded-[4px] my-2
          border-solid border-[1px] border-HTKBorder px-3 py-2
          items-center'
        >
          <div className='w-[35px] text-2xl font-Title font-bold'>
            {set.title}
          </div>
          <div className='flex mx-3 w-full items-center justify-center'>
            <input 
              type='text'
              value={set.repetitions}
              className='w-[15em] py-2 px-2 text-center rounded-[3px] 
              bg-HTKMiddleGrey font-medium outline-none'
              onChange={(e) => dispatch(updateExerciseRepetitions({
                repetitions: e.target.value,
                set
              }))}
            />
          </div>
          <BinIcon 
            className='cursor-pointer'
            onClick={() => dispatch(deleteExerciseSet(set))}
          />
        </div>
      ))}
    </div>
  )
}

export default HTKSingleColInput