import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { closeModal, modalSelector, openModal } from "../../../features/Cores/modalSlice";
import { useLazyGetProgramListQuery } from "../../../services/ProgramService";
import { Dialog } from "@headlessui/react";
import { domAnimation, LazyMotion } from "framer-motion";
import { AddIcon, CloseIcon } from "../../../assets/icons/icons";
import { CardError, CardLoading, CardLogo, HTKButton, HTKSearchbar } from "../../atoms/atoms";
import InfiniteScroll from "react-infinite-scroller";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { programsSelector, setPrograms } from "../../../features/Training/Programs/programsSlice";
import { clearProgram, programSelector } from "../../../features/Training/Programs/programSlice";
import { Program } from "../../../types/stateTypes";
import { useSaveProgramToCourseMutation } from "../../../services/CourseService";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import { courseIdSelector, courseSelector } from "../../../features/Training/Courses/courseSlice";
import asyncTimeout from "../../../utils/asyncTimeout";

const AddProgramToCourseModal = () => {
	const [searchText, setSearchText] = useState("");
	const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText]);
	const courseId = useHTKSelector(courseIdSelector);
	const course = useHTKSelector(courseSelector);
	const take = 10;
	const [skip, setSkip] = useState(0);
	const [isLoadMore, setIsLoadMore] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const programs = useHTKSelector(programsSelector);
	const [selectedPrograms, setSelectedPrograms] = useState<{ id: string; internalTitle: string }[]>(
		course.programs
			? course.programs.map((program) => {
					return { id: program.id, internalTitle: program.internalTitle };
			  })
			: []
	);
	const { title } = useHTKSelector(modalSelector);
	const dispatch = useHTKDispatch();
	const [getPrograms, { isLoading, isError }] = useLazyGetProgramListQuery();
	const [addPrograms] = useSaveProgramToCourseMutation();

	useEffect(() => {
		setSkip(0);
		loadMoreItems(10, 0);
	}, [searchText]);

	const loadMoreItems = async (take: number, skip: number) => {
		if (!isLoadMore) setIsLoadMore(true);
		try {
			const items = await getPrograms({
				take,
				skip,
				search: searchText,
			}).unwrap();

			if (!items.length && skip === 0) dispatch(clearProgram());
			if (!items || !items.length) {
				setHasMore(false);
			} else {
				if (skip === 0) {
					dispatch(setPrograms({ programs: items }));
					setHasMore(true);
					setSkip(10);
				} else if (items.length < take) {
					dispatch(setPrograms({ programs: items }));
					setHasMore(false);
				} else {
					dispatch(setPrograms({ programs: items }));
					setHasMore(true);
					setSkip(skip + 10);
				}
			}

			setTimeout(() => {
				setIsLoadMore(false);
			}, 1000);
		} catch (error) {
			console.log({ error });
		}
	};

	const saveProgram = async () => {
		try {
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Saving Programs" }));
			await Promise.all([
				addPrograms({
					courseId: courseId,
					programs: selectedPrograms.map((program, index) => {
						return { id: program.id, index: index };
					}),
				}).unwrap(),
				asyncTimeout(1000),
			]);
			if (isError) throw Error;
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "PROGRAMS SAVED",
					body: "You have successfully saved the Programs!",
				})
			);
		} catch (error) {
			console.log("Error while Adding the programs", JSON.stringify(error, null, 3));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to save Programs",
					body: "Please try again.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	const renderAnnouncementProgramTitles = () => {
		if (isLoading) return <CardLogo />;
		if (isError) return <CardError />;

		return (
			<LazyMotion features={domAnimation} key="workout-card">
				<div className="grid grid-cols-2 gap-x-[0.5em] px-2">
					{programs.map((program, index) => {
						const isSelected = selectedPrograms.some((programDetail) => programDetail.id === program.id);
						return (
							<div key={index} className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2" onClick={() => !isSelected && setSelectedPrograms((prevPrograms) => [...prevPrograms, { id: program.id, internalTitle: program.internalTitle }])}>
								<p
									className={`${!isSelected ? "bg-HTKMiddleGrey text-HTKBlack" : "bg-HTKBlack text-HTKWhite"} w-full px-2 py-1
                                        border-solid border-[1px] border-HTKBorder rounded-l-[3px]
                                        font-semibold font-Title`}
								>
									{program.internalTitle}
								</p>
								<AddIcon
									width={"30"}
									height={"30"}
									className={`${!isSelected ? "bg-HTKMiddleGrey fill-HTKBlack" : "bg-HTKBlack fill-HTKWhite"} py-[1px]
                                        border-solid border-[1px] border-HTKBorder rounded-r-[3px]`}
								/>
							</div>
						);
					})}
				</div>
			</LazyMotion>
		);
	};

	return (
		<div className={`transition-all duration-300  p-2 relative ${selectedPrograms.length ? "w-[800px] pt-3" : "w-[500px] py-3"}`}>
			<Dialog.Title className="flex items-center justify-between">
				<div>
					<p className="font-Title font-extrabold text-xl">ADD PROGRAM TO COURSE</p>
					<p className="text-HTKBlack/80">Select what a program you would like to add to this course.</p>
				</div>
				<CloseIcon width={"30"} height={"30"} className="cursor-pointer" onClick={() => dispatch(closeModal())} />
			</Dialog.Title>
			<hr className="htk-border my-2" />
			<div className={`grid transition-all duration-200  border-b-2 border-b-HTKGrey ${selectedPrograms.length ? "grid-cols-500-300-cols" : "grid-cols-1"}`}>
				<div className="w-full px-4 max-h-[28em]">
					<div className="font-Title font-bold">Programs</div>
					<HTKSearchbar onChange={onChangeText} />
					<div className="max-h-[25em] overflow-y-auto overflow-x-hidden pb-[1em]">
						<InfiniteScroll loadMore={() => loadMoreItems(take, skip)} hasMore={hasMore} useWindow={false} loader={<CardLoading key={0} />} threshold={150}>
							{renderAnnouncementProgramTitles()}
						</InfiniteScroll>
					</div>
				</div>
				{selectedPrograms.length ? (
					<div className="w-full px-4 border-l-2 border-l-HTKGrey">
						<p className="font-Title font-semibold">SELECTED PROGRAMS</p>
						<div className="max-w-[90%]">
							{selectedPrograms.map((program, index) => (
								<div key={index} className="w-full flex items-center justify-between cursor-pointer drop-shadow-md my-2">
									<p
										className="w-full px-2 py-1 font-semibold font-Title bg-HTKRed
													text-HTKWhite border-solid border-[1px] border-HTKBorder rounded-l-[3px]"
									>
										{program.internalTitle}
									</p>
									<CloseIcon
										width={"24"}
										height={"24"}
										className="py-[4px] border-solid border-[1px] border-HTKBorder
                   									 rounded-r-[3px] bg-HTKRed fill-HTKWhite hover:bg-HTKWhite
                   									 hover:fill-HTKRed hover:border-HTKRed transition-all duration-300"
										onClick={() => {
											const filteredPrograms = selectedPrograms.filter((selectedProgram) => selectedProgram.id !== program.id);
											setSelectedPrograms(filteredPrograms);
										}}
									/>
								</div>
							))}
						</div>
					</div>
				) : null}
			</div>

			<div className="sticky bottom-0 w-full flex items-center h-16 ">
				<div className="flex flex-1 justify-between items-center">
					<HTKButton text="Cancel" className="cancel-item-button" onSubmit={() => dispatch(closeModal())} />
					<HTKButton text="Save Programs" className="create-item-button" onSubmit={saveProgram} />
				</div>
			</div>
		</div>
	);
};

export default AddProgramToCourseModal;
