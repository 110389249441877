const addCircleIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'} 
            height={height ?? '24'} 
            viewBox="0 0 24 24"
            onClick={onClick}
        >
            <path id="Path_1119" data-name="Path 1119" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_1120" data-name="Path 1120" d="M13,7H11v4H7v2h4v4h2V13h4V11H13ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20Z"/>
        </svg>
      </div>
    )
  }
  
  export default addCircleIcon