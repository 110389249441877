import { useState } from "react";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { useCreateProgramMutation } from "../../../services/ProgramService";
import {
  addNewProgramDescription,
  addNewProgramImage,
  addNewProgramInternalTitle,
  addNewProgramPlannedFor,
  addNewProgramSkillRequired,
  addNewProgramTitle,
  newProgramSelector,
  selectProgram,
} from "../../../features/Training/Programs/programSlice";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/Cores/overlaySlice";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { addProgram } from "../../../features/Training/Programs/programsSlice";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { Dialog } from "@headlessui/react";
import { CloseIcon } from "../../../assets/icons/icons";
import {
  HTKButton,
  HTKTabListInput,
  HTKTextArea,
  HTKTextInput,
  HTKTitleBlock,
  HTKUploadInput,
} from "../../atoms/atoms";
import {
  programPlannedForTypes,
  programSkillRequiredTypes,
} from "../../../assets/data/arrays";
import HTKTabInput from "../../atoms/inputField/HTKTabInput";

const CreateProgramModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    internalTitle,
    title,
    description,
    programPlannedFor,
    ProgramSkillRequired,
    image,
  } = useHTKSelector(newProgramSelector);
  const dispatch = useHTKDispatch();

  const [createProgram] = useCreateProgramMutation();

  const createNewProgram = async () => {
    try {
      setIsLoading(true);
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Creating New Program" }));
      const [data] = await Promise.all([
        createProgram({
          internalTitle,
          title,
          description,
          image,
          programPlannedFor,
          ProgramSkillRequired,
        }).unwrap(),
        asyncTimeout(2000),
      ]);

      if (!data) throw Error;
      dispatch(addProgram(data));
      dispatch(selectProgram({ id: data.id, program: data }));
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "PROGRAM CREATED",
          body: "You have successfully created a new program!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to create program",
          body: "Please check your input details and try again.",
        })
      );
    } finally {
      setIsLoading(false);
      dispatch(closeOverlay());
    }
  };

  return (
    <>
      <div className="min-w-[600px] max-w-[650px] py-3 px-6">
        <Dialog.Title className="flex items-center justify-between">
          <div>
            <p className="font-Title font-extrabold text-xl">NEW PROGRAM</p>
            <p className="text-HTKBlack/80">Let’s create a new program.</p>
          </div>
          <CloseIcon
            width={"30"}
            height={"30"}
            className="cursor-pointer"
            onClick={() => dispatch(closeModal())}
          />
        </Dialog.Title>
        <hr className="htk-border my-2" />
        <div className="max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]">
          <HTKTitleBlock
            title="INTERNAL PROGRAM TITLE"
            description="The Internal Program title allows you to title this Program anything you would like. This title is not shown to users on the front end of the HTK Application."
            isRequired={!internalTitle}
          />
          <HTKTextInput
            name="internal-title"
            value={internalTitle}
            placeholder="Internal Program Title"
            onChange={(text) => dispatch(addNewProgramInternalTitle(text))}
          />
          <HTKTitleBlock
            title="USER FACING PROGRAM TITLE"
            description="This is the Program title a user will see inside of the HTK application."
            isRequired={!title}
          />
          <HTKTextInput
            name="title"
            value={title}
            placeholder="Program Title"
            onChange={(text) => dispatch(addNewProgramTitle(text))}
          />
          <HTKTitleBlock
            title="PROGRAM IMAGE"
            description={`Upload an image for this program's thumbnail image. This image will be shown to users during program selection and in the program library.`}
            isRequired={!image}
          />
          <HTKUploadInput
            type="image"
            source={image ?? ""}
            onUpload={(image) => dispatch(addNewProgramImage(image ?? ""))}
          />
        </div>
        <div className="sticky bottom-0 w-full h-12 flex items-center">
          <HTKButton
            text="Create Program"
            isLoading={isLoading}
            disabled={isLoading}
            className="create-item-button"
            onSubmit={createNewProgram}
          />
          <HTKButton
            text="Cancel"
            className="cancel-item-button"
            onSubmit={() => dispatch(closeModal())}
          />
        </div>
      </div>
    </>
  );
};

export default CreateProgramModal;
