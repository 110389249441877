import { useEffect } from 'react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { measurementSelector } from '../../../features/Cores/adminSlice'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import {
  addNewRecipeIngredient, addNewRecipeStep, clearRecipe, deleteRecipeIngredient,
  deleteRecipeStep, recipeIdSelector, recipeSelector, removeRecipeCategory,
  selectRecipe,
  storeRecipe, updateRecipeCarbs, updateRecipeDescription, updateRecipeFats,
  updateRecipeImage, updateRecipeIngredientAmount, updateRecipeIngredientName, updateRecipeIntakeLevel,
  updateRecipeProtein, updateRecipeStepDescription, updateRecipeStepTitle, updateRecipeTitle
} from '../../../features/Library/Recipes/recipeSlice'
import { useCreateRecipeMutation, useDeleteRecipeMutation, useGetRecipeQuery, useUpdateRecipeMutation } from '../../../services/RecipeService'
import {
  HTKDetailTitleblock, HTKPrefixTextInput, HTKTextArea,
  HTKTextInput, HTKTitleBlock, HTKUploadInput
} from '../../atoms/atoms'
import { closeModal, openModal } from '../../../features/Cores/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import {
  ImageSkeleton, InputSkeleton, RecipeCategoryField,
  RecipeIngredientsField, RecipeStepsField, TextAreaSkeleton
} from '../../molecules/molecules'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { addRecipe, deleteRecipeWithId, updateRecipeInfo } from '../../../features/Library/Recipes/recipesSlice'
import { convertCalToKJ, convertOzToG, convertUnitNameForCalAndKJ, convertUnitNameForOzAndG } from '../../../utils/measurementConverter'

const RecipeDetail = () => {
  const measurement = useHTKSelector(measurementSelector)
  const recipeId = useHTKSelector(recipeIdSelector)
  const {
    id, title, image, carbs, fats, protein, description, isActiveOnApp,
    recipeCategory, intakeLevel, recipeIngredient, recipeStep
  } = useHTKSelector(recipeSelector)
  const dispatch = useHTKDispatch()

  const { data: recipeData, isLoading: recipeLoading, isFetching: recipeFetching } = useGetRecipeQuery(recipeId ?? skipToken)
  const [ createRecipe ] = useCreateRecipeMutation()
  const [ updateRecipe ] = useUpdateRecipeMutation()
  const [ deleteRecipe ] = useDeleteRecipeMutation()

  useEffect(() => {
    recipeData && dispatch(storeRecipe({ id: recipeData.id, recipe: recipeData }))
  },[recipeData])

  const duplicateRecipe = async () => {
    if (!title || !image || !carbs || !fats || !protein || !description ||
      !recipeCategory || !intakeLevel || !recipeIngredient || !recipeStep) return
    try {
      dispatch(openOverlay({ text: 'Duplicating Recipe' }))
      const [ data ] = await Promise.all([
        createRecipe({
          title, image, carbs, fats, protein, description,
          recipeCategory, intakeLevel, recipeIngredient, recipeStep
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(addRecipe(data))
      dispatch(selectRecipe({ id: data.id, recipe: data }))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'RECIPE DUPLICATED',
        body: 'You have successfully duplicated a recipe!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to duplicated recipe',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const saveRecipe = async () => {
    try {
      dispatch(openOverlay({ text: 'Updating Recipe' }))
      const [ data ] = await Promise.all([
        updateRecipe({
          id, title, image, carbs, fats, protein, description, isActiveOnApp,
          recipeCategory, intakeLevel, recipeIngredient, recipeStep
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(updateRecipeInfo(data))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'RECIPE UPDATED',
        body: 'You have successfully updated the recipe!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to update recipe',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const removeRecipe = async (recipeId: string) => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting this Recipe' }))
      const [ data ] = await Promise.all([
        deleteRecipe(recipeId).unwrap(),
        asyncTimeout(1000)
      ])
      if (!data) throw Error
      dispatch(deleteRecipeWithId(recipeId))
      dispatch(clearRecipe())
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'RECIPE DELETED',
        body: 'You have successfully deleted the recipe!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete recipe',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <div className='flex flex-col mx-3'>
      <p className='min-h-[2.5em] text-2xl font-bold'></p>
      <div className='w-[33em] bg-HTKWhite htk-container'>

        <HTKDetailTitleblock
          title='VIEW RECIPE'
          mainBtnTitle='Delete Recipe'
          onMainClick={() => dispatch(openModal({
            modalType: MODAL_TYPES.DELETE,
            deleteId: id,
            deleteFn: removeRecipe
          }))}
          subBtnTitle='Save'
          onSubClick={saveRecipe}
          onDuplicate={duplicateRecipe}
        />

        <div className='py-3 px-2 h-screen overflow-y-auto overflow-x-hidden pb-[15em]'>
          <HTKTitleBlock
            title='RECIPE NAME'
            description='Give this recipe a unique name for users to find it in our app.'
            isRequired={!title}
          />
          {(recipeLoading || recipeFetching) ? (<InputSkeleton />) : (
            <HTKTextInput
              name='title'
              value={title}
              placeholder='Title'
              onChange={(text) => dispatch(updateRecipeTitle(text))}
            />
          )}
          <HTKTitleBlock
            title='RECIPE IMAGE'
            description='Upload a delicious looking image of this meal!'
            className='mt-6'
            isRequired={!image}
          />
          {(recipeLoading || recipeFetching) ? (<ImageSkeleton />) : (
            <HTKUploadInput
              type='image'
              source={image ?? ''}
              onUpload={(image) => dispatch(updateRecipeImage(image ?? ''))}
            />
          )}
          <HTKTitleBlock
            title='RECIPE CATEGORY'
            description='Add a category to this Recipe. This allows users to find a recipe by its allocated category.'
            className='mt-6'
            isRequired={!recipeCategory?.length}
          />
          {(recipeLoading || recipeFetching) ? (<InputSkeleton />) : (
            <RecipeCategoryField
              categories={recipeCategory}
              title='UPDATE_RECIPE'
              onDelete={(categoryId) => dispatch(removeRecipeCategory(categoryId))}
            />
          )}
          <HTKTitleBlock
            title='INTAKE LEVEL'
            description='What is the intake level for this meal? This is the overall total calories per serving for this meal.'
            className='mt-6'
            isRequired={!intakeLevel}
          />
          {(recipeLoading || recipeFetching) ? (<InputSkeleton />) : (
            <HTKPrefixTextInput
              title='Intake'
              unit={`${convertUnitNameForCalAndKJ(measurement)}`}
              value={convertCalToKJ(measurement ,intakeLevel)}
              placeholder='Intake Level'
              className='mr-4 w-[90px]'
              onChange={(intake) => dispatch(updateRecipeIntakeLevel({ measurement, value: intake }))}
            />
          )}
          <HTKTitleBlock
            title='MICRO NUTRIENTS'
            description='What are the micro nutrients for this meal? This allows users to pick a meal that aligns with their goals.'
            className='mt-6'
            isRequired={!carbs || !fats || !protein}
          />
          {(recipeLoading || recipeFetching) ? (<InputSkeleton />) : (
            <HTKPrefixTextInput
              title='Carbs'
              unit={`${convertUnitNameForOzAndG(measurement)}`}
              value={convertOzToG(measurement, carbs)}
              placeholder='Carbs'
              className='mr-4 w-[90px]'
              onChange={(carbs) => dispatch(updateRecipeCarbs({ measurement, value: carbs }))}
            />
          )}
          {(recipeLoading || recipeFetching) ? (<InputSkeleton />) : (
            <HTKPrefixTextInput
              title='Fats'
              unit={`${convertUnitNameForOzAndG(measurement)}`}
              value={convertOzToG(measurement, fats)}
              placeholder='Fats'
              className='mr-4 w-[90px]'
              onChange={(fats) => dispatch(updateRecipeFats({ measurement, value: fats }))}
            />
          )}
          {(recipeLoading || recipeFetching) ? (<InputSkeleton />) : (
            <HTKPrefixTextInput
              title='Protein'
              unit={`${convertUnitNameForOzAndG(measurement)}`}
              value={convertOzToG(measurement, protein)}
              placeholder='Protein'
              className='mr-4 w-[90px]'
              onChange={(protein) => dispatch(updateRecipeProtein({ measurement, value: protein }))}
            />
          )}
          <HTKTitleBlock
            title='DESCRIPTION'
            description='Create an enticing description for this recipe. Outline what it is and what a user will get out from making it.'
            className='mt-6'
            isRequired={!description}
          />
          {(recipeLoading || recipeFetching) ? (<TextAreaSkeleton />) : (
            <HTKTextArea
              text={description ?? ''}
              onChange={(text) => dispatch(updateRecipeDescription(text))}
            />
          )}
          <HTKTitleBlock
            title='INGREDIENTS'
            description='What does a user need to make this meal? Add the ingredients below so users know what to use for this meal. The amount should be based on a SINGLE SERVE of this meal.'
            className='mt-10'
            isRequired={!recipeIngredient?.length}
          />
          <RecipeIngredientsField
            ingredients={recipeIngredient}
            onChangeName={(ingredientId, name) => dispatch(updateRecipeIngredientName({ ingredientId, name }))}
            onChangeAmount={(ingredientId, amount) => dispatch(updateRecipeIngredientAmount({ ingredientId, amount }))}
            onDelete={(ingredientId) => dispatch(deleteRecipeIngredient(ingredientId))}
            onAdd={() => dispatch(addNewRecipeIngredient())}
          />
          <HTKTitleBlock
            title='STEPS'
            description='Add in the steps a user needs to complete in order to make their meal.'
            className='mt-6'
            isRequired={!recipeStep?.length}
          />
          <RecipeStepsField
            steps={recipeStep}
            onChangeTitle={(stepId, title) => dispatch(updateRecipeStepTitle({ stepId, title }))}
            onChangeDescription={(stepId, description) => dispatch(updateRecipeStepDescription({ stepId, description }))}
            onDelete={(stepId) => dispatch(deleteRecipeStep(stepId))}
            onAdd={() => dispatch(addNewRecipeStep())}
          />
        </div>
      </div>
    </div>
  )
}

export default RecipeDetail