const avatarPlaceholder = ({ width, height, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg
          className="absolute -left-1 w-12 h-12 text-gray-400"
          fill="currentColor"
          width={width ?? '20'}
          height={height ?? '20'}
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
    )
  }
  
  export default avatarPlaceholder