import { useState } from 'react'
import { DASHBOARD_SUB_TYPES } from '../../assets/data/enums'
import { libraryBoardTitles } from '../../assets/data/arrays'
import { DashboardLayout, SubNavbar } from '../../components/templates/templates'
import { Recipes, Articles } from '../pages'

const Library = () => {
    const [ currentPage, setCurrentPage ] = useState<string>(DASHBOARD_SUB_TYPES.RECIPES)

    const renderPage = () => {
        switch (currentPage) {
            case DASHBOARD_SUB_TYPES.RECIPES:  return <Recipes />
            case DASHBOARD_SUB_TYPES.ARTICLES: return <Articles />
            default:                           return <></>
        }
    }
    
    return (
        <DashboardLayout page='Library'>
        <>
            <div className='w-full h-max bg-HTKWhite'>
                <SubNavbar 
                list={libraryBoardTitles}
                currentPage={currentPage}
                onClick={(pageTitle) => setCurrentPage(pageTitle)}
                />
            </div>
            { renderPage() }
        </>
        </DashboardLayout>
    )
}

export default Library