import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers) => {
		const token = localStorage.getItem("HTKAdminToken");
		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
		}
		return headers;
	},
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const HTKApi = createApi({
	reducerPath: "HTKApi",
	baseQuery: baseQueryWithRetry,
	tagTypes: ["Admin", "Announcement", "Program", "Workout", "Exercise", "ExerciseCategory", "Recipe", "RecipeCategory", "Article", "User", "Course"],
	endpoints: () => ({}),
});
