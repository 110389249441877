import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialExerciseState } from '../../../assets/data/initialState'
import { ExerciseData, NewExerciseData } from '../../../types/serviceTypes'
import { ExerciseCategory } from '../../../types/stateTypes'

export type ExerciseSliceType = {
    id:          string
    exercise:    ExerciseData
}

export type NewExerciseSliceType = {
    newExercise: NewExerciseData
}

export const exerciseSlice = createSlice({
    name: 'exercsie',
    initialState: InitialExerciseState,
    reducers: {
        // Select Existing Exercise
        selectExercise: (state, action:PayloadAction<ExerciseSliceType>) => {
            state.id = action.payload.id
        },
        storeExercise: (state, action:PayloadAction<ExerciseSliceType>) => {
            state.exercise = action.payload.exercise
        },
        updateExerciseTitle: (state, action:PayloadAction<string>) => {
            state.exercise.title = action.payload
        },
        updateExerciseVideo: (state, action:PayloadAction<string>) => {
            state.exercise.video = action.payload
        },
        updateExerciseImage: (state, action:PayloadAction<string>) => {
            state.exercise.image = action.payload
        },
        updateExerciseCategory: (state, action:PayloadAction<ExerciseCategory[]>) => {
            state.exercise.exerciseCategory = action.payload
        },
        updateExerciseDescription: (state, action:PayloadAction<string>) => {
            state.exercise.description = action.payload
        },
        updateExerciseActiveStatus: (state, action:PayloadAction<boolean>) => {
            state.exercise.isActiveOnApp = action.payload
        },
        removeExerciseCategory: (state, action:PayloadAction<string>) => {
            const filteredCategories = state.exercise.exerciseCategory?.filter(category => category.id !== action.payload)
            state.exercise.exerciseCategory = filteredCategories
        },
        clearExercise: (state) => {
            state.id       = ''
            state.exercise = { id: '' }
        },

        // Add New Exercise
        clearNewExercise: (state) => {
            state.newExercise = {
                title: '',
                video: '',
                exerciseCategory: [],
                description: '',
                image: '',
            }
        },
        addNewExerciseTitle: (state, action:PayloadAction<string>) => {
            state.newExercise.title = action.payload
        },
        addNewExerciseVideo: (state, action:PayloadAction<string>) => {
            state.newExercise.video = action.payload
        },
        addNewExerciseImage: (state, action:PayloadAction<string>) => {
            state.newExercise.image = action.payload
        },
        addNewExerciseCategory: (state, action:PayloadAction<ExerciseCategory[]>) => {
            state.newExercise.exerciseCategory = action.payload
        },
        addNewExerciseDescription: (state, action:PayloadAction<string>) => {
            state.newExercise.description = action.payload
        },
        removeNewExerciseCategory: (state, action:PayloadAction<string>) => {
            const filteredCategories = state.newExercise.exerciseCategory.filter(category => category.id !== action.payload)
            state.newExercise.exerciseCategory = filteredCategories
        },
    }
})

export const exerciseIdSelector  = (state: RootState) => state.exercise.id
export const exerciseSelector    = (state: RootState) => state.exercise.exercise
export const newExerciseSelector = (state: RootState) => state.exercise.newExercise
export const {
    selectExercise,
    storeExercise,
    updateExerciseTitle,
    updateExerciseVideo,
    updateExerciseImage,
    updateExerciseCategory,
    updateExerciseDescription,
    updateExerciseActiveStatus,
    removeExerciseCategory,
    clearExercise,

    clearNewExercise,
    addNewExerciseTitle,
    addNewExerciseVideo,
    addNewExerciseImage,
    addNewExerciseCategory,
    addNewExerciseDescription,
    removeNewExerciseCategory,
} = exerciseSlice.actions
export default exerciseSlice.reducer