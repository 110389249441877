import { useState } from 'react'
import { FormikType, RFC } from '../../../types/propTypes'
import { LoginArgs } from '../../../types/serviceTypes'
import { HTKButton, HTKLoginTextInput } from '../../atoms/atoms'

const LoginField:RFC<FormikType<LoginArgs>> = ({ formik }) => {
    const [ isVisible, setIsVisible ] = useState(false)
    return (
        <div className='w-full py-2 px-5 text-[0.9em]'>
            <p className='pb-2'>
                Welcome back to Hard To Kill. 
                Sign in using your specific Email and Password to access the admin panel.
            </p>
            <form 
                className='w-full'
                onSubmit={formik.handleSubmit}
            >
                <HTKLoginTextInput 
                    label='Email'
                    name='email'
                    type='email'
                    value={formik.values.email}
                    required={true}
                    touched={formik.touched.email}
                    error={formik.errors.email ?? null}
                    onChange={(text) => formik.setFieldValue('email', text)}
                />
                <HTKLoginTextInput 
                    label='Password'
                    name='password'
                    type={isVisible ? 'text' : 'password'}
                    value={formik.values.password}
                    required={true}
                    isVisible={isVisible}
                    touched={formik.touched.password}
                    error={formik.errors.password}
                    onIconPress={setIsVisible}
                    onChange={(text) => formik.setFieldValue('password', text)}
                />
                <div className='py-2'>
                    <HTKButton 
                        text='SIGN IN'
                        isLoading={formik.isSubmitting}
                        disabled={formik.isSubmitting || (!formik.values.email || !formik.values.password)}
                        className='login-button'
                        onSubmit={formik.handleSubmit}
                    />
                </div>
            </form>
        </div>
    )
}

export default LoginField