const eyeOpenIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 24 24"
        >
            <path id="Path_884" data-name="Path 884" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_885" data-name="Path 885" d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"/>
        </svg>
      </div>
    )
  }
  
  export default eyeOpenIcon