import { HTKApi } from '../app/api'
import { FreeAccessArgs, FreeAccessData, PaginationPayload, RetrieveCSVArgs } from '../types/serviceTypes'
import { CSVUser, User } from '../types/stateTypes'

export const userApi = HTKApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllUsers: builder.query<User[], PaginationPayload>({
            query: ({ search, skip, take }) => ({
                url: '/admin/users',
                params: { search, skip, take }
            }),
            providesTags: ['User']
        }),
        getPayingUsers: builder.query<User[], PaginationPayload>({
            query: ({ search, skip, take }) => ({
                url: '/admin/users/paying',
                params: { search, skip, take }
            }),
            providesTags: ['User']
        }),
        getTrialUsers: builder.query<User[], PaginationPayload>({
            query: ({ search, skip, take }) => ({
                url: '/admin/users/trial',
                params: { search, skip, take }
            }),
            providesTags: ['User']
        }),
        getUser: builder.query<User, string>({
            query: (userId) => `/admin/users/${userId}`,
            providesTags: ['User']
        }),
        restrictUser: builder.mutation<User, string>({
            query: (userId) => ({
                url: `/admin/users/${userId}/restrict`,
                method: 'PUT'
            }),
            invalidatesTags: ['User']
        }),
        unrestrictUser: builder.mutation<User, string>({
            query: (userId) => ({
                url: `/admin/users/${userId}/unrestrict`,
                method: 'PUT'
            }),
            invalidatesTags: ['User']
        }),
        getUserCSV: builder.query<CSVUser[], RetrieveCSVArgs>({
            query: ({ startDate, endDate, isDownloadAllData }) => ({
                url: '/admin/users/csv/data',
                params: { startDate, endDate, isDownloadAllData } 
            }),
            providesTags: ['User']
        }),
        freeAccessToUser: builder.mutation<FreeAccessData,FreeAccessArgs>({
            query : ({userId, hasFreeAccess}) =>({
                url :`/admin/users/subscription/free-access`,
                method:'PATCH',
                body:{
                    userId,
                    hasFreeAccess
                }
            }),
            invalidatesTags:['User']
        })
    })
})

export const {
    useLazyGetAllUsersQuery,
    useLazyGetPayingUsersQuery,
    useLazyGetTrialUsersQuery,
    useGetUserQuery,
    useRestrictUserMutation,
    useUnrestrictUserMutation,
    useLazyGetUserCSVQuery,
    useFreeAccessToUserMutation
} = userApi