import { useHTKDispatch, useHTKSelector } from '../../app/hooks'
import { recipeIdSelector, recipeSelector, updateRecipeActiveStatus } from '../../features/Library/Recipes/recipeSlice'
import { RecipeDetail, RecipesList } from '../../components/organisms/organisms'
import { ActiveStatusField } from '../../components/molecules/molecules'
import { HTKEmptyDetailBox } from '../../components/atoms/atoms'

const Recipes = () => {
  const recipeId = useHTKSelector(recipeIdSelector)
  const { isActiveOnApp } = useHTKSelector(recipeSelector)
  const dispatch = useHTKDispatch()

  return (
    <div className='w-full h-full flex justify-center'>
      <RecipesList />
      {recipeId ? (
        <>
        <RecipeDetail />
        <ActiveStatusField 
          width='w-[17em]'
          isActive={isActiveOnApp}
          onChangeStatus={(isOpen) => dispatch(updateRecipeActiveStatus(isOpen))}
        />
        </>
      ) : (
        <HTKEmptyDetailBox title='recipe'/>
      )}
    </div>
  )
}

export default Recipes