import { RFC } from '../../../types/propTypes'
import { RecipeCategory } from '../../../types/stateTypes'
import { useHTKDispatch } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { AddNewIcon } from '../../../assets/icons/icons'
import { openModal } from '../../../features/Cores/modalSlice'
import { HTKCategoryButton } from '../../atoms/atoms'

type RecipeCategoryFieldProps = {
    categories: RecipeCategory[] | undefined
    title:      string
    onDelete:   (categoryId: string) => void
}

const RecipeCategoryField:RFC<RecipeCategoryFieldProps> = ({
    categories,
    title,
    onDelete,
}) => {
    const dispatch = useHTKDispatch()

    return (
        <div className='w-full flex items-center mt-2 mb-5'>
            <AddNewIcon
                className='w-[30px] h-[30px] flex items-center justify-center
                rounded-[3px] border-[2px] border-HTKBlack border-solid cursor-pointer
                drop-shadow-md hover:shadow-md transition-all duration-200'
                onClick={() => dispatch(openModal({
                    modalType: MODAL_TYPES.RECIPE_CATEGORY,
                    title: title ?? undefined
                }))}
            />
            <div className='ml-1 flex flex-wrap gap-1'>
                {!categories?.length ? (
                    <div className='text-HTKBlack/80 ml-2'>
                        No Recipe Category
                    </div>
                ): null}
                {categories?.map((category, index) => (
                    <HTKCategoryButton
                        key={index}
                        text={category.title}
                        onClick={() => onDelete(category.id)}
                    />
                ))}
            </div>
        </div>
    )
}

export default RecipeCategoryField