import { useEffect, useState } from 'react'
import { closeModal, modalSelector, openModal } from '../../../features/Cores/modalSlice'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { addNewExerciseCategory, exerciseSelector, newExerciseSelector, updateExerciseCategory } from '../../../features/Training/Exercises/exerciseSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { Dialog } from '@headlessui/react'
import { AddCircleIcon, AddIcon, CloseIcon } from '../../../assets/icons/icons'
import { CardError, CardLoading, CardLogo, HTKButton, HTKTextInput } from '../../atoms/atoms'
import { ExerciseCategory } from '../../../types/stateTypes'
import { useCreateExerciseCategoryMutation, useDeleteExerciseCategoryMutation, useLazyGetExerciseCategoriesQuery, useUpdateExerciseCategoryMutation } from '../../../services/ExerciseService'
import { addBrandNewCategory, clearExerciseCategories, exerciseCategoriesSelector, setExerciseCategories, setInitialExerciseCategories } from '../../../features/Training/Exercises/exerciseCategorySlice'
import { domAnimation, LazyMotion } from 'framer-motion'
import InfiniteScroll from 'react-infinite-scroller'
import asyncTimeout from '../../../utils/asyncTimeout'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import { v1 as uuid } from 'uuid'

const AddExerciseCategoryModal = () => {
  const searchText = ''
  const take = 10
  const [ skip, setSkip ] = useState(0)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const [ selectedCategories, setSelectedCategories ] = useState<ExerciseCategory[]>([])
  const [ clickedCategory, setClickedCategory ] = useState<ExerciseCategory>()

  const { title } = useHTKSelector(modalSelector)
  const exerciseCategoryState = useHTKSelector(exerciseCategoriesSelector)
  const { exerciseCategory: exerciseCategories } = useHTKSelector(exerciseSelector)
  const { exerciseCategory: newExerciseCategories } = useHTKSelector(newExerciseSelector)
  const dispatch = useHTKDispatch()

  const [ getCategories, { isLoading, isError } ] = useLazyGetExerciseCategoriesQuery()
  const [ createCategory ] = useCreateExerciseCategoryMutation()
  const [ updateCategoryInfo ] = useUpdateExerciseCategoryMutation()
  const [ deleteCategoryWithId ] = useDeleteExerciseCategoryMutation()

  useEffect(() => {
    updateSelectedCategories()
  },[title])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreItems(take, skip)
  }

  const loadMoreItems = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const items = await getCategories({ take, skip, search: searchText }).unwrap()
  
      if (!items?.length && skip === 0) dispatch(clearExerciseCategories())
      if (!items || !items.length) {
        setHasMore(false)
      } else {
        if (skip === 0) {
          dispatch(setInitialExerciseCategories({ categories: items }))
          setHasMore(true)
          setSkip(10)
        } else if (items.length < take) {
          dispatch(setExerciseCategories({ categories: items }))
          setHasMore(false)
        } else {
          dispatch(setExerciseCategories({ categories: items }))
          setHasMore(true)
          setSkip(skip + 10)
        }
      }
  
      setTimeout(() => {
        setIsLoadMore(false)
      }, 1000)
    } catch (error) {
      console.log({error})
    }
  }

  const addNewCategory = () => {
    const newCategoryId = uuid()
    const newCategoryObj = {
      createdAt:  new Date(),
      id:         `new-category_${newCategoryId}`,
      title:      'New Category',
      updatedAt:  new Date(),
    }
    dispatch(addBrandNewCategory(newCategoryObj))
    setClickedCategory(newCategoryObj)
  }

  const createNewCategory = async () => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Creating Exercise Category' }))
      const [data] = await Promise.all([
        createCategory(clickedCategory?.title ?? '').unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'EXERCISE CATEGORY CREATED',
        body: 'You have successfully created the exercise category!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to create exercise category',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const updateCategory = async () => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Updating Exercise Category' }))
      const [ data ] = await Promise.all([
        updateCategoryInfo({
          categoryId: clickedCategory?.id ?? '',
          title: clickedCategory?.title ?? ''
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'EXERCISE CATEGORY UPDATED',
        body: 'You have successfully updated the exercise category!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to update exercise category',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const deleteCategory = async () => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting Exercise Category' }))
      const [data] = await Promise.all([
        deleteCategoryWithId(clickedCategory?.id ?? '').unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'EXERCISE CATEGORY DELETED',
        body: 'You have successfully deleted the exercise category!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete exercise category',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const updateSelectedCategories = () => {
    switch (title) {
      case 'CREATE_EXERCISE': return setSelectedCategories(newExerciseCategories ?? [])
      case 'UPDATE_EXERCISE': return setSelectedCategories(exerciseCategories ?? [])
      default:                return
    }
  }

  const saveCategories = () => {
    switch (title) {
      case 'CREATE_EXERCISE': {
        dispatch(addNewExerciseCategory(selectedCategories))
        dispatch(openModal({
          modalType: MODAL_TYPES.CREATE_EXERCISE
        }))
        return
      }
      case 'UPDATE_EXERCISE': {
        dispatch(updateExerciseCategory(selectedCategories))
        dispatch(closeModal())
        return
      }
      default:
        return
    }
  }

  const cancelCategories = () => {
    switch (title) {
      case 'CREATE_EXERCISE': {
        dispatch(openModal({
          modalType: MODAL_TYPES.CREATE_EXERCISE,
        }))
        return
      }
      case 'UPDATE_EXERCISE': {
        dispatch(closeModal())
        return
      }
      default: 
        return
    }
  }

  const renderCategoryTitles = () => {
    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!exerciseCategoryState.length) return (
      <div className='w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7'>
        No Exercise Category
      </div>
    )

    return (
      <LazyMotion
        features={domAnimation}
        key='exercise-category-card'
      >
        <div className='w-full'>
          {exerciseCategoryState.map((category, index) => {
            const isSelected = selectedCategories.find(selected => selected.id === category.id)

            return (
              <div
                className='flex items-center justify-between cursor-pointer drop-shadow-md my-2'
                key={index}
              >
                <p
                  className={`${clickedCategory?.id !== category.id
                    ? 'bg-HTKMiddleGrey text-HTKBlack'
                    : 'bg-HTKBlack text-HTKWhite'} w-full px-2 py-1
                      border-solid border-[1px] border-HTKBorder rounded-l-[3px]
                      font-semibold font-Title`}
                  onClick={() => setClickedCategory(category)}
                >
                  {category.title}
                </p>
                <AddIcon
                  width={'30'}
                  height={'30'}
                  className={`${!isSelected
                    ? 'bg-HTKMiddleGrey fill-HTKBlack'
                    : 'bg-HTKBlack fill-HTKWhite'} py-[1px]
                      border-solid border-[1px] border-HTKBorder rounded-r-[3px]`}
                  onClick={() => !isSelected && setSelectedCategories(prev => [...prev, category])}
                />
              </div>
            )
          })}
        </div>
      </LazyMotion>
    )
  }

  return (
    <>
      <div 
        className={`py-3 px-6 transition-all duration-300 relative
        ${(!selectedCategories.length && !clickedCategory) && 'w-[400px]'}
        ${((!selectedCategories.length && clickedCategory) ||
          (selectedCategories.length && !clickedCategory)) && 'w-[600px]'}
          ${(selectedCategories.length && clickedCategory) && 'w-[800px]'}`}
      >
        <Dialog.Title className='flex items-center justify-between'>
          <div>
            <p className='font-Title font-extrabold text-xl'>
              EDIT EXERCISE CATEGORIES
            </p>
            <p className='text-HTKBlack/80'>
              Add categories to this exercise and edit your exercising category list.
            </p>
          </div>
          <CloseIcon 
            width={'30'} 
            height={'30'}
            className='cursor-pointer'
            onClick={() => dispatch(closeModal())}
          />
        </Dialog.Title>
        <hr className='htk-border my-2'/>
        <div 
          className={`grid transition-all duration-200
          ${(!selectedCategories.length && !clickedCategory) && 'grid-cols-1 w-[90%] mx-auto'}
          ${((!selectedCategories.length && clickedCategory) ||
            (selectedCategories.length && !clickedCategory)) && 'grid-cols-2'}
          ${(selectedCategories.length && clickedCategory) && 'grid-cols-3'}`}
        >
          {/* All Available Categories */}
          <div className='w-full px-4 max-h-[28em] overflow-y-auto overflow-x-hidden'>
            <div className='flex items-center justify-between'>
              <p className='font-Title font-bold'>
                CATEGORIES
              </p>
              <AddCircleIcon 
                onClick={addNewCategory}
                className='cursor-pointer'
              />
            </div>
            <div className='w-full max-h-[25em] overflow-y-auto overflow-x-hidden pb-[1em]'>
              <InfiniteScroll
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                loader={<CardLoading key={0} />}
                threshold={150}
              >
                { renderCategoryTitles() }
              </InfiniteScroll>
            </div>
          </div>

          {/* All Selected Categories */}
          {selectedCategories.length ? (
            <div className='w-full px-4 max-h-[28em] overflow-y-auto overflow-x-hidden'>
              <p className='font-Title font-bold'>
                SELECTED CATEGORIES
              </p>
              <div className='w-full'>
                {selectedCategories.map((category, index) => (
                <div 
                  className='flex items-center justify-between cursor-pointer drop-shadow-md my-2'
                  key={index}
                >
                  <p 
                    className='w-full px-2 py-1 font-semibold font-Title
                    bg-HTKRed text-HTKWhite
                    border-solid border-[1px] border-HTKBorder rounded-l-[3px]'
                  >
                    {category.title}
                  </p>
                  <CloseIcon 
                    width={'24'}
                    height={'24'}
                    className='py-[4px] border-solid border-[1px] border-HTKBorder 
                    rounded-r-[3px] bg-HTKRed fill-HTKWhite hover:bg-HTKWhite
                    hover:fill-HTKRed hover:border-HTKRed transition-all duration-300'
                    onClick={() => {
                      const filteredCategories = selectedCategories.filter(selectedCategory => selectedCategory.id !== category.id)
                      setSelectedCategories(filteredCategories)
                    }}
                  />
                </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* Clicked Category to Modify */}
          {clickedCategory ? (
            <div className='w-full px-4 max-h-[28em] overflow-y-auto overflow-x-hidden'>
              <p className='font-Title font-bold'>
                CATEGORY DETAILS
              </p>
              <p className='text-HTKBlack/80 text-sm'>
                Edit this categories details.
              </p>
              <div className=' my-3'>
                <p className='font-medium'>
                  Category title
                </p>
                <HTKTextInput 
                  name='category'
                  value={clickedCategory.title}
                  onChange={(text) => setClickedCategory({
                    ...clickedCategory,
                    title: text
                  })}
                />
              </div>
            </div>
          ) : null}

        </div>
      </div>
      
      <div className='sticky bottom-0 w-full flex items-center justify-between h-12 pl-6 pr-16'>
        <div className='flex items-center'>
          <HTKButton
            text='Save Categories'
            className='create-item-button'
            onSubmit={saveCategories}
          />
          <HTKButton
            text='Cancel'
            className='cancel-item-button'
            onSubmit={cancelCategories}
          />
        </div>
        {clickedCategory ? (
        <div className='flex items-center'>
          <HTKButton
              text={`${clickedCategory.id.split('_')[0] === 'new-category' 
              ? 'Create New Category' 
              : 'Save Details'}`}
            isLoading={false}
            disabled={false}
            className='save-item-button'
            onSubmit={clickedCategory.id.split('_')[0] === 'new-category'
            ? createNewCategory
            : updateCategory}
          />
          <HTKButton
              text='Delete'
            className='cancel-item-button'
            onSubmit={deleteCategory}
          />
        </div>
        ) : null}
      </div>
    </>
  )
}

export default AddExerciseCategoryModal