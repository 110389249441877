import { AddIcon, InstallIcon } from '../../../assets/icons/icons'
import { RFC } from '../../../types/propTypes'
import { HTKButton } from '../atoms'

type HTKSearchTitleBlockProps = {
    title:       string
    btnTitle?:   string
    onClick?:    () => void
    onDownload?: () => void
}

const HTKSearchTitleBlock:RFC<HTKSearchTitleBlockProps> = ({
    title,
    btnTitle,
    onClick,
    onDownload,
}) => {
    return (
        <div 
            className='w-full flex items-center justify-between px-3 py-2
            border-b-[1px] border-HTKBorder border-solid'
        >
            <div className='font-Title font-semibold text-HTKBlack/90 text-lg'>
                {title}
            </div>
            {(btnTitle && onClick) ? (
                <HTKButton 
                    text={btnTitle ?? 'New'}
                    className='new-button'
                    icon={<AddIcon />}
                    onSubmit={onClick}
                />
            ): null}
            {onDownload ? (
                <InstallIcon 
                    width={'30'}
                    height={'30'}
                    className='fill-HTKBlack cursor-pointer'
                    onClick={onDownload}
                />
            ) : null}
        </div>
    )
}

export default HTKSearchTitleBlock