import { RFC } from '../../../types/propTypes'

type HTKTitleBlockProps = {
    title: string
    description: string
    isRequired?: boolean
    className?: string
    error?: string | string[]
}

const HTKTitleBlock: RFC<HTKTitleBlockProps> = ({
    title,
    description,
    isRequired,
    className,
    error
}) => {
    return (
        <div className={`w-full ${className}`}>

            <p className='font-Title font-extrabold'>

                {title}
                {isRequired && (<span className='text-HTKRed text-sm ml-1'>* {error}</span>)}
            </p>
            <p className='text-HTKBlack/80 text-sm'>
                {description}
            </p>

        </div>
    )
}

export default HTKTitleBlock