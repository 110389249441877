import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import asyncTimeout from "../../../utils/asyncTimeout";
import { HTKDetailTitleblock, TitleCard } from "../../atoms/atoms";
import { CourseManagementField } from "../../molecules/molecules";
import { useGetCourseQuery, useRemoveCourseMutation, useSaveProgramToCourseMutation, useUpdateCourseMutation } from "../../../services/CourseService";
import { clearCourse, courseIdSelector, courseProgramSelector, courseSelector, removeSingleCourseProgram, saveCoursePrograms, storeCourse } from "../../../features/Training/Courses/courseSlice";
import { deletecourseWithId, updateCourseInfo } from "../../../features/Training/Courses/coursesSlice";
import { CourseUpdateArgs } from "../../../types/CourseTypes";
import { addProgramRecommendation, clearRecommendationState, RecommendationOptionType } from "../../../features/Training/Programs/programSlice";
import { programRecommendationConverter } from "../../../utils/filterListFormatter";
import moment from "moment";
import { Program } from "../../../types/CourseTypes";
import ProgramPopover from "../../molecules/Popovers/ProgramPopover";

const CourseDetail = () => {
	const courseId = useHTKSelector(courseIdSelector);
	const course = useHTKSelector(courseSelector);
	const coursePrograms = useHTKSelector(courseProgramSelector);
	const dispatch = useHTKDispatch();
	const { data: courseData, isLoading: isCourseLoading, isFetching: isCourseFetching } = useGetCourseQuery(courseId ? { id: courseId } : skipToken);
	const [removeCourse] = useRemoveCourseMutation();
	const [updateCourse, { isLoading: isCourseUpdating }] = useUpdateCourseMutation();
	const [savePrograms] = useSaveProgramToCourseMutation();
	const [selectedProgram, setSelectedProgram] = useState<Program | undefined>(undefined);

	useEffect(() => {
		if (courseData) {
			dispatch(storeCourse({ id: courseData.id, course: courseData }));
			dispatch(saveCoursePrograms(courseData.programs));
		}
	}, [courseData]);

	const deleteCourse = async (courseId: string) => {
		try {
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Deleting this course" }));
			const [data] = await Promise.all([removeCourse(courseId).unwrap(), asyncTimeout(2000)]);
			if (!data) throw Error;
			dispatch(clearCourse());
			dispatch(deletecourseWithId(courseId));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "COURSE DELETED",
					body: "You have successfully deleted the Course!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to delete course",
					body: "Something went wrong. Please try again later.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};
	const updateCourseDetails = async () => {
		if (!course.internalTitle || !course.userFacingTitle || !course.snippetDescription || !course.fullDescription || !course.image || !course.skillRequired || !course.courseProgramLength || !course.coursePlannedFor) return;
		const updateCourseData: CourseUpdateArgs = {
			id: course.id,
			internalTitle: course.internalTitle,
			userFacingTitle: course.userFacingTitle,
			snippetDescription: course.snippetDescription,
			fullDescription: course.fullDescription,
			image: course.image,
			skillRequired: course.skillRequired,
			courseProgramLength: course.courseProgramLength,
			isActive: course.isActive,
			coursePlannedFor: course.coursePlannedFor?.map((coursePlan) => {
				return { title: coursePlan.title };
			}),
		};
		try {
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Updating this course" }));
			const [data] = await Promise.all([
				updateCourse(updateCourseData).unwrap(),
				savePrograms({
					courseId: courseId,
					programs: coursePrograms.map((program, index) => {
						return { id: program.id, index: index };
					}),
				}),
				asyncTimeout(2000),
			]);
			if (!data) throw Error;
			dispatch(updateCourseInfo(data));
			dispatch(storeCourse({ id: data.id, course: data }));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "COURSE UPDATED",
					body: "You have successfully deleted the Course!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to update course",
					body: "Something went wrong. Please try again later.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	const onProgramRemove = (program: Program) => {
		dispatch(removeSingleCourseProgram(program));
	};

	const moveProgramPosition = (program: Program, isMovingUp: boolean) => {
		if (program.index === 0 && isMovingUp) {
			return;
		}
		if (program.index === coursePrograms.length - 1 && !isMovingUp) {
			return;
		}
		const newIndex = isMovingUp ? program.index - 1 : program.index + 1;
		const tempPrograms = [...coursePrograms];
		tempPrograms.splice(program.index, 1);
		tempPrograms.splice(newIndex, 0, program);
		const updatedPrograms = tempPrograms.map((program, indexValue) => {
			return {
				...program,
				index: indexValue,
			};
		});
		dispatch(saveCoursePrograms(updatedPrograms));
	};

	function renderPrograms() {
		if (!coursePrograms.length) return;
		return coursePrograms.map((program, index) => {
			return (
				<div className="w-[100%]" key={index}>
					<ProgramPopover
						program={program}
						hideUpButton={program.index === 0}
						hideDownButton={program.index === coursePrograms.length - 1}
						programLength={course.courseProgramLength}
						isActive={course.isActive}
						onPress={setSelectedProgram}
						onDeletePress={onProgramRemove}
						onUpPress={(program) => moveProgramPosition(program, true)}
						onDownPress={(program) => moveProgramPosition(program, false)}
					/>
				</div>
			);
		});
	}

	return (
		<div className="flex flex-col mx-3">
			<p className="min-h-[2.5em] text-2xl font-bold"></p>
			<div className="w-[70vw] bg-HTKWhite htk-container">
				<HTKDetailTitleblock
					title="VIEW COURSE"
					subTitle={course.internalTitle}
					mainBtnTitle="Course Settings"
					onMainClick={() =>
						dispatch(
							openModal({
								modalType: MODAL_TYPES.EDIT_COURSE,
							})
						)
					}
					subBtnTitle="Delete Course"
					onSubClick={() =>
						dispatch(
							openModal({
								modalType: MODAL_TYPES.DELETE,
								deleteId: courseId,
								deleteFn: deleteCourse,
							})
						)
					}
				/>
				<div className="h-screen grid grid-cols-detail-2cols">
					<div className=" flex flex-1 flex-col items-start justify-start">
						<div className="w-full bg-HTKMiddleGrey flex items-center justify-between px-3  border-b-[1px] border-HTKBorder border-solid">
							<div className="font-Title font-semibold text-HTKBlack/90 text-lg">COURSE PROGRAMS</div>
							<p className={`text-HTKGreen hover:cursor-pointer`} onClick={() => dispatch(openModal({ modalType: MODAL_TYPES.ADD_PROGRAM_TO_COURSE, title: "ADD PROGRAMS TO COURSE" }))}>
								ADD PROGRAMS
							</p>
						</div>
						{renderPrograms()}
					</div>
					<CourseManagementField isLoading={isCourseLoading || isCourseFetching} isActive={course.isActive} onSaveChangesPress={() => updateCourseDetails()} />
				</div>
			</div>
		</div>
	);
};

export default CourseDetail;
