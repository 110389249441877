import { RecipeCategorySliceType } from "../../features/Library/Recipes/recipeCategorySlice";
import { NewRecipeSliceType, RecipeSliceType } from "../../features/Library/Recipes/recipeSlice";
import { RecipesSliceType } from "../../features/Library/Recipes/recipesSlice";
import { ExerciseCategorySliceType } from "../../features/Training/Exercises/exerciseCategorySlice";
import { ExerciseSliceType, NewExerciseSliceType } from "../../features/Training/Exercises/exerciseSlice";
import { ExercisesSliceType } from "../../features/Training/Exercises/exercisesSlice";
import { NewProgramSliceType, ProgramSliceType } from "../../features/Training/Programs/programSlice";
import { ProgramsSliceType } from "../../features/Training/Programs/programsSlice";
import { ProgramWorkoutsSliceType } from "../../features/Training/Programs/programWorkoutsSlice";
import { WeekSliceType } from "../../features/Training/Programs/weekSlice";
import { ExerciseGroupSliceType } from "../../features/Training/Workouts/exerciseGroupSlice";
import { NewWorkoutSliceType, WorkoutSliceType } from "../../features/Training/Workouts/workoutSlice";
import { WorkoutsSliceType } from "../../features/Training/Workouts/workoutsSlice";
import { Modal, Overlay, Snackbar } from "../../types/propTypes";
import { LoginArgs } from "../../types/serviceTypes";
import { ERROR_MESSAGES, SNACKBAR_TYPES, WORKOUT_TYPES } from "./enums";
import { v1 as uuid } from "uuid";
import { ArticlesSliceType } from "../../features/Library/Articles/articlesSlice";
import { ArticleSliceType, NewArticleSliceType } from "../../features/Library/Articles/articleSlice";
import { AnnouncementSliceType, NewAnnouncementSliceType } from "../../features/Announcement/announcementSlice";
import { AnnouncementsSliceType } from "../../features/Announcement/announcementsSlice";
import { UserSliceType } from "../../features/User/userSlice";
import { UsersSliceType } from "../../features/User/usersSlice";
import { AdminSliceType } from "../../features/Cores/adminSlice";
import { CourseProgram, CourseSliceType, NewCourseSliceType } from "../../features/Training/Courses/courseSlice";
import { CoursesSliceType } from "../../features/Training/Courses/coursesSlice";
import { AnnouncementCoursesSliceType } from "../../features/Announcement/announcementCourseSlice";

// --- Snackbar ---
export const InitialSnackbarState: Snackbar = {
	isDisplayed: false,
	snackbarType: SNACKBAR_TYPES.ERROR,
	snackbarMessage: ERROR_MESSAGES.LOGIN,
	snackbarTimeout: 10000,
	snackbarBtnText: "CLOSE",
	snackbarBtnFunc: () => {},
};

// --- Modal ---
export const InitialModalState: Modal = {
	isDisplayed: false,
	modalType: null,
	title: "",
	body: "",
	deleteId: "",
	dayId: "",
	deleteFn: () => {},
	addItemFn: () => {},
};

// --- Overlay ---
export const InitialOverlayState: Overlay = {
	isLoading: false,
	text: "",
};

// --- Auth ---
export const InitialLoginState: LoginArgs = {
	email: "",
	password: "",
};

// --- Admin ---
export const InitialAdminState: AdminSliceType = {
	admin: {
		createdAt: new Date(),
		email: "",
		firstName: "",
		id: "",
		lastName: "",
		unitOfMeasurement: "Metric",
		mobile: "",
		password: "",
		updatedAt: new Date(),
	},
};

// --- Announcement ---
export const InitialAnnouncementState: AnnouncementSliceType & NewAnnouncementSliceType = {
	id: "",
	announcement: { id: "" },
	newAnnouncement: {
		title: "",
		link: "",
		image: "",
		body: "",
		courseId: "",
	},
};

export const InitialAnnouncementsState: AnnouncementsSliceType = {
	annoucements: [],
};

export const InitialAnnouncementProgramsState: AnnouncementCoursesSliceType = {
	courses: [],
};

// --- Program ---
export const InitialProgramsState: ProgramsSliceType = {
	programs: [],
};

export const InitialProgramState: ProgramSliceType & NewProgramSliceType = {
	id: "",
	program: { id: "" },
	newProgram: {
		userFacingTitle: "",
		internalTitle: "",
		title: "",
		programPlannedFor: [
			{
				id: "",
				title: "Gym",
			},
		],
		ProgramSkillRequired: [
			{
				id: "",
				title: "Beginner",
			},
		],
		description: "",
		image: "",
	},
};

export const InitialWeekState: WeekSliceType = {
	week: {
		id: "",
		createdAt: "",
		updatedAt: "",
		weekIndex: 0,
		weekName: "",
		programId: "",
		day: [],
	},
};

export const InitialProgramWorkoutsState: ProgramWorkoutsSliceType = {
	workouts: [],
};

// --- Workout ---
export const InitialExerciseGroupState: ExerciseGroupSliceType = {
	exerciseGroup: {
		createdAt: new Date(),
		execution: "",
		executionDescription: "",
		exerciseInExerciseGroups: [],
		id: "",
		updatedAt: new Date(),
		workoutId: "",
		index: 0,
	},
	newExerciseGroup: {
		createdAt: new Date(),
		execution: "",
		executionDescription: "",
		exerciseInExerciseGroups: [],
		id: "",
		updatedAt: new Date(),
		workoutId: "",
		index: 0,
	},
};

export const InitialWorkoutState: WorkoutSliceType & NewWorkoutSliceType = {
	id: "",
	workout: { id: "" },
	newWorkout: {
		internalTitle: "",
		title: "",
		type: WORKOUT_TYPES.Circuit,
		circuitRotations: 1,
		description: "",
	},
};

export const InitialWorkoutsState: WorkoutsSliceType = {
	workouts: [],
};

// --- Exercise ---
export const InitialExerciseState: ExerciseSliceType & NewExerciseSliceType = {
	id: "",
	exercise: { id: "" },
	newExercise: {
		title: "",
		video: "",
		exerciseCategory: [],
		description: "",
		image: "",
	},
};

export const InitialExercisesState: ExercisesSliceType = {
	exercises: [],
};

export const InitialExerciseCategoriesState: ExerciseCategorySliceType = {
	categories: [],
};

// --- Course ---

export const InitialCourseState: CourseSliceType & NewCourseSliceType & CourseProgram = {
	id: "",
	course: { id: "" },
	newCourse: {
		internalTitle: "",
		userFacingTitle: "",
		snippetDescription: "",
		fullDescription: "",
		image: "",
		skillRequired: "",
		courseProgramLength: "",
		coursePlannedFor: [],
	},
	coursePrograms: [],
};

export const InitialCoursesState: CoursesSliceType = {
	courses: [],
};

// --- Recipe ---
const recipeId = uuid();
export const InitialRecipeState: RecipeSliceType & NewRecipeSliceType = {
	id: "",
	recipe: { id: "" },
	newRecipe: {
		id: recipeId,
		title: "",
		image: "",
		recipeCategory: [],
		intakeLevel: 0,
		carbs: 0,
		fats: 0,
		protein: 0,
		description: "",
		recipeIngredient: [],
		recipeStep: [],
	},
};

export const InitialRecipesState: RecipesSliceType = {
	recipes: [],
};

export const InitialRecipeCategoriesState: RecipeCategorySliceType = {
	categories: [],
};

// --- Article ---
export const InitialArticleState: ArticleSliceType & NewArticleSliceType = {
	id: "",
	article: { id: "" },
	newArticle: {
		title: "",
		image: "",
		category: "",
		body: "",
	},
};

export const InitialArticlesState: ArticlesSliceType = {
	articles: [],
};

// --- User ---
export const InitialUserState: UserSliceType = {
	id: "",
	user: {
		id: "",
		userSubscription: {
			createdAt: "",
			updatedAt: "",
			stripeCustomerId: "",
			productId: null,
			paymentType: null,
			isFreeTrailFinished: false,
			userId: "",
			hasFreeAccess: false,
		},
	},
};

export const InitialUSersState: UsersSliceType = {
	users: [],
};
