const announcementIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "24"} 
            height={height ?? "24"} 
            viewBox="0 0 24 24"
        >
          <g id="campaign_black_24dp">
              <g id="Group_1583" data-name="Group 1583">
              <rect id="Rectangle_873" data-name="Rectangle 873" width="24" height="24" fill="none"/>
              </g>
              <path id="Path_1103" data-name="Path 1103" d="M18,11v2h4V11Z" transform="translate(0 0)"/>
              <path id="Path_1104" data-name="Path 1104" d="M16,17.61c.96.71,2.21,1.65,3.2,2.39.4-.53.8-1.07,1.2-1.6-.99-.74-2.24-1.68-3.2-2.4C16.8,16.54,16.4,17.08,16,17.61Z" transform="translate(0 0)"/>
              <path id="Path_1105" data-name="Path 1105" d="M20.4,5.6c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24,1.68-3.2,2.4.4.53.8,1.07,1.2,1.6C18.16,7.28,19.41,6.35,20.4,5.6Z" transform="translate(0 0)"/>
              <path id="Path_1106" data-name="Path 1106" d="M4,9a2.006,2.006,0,0,0-2,2v2a2.006,2.006,0,0,0,2,2H5v4H7V15H8l5,3V6L8,9Zm5.03,1.71L11,9.53v4.94L9.03,13.29,8.55,13H4V11H8.55Z" transform="translate(0)"/>
              <path id="Path_1107" data-name="Path 1107" d="M15.5,12A4.48,4.48,0,0,0,14,8.65v6.69A4.442,4.442,0,0,0,15.5,12Z" transform="translate(0)"/>
          </g>
        </svg>
      </div>
    )
  }
  
  export default announcementIcon