import { RFC } from '../../../types/propTypes'

type HTKSearchTabsProps = {
    tabs:        string[]
    selectedTab: string
    onClick:     (tab: string) => void
}

const HTKSearchTabs:RFC<HTKSearchTabsProps> = ({
    tabs,
    selectedTab,
    onClick
}) => {
    return (
        <div 
            className='w-full border-y-[1px] border-solid border-HTKBorder
            grid grid-cols-3 text-xs items-center text-center cursor-pointer'
        >
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`py-[6px] font-Title font-medium 
                    hover:bg-HTKMiddleGrey transition-all duration-200
                    ${tabs.length - 1 !== index && 'border-r-[1px] border-solid border-HTKBorder'}
                    ${selectedTab !== tab 
                    ? 'bg-HTKLightGrey text-HTKBlack/80'
                    : 'bg-HTKDarkGrey text-HTKWhite hover:bg-HTKDarkGrey'}`}
                    onClick={() => onClick(tab)}
                >
                    {tab}
                </div>
            ))}
        </div>
    )
}

export default HTKSearchTabs