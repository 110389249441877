import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { EXERCISE_TYPES, WORKOUT_TYPES } from "../../../assets/data/enums";
import { InitialWorkoutState } from "../../../assets/data/initialState";
import { NewWorkoutData, WorkoutData } from "../../../types/serviceTypes";
import { Exercise, GroupExercise } from "../../../types/stateTypes";
import { v1 as uuid } from "uuid";

export type WorkoutSliceType = {
	id: string;
	workout: WorkoutData;
};

export type NewWorkoutSliceType = {
	newWorkout: NewWorkoutData;
};

export const workoutSlice = createSlice({
	name: "workout",
	initialState: InitialWorkoutState,
	reducers: {
		// Select Existing Workout
		selectWorkout: (state, action: PayloadAction<WorkoutSliceType>) => {
			state.id = action.payload.id;
		},
		storeWorkout: (state, action: PayloadAction<WorkoutSliceType>) => {
			state.workout = action.payload.workout;
		},
		updateWorkoutInternalTitle: (state, action: PayloadAction<string>) => {
			state.workout.internalTitle = action.payload;
		},
		updateWorkoutTitle: (state, action: PayloadAction<string>) => {
			state.workout.title = action.payload;
		},
		updateWorkoutType: (state, action: PayloadAction<string>) => {
			state.workout.type = action.payload as WORKOUT_TYPES;
		},
		updateWorkoutRotation: (state, action: PayloadAction<number>) => {
			state.workout.circuitRotations = action.payload;
		},
		updateWorkoutDescription: (state, action: PayloadAction<string>) => {
			state.workout.description = action.payload;
		},
		addNewExerciseGroup: (state, action: PayloadAction<Exercise[]>) => {
			const newAddedExercises = action.payload.map((exercise, index) => {
				const workoutExerciseId = uuid();
				const groupExerciseId = uuid();
				const newExerciseObj: GroupExercise = {
					id: workoutExerciseId,
					createdAt: new Date(),
					updatedAt: new Date(),
					execution: "",
					executionDescription: "",
					workoutId: state.workout.id,
					index: index,
					exerciseInExerciseGroups: [
						{
							createdAt: new Date(),
							exercise: exercise,
							exerciseId: exercise.id,
							groupExerciseId: workoutExerciseId,
							id: groupExerciseId,
							sets: [],
							type: EXERCISE_TYPES.CIRCUIT,
							updatedAt: new Date(),
						},
					],
				};
				return newExerciseObj;
			});
			newAddedExercises.map((exercise) => state.workout.exerciseGroups?.push(exercise));
		},
		clearWorkout: (state) => {
			state.id = "";
			state.workout = { id: "" };
		},

		// Add New Workout
		clearNewWorkout: (state) => {
			state.newWorkout = {
				internalTitle: "",
				title: "",
				type: WORKOUT_TYPES.Circuit,
				circuitRotations: 1,
				description: "",
			};
		},
		addNewWorkoutInternalTitle: (state, action: PayloadAction<string>) => {
			state.newWorkout.internalTitle = action.payload;
		},
		addNewWorkoutTitle: (state, action: PayloadAction<string>) => {
			state.newWorkout.title = action.payload;
		},
		addNewWorkoutType: (state, action: PayloadAction<string>) => {
			state.newWorkout.type = action.payload as WORKOUT_TYPES;
		},
		addNewWorkoutRotation: (state, action: PayloadAction<number>) => {
			if (!action.payload) {
				state.newWorkout.circuitRotations = 0;
			} else {
				state.newWorkout.circuitRotations = action.payload;
			}
		},
		addNewWorkoutDescription: (state, action: PayloadAction<string>) => {
			state.newWorkout.description = action.payload;
		},
	},
});

export const workoutIdSelector = (state: RootState) => state.workout.id;
export const workoutSelector = (state: RootState) => state.workout.workout;
export const newWorkoutSelector = (state: RootState) => state.workout.newWorkout;
export const {
	selectWorkout,
	storeWorkout,
	updateWorkoutInternalTitle,
	updateWorkoutTitle,
	updateWorkoutType,
	updateWorkoutRotation,
	updateWorkoutDescription,
	addNewExerciseGroup,
	clearWorkout,
	clearNewWorkout,
	addNewWorkoutInternalTitle,
	addNewWorkoutTitle,
	addNewWorkoutType,
	addNewWorkoutRotation,
	addNewWorkoutDescription,
} = workoutSlice.actions;
export default workoutSlice.reducer;
