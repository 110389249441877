import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { selectUser, userSelector } from "../../../features/User/userSlice";
import { Dialog } from "@headlessui/react";
import { HTKAsyncAvatar, HTKButton } from "../../atoms/atoms";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { useFreeAccessToUserMutation, useRestrictUserMutation, useUnrestrictUserMutation } from "../../../services/UserService";
import asyncTimeout from "../../../utils/asyncTimeout";
import { updateUser } from "../../../features/User/usersSlice";
import { User } from "../../../types/stateTypes";

const AccessToUserModal = () => {
	const user = useHTKSelector(userSelector);
	const { id, avatar, firstName, lastName, isPaying, trialUser, userSubscription, isRestricted } = useHTKSelector(userSelector);
	const { hasFreeAccess } = userSubscription;
	const dispatch = useHTKDispatch();
	const [giveFreeAccess] = useFreeAccessToUserMutation();

	const giveFreeAccessToUser = async (shouldGiveFreeAccess: boolean) => {
		try {
			dispatch(closeModal());
			dispatch(openOverlay({ text: `${hasFreeAccess ? "Removing" : "Granting"} Free Access` }));
			const [data] = await Promise.all([giveFreeAccess({ userId: id, hasFreeAccess: shouldGiveFreeAccess }).unwrap(), asyncTimeout(2000)]);
			if (!data) throw Error;
			dispatch(selectUser({ id: data.id, user: { ...user, userSubscription: { ...user.userSubscription, hasFreeAccess: data.hasFreeAccess } } }));
			dispatch(updateUser({ ...user, userSubscription: { ...user.userSubscription, hasFreeAccess: data.hasFreeAccess } } as User));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: `${data.hasFreeAccess ? "FREE ACCESS GIVEN" : "FREE ACCESS REMOVED"}`,
					body: `You have successfully ${data.hasFreeAccess ? "given free access to" : "removed free access from"} this user!`,
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: `Failed to ${hasFreeAccess ? "remove free access from" : "give free access to "} user`,
					body: "Something went wrong. Please try again later.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	return (
		<div className="w-[500px] py-3 px-6">
			<Dialog.Title className="flex items-center justify-between">
				<span className="font-Title font-extrabold text-xl">{hasFreeAccess ? "REMOVE FREE ACCESS" : "GIVE FREE ACCESS"}</span>
			</Dialog.Title>
			<hr className="htk-border my-2" />
			<div>
				<p className="text-HTKBlack/80">By giving free access to the user, they will be able to access all of the HTK premium features.</p>
				<div
					className="flex items-center rounded-[4px] bg-HTKMiddleGrey
                    px-3 py-4 my-2"
				>
					<HTKAsyncAvatar fileName={avatar ?? ""} width={"w-14"} height={"h-14"} />
					<div className="ml-3">
						<p className="font-Title font-bold text-lg">
							{firstName}&nbsp;{lastName}
						</p>
						{isPaying ? <p className="text-HTKGreen font-medium">Paying User</p> : null}
						{!isPaying && trialUser ? <p className="text-HTKBlack/60 font-medium">Trial User</p> : null}
						{!isPaying && !trialUser ? <p className="text-HTKBlack/60 font-medium">No Subscription</p> : null}
					</div>
				</div>
				<div className="w-full flex items-center mt-3">
					{!hasFreeAccess ? <HTKButton text="Give Free Access" className="unrestrict-button" onSubmit={() => giveFreeAccessToUser(true)} /> : <HTKButton text="Remove Free Access" className="main-detail-button" onSubmit={() => giveFreeAccessToUser(false)} />}
					<HTKButton text="Go Back" className="delete-goback-button" onSubmit={() => dispatch(closeModal())} />
				</div>
			</div>
		</div>
	);
};

export default AccessToUserModal;
