import { DashboardTitleType } from "../../types/propTypes";
import { AnnouncementIcon, LibraryIcon, TrainingIcon, UserIcon } from "../icons/icons";
import { DASHBOARD_SUB_TYPES } from "./enums";

// Nav Tabs
export const navbarTabs = [
	{
		icon: AnnouncementIcon,
		page: "Announcements",
		link: "/announcements",
	},
	{
		icon: TrainingIcon,
		page: "Training",
		link: "/training",
	},
	{
		icon: LibraryIcon,
		page: "Library",
		link: "/library",
	},
	{
		icon: UserIcon,
		page: "User",
		link: "/users",
	},
];

// Sub Nav Tabs
export const trainingBoardTitles: DashboardTitleType[] = [
	{
		title: "COURSES",
		titlePage: DASHBOARD_SUB_TYPES.COURSES,
	},
	{
		title: "PROGRAMS",
		titlePage: DASHBOARD_SUB_TYPES.PROGRAMS,
	},
	{
		title: "WORKOUTS",
		titlePage: DASHBOARD_SUB_TYPES.WORKOUTS,
	},
	{
		title: "EXERCISES",
		titlePage: DASHBOARD_SUB_TYPES.EXERCISES,
	},
];

export const libraryBoardTitles: DashboardTitleType[] = [
	{
		title: "RECIPES",
		titlePage: DASHBOARD_SUB_TYPES.RECIPES,
	},
	{
		title: "ARTICLES",
		titlePage: DASHBOARD_SUB_TYPES.ARTICLES,
	},
];

// Search Tabs
export const programSearchTabs = ["ALL PROGRAMS", "ACTIVE PROGRAMS", "DEACTIVATED PROGRAMS"];

export const workoutSearchTabs = ["ALL WORKOUTS", "STRENGTH", "CIRCUIT"];

export const exerciseSearchTabs = ["ALL EXERCISES", "ACTIVE", "INACTIVE"];

export const userSearchTabs = ["ALL USERS", "PAYING USERS", "TRIAL USERS"];

// Course Types

export const coursePlannedForTypes = ["Gym", "Home", "Specialised", "Other"];

// Program Types
export const programPlannedForTypes = ["Gym", "Home"];

export const programSkillRequiredTypes = ["Beginner", "Intermediate", "Advanced"];

export const trainingGoalTypes = ["Build muscle mass and gain strength", "Shred fat and build lean muscle", "Improve performance or prepare for elite selection course"];

export const liftingExperienceTypes = ["Yes, I have had past experience", "No, I’m new to weight lifting"];

export const gymAccessTypes = ["Yes, I have access to a standard gym", "Yes, I have access to a home gym", "No, I don’t have access"];

export const accessToPullupBarTypes = ["Yes, I have a pull up bar", "No, I don’t have a pull up bar"];

// Workout Types
export const workoutTypes = ["Circuit", "Strength"];

// Workout Exercise Types
export const exerciseTypes = ["Circuit", "Weighted", "1RM Strength", "Body Weight"];

// Article Category Types
export const articleCategoryTypes = ["Training", "Nutrition", "Mindset", "App Help", "Misc"];
