import { useState } from 'react'
import { useLazyGetUserCSVQuery } from '../../../services/UserService'
import moment from 'moment'
import { useHTKDispatch } from '../../../app/hooks'
import { closeSnackbar, openSnackbar } from '../../../features/Cores/snackbarSlice'
import { ERROR_MESSAGES, SNACKBAR_TYPES } from '../../../assets/data/enums'
import { Dialog, Switch } from '@headlessui/react'
import { CloseIcon } from '../../../assets/icons/icons'
import { closeModal } from '../../../features/Cores/modalSlice'
import { HTKButton } from '../../atoms/atoms'
import { CSVDownload } from 'react-csv'

const ConvertCSVModal = () => {
  const [ initiateDownload, setInitiateDownload ] = useState(false)
  const initialStartDate = moment().subtract(1, 'month').format('YYYY-MM-DD')
  const initialEndDate = moment().format('YYYY-MM-DD')
  const [ isAgreed, setIsAgreed ] = useState(false)
  const [ startDate, setStartDate ] = useState(initialStartDate)
  const [ endDate, setEndDate ] = useState(initialEndDate)
  const dispatch = useHTKDispatch()

  const [ getUserCSV, { data } ] = useLazyGetUserCSVQuery()

  const headers = [
    { label: 'Id',           key: 'id'           },
    { label: 'First Name',   key: 'firstName'    },
    { label: 'Last Name',    key: 'lastName'     },
    { label: 'Email',        key: 'email'        },
    { label: 'Avatar',       key: 'avatar'       },
    { label: 'Restricted',   key: 'isRestricted' },
    { label: 'Created Date', key: 'createdAt'    },
  ]

  const downloadAllUsers = async () => {
    try {
      const data = await getUserCSV({
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        isDownloadAllData: 'true'
      }).unwrap()
      if (!data || !data.length) throw Error
      setInitiateDownload(true)
    } catch (error) {
      dispatch(openSnackbar({
        snackbarType: SNACKBAR_TYPES.ERROR,
        snackbarMessage: ERROR_MESSAGES.USER_CSV,
        snackbarTimeout: 10000,
        snackbarBtnText: 'CLOSE',
        snackbarBtnFunc: () => dispatch(closeSnackbar())
      }))
      setInitiateDownload(false)
    }
  }

  return (
    <div className='w-[500px] py-3 px-6'>
      <Dialog.Title className='flex items-center justify-between'>
        <span className='font-Title font-extrabold text-xl'>
          DOWNLOAD CSV
        </span>
        <CloseIcon
          width={'30'}
          height={'30'}
          className='cursor-pointer'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='htk-border my-2' />
      <div>
        <p className='text-HTKBlack/80 text-sm'>
          Download a CSV file of the user data within our HTK database. Select a date range to download from below.
        </p>
        <div className='flex items-center justify-between'>
          <div className='flex w-[48%] bg-HTKMiddleGrey rounded-[3px] px-3 py-2 my-2'>
            <p className='font-medium'>
              START
            </p>
            <input 
              type='date'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className='w-full ml-2 bg-HTKMiddleGrey cursor-pointer outline-none'
            />
          </div>
          <div className='flex w-[48%] bg-HTKMiddleGrey rounded-[3px] px-3 py-2 my-2'>
            <p className='font-medium'>
              END
            </p>
            <input
              type='date'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className='w-full ml-2 bg-HTKMiddleGrey cursor-pointer outline-none'
            />
          </div>
        </div>
        <div className='rounded-[4px] bg-HTKRed/10 px-4 py-2 my-2'>
          <p className='font-medium'>
            Download All
          </p>
          <p className='text-sm mb-2'>
            Be sure you want to download ALL user records. This action can have a high amount of processing stress on your machine.
          </p>
          <Switch
            checked={isAgreed ? true : false}
            onChange={() => setIsAgreed(prevState => !prevState)}
            className={`${isAgreed ? 'bg-HTKGreen' : 'bg-HTKRed'}
            relative inline-flex h-[28px] w-12 items-center rounded-full 
            border-[1px] border-solid border-HTKBorder`}
          >
            <span className='sr-only'>Use setting</span>
            <span
              aria-hidden='true'
              className={`${isAgreed ? 'translate-x-5' : '-translate-x-[1px]'}
              inline-block h-[28px] w-[28px] transform rounded-full bg-HTKWhite border-[2px] border-solid 
              border-HTKBorder transition-all duration-300`}
            />
          </Switch>
        </div>
      </div>
      <div className='w-full flex items-center mt-3'>
        {isAgreed ? (
        <>
          <HTKButton
            text='Download CSV File'
            className='main-detail-button'
            onSubmit={downloadAllUsers}
          />
          {(initiateDownload && data) && (
            <CSVDownload
              filename={`HTK-Users-${startDate}-${endDate}.csv`}
              headers={headers}
              data={data}
              target='_blank'
            />
          )}
          <HTKButton
            text='Go Back'
            className='delete-goback-button'
            onSubmit={() => dispatch(closeModal())}
          />
        </>
        ) : null}
      </div>
    </div>
  )
}

export default ConvertCSVModal