import { useHTKSelector } from '../../app/hooks'
import { HTKEmptyDetailBox } from '../../components/atoms/atoms'
import { WorkoutDetail, WorkoutsList } from '../../components/organisms/organisms'
import { workoutIdSelector } from '../../features/Training/Workouts/workoutSlice'

const Workouts = () => {
  const workoutId = useHTKSelector(workoutIdSelector)

  return (
    <div className='w-full h-full flex justify-center'>
      <WorkoutsList />
      {workoutId ? (
        <WorkoutDetail />
      ) : (
        <HTKEmptyDetailBox title='workout'/>
      )}
    </div>
  )
}

export default Workouts