import { RFC } from '../../../types/propTypes'
import { CloseIcon } from '../../../assets/icons/icons'

type HTKCategoryButtonProps = {
    text: string
    onClick: () => void
}

const HTKCategoryButton:RFC<HTKCategoryButtonProps> = ({
    text,
    onClick
}) => {
  return (
    <div className='flex items-center drop-shadow-md'>
        <p className='bg-HTKRed text-HTKWhite px-[14px] py-[3px] rounded-l-[3px] '>
            {text}
        </p>
        <button 
            className='bg-HTKRed fill-HTKWhite py-[3px] ml-[1px] rounded-r-[3px]
            hover:bg-HTKWhite hover:fill-HTKRed hover:shadow-hovered transition-all duration-300'
            onClick={onClick}
        >
            <CloseIcon />
        </button>
    </div>
  )
}

export default HTKCategoryButton