import { configureStore } from "@reduxjs/toolkit";
import { HTKApi } from "./api";
import SanckbarReducer from "../features/Cores/snackbarSlice";
import ModalReducer from "../features/Cores/modalSlice";
import OverLayReducer from "../features/Cores/overlaySlice";
import AdminReducer from "../features/Cores/adminSlice";
import AnnouncementReducer from "../features/Announcement/announcementSlice";
import AnnouncementsReducer from "../features/Announcement/announcementsSlice";
import AnnouncementProgramsReducer from "../features/Announcement/announcementCourseSlice";
import WeekReducer from "../features/Training/Programs/weekSlice";
import ProgramReducer from "../features/Training/Programs/programSlice";
import ProgramsReducer from "../features/Training/Programs/programsSlice";
import ProgramWorkoutsReducer from "../features/Training/Programs/programWorkoutsSlice";
import ExerciseGroupReducer from "../features/Training/Workouts/exerciseGroupSlice";
import WorkoutReducer from "../features/Training/Workouts/workoutSlice";
import WorkoutsReducer from "../features/Training/Workouts/workoutsSlice";
import WorkoutExercisesReducer from "../features/Training/Workouts/workoutExercisesSlice";
import ExerciseReducer from "../features/Training/Exercises/exerciseSlice";
import ExercisesReducer from "../features/Training/Exercises/exercisesSlice";
import ExerciseCategoriesReducer from "../features/Training/Exercises/exerciseCategorySlice";
import RecipeReducer from "../features/Library/Recipes/recipeSlice";
import RecipesReducer from "../features/Library/Recipes/recipesSlice";
import RecipeCategoriesReducer from "../features/Library/Recipes/recipeCategorySlice";
import ArticleReducer from "../features/Library/Articles/articleSlice";
import ArticlesReducer from "../features/Library/Articles/articlesSlice";
import UserReducer from "../features/User/userSlice";
import UsersReducer from "../features/User/usersSlice";
import CourseReducer from "../features/Training/Courses/courseSlice";
import CoursesReducer from "../features/Training/Courses/coursesSlice";

export const store = configureStore({
	reducer: {
		[HTKApi.reducerPath]: HTKApi.reducer,
		snackbar: SanckbarReducer,
		modal: ModalReducer,
		overlay: OverLayReducer,
		admin: AdminReducer,
		announcement: AnnouncementReducer,
		announcements: AnnouncementsReducer,
		announcementPrograms: AnnouncementProgramsReducer,
		week: WeekReducer,
		program: ProgramReducer,
		programs: ProgramsReducer,
		programWorkouts: ProgramWorkoutsReducer,
		exerciseGroup: ExerciseGroupReducer,
		workout: WorkoutReducer,
		workouts: WorkoutsReducer,
		workoutExercises: WorkoutExercisesReducer,
		exercise: ExerciseReducer,
		exercises: ExercisesReducer,
		exerciseCategories: ExerciseCategoriesReducer,
		recipe: RecipeReducer,
		recipes: RecipesReducer,
		recipeCategories: RecipeCategoriesReducer,
		article: ArticleReducer,
		articles: ArticlesReducer,
		user: UserReducer,
		users: UsersReducer,
		course: CourseReducer,
		courses: CoursesReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(HTKApi.middleware),
	devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
