import { RFC } from "../../../types/propTypes";
import { motion } from "framer-motion";

type TitleCardProps = {
	isSelected: boolean;
	children: JSX.Element;
	onClick: () => void;
	className?: string;
};

const cardAnimations = {
	initial: { x: -150, opacity: 0, scale: 0 },
	animate: { x: 0, opacity: 1, scale: 1 },
	exit: { x: 150, opacity: 0 },
	transition: { stiffness: 80 },
};

const TitleCard: RFC<TitleCardProps> = ({ isSelected, children, onClick, className }) => {
	return (
		<motion.div
			key="title-card"
			{...cardAnimations}
			className={`flex flex-col p-2 border-HTKBorder border-b-[1px] border-solid
            cursor-pointer transition-all duration-300 shadow-sm hover:bg-HTKMiddleGrey
            ${isSelected ? "text-HTKWhite bg-HTKGrey hover:bg-HTKGrey" : "text-HTKBlack bg-HTKWhite"} ${className}`}
			onClick={onClick}
		>
			{children}
		</motion.div>
	);
};

export default TitleCard;
