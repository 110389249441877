import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useHTKDispatch } from "../../../app/hooks";
import { programSearchTabs } from "../../../assets/data/arrays";
import { DASHBOARD_TYPES, MODAL_TYPES } from "../../../assets/data/enums";
import { openModal } from "../../../features/Cores/modalSlice";
import { clearNewProgram } from "../../../features/Training/Programs/programSlice";
import { HTKButton, HTKSearchbar, HTKSearchTabs, HTKSearchTitleBlock } from "../../atoms/atoms";
import InfiniteScrollLists from "./InfiniteScrollLists";

const ProgramsList = () => {
	const [searchText, setSearchText] = useState("");
	const [filterText, setFilterText] = useState("");
	const [searchTab, setSearchTab] = useState("ALL PROGRAMS");
	const dispatch = useHTKDispatch();

	return (
		<div className="flex flex-col">
			<p className="min-h-[3em] pb-1 flex items-end font-Title text-2xl font-bold">PROGRAMS</p>
			<div className="w-[40em] bg-HTKWhite htk-container">
				<HTKSearchTitleBlock
					title="ALL PROGRAMS"
					btnTitle="New Program"
					onClick={() => {
						dispatch(clearNewProgram());
						dispatch(
							openModal({
								modalType: MODAL_TYPES.CREATE_PROGRAM,
							})
						);
					}}
				/>
				<div className="w-full flex items-center justify-center">
					<HTKSearchbar onChange={setSearchText} />
					<HTKButton text="Search Filters" className="search-filter-button" onSubmit={() => setFilterText(searchText)} />
				</div>
				<div className="w-full my-1">
					<AnimatePresence>
						<InfiniteScrollLists key="infinite_scroll" page={DASHBOARD_TYPES.PROGRAMS} searchText={filterText} searchTab={searchTab} pb={"pb-[13em]"} />
					</AnimatePresence>
				</div>
			</div>
		</div>
	);
};

export default ProgramsList;
