import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialWorkoutsState } from '../../../assets/data/initialState'
import { Workout } from '../../../types/stateTypes'

export type WorkoutsSliceType = {
    workouts: Workout[]
}

export type AddNewExerciseGroupType = {
    workoutId:  string
    exerciseId: string
}

export const workoutsSlice = createSlice({
    name: 'workouts',
    initialState: InitialWorkoutsState,
    reducers: {
        clearWorkouts: (state) => {
            state.workouts = []
        },
        setInitialWorkouts: (state, action:PayloadAction<WorkoutsSliceType>) => {
            state.workouts = action.payload.workouts
        },
        setWorkouts: (state, action:PayloadAction<WorkoutsSliceType>) => {
            const { workouts: storedWorkouts } = state
            const { workouts: newWorkouts } = action.payload
            const workoutIds = storedWorkouts.map(workout => workout.id)
            const filteredDuplicates = newWorkouts.filter(workout => !workoutIds.includes(workout.id))
            filteredDuplicates.map(workout => state.workouts.push(workout))
        },
        addWorkout: (state, action:PayloadAction<Workout>) => {
            state.workouts.unshift(action.payload)
        },
        updateWorkoutList: (state, action:PayloadAction<Workout>) => {
            state.workouts.map((workout, index) => {
                if (workout.id === action.payload.id)
                state.workouts[index] = action.payload
            })
        },
        deleteWorkoutWithId: (state, action:PayloadAction<string>) => {
            const filteredWorkout = state.workouts.filter(workout => workout.id !== action.payload)
            state.workouts = filteredWorkout
        }
    }
})

export const workoutsSelector = (state: RootState) => state.workouts
export const {
    clearWorkouts,
    setInitialWorkouts,
    setWorkouts,
    addWorkout,
    updateWorkoutList,
    deleteWorkoutWithId,
} = workoutsSlice.actions
export default workoutsSlice.reducer