import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { openModal } from "../../../features/Cores/modalSlice";
import { storeUser, userIdSelector, userSelector } from "../../../features/User/userSlice";
import { useGetUserQuery } from "../../../services/UserService";
import { HTKAsyncAvatar, HTKDetailUserBlock, HTKTitleBlock } from "../../atoms/atoms";
import { AvatarSkeleton, InputSkeleton } from "../../molecules/molecules";

const UserDetail = () => {
	const userId = useHTKSelector(userIdSelector);
	const { avatar, firstName, lastName, trialUser, email, isPaying, isRestricted, userSubscription } = useHTKSelector(userSelector);
	const dispatch = useHTKDispatch();

	const { data: userData, isLoading: userLoading, isFetching: userFetching } = useGetUserQuery(userId ?? skipToken);

	useEffect(() => {
		userData && dispatch(storeUser({ id: userData.id, user: userData }));
	}, [userData]);

	function getSubscriptionInfo() {
		if (!userSubscription) return "No Subscription Plan";
		if (userSubscription?.hasFreeAccess) return "Free Access";
		if (userSubscription.paymentType === null) return "No Subscription Plan";
		if (userSubscription.paymentType === "RevenueCat") return "Revenue Cat";
		return "Stripe";
	}

	return (
		<div className="flex flex-col mx-3">
			<p className="min-h-[2.5em] text-2xl font-bold"></p>
			<div className="w-[50em] bg-HTKWhite htk-container">
				<HTKDetailUserBlock
					title="VIEW USER"
					isRestricted={isRestricted}
					hasFreeAccess={userSubscription?.hasFreeAccess ?? false}
					onAccessClick={() =>
						dispatch(
							openModal({
								modalType: MODAL_TYPES.ACCESS_TO_USER,
							})
						)
					}
					onClick={() =>
						dispatch(
							openModal({
								modalType: MODAL_TYPES.RESTRICT_USER,
							})
						)
					}
				/>

				<div className="pt-2 pb-4 px-3 h-fit">
					{isRestricted ? <div className="w-fit bg-HTKRed/10 mx-auto rounded-[4px] px-[2em] py-2 mb-4">This user is currently restricted from using the HTK application. This was set by an admin.</div> : null}
					<HTKTitleBlock title="USER DETAILS" description="These are the users personal details they have added to their account." />
					<div className="flex items-center mt-2">
						<p className="font-semibold mr-3 min-w-[110px]">Users Unique ID:</p>
						<p className="text-HTKBlack/60 font-semibold">{userId}</p>
					</div>
					<div className="flex items-center mt-2">
						<p className="font-semibold mr-3 min-w-[110px]">Users Name:</p>
						{userLoading || userFetching ? (
							<InputSkeleton />
						) : (
							<p className="text-HTKBlack/60 font-semibold">
								{firstName}&nbsp;{lastName}
							</p>
						)}
					</div>
					<div className="flex items-center mt-2">
						<p className="font-semibold mr-3 min-w-[110px]">Email Address:</p>
						{userLoading || userFetching ? <InputSkeleton /> : <p className="text-HTKBlack/60 font-semibold">{email}</p>}
					</div>
					<div className="flex items-center mt-2">
						<p className="font-semibold mr-3 min-w-[110px]">Profile Picture:</p>
						{userLoading || userFetching ? <AvatarSkeleton /> : <HTKAsyncAvatar fileName={avatar ?? ""} width={"w-20"} height={"h-20"} />}
					</div>

					<HTKTitleBlock title="USER PLAN" description="The details outlined below are for the user’s current HTK plan." className="mt-6" />
					<div className="flex items-center mt-2">
						<p className="font-semibold mr-3 min-w-[110px]">Subscription:</p>
						{userLoading || userFetching ? (
							<InputSkeleton />
						) : (
							<>
								{userSubscription?.hasFreeAccess && <p className="text-HTKGreen font-semibold">Free Access</p>}
								{isPaying ? <p className="text-HTKGreen font-semibold ml-2"> Paying User</p> : null}
								{!isPaying && trialUser ? <p className="text-HTKBlack/60 font-semibold ml-2 ">Trial User</p> : null}
								{!userSubscription?.hasFreeAccess && !isPaying && !trialUser ? <p className="text-HTKBlack/60 font-semibold">No Subscription</p> : null}
							</>
						)}
					</div>
					<div className="flex items-center mt-2">
						<p className="font-semibold mr-3 min-w-[110px]">Current Plan:</p>
						{userLoading || userFetching ? <InputSkeleton /> : <p className="text-HTKBlack/60 font-semibold">{getSubscriptionInfo()}</p>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserDetail;
