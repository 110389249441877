import { DashboardType, RFC } from '../../types/propTypes'
import { Navbar } from './templates'

type DashboardLayoutProps = Pick<DashboardType, 'page'> & {
  children: JSX.Element
}

const DashboardLayout:RFC<DashboardLayoutProps> = ({ page, children }) => {
  return (
    <div className='w-screen h-screen overflow-hidden'>
        <Navbar page={page}/>
        <div className='w-screen h-full overflow-hidden bg-HTKLightGrey'>
        { children }
        </div>
    </div>
  )
}

export default DashboardLayout