import { HTKBlackLogo } from "../../../assets/images/images"

const CardLogo = () => {
  return (
    <div className='w-full flex justify-center items-center'>
      <img src={HTKBlackLogo} alt='card-logo' className='max-w-[120px] m-h-[120px]'/>
    </div>
  )
}

export default CardLogo