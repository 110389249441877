const arrowLeftIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? '24'} 
                height={height ?? '24'} 
                viewBox="0 0 24 24"
                onClick={onClick}
            >
                <g id="arrow_forward_black_24dp" transform="translate(24 24) rotate(180)">
                    <path id="Path_868" data-name="Path 868" d="M0,0H24V24H0Z" fill="none" />
                    <path id="Path_869" data-name="Path 869" d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" />
                </g>
            </svg>
        </div>
    )
}

export default arrowLeftIcon