import { useHTKDispatch, useHTKSelector } from '../../app/hooks'
import { articleIdSelector, articleSelector, updateArticleActiveStatus } from '../../features/Library/Articles/articleSlice'
import { ActiveStatusField } from '../../components/molecules/molecules'
import { ArticleDetail, ArticlesList } from '../../components/organisms/organisms'
import { HTKEmptyDetailBox } from '../../components/atoms/atoms'

const Articles = () => {
  const articleId = useHTKSelector(articleIdSelector)
  const { isActiveOnApp } = useHTKSelector(articleSelector)
  const dispatch = useHTKDispatch()

  return (
    <div className='w-full h-full flex justify-center'>
      <ArticlesList />
      {articleId ? (
        <>
        <ArticleDetail />
        <ActiveStatusField 
          width='w-[17em]'
          isActive={isActiveOnApp}
          onChangeStatus={(isOpen) => dispatch(updateArticleActiveStatus(isOpen))}
        />
        </>
      ) : (
        <HTKEmptyDetailBox title='article'/>
      )}
    </div>
  )
}

export default Articles