const BoxSkeleton = () => {
    return (
        <div
            role='status'
            className='w-full animate-pulse my-2 px-2'
        >
            <div className='h-32 bg-HTKBorder rounded-[5px] dark:bg-HTKBorder w-full'></div>
        </div>
    )
}

export default BoxSkeleton