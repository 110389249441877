import { RFC } from '../../../types/propTypes'
import { EXERCISE_TYPES } from '../../../assets/data/enums'
import { HTKButton, HTKDetailSectionTitleBlock, HTKDoubleColsInput, HTKSingleColInput } from '../../atoms/atoms'
import { ExerciseToGroupExercise, Set } from '../../../types/stateTypes'
import { useHTKDispatch } from '../../../app/hooks'
import { addNewExerciseSet } from '../../../features/Training/Workouts/exerciseGroupSlice'

type ExerciseSetFieldProps = {
    setIndex:             number
    exerciseGroup:        ExerciseToGroupExercise
}

const ExerciseSetField:RFC<ExerciseSetFieldProps> = ({ 
    setIndex,
    exerciseGroup 
}) => {
    const dispatch = useHTKDispatch()

    const renderTitle = () => {
        switch (exerciseGroup.type) {
            case EXERCISE_TYPES.WEIGHTED:    return 'WEIGHTED EXERCISE WORK'
            case EXERCISE_TYPES.ONE_RM:      return '1RM STRENGTH EXERCISE WORK'
            case EXERCISE_TYPES.BODY_WEIGHT: return 'BODY WEIGHT EXERCISE WORK'
            default:                         return ''
        }
    }

    const renderSetColumns = (sets: Set[]) => {
        switch (exerciseGroup.type) {
            case EXERCISE_TYPES.WEIGHTED:    return (
                <HTKDoubleColsInput 
                    sets={sets}
                    leftTitle='REPETITIONS'
                    rightTitle='WEIGHT'
                    type={EXERCISE_TYPES.WEIGHTED}
                />
            )
            case EXERCISE_TYPES.ONE_RM:      return (
                <HTKDoubleColsInput 
                    sets={sets}
                    leftTitle='REP RANGE'
                    rightTitle='KG (% OF 100KG)'
                    type={EXERCISE_TYPES.ONE_RM}
                />
            )
            case EXERCISE_TYPES.BODY_WEIGHT: return (
                <HTKSingleColInput 
                    sets={sets}
                    colTitle='WORK'
                />
            )
            default: return (<></>)
        }
    }

    return (
        <div className='w-full px-3 py-2 my-3'>
            <HTKDetailSectionTitleBlock
                title={renderTitle()}
                description='Add how many sets and reps you would like this user to execute in this exercise.'
            />
            <div className='w-[26em] flex flex-col items-center my-2'>
                <div className='flex flex-col'>
                    {renderSetColumns(exerciseGroup.sets)}
                </div>
                <HTKButton 
                    text='Add Set'
                    className='main-detail-button mt-3'
                    onSubmit={() => dispatch(addNewExerciseSet(setIndex))}
                />
            </div>
        </div>
    )
}

export default ExerciseSetField