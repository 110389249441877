import { HTKApi } from "../app/api";
import { SaveProgramsToCourseArgs, Course, CourseCreate, CourseUpdateArgs, EditRecommendationArgs, RemoveProgramFromCourseArgs, SwapProgramFromCourseArgs } from "../types/CourseTypes";
import { PaginationPayload } from "../types/serviceTypes";

const courseApi = HTKApi.injectEndpoints({
	endpoints: (builder) => ({
		createCourse: builder.mutation<Course, CourseCreate>({
			query: (args) => ({
				url: `/courses`,
				body: args,
				method: "POST",
			}),
			invalidatesTags: ["Course"],
		}),
		getCourses: builder.query<Course[], PaginationPayload>({
			query: ({ search, take, skip }) => ({
				url: `/courses/admin`,
				params: { search, take, skip },
			}),
			providesTags: ["Course"],
		}),
		getCourse: builder.query<Course, { id: string }>({
			query: (args) => ({
				url: `/courses/${args.id}/admin`,
			}),
			providesTags: ["Course"],
		}),
		removeCourse: builder.mutation<any, string>({
			query: (courseId) => ({
				url: `/courses/${courseId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Course"],
		}),
		updateCourseRecommendation: builder.mutation<void, EditRecommendationArgs>({
			query: (args) => (
				console.log("Hey this is the information being passed to the update recommendation api", JSON.stringify(args, null, 3)),
				{
					url: `/courses/${args.courseId}/recommendation`,
					method: "PUT",
					body: {
						recommendations: args.recommendations,
					},
				}
			),
			invalidatesTags: ["Course"],
		}),
		saveProgramToCourse: builder.mutation<void, SaveProgramsToCourseArgs>({
			query: (args) => ({
				url: `/courses/${args.courseId}/save-programs`,
				method: "PUT",
				body: {
					programs: args.programs,
				},
			}),
			invalidatesTags: ["Course"],
		}),
		removeSingleProgramFromCourse: builder.mutation<void, RemoveProgramFromCourseArgs>({
			query: (args) => ({
				url: `/courses/${args.courseId}/programs/${args.programId}/remove`,
				method: "PUT",
			}),
			invalidatesTags: ["Course"],
		}),
		swapProgram: builder.mutation<void, SwapProgramFromCourseArgs>({
			query: (args) => ({
				url: `/courses/${args.courseId}/programs/${args.programId}/swap`,
				method: "PUT",
			}),
			invalidatesTags: ["Course"],
		}),
		updateCourse: builder.mutation<Course, CourseUpdateArgs>({
			query: (args) => ({
				url: `/courses/${args.id}`,
				method: "PATCH",
				body: {
					isActive: args.isActive,
					internalTitle: args.internalTitle,
					userFacingTitle: args.userFacingTitle,
					snippetDescription: args.snippetDescription,
					fullDescription: args.fullDescription,
					image: args.image,
					courseProgramLength: args.courseProgramLength,
					courseRecommendations: args.courseRecommendations,
					coursePlannedFor: args.coursePlannedFor,
					skillRequired: args.skillRequired,
				},
			}),
			invalidatesTags: ["Course"],
		}),
	}),
});

export const { useSaveProgramToCourseMutation, useGetCoursesQuery, useLazyGetCoursesQuery, useCreateCourseMutation, useGetCourseQuery, useRemoveCourseMutation, useUpdateCourseMutation, useUpdateCourseRecommendationMutation, useRemoveSingleProgramFromCourseMutation, useSwapProgramMutation } =
	courseApi;
