import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialExerciseCategoriesState } from '../../../assets/data/initialState'
import { ExerciseCategory } from '../../../types/stateTypes'

export type ExerciseCategorySliceType = {
    categories: ExerciseCategory[]
}

export const exerciseCategoriesSlice = createSlice({
    name: 'exerciseCategories',
    initialState: InitialExerciseCategoriesState,
    reducers: {
        clearExerciseCategories: (state) => {
            state.categories = []
        },
        setInitialExerciseCategories: (state, action:PayloadAction<ExerciseCategorySliceType>) => {
            state.categories = action.payload.categories
        },
        setExerciseCategories: (state, aciton: PayloadAction<ExerciseCategorySliceType>) => {
            const { categories: storedCategories } = state
            const { categories: newCategories } = aciton.payload
            const categoryIds = storedCategories.map(category => category.id)
            const filteredDuplicates = newCategories.filter(category => !categoryIds.includes(category.id))
            filteredDuplicates.map(category => state.categories.push(category))
        },
        addBrandNewCategory: (state, action:PayloadAction<ExerciseCategory>) => {
            state.categories.unshift(action.payload)
        }
    }
})

export const exerciseCategoriesSelector = (state: RootState) => state.exerciseCategories.categories
export const {
    clearExerciseCategories,
    setInitialExerciseCategories,
    setExerciseCategories,
    addBrandNewCategory,
} = exerciseCategoriesSlice.actions
export default exerciseCategoriesSlice.reducer