import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import {
  accessToPullupBarTypes,
  gymAccessTypes,
  liftingExperienceTypes,
  trainingGoalTypes,
} from "../../../assets/data/arrays";
import { Dialog } from "@headlessui/react";
import { CloseIcon } from "../../../assets/icons/icons";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { HTKButton, HTKRecommendationTabs } from "../../atoms/atoms";
import { useEditProgramRecommendationMutation } from "../../../services/ProgramService";
import {
  addProgramRecommendation,
  clearRecommendationState,
  programIdSelector,
  RecommendationOptionType,
  recommendationSelector,
} from "../../../features/Training/Programs/programSlice";
import {
  assignRecommendationType,
  programRecommendationConverter,
  reverseProgramRecommendationConverter,
} from "../../../utils/filterListFormatter";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/Cores/overlaySlice";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { useUpdateCourseRecommendationMutation } from "../../../services/CourseService";
import {
  courseIdSelector,
  courseSelector,
} from "../../../features/Training/Courses/courseSlice";
import { Recommendation } from "../../../types/CourseTypes";
import { useEffect } from "react";

const CourseRecommentationModal = () => {
  const courseId = useHTKSelector(courseIdSelector);
  const course = useHTKSelector(courseSelector);
  const recommendationState = useHTKSelector(recommendationSelector);
  const dispatch = useHTKDispatch();

  const [updateRecommendation, { status, isError }] =
    useUpdateCourseRecommendationMutation();

  useEffect(() => {
    assignRecommendations();
  }, [course]);

  const assignRecommendations = () => {
    if (!course.courseRecommendations) return;
    course.courseRecommendations.map((recommendation, index) => {
      dispatch(
        addProgramRecommendation({
          option: recommendation.title,
          type: assignRecommendationType(recommendation.title),
        })
      );
    });
  };

  const editRecommendation = async () => {
    if (!recommendationState?.length) throw Error;
    try {
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Updating Recommendation" }));
      await Promise.all([
        updateRecommendation({
          courseId: courseId,
          recommendations: recommendationState.map((recommendation) => {
            return recommendation.title as Recommendation;
          }),
        }).unwrap(),
        asyncTimeout(1000),
      ]);
      if (isError) throw Error;
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "RECOMMENDATION UPDATED",
          body: "You have successfully updated the Recommendation!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to update recommendation",
          body: "Please check your input details and try again.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  return (
    <>
      <div className="min-w-[600px] max-w-[650px] py-3 px-6">
        <Dialog.Title className="flex items-center justify-between">
          <div>
            <p className="font-Title font-extrabold text-xl">
              PROGRAM RECOMMENDATION
            </p>
            <p className="text-HTKBlack/80">
              Select the answers a user needs to input to have this program
              recommended to them in the HTK app.
            </p>
          </div>
          <CloseIcon
            width={"30"}
            height={"30"}
            className="cursor-pointer"
            onClick={() => dispatch(closeModal())}
          />
        </Dialog.Title>
        <hr className="htk-border my-2" />
        <div className="max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]">
          <HTKRecommendationTabs
            title="WHAT IS YOUR TRAINING GOAL?"
            description="Pick what workout type best describes this workout. Select from Circuit or Strength."
            list={trainingGoalTypes}
            selectedItem={reverseProgramRecommendationConverter(
              recommendationState,
              "Goal"
            )}
            className={""}
            onSelected={(text) =>
              dispatch(
                addProgramRecommendation({
                  option: programRecommendationConverter(text),
                  type: "Goal",
                })
              )
            }
          />
          <HTKRecommendationTabs
            title="DO YOU HAVE EXPERIENCE LIFTING WEIGHTS?"
            description="Select the level of fitness expertise a user will need to complete this program."
            list={liftingExperienceTypes}
            selectedItem={reverseProgramRecommendationConverter(
              recommendationState,
              "Experience"
            )}
            className={"mt-7"}
            onSelected={(text) =>
              dispatch(
                addProgramRecommendation({
                  option: programRecommendationConverter(text),
                  type: "Experience",
                })
              )
            }
          />
          <HTKRecommendationTabs
            title="DO YOU HAVE ACCESS TO A GYM?"
            description="Pick what workout type best describes this workout. Select from Circuit or Strength."
            list={gymAccessTypes}
            selectedItem={reverseProgramRecommendationConverter(
              recommendationState,
              "Gym"
            )}
            className={"mt-7"}
            onSelected={(text) =>
              dispatch(
                addProgramRecommendation({
                  option: programRecommendationConverter(text),
                  type: "Gym",
                })
              )
            }
          />
          <HTKRecommendationTabs
            title="DO YOU HAVE ACCESS TO A PULL UP BAR?"
            description="Pick what workout type best describes this workout. Select from Circuit or Strength."
            list={accessToPullupBarTypes}
            selectedItem={reverseProgramRecommendationConverter(
              recommendationState,
              "Pullup"
            )}
            className={"mt-7"}
            onSelected={(text) =>
              dispatch(
                addProgramRecommendation({
                  option: programRecommendationConverter(text),
                  type: "Pullup",
                })
              )
            }
          />
        </div>
        {(recommendationState?.length ?? 0) >= 4 && (
          <div className="sticky bottom-0 w-full h-12 flex items-center">
            <HTKButton
              text="Update Recommendation"
              className="create-item-button"
              onSubmit={editRecommendation}
            />
            <HTKButton
              text="Cancel"
              className="cancel-item-button"
              onSubmit={() => {
                dispatch(clearRecommendationState());
                dispatch(closeModal());
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CourseRecommentationModal;
