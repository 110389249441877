import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store' 
import { InitialUSersState } from '../../assets/data/initialState'
import { User } from '../../types/stateTypes'

export type UsersSliceType = {
    users: User[]
}

export const usersSlice = createSlice({
    name: 'users',
    initialState: InitialUSersState,
    reducers: {
        clearUsers: (state) => {
            state.users = []
        },
        setInitialUsers: (state, action:PayloadAction<UsersSliceType>) => {
            state.users = action.payload.users
        },
        setUsers: (state, action:PayloadAction<UsersSliceType>) => {
            const { users: storedUsers } = state
            const { users: newUsers } = action.payload
            const userIds = storedUsers.map(user => user.id)
            const filteredDuplicates = newUsers.filter(user => !userIds.includes(user.id))
            filteredDuplicates.map(user => state.users.push(user))
        },
        updateUser: (state, action:PayloadAction<User>) => {
            const filteredUsers = state.users.filter(user => user.id !== action.payload.id)
            state.users = [ action.payload, ...filteredUsers ]
        },
    }
})

export const usersSelector = (state: RootState) => state.users.users
export const {
    clearUsers,
    setInitialUsers,
    setUsers,
    updateUser,
} = usersSlice.actions
export default usersSlice.reducer