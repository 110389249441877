import { RFC } from '../../../types/propTypes'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { selectWorkout, workoutIdSelector, workoutSelector } from './workoutSlice'
import { workoutsSelector } from './workoutsSlice'
import { Workout } from '../../../types/stateTypes'
import { CardError, CardLogo, TitleCard } from '../../../components/atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import moment from 'moment'
import { clearExerciseGroup } from './exerciseGroupSlice'

type WorkoutTitlesProps = {
    isLoading: boolean
    isError:   boolean
}

export const WorkoutTitles:RFC<WorkoutTitlesProps> = ({
    isLoading,
    isError,
}) => {
    const workoutId = useHTKSelector(workoutIdSelector)
    const workoutsState = useHTKSelector(workoutsSelector)
    const dispatch = useHTKDispatch()

    const handleClickCard = (item: Workout) => {
        dispatch(clearExerciseGroup())
        dispatch(selectWorkout({ id: item.id, workout: item }))
    }

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!workoutsState.workouts.length) return (
        <div className='w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7'>
            No Workout
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='workout-card'>
        {workoutsState.workouts.map((workout, index) => (
            <TitleCard
                key={index}
                isSelected={workoutId === workout.id}
                onClick={() => handleClickCard(workout)}
            >
                <div className='w-full truncate'>
                    <p className='font-Title uppercase font-bold text-lg truncate'>
                        {workout.internalTitle}
                    </p>
                    <div className='flex items-center text-sm'>
                        <p className='w-16 font-bold mr-2 text-[15px]'>
                            {workout.type}
                        </p>
                        <p className='font-semibold text-inherit/80'>
                            Created
                        </p>
                        <p className='pl-2 text-inherit/80'>
                            {moment(workout.createdAt).format('DD MMM YYYY')}
                        </p>
                    </div>
                </div>
            </TitleCard>
        ))}
        </LazyMotion>
    )
}