import { ReactElement } from 'react'
import { RFC } from '../../../types/propTypes'
import { HTKLoading } from '../atoms'

type HTKButtonProps = {
  text:       string
  icon?:      ReactElement
  disabled?:  boolean
  isLoading?: boolean
  className:  string
  onSubmit:   () => void
}

const HTKButton:RFC<HTKButtonProps> = ({
  text,
  icon,
  disabled,
  isLoading,
  className,
  onSubmit
}) => {
  return (
    <>
      <button
        type='button'
        data-mdb-ripple="true" 
        data-mdb-ripple-color="light" 
        data-mdb-ripple-duration="1s"
        className={className}
        disabled={disabled}
        onClick={onSubmit}
      >
        {isLoading ? (
          <div 
            className='flex justify-center items-center
            w-full text-xl px-4 py-2'
          >
            <HTKLoading shape='dots'/>
          </div>
        ) : (
          <>
            { text }
            { icon && (
              <div className='ml-1'>
                {icon}
              </div>
            )}
          </>
        )}
      </button>
    </>
  )
}

export default HTKButton