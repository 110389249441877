const swapIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? '24'} 
                height={height ?? '24'} 
                viewBox="0 0 24 24"
                onClick={onClick}
            >
                <path id="Path_942" data-name="Path 942" d="M0,0H24V24H0Z" fill="none" />
                <path id="Path_943" data-name="Path 943" d="M6.99,11,3,15l3.99,4V16H14V14H6.99ZM21,9,17.01,5V8H10v2h7.01v3Z" />
            </svg>
        </div>
    )
}

export default swapIcon