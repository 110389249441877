import { useEffect } from 'react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { closeModal, openModal } from '../../../features/Cores/modalSlice'
import {
  articleIdSelector, articleSelector, clearArticle,
  removeArticleCategory, selectArticle, storeArticle,
  updateArticleBody, updateArticleImage, updateArticleTitle
} from '../../../features/Library/Articles/articleSlice'
import {
  useCreateArticleMutation, useDeleteArticleMutation,
  useGetArticleQuery, useUpdateArticleMutation
} from '../../../services/ArticleService'
import { HTKDetailTitleblock, HTKTextArea, HTKTextInput, HTKTitleBlock, HTKUploadInput } from '../../atoms/atoms'
import { ArticleCategoryField, ImageSkeleton, InputSkeleton, TextAreaSkeleton } from '../../molecules/molecules'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { addArticle, deleteArticleWithId, updateArticleInfo } from '../../../features/Library/Articles/articlesSlice'


const ArticleDetail = () => {
  const articleId = useHTKSelector(articleIdSelector)
  const { id, title, image, category, body, isActiveOnApp } = useHTKSelector(articleSelector)
  const dispatch = useHTKDispatch()

  const { data: articleData, isLoading: articleLoading, isFetching: articleFetching } = useGetArticleQuery(articleId ?? skipToken)
  const [ createArticle ] = useCreateArticleMutation()
  const [ updateArticle ] = useUpdateArticleMutation()
  const [ deleteArticle ] = useDeleteArticleMutation()

  useEffect(() => {
    articleData && dispatch(storeArticle({ id: articleData.id, article: articleData }))
  },[articleData])


  const duplicateArticle = async () => {
    if (!title || !image || !category || !body) throw Error
    try {
      dispatch(openOverlay({ text: 'Duplicating Article' }))
      const [data] = await Promise.all([
        createArticle({
          title, image, category, body
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(addArticle(data))
      dispatch(selectArticle({ id: data.id, article: data }))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'ARTICLE DUPLICATED',
        body: 'You have successfully duplicated an article!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to duplicate article',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const saveArticle = async () => {
    try {
      dispatch(openOverlay({ text: 'Updating Article ' }))
      const [data] = await Promise.all([
        updateArticle({
          id, title, image, category, body, isActiveOnApp
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(updateArticleInfo(data))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'ARTICLE UPDATED',
        body: 'You have successfully updated the article!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to update article',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const removeArticle = async (articleId: string) => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting this Article' }))
      const [data] = await Promise.all([
        deleteArticle(articleId).unwrap(),
        asyncTimeout(1000)
      ])
      if (!data) throw Error
      dispatch(clearArticle())
      dispatch(deleteArticleWithId(articleId))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'ARTICLE DELETED',
        body: 'You have successfully deleted the article!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete article',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <div className='flex flex-col mx-3'>
      <p className='min-h-[2.5em] text-2xl font-bold'></p>
      <div className='w-[33em] bg-HTKWhite htk-container'>

        <HTKDetailTitleblock
          title='VIEW ARTICLE'
          mainBtnTitle='Delete Article'
          onMainClick={() => dispatch(openModal({
            modalType: MODAL_TYPES.DELETE,
            deleteId: id,
            deleteFn: removeArticle
          }))}
          subBtnTitle='Save'
          onSubClick={saveArticle}
          onDuplicate={duplicateArticle}
        />

        <div className='py-3 px-2 h-screen overflow-y-auto overflow-x-hidden pb-[15em]'>
          <HTKTitleBlock
            title='ARTICLE TITLE'
            description='The article title will be displayed to users in the HTK app as the main headline for this article.'
            isRequired={!title}
          />
          {(articleLoading || articleFetching) ? (<InputSkeleton />) : (
            <HTKTextInput
              name='title'
              value={title}
              placeholder='Title'
              onChange={(text) => dispatch(updateArticleTitle(text))}
            />
          )}
          <HTKTitleBlock
            title='ARTICLE IMAGE'
            description='Upload an image that relates to this articles contents.'
            className='mt-6'
            isRequired={!image}
          />
          {(articleLoading || articleFetching) ? (<ImageSkeleton />) : (
            <HTKUploadInput
              type='image'
              source={image ?? ''}
              onUpload={(image) => dispatch(updateArticleImage(image ?? ''))}
            />
          )}
          <HTKTitleBlock
            title='ARTICLE CATEGORY'
            description='Add a category to this Article. This allows users to find an article by it’s allocated category.'
            className='mt-6'
            isRequired={!category?.length}
          />
          {(articleLoading || articleFetching) ? (<InputSkeleton />) : (
            <ArticleCategoryField
              category={category}
              title='UPDATE_ARTICLE'
              onDelete={() => dispatch(removeArticleCategory())}
            />
          )}
          <HTKTitleBlock
            title='ARTICLE CONTENTS'
            description='Place in the contents of this article.'
            className='mt-6'
            isRequired={(!body || body === '<p><br></p>') ? true : false}
          />
          {(articleLoading || articleFetching) ? (<TextAreaSkeleton />) : (
            <HTKTextArea
              text={body ?? ''}
              onChange={(text) => dispatch(updateArticleBody(text))}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ArticleDetail