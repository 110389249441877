import { BiErrorCircle as ErrorIcon } from 'react-icons/bi'

const CardError = () => {
    return (
        <div
            className='flex justify-center flex-col items-center'
        >
            <ErrorIcon className='w-[3em] h-[3em] text-HTKRed mt-8'/>
            <p className='mt-4 text-HTKRed text-center'>
                Something went wrong.<br/> 
                Please try again later.
            </p>
        </div>
    )
}

export default CardError