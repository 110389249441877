import { useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useGetPresignedDownloadURLQuery } from '../../../services/FileService'
import { HTKLoading } from '../atoms'
import { ErrorIcon, TrainingIcon } from '../../../assets/icons/icons'

type HTKAsyncVideoProps = {
  fileName: string | undefined 
}

const HTKAsyncVideo: RFC<HTKAsyncVideoProps> = ({ fileName }) => {
  const [ isVideoError, setIsVideoError ] = useState(false)
  const { data, isLoading, isError } = useGetPresignedDownloadURLQuery({ imageKey: fileName }, { skip: !fileName })

  if (isLoading) return (
    <div className='h-full w-full flex justify-center items-center rounded-lg'>
      <HTKLoading shape='circle' />
    </div>
  )

  if (!data) return (
    <TrainingIcon 
      className='flex justify-center items-center h-full
      bg-HTKMiddleGrey rounded-lg hover:bg-HTKLightBlack
      cursor-pointer transition-all duration-200'
      width={'30'}
      height={'30'}
    />
  )

  if (isError || isVideoError ) return (
    <div className='w-full h-full flex flex-col items-center justify-center'>
      <ErrorIcon width='30' height='30' />
      <p className='text-lg text-HTKBlack'>Video unavailable</p>
    </div>
  )
        
  return (
    <video className='h-full w-full rounded-lg' controls>
      <source 
        src={data}
        onError={() => setIsVideoError(true)}
      />
    </video>
  )
}

export default HTKAsyncVideo