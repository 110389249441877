import { useState } from 'react'
import { trainingBoardTitles } from '../../assets/data/arrays'
import { DASHBOARD_SUB_TYPES } from '../../assets/data/enums'
import { DashboardLayout, SubNavbar } from '../../components/templates/templates'
import { Programs, Workouts, Exercises } from '../pages'
import Courses from './Courses'

const Training = () => {
    const [ currentPage, setCurrentPage ] = useState<string>(DASHBOARD_SUB_TYPES.PROGRAMS)

    const renderPage = () => {
        switch (currentPage) {
            case DASHBOARD_SUB_TYPES.COURSES:  return <Courses />
            case DASHBOARD_SUB_TYPES.PROGRAMS:  return <Programs />
            case DASHBOARD_SUB_TYPES.WORKOUTS:  return <Workouts />
            case DASHBOARD_SUB_TYPES.EXERCISES: return <Exercises />
            default:                            return <></>
        }
    }

    return (
        <DashboardLayout page='Training'>
        <>
            <div className='w-full h-max bg-HTKWhite'>
                <SubNavbar
                list={trainingBoardTitles}
                currentPage={currentPage}
                onClick={(pageTitle) => setCurrentPage(pageTitle)}
                />
            </div>
            { renderPage() }
        </>
        </DashboardLayout>
    )
}

export default Training