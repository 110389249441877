import { RFC } from '../../../types/propTypes'
import { HTKButton } from '../atoms'
import { DuplicateIcon } from '../../../assets/icons/icons'
import moment from 'moment'

type HTKDetailTitleBlockWithToggleProps = {
    title:       string
    updatedAt:   string | undefined
    status:      boolean | undefined
    onMainClick: () => void
    onSave:      () => void
    onDelete:    () => void
    onDuplicate: () => void
}

const HTKDetailTitleBlockWithToggle:RFC<HTKDetailTitleBlockWithToggleProps> = ({
    title,
    updatedAt,
    status,
    onMainClick,
    onSave,
    onDelete,
    onDuplicate,
}) => {
    return (
        <div
            className='w-full flex items-center justify-between px-3 py-2
        border-b-[1px] border-HTKBorder border-solid'
        >
            <div className='flex font-Title font-semibold text-HTKBlack/90 text-lg'>
                {title}
            </div>
            <div className='flex items-center'>
                <DuplicateIcon
                    onClick={onDuplicate}
                    className=' cursor-pointer'
                />
                <HTKButton
                    text='Save'
                    onSubmit={onSave}
                    className='sub-detail-button'
                />
                <HTKButton
                    text='Delete'
                    onSubmit={onDelete}
                    className={`main-detail-button ${!status && 'mr-3'}`}
                />
                {status ? (
                    <p className='text-HTKGreen font-medium mx-3'>
                        Active On App
                    </p>
                ) : null}
                {updatedAt ? (
                    <p>
                        {moment(updatedAt).format('D MMM YYYY')}
                    </p>
                ) : null}
                <HTKButton
                    text={status ? 'Hide On App' : 'Display On App'}
                    className={`ml-3 ${status
                        ? 'main-detail-button'
                        : 'deactivated-button'}
                        transition-all duration-200`}
                    onSubmit={onMainClick}
                />
            </div>
        </div>
    )
}

export default HTKDetailTitleBlockWithToggle