const supersetIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? '24'} 
                height={height ?? '24'} 
                viewBox="0 0 24 24"
                onClick={onClick}
            >
                <rect id="Rectangle_931" data-name="Rectangle 931" width="24" height="24" fill="none" />
                <path id="Path_1121" data-name="Path 1121" d="M22,7H20V8h3V9H19V7a1,1,0,0,1,1-1h2V5H19V4h3a1,1,0,0,1,1,1V6A1,1,0,0,1,22,7ZM5.88,20H8.54l3.4-5.42h.12L15.46,20h2.66l-4.65-7.27L17.81,6H15.13l-3.07,4.99h-.12L8.85,6H6.19l4.32,6.73Z" />
            </svg>
        </div>
    )
}

export default supersetIcon