import { RFC } from "../../../types/propTypes";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { exerciseTypes } from "../../../assets/data/arrays";
import { updateNewExerciseExecutionDescription, updateNewExerciseExecutions, updateNewExerciseType } from "../../../features/Training/Workouts/exerciseGroupSlice";
import { ExerciseToGroupExercise } from "../../../types/stateTypes";
import { exerciseTypeConverter, exerciseTypeReverseConverter } from "../../../utils/filterListFormatter";
import { HTKDetailSectionTitleBlock, HTKTabListInput, HTKTextArea, HTKTextInput } from "../../atoms/atoms";
import { DetailSkeleton, ExerciseSetField, ExerciseTitleBlock } from "../molecules";
import { workoutSelector } from "../../../features/Training/Workouts/workoutSlice";
import HTKTabInput from "../../atoms/inputField/HTKTabInput";

type SingleExerciseFieldProps = {
	isLoading: boolean;
	execution: string;
	executionDescription: string;
	exerciseGroupDetails: ExerciseToGroupExercise[];
};

const SingleExerciseField: RFC<SingleExerciseFieldProps> = ({ isLoading, execution, executionDescription, exerciseGroupDetails }) => {
	const { type } = useHTKSelector(workoutSelector);
	const dispatch = useHTKDispatch();

	if (isLoading) return <DetailSkeleton />;

	return (
		<div className="w-full h-screen overflow-y-auto overflow-x-hidden pb-[15em]">
			<ExerciseTitleBlock isSingleExercise={true} isCircuitWorkout={type === "Circuit"} groupExercises={exerciseGroupDetails} />
			<HTKTabInput
				title={"EXERCISE STYLE"}
				description={"Outline what the user needs to complete in this exercise. Include, work time, rest, and timing styles."}
				list={exerciseTypes}
				selectedItem={exerciseTypeConverter(exerciseGroupDetails[0].type)}
				onSelect={(type) =>
					dispatch(
						updateNewExerciseType({
							id: exerciseGroupDetails[0].id,
							type: exerciseTypeReverseConverter(type),
						})
					)
				}
				className={"px-3"}
				type={type}
			/>
			<div className="w-full px-3 py-2">
				<HTKDetailSectionTitleBlock title="EXERCISE EXECUTIONS" description="Outline what the user needs to complete in this exercise. Include, work time, rest, and timing styles." />
				<HTKTextInput name="execution" placeholder="Exercise Executions" value={execution} onChange={(text) => dispatch(updateNewExerciseExecutions(text))} />
				<HTKDetailSectionTitleBlock title="EXECUTION DESCRIPTION" description="Provide some extra details about this exercise. This will help the user how they need to execute this movement correctly." />
				<HTKTextArea text={executionDescription} onChange={(text) => dispatch(updateNewExerciseExecutionDescription(text))} />
			</div>
			{exerciseGroupDetails[0].type !== "Circuit" ? <ExerciseSetField setIndex={0} exerciseGroup={exerciseGroupDetails[0]} /> : null}
		</div>
	);
};

export default SingleExerciseField;
