import { RFC } from '../../../types/propTypes'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { recipeIdSelector, selectRecipe, updateRecipeImage } from './recipeSlice'
import { recipesSelector } from './recipesSlice'
import { Recipe } from '../../../types/stateTypes'
import { CardError, CardLogo, HTKAsyncImage, TitleCard } from '../../../components/atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import moment from 'moment'

type RecipeTitlesProps = {
    isLoading:   boolean
    isError:     boolean
}

export const RecipeTitles:RFC<RecipeTitlesProps> = ({
    isLoading,
    isError,
}) => {
    const recipeId = useHTKSelector(recipeIdSelector)
    const recipesState = useHTKSelector(recipesSelector)
    const dispatch = useHTKDispatch()

    const handleClickCard = (item: Recipe) => {
        dispatch(updateRecipeImage(''))
        dispatch(selectRecipe({ id: item.id, recipe: item }))
    }

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!recipesState.length) return (
        <div className='w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7'>
            No Recipe
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='recipe-card'>
            {recipesState.map((recipe, index) => (
                <TitleCard
                    key={index}
                    isSelected={recipeId === recipe.id}
                    onClick={() => handleClickCard(recipe)}
                >
                    <div className='flex items-center justify-between'>
                        <div className='min-w-[50px] h-[50px]'>
                            <HTKAsyncImage fileName={recipe.image} />
                        </div>
                        <div className='w-full pl-3 truncate'>
                            <p className='font-Title uppercase font-bold text-lg truncate'>
                                {recipe.title}
                            </p>
                            <div className='flex items-center text-sm'>
                                <p className='font-medium'>
                                    Created
                                </p>
                                <p className='pl-2 text-inherit/80'>
                                    {moment(recipe.createdAt).format('DD MMM YYYY')}
                                </p>
                            </div>
                        </div>
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}