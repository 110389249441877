import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { InitialCoursesState } from "../../../assets/data/initialState";
import { Course } from "../../../types/CourseTypes";

export type CoursesSliceType = {
	courses: Course[];
};

export const coursesSlice = createSlice({
	name: "courses",
	initialState: InitialCoursesState,
	reducers: {
		clearCourses: (state) => {
			state.courses = [];
		},
		setInitialCourses: (state, action: PayloadAction<CoursesSliceType>) => {
			state.courses = action.payload.courses;
		},
		setCourses: (state, action: PayloadAction<CoursesSliceType>) => {
			const { courses: storedCourses } = state;
			const { courses: newCourses } = action.payload;
			const coursesIds = storedCourses.map((course) => course.id);
			const filteredDuplicates = newCourses.filter((course) => !coursesIds.includes(course.id));
			filteredDuplicates.map((course) => state.courses.push(course));
		},
		addcourse: (state, action: PayloadAction<Course>) => {
			state.courses.unshift(action.payload);
		},
		updateCourseInfo: (state, action: PayloadAction<Course>) => {
			const filteredCourses = state.courses.filter((course) => course.id !== action.payload.id);
			state.courses = [action.payload, ...filteredCourses];
		},
		deletecourseWithId: (state, action: PayloadAction<string>) => {
			const filteredCourses = state.courses.filter((course) => course.id !== action.payload);
			state.courses = filteredCourses;
		},
	},
});
export const { addcourse, clearCourses, deletecourseWithId, setCourses, setInitialCourses, updateCourseInfo } = coursesSlice.actions;
export const coursesSelector = (state: RootState) => state.courses;
export default coursesSlice.reducer;
