const addNewIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={width ?? '24'} 
        height={height ?? '24'} 
        viewBox="0 0 24 24"
        onClick={onClick}
      >
      <path id="Path_899" data-name="Path 899" d="M0,0H24V24H0Z" fill="none"/>
      <path id="Path_900" data-name="Path 900" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z"/>
      </svg>
    </div>
  )
}

export default addNewIcon