import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { InitialCourseState } from "../../../assets/data/initialState";
import { Course, CourseCreate, CoursePlannedFor, CoursePlannedForCreate, Program } from "../../../types/CourseTypes";
import { CourseData } from "../../../types/serviceTypes";
import { CoursesSliceType } from "./coursesSlice";

export type CourseSliceType = {
	id: string;
	course: CourseData;
};

export type NewCourseSliceType = {
	newCourse: CourseCreate;
};

export type CourseProgram = {
	coursePrograms: Program[];
};

export const courseSlice = createSlice({
	name: "course",
	initialState: InitialCourseState,
	reducers: {
		selectCourse: (state, action: PayloadAction<CourseSliceType>) => {
			state.id = action.payload.id;
		},
		storeCourse: (state, action: PayloadAction<CourseSliceType>) => {
			state.id = action.payload.id;
			state.course = action.payload.course;
		},
		clearCourse: (state) => {
			state.id = "";
			state.course = { id: "" };
		},
		updateCourseInternalTitle: (state, action: PayloadAction<string>) => {
			state.course.internalTitle = action.payload;
		},
		updateCourseUserFacingTitle: (state, action: PayloadAction<string>) => {
			state.course.userFacingTitle = action.payload;
		},
		updateCourseSnippetDescription: (state, action: PayloadAction<string>) => {
			state.course.snippetDescription = action.payload;
		},
		updateCourseFullDescription: (state, action: PayloadAction<string>) => {
			state.course.fullDescription = action.payload;
		},
		updateCourseImage: (state, action: PayloadAction<string>) => {
			state.course.image = action.payload;
		},
		updateCourseSkillRequired: (state, action: PayloadAction<string>) => {
			state.course.skillRequired = action.payload;
		},
		updateCourseProgramLength: (state, action: PayloadAction<string>) => {
			state.course.courseProgramLength = action.payload;
		},
		updateCourseActiveStatus: (state, action: PayloadAction<boolean>) => {
			state.course.isActive = action.payload;
		},
		updateCoursePlannedFor: (state, action: PayloadAction<string>) => {
			const coursePlannedForObject = { id: "", courseId: "", createdAt: "", title: action.payload, updatedAt: "" };
			if (!state.course.coursePlannedFor) {
				state.course.coursePlannedFor = [coursePlannedForObject];
				return;
			}
			const doeesPlanExist = state.course.coursePlannedFor.some((coursePlannedFor) => coursePlannedFor.title === action.payload);
			if (doeesPlanExist) {
				state.course.coursePlannedFor = state.course.coursePlannedFor.filter((coursePlannedFor) => coursePlannedFor.title !== action.payload);
			} else {
				state.course.coursePlannedFor.push(coursePlannedForObject);
			}
		},
		// NEW COURSE
		addNewCourseInternalTitle: (state, action: PayloadAction<string>) => {
			state.newCourse.internalTitle = action.payload;
		},
		addNewCourseUserFacingTitle: (state, action: PayloadAction<string>) => {
			state.newCourse.userFacingTitle = action.payload;
		},
		addNewCourseSnippetDescription: (state, action: PayloadAction<string>) => {
			state.newCourse.snippetDescription = action.payload;
		},
		addNewCourseFullDescription: (state, action: PayloadAction<string>) => {
			state.newCourse.fullDescription = action.payload;
		},
		addNewCourseImage: (state, action: PayloadAction<string>) => {
			state.newCourse.image = action.payload;
		},
		addNewCourseSkillRequired: (state, action: PayloadAction<string>) => {
			state.newCourse.skillRequired = action.payload;
		},
		addNewCourseProgramLength: (state, action: PayloadAction<string>) => {
			state.newCourse.courseProgramLength = action.payload;
		},
		addNewCoursePlannedFor: (state, action: PayloadAction<CoursePlannedForCreate>) => {
			if (!state.newCourse.coursePlannedFor) {
				state.newCourse.coursePlannedFor = [action.payload];
				return;
			}
			const doeesPlanExist = state.newCourse.coursePlannedFor.some((coursePlannedFor) => coursePlannedFor.title === action.payload.title);
			if (doeesPlanExist) {
				state.newCourse.coursePlannedFor = state.newCourse.coursePlannedFor.filter((coursePlannedFor) => coursePlannedFor.title !== action.payload.title);
			} else {
				state.newCourse.coursePlannedFor.push(action.payload);
			}
		},
		clearNewCourseData: (state) => {
			state.newCourse = InitialCourseState.newCourse;
		},
		saveCoursePrograms: (state, action: PayloadAction<Program[]>) => {
			state.coursePrograms = action.payload;
		},
		clearCoursePrograms: (state) => {
			state.coursePrograms = [];
		},
		removeSingleCourseProgram: (state, action: PayloadAction<Program>) => {
			state.coursePrograms = state.coursePrograms.filter((programs) => programs.id !== action.payload.id);
		},
	},
});

export const courseIdSelector = (state: RootState) => state.course.id;
export const courseSelector = (state: RootState) => state.course.course;
export const newCourseSelector = (state: RootState) => state.course.newCourse;
export const courseProgramSelector = (state: RootState) => state.course.coursePrograms;

export const {
	clearCourse,
	storeCourse,
	clearNewCourseData,
	selectCourse,
	updateCourseInternalTitle,
	updateCourseFullDescription,
	updateCourseImage,
	updateCoursePlannedFor,
	updateCourseProgramLength,
	updateCourseSkillRequired,
	updateCourseSnippetDescription,
	updateCourseActiveStatus,
	updateCourseUserFacingTitle,
	addNewCourseFullDescription,
	addNewCourseImage,
	addNewCourseInternalTitle,
	addNewCoursePlannedFor,
	addNewCourseProgramLength,
	addNewCourseSkillRequired,
	addNewCourseSnippetDescription,
	addNewCourseUserFacingTitle,
	clearCoursePrograms,
	saveCoursePrograms,
	removeSingleCourseProgram,
} = courseSlice.actions;
export default courseSlice.reducer;
