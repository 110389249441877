import { RFC } from '../../../types/propTypes'
import { Switch } from '@headlessui/react'

type ActiveStatusFieldProps = {
  width:          string
  size?:          string
  isActive:       boolean | undefined
  onChangeStatus: (isOpen: boolean) => void
}

const ActiveStatusField:RFC<ActiveStatusFieldProps> = ({
  width,
  size,
  isActive,
  onChangeStatus,
}) => {
  return (
    <div className='mx-3'>
      <p className='min-h-[0.5em] text-2xl font-bold'></p>
      <div className={`${width} bg-HTKWhite htk-container p-3 rounded-[3px]`}>

        <div className='flex justify-between items-center'>
          <p className='font-Title font-semibold'>
            ACTIVE STATUS
          </p>
          <Switch
            checked={isActive ? true : false}
            onChange={onChangeStatus}
            className={`${isActive ? 'bg-HTKGreen' : 'bg-HTKRed'}
            relative inline-flex h-[28px] w-12 items-center rounded-full
            border-[1px] border-solid border-HTKBorder`}
          >
            <span className='sr-only'>Use setting</span>
            <span
              aria-hidden='true'
              className={`${isActive ? 'translate-x-5' : '-translate-x-[1px]'}
              inline-block h-[28px] w-[28px] transform rounded-full bg-HTKWhite border-[2px] border-solid
              border-HTKBorder transition-all duration-300`}
            />
          </Switch>
        </div>

        <p className={`${size ?? 'text-sm'} leading-[18px] text-HTKBlack/80 mt-3`}>
          The Active status allows you to hide certain items from the HTK application but keep the data stored for later.<br/><br/>
          Turn the active status off to hide the data. Turn the active status on to show it to users.
        </p>

      </div>
    </div>
  )
}

export default ActiveStatusField