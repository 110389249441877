import { Popover, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../../assets/data/enums'
import { CalculateIcon } from '../../../assets/icons/icons'
import { adminSelector, storeAdmin, updateMeasurement } from '../../../features/Cores/adminSlice'
import { closeSnackbar, openSnackbar } from '../../../features/Cores/snackbarSlice'
import { useUpdateAdminUnitOfMeasurementMutation } from '../../../services/AuthService'
import { HTKButton, HTKMeasurementTabs } from '../../atoms/atoms'

const MeasurementPopover = () => {
    const { id, unitOfMeasurement } = useHTKSelector(adminSelector)
    const [ currentMeasurement, setCurrentMeasurement ] = useState(unitOfMeasurement)
    const dispatch = useHTKDispatch()

    const [ updateAdminUnitOfMeasurement ] = useUpdateAdminUnitOfMeasurementMutation()

    const updateUnitOfMeasurement = async (
        close: (focusableElement?: 
            HTMLElement                                | 
            React.MutableRefObject<HTMLElement | null> | 
            React.MouseEvent<HTMLElement, MouseEvent>  | 
            undefined) => void
        ) => {
        try {
            const data = await updateAdminUnitOfMeasurement({
                adminId: id,
                unitOfMeasurement: currentMeasurement
            }).unwrap()
            if (!data) throw Error
            dispatch(updateMeasurement(currentMeasurement))
            dispatch(storeAdmin({ admin: data }))
        } catch (error) {
            dispatch(openSnackbar({
                snackbarType: SNACKBAR_TYPES.ERROR,
                snackbarMessage: ERROR_MESSAGES.CHANGE_UNIT,
                snackbarTimeout: 10000,
                snackbarBtnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                snackbarBtnFunc: () => dispatch(closeSnackbar())
            }))
        } finally {
            close()
        }
    }

    return (
        <Popover
            className='h-full hover:bg-HTKMiddleGrey
            cursor-pointer transition-all duration-300'
        >
            {({ close }) => (
                <>
                    <Popover.Button
                        className='flex items-center font-bold px-5 h-full 
                        border-l-[1px] border-solid border-HTKBorder outline-none'
                    >
                        <CalculateIcon />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100 translate-y-0'
                        leaveTo='opacity-0 translate-y-1'
                    >
                        <Popover.Panel
                            className='absolute right-6 z-[100] mt-3 w-[500px] max-w-sm transform px-4'
                        >
                            <div
                                className='overflow-hidden rounded-[3px] shadow-sm drop-shadow-sm
                              bg-HTKWhite px-2 py-3'
                            >
                                <p className='font-Title font-bold'>
                                    UNIT OF MEASUREMENT
                                </p>
                                <hr className='htk-border my-2' />
                                <p className='text-HTKBlack/80 text-sm'>
                                    Change the units of measurements that you prefer to use the HTK Admin Panel with.
                                </p>
                                <HTKMeasurementTabs 
                                    selectedMesurement={currentMeasurement}
                                    setMeasurementTab={setCurrentMeasurement}
                                />
                                <div className='w-full flex items-center mt-3'>
                                    <HTKButton
                                        text='Set Units'
                                        className='deactivated-button'
                                        onSubmit={() => updateUnitOfMeasurement(close)}
                                    />
                                    <HTKButton
                                        text='Go Back'
                                        className='sub-detail-button'
                                        onSubmit={() => close()}
                                    />
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default MeasurementPopover