const DetailSkeleton = () => {
    return (
        <div>
            <div
                role='status'
                className='w-3/4 animate-pulse mt-2 mb-5 px-2'
            >
                <div className='h-10 bg-HTKBorder rounded-[5px] dark:bg-HTKBorder w-full'></div>
            </div>
            <div
                role='status'
                className='w-[300px] animate-pulse my-2 px-2'
            >
                <div className='h-8 bg-HTKBorder rounded-[5px] dark:bg-HTKBorder w-full'></div>
            </div>
            <div
                role='status'
                className='w-3/4 animate-pulse my-2 px-2'
            >
                <div className='h-10 bg-HTKBorder rounded-[5px] dark:bg-HTKBorder w-full mb-5'></div>
            </div>
            <div
                role='status'
                className='w-[350px] animate-pulse my-2 px-2'
            >
                <div className='h-8 bg-HTKBorder rounded-[5px] dark:bg-HTKBorder w-full'></div>
            </div>
            <div
                role='status'
                className='w-3/4 animate-pulse my-2 px-2'
            >
                <div className='h-10 bg-HTKBorder rounded-[5px] dark:bg-HTKBorder w-full mb-5'></div>
            </div>
            <div
                role='status'
                className='w-[400px] animate-pulse my-2 px-2'
            >
                <div className='h-8 bg-HTKBorder rounded-[5px] dark:bg-HTKBorder w-full'></div>
            </div>
            <div
                role='status'
                className='w-3/4 animate-pulse my-2 px-2'
            >
                <div className='h-40 bg-HTKBorder rounded-[5px] dark:bg-HTKBorder w-full'></div>
            </div>
        </div>
    )
}

export default DetailSkeleton