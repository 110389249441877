import { RFC } from "../../../types/propTypes";

type HTKEmptyDetailBoxProps = {
	title: string;
	className?: string;
};

const HTKEmptyDetailBox: RFC<HTKEmptyDetailBoxProps> = ({ title, className }) => {
	return (
		<div className={`flex flex-col mx-3 ${className}`}>
			<p className="min-h-[2.5em] text-2xl font-bold"></p>
			<div className="w-[50em] h-[85vh] flex items-center justify-center bg-HTKWhite htk-container">
				<p className="text-HTKBlack/80 font-Title text-lg">Select {title} to see details</p>
			</div>
		</div>
	);
};

export default HTKEmptyDetailBox;
