import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "@remix-run/router";
import { RootState } from "../../app/store";
import { InitialAnnouncementState } from "../../assets/data/initialState";
import { AnnouncementData, NewAnnouncementData } from "../../types/serviceTypes";

export type AnnouncementSliceType = {
	id: string;
	announcement: AnnouncementData;
};

export type NewAnnouncementSliceType = {
	newAnnouncement: NewAnnouncementData;
};

export const announcementSlice = createSlice({
	name: "announcement",
	initialState: InitialAnnouncementState,
	reducers: {
		// Select Existing Announcement
		selectAnnouncement: (state, action: PayloadAction<AnnouncementSliceType>) => {
			state.id = action.payload.id;
		},
		storeAnnouncement: (state, action: PayloadAction<AnnouncementSliceType>) => {
			state.announcement = action.payload.announcement;
		},
		updateAnnouncementTitle: (state, action: PayloadAction<string>) => {
			state.announcement.title = action.payload;
		},
		updateAnnouncementLink: (state, action: PayloadAction<string>) => {
			state.announcement.link = action.payload;
		},
		updateAnnouncementImage: (state, action: PayloadAction<string>) => {
			state.announcement.image = action.payload;
		},
		updateAnnouncementBody: (state, action: PayloadAction<string>) => {
			state.announcement.body = action.payload;
		},
		updateAnnouncementProgram: (state, action: PayloadAction<string>) => {
			state.announcement.courseId = action.payload;
		},
		removeAnnouncementProgram: (state) => {
			state.announcement.courseId = "";
		},
		clearAnnouncement: (state) => {
			state.id = "";
			state.announcement = { id: "" };
		},

		// Add New Announcement
		clearNewAnnouncement: (state) => {
			state.newAnnouncement = {
				title: "",
				link: "",
				image: "",
				body: "",
				courseId: "",
			};
		},
		addNewAnnouncementTitle: (state, action: PayloadAction<string>) => {
			state.newAnnouncement.title = action.payload;
		},
		addNewAnnouncementLink: (state, action: PayloadAction<string>) => {
			state.newAnnouncement.link = action.payload;
		},
		addNewAnnouncementImage: (state, action: PayloadAction<string>) => {
			state.newAnnouncement.image = action.payload;
		},
		addNewAnnouncementBody: (state, action: PayloadAction<string>) => {
			state.newAnnouncement.body = action.payload;
		},
		addNewAnnouncementProgram: (state, action: PayloadAction<string>) => {
			state.newAnnouncement.courseId = action.payload;
		},
		removeNewAnnouncementProgram: (state) => {
			state.newAnnouncement.courseId = "";
		},
	},
});

export const announcementIdSelector = (state: RootState) => state.announcement.id;
export const announcementSelector = (state: RootState) => state.announcement.announcement;
export const newAnnouncementSelector = (state: RootState) => state.announcement.newAnnouncement;
export const {
	selectAnnouncement,
	storeAnnouncement,
	updateAnnouncementTitle,
	updateAnnouncementLink,
	updateAnnouncementImage,
	updateAnnouncementBody,
	updateAnnouncementProgram,
	removeAnnouncementProgram,
	clearAnnouncement,

	clearNewAnnouncement,
	addNewAnnouncementTitle,
	addNewAnnouncementLink,
	addNewAnnouncementImage,
	addNewAnnouncementBody,
	addNewAnnouncementProgram,
	removeNewAnnouncementProgram,
} = announcementSlice.actions;
export default announcementSlice.reducer;
