import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { InitialWeekState } from "../../../assets/data/initialState";
import {
  UpdateProgramWeekContentArgs,
  RemoveDailyWorkout,
  SwapDailyWorkout,
} from "../../../types/serviceTypes";
import { Week } from "../../../types/stateTypes";

export type WeekSliceType = {
  week: Week;
};

export const weekSlice = createSlice({
  name: "week",
  initialState: InitialWeekState,
  reducers: {
    clearWeek: (state) => {
      state.week = {
        id: "",
        createdAt: "",
        updatedAt: "",
        weekIndex: 0,
        weekName: "",
        programId: "",
        day: [],
      };
    },
    setWeek: (state, action: PayloadAction<Week>) => {
      state.week = action.payload;
    },
    addNewProgramWorkouts: (
      state,
      action: PayloadAction<UpdateProgramWeekContentArgs>
    ) => {
      state.week.day.forEach((day, index) => {
        if (day.id === action.payload.dayId) {
          state.week.day[index].dayWorkouts = [
            ...action.payload.dayWorkouts,
          ];
        }
      });
    },
    removeDailyWorkout: (state, action: PayloadAction<RemoveDailyWorkout>) => {
      const { dayId, workoutId } = action.payload;
      state.week.day.forEach((day, day_index) => {
        if (day.id === dayId) {
          const filteredWorkouts = state.week.day[day_index].dayWorkouts.filter(
            (dayWorkout) => dayWorkout.workout.id !== workoutId
          );
          state.week.day[day_index].dayWorkouts = filteredWorkouts;
        }
      });
    },
    swapDailyWorkout: (state, action: PayloadAction<SwapDailyWorkout>) => {
      const { dayId, workoutId, dayWorkout } = action.payload;
      state.week.day.forEach((day, day_index) => {
        if (day.id === dayId) {
          state.week.day[day_index].dayWorkouts.forEach(
            (inDayWorkout, dayWorkoutIndex) => {
              if (inDayWorkout.workoutId === workoutId) {
                state.week.day[day_index].dayWorkouts[dayWorkoutIndex] =
                  dayWorkout;
              }
            }
          );
        }
      });
    },
  },
});

export const weekSelector = (state: RootState) => state.week.week;
export const {
  clearWeek,
  setWeek,
  addNewProgramWorkouts,
  removeDailyWorkout,
  swapDailyWorkout,
} = weekSlice.actions;
export default weekSlice.reducer;
