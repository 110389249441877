import { HTKApi } from '../app/api'
import { CreateArticleArgs, PaginationPayload, UpdateArticleArgs } from '../types/serviceTypes'
import { Article } from '../types/stateTypes'

export const articleApi = HTKApi.injectEndpoints({
    endpoints: (builder) => ({
        getArticles: builder.query<Article[], PaginationPayload>({
            query: ({ search, skip, take }) => ({
                url: '/articles/search/admin',
                params: { search, skip, take }
            }),
            providesTags: ['Article']
        }),
        getArticle: builder.query<Article, string>({
            query: (articleId) => `/articles/${articleId}`,
            providesTags: ['Article']
        }),
        createArticle: builder.mutation<Article, CreateArticleArgs>({
            query: ({ title, image, category, body }) => ({
                url: '/articles',
                method: 'POST',
                body: {
                    title,
                    image,
                    category,
                    body,
                    isActiveOnApp: false
                }
            }),
            invalidatesTags: ['Article']
        }),
        updateArticle: builder.mutation<Article, UpdateArticleArgs>({
            query: ({ 
                id, title, image, category, 
                body, isActiveOnApp
            }) => ({
                url: `/articles/${id}`,
                method: 'PUT',
                body: {
                    title, image, category,
                    body, isActiveOnApp
                }
            }),
            invalidatesTags: ['Article']
        }),
        deleteArticle: builder.mutation<Article, string>({
            query: (articleId) => ({
                url: `/articles/${articleId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Article']
        })
    })
})

export const {
    useLazyGetArticlesQuery,
    useGetArticleQuery,
    useCreateArticleMutation,
    useUpdateArticleMutation,
    useDeleteArticleMutation,
} = articleApi