import { useState } from "react";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { programSelector, updateProgramDescription, updateProgramImage, updateProgramInternalTitle, updateProgramProgramPlannedFor, updateProgramSkillRequired, updateProgramTitle, updateProgramUserFacingTitle } from "../../../features/Training/Programs/programSlice";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import { useUpdateProgramMutation } from "../../../services/ProgramService";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { Dialog } from "@headlessui/react";
import { CloseIcon } from "../../../assets/icons/icons";
import { HTKButton, HTKTabListInput, HTKTextArea, HTKTextInput, HTKTitleBlock, HTKUploadInput } from "../../atoms/atoms";
import { programPlannedForTypes, programSkillRequiredTypes } from "../../../assets/data/arrays";
import HTKTabInput from "../../atoms/inputField/HTKTabInput";

const EditProgramModal = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { id, internalTitle, title, description, isActiveOnApp, programPlannedFor, ProgramSkillRequired, image, userFacingTitle } = useHTKSelector(programSelector);
	const program = useHTKSelector(programSelector);
	const dispatch = useHTKDispatch();

	const [updateProgram] = useUpdateProgramMutation();

	const saveProgram = async () => {
		try {
			setIsLoading(true);
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Updating Program" }));
			const [data] = await Promise.all([
				updateProgram({
					id,
					internalTitle,
					title,
					description,
					isActiveOnApp,
					programPlannedFor,
					ProgramSkillRequired,
					image,
					userFacingTitle,
				}).unwrap(),
				asyncTimeout(2000),
			]);
			if (!data) throw Error;
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "PROGRAM UPDATED",
					body: "You have successfully updated the program!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to update program",
					body: "Please check your input details and try again.",
				})
			);
		} finally {
			setIsLoading(false);
			dispatch(closeOverlay());
		}
	};

	return (
		<>
			<div className="min-w-[600px] max-w-[650px] py-3 px-6">
				<Dialog.Title className="flex items-center justify-between">
					<div>
						<p className="font-Title font-extrabold text-xl">EDIT PROGRAM</p>
						<p className="text-HTKBlack/80">Make any adjustment you need to this program.</p>
					</div>
					<CloseIcon width={"30"} height={"30"} className="cursor-pointer" onClick={() => dispatch(closeModal())} />
				</Dialog.Title>
				<hr className="htk-border my-2" />
				<div className="max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]">
					<HTKTitleBlock title="INTERNAL PROGRAM TITLE" description="The Internal Program title allows you to title this Program anything you would like. This title is not shown to users on the front end of the HTK Application." isRequired={!internalTitle} />
					<HTKTextInput name="internal-title" value={internalTitle} placeholder="Internal Program Title" onChange={(text) => dispatch(updateProgramInternalTitle(text))} />
					<HTKTitleBlock title="USER FACING PROGRAM TITLE" description="This is the Program title a user will see inside of the HTK application." isRequired={!title} />
					<HTKTextInput name="title" value={userFacingTitle} placeholder="Program Title" onChange={(text) => dispatch(updateProgramUserFacingTitle(text))} />
					<HTKTitleBlock title="EDIT IMAGE" description="Upload a new image" className="mt-6" isRequired={!image} />
					<HTKUploadInput onUpload={(image) => dispatch(updateProgramImage(image ?? ""))} source={image ?? ""} type="image" />
				</div>
				<div className="sticky bottom-0 w-full h-12 flex items-center">
					<HTKButton text="Update Program" isLoading={isLoading} disabled={isLoading} className="create-item-button" onSubmit={saveProgram} />
					<HTKButton text="Cancel" className="cancel-item-button" onSubmit={() => dispatch(closeModal())} />
				</div>
			</div>
		</>
	);
};

export default EditProgramModal;
