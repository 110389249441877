import * as Yup from 'yup'

export const LoginSchema = Yup.object({
    email: Yup.string().required('Email is required').email('Invalid email address'),
    password: Yup.string().required('Password is required')
})

export const ingredientSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    amount: Yup.string().required('Required'),
});

export const recipeStepSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
});

export const recipeCategoriesSchema = Yup.object().shape({
    title: Yup.string().required('Required'),

})

export const createRecipeSchema = Yup.object({
    title: Yup.string().required('Required'),
    image: Yup.string().required('Required'),
    carbs: Yup.number().required('Required').moreThan(0, 'Required'),
    protein: Yup.number().required('Required').moreThan(0, 'Required'),
    fats: Yup.number().required('Required').moreThan(0, 'Required'),
    description: Yup.string().required('Required'),
    recipeCategory: Yup.array().of(recipeCategoriesSchema).required('Required').min(1, 'At least one category required'),
    intakeLevel: Yup.number().required('Required').moreThan(0, 'Required'),
    recipeIngredient: Yup.array().of(ingredientSchema).required('Required').min(1, 'All ingredients fields required'),
    recipeStep: Yup.array().of(recipeStepSchema).required('Required').min(1, 'All step fields required'),
})