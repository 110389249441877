const arrowRightIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? '24'} 
                height={height ?? '24'} 
                viewBox="0 0 24 24"
            >
                <path id="Path_892" data-name="Path 892" d="M0,0H24V24H0Z" fill="none" />
                <path id="Path_893" data-name="Path 893" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(-0.021)" fill="#ee5340" />
            </svg>
        </div>
    )
}

export default arrowRightIcon