import { RFC } from '../../../types/propTypes'
import { HTKTitleBlock } from '../atoms'

type HTKRecommendationTabsProps = {
    title:        string
    description:  string
    list:         string[]
    selectedItem: string | undefined
    className:    string | undefined
    onSelected:   (type: string) => void
}

const HTKRecommendationTabs:RFC<HTKRecommendationTabsProps> = ({
    title,
    description,
    list,
    selectedItem,
    className,
    onSelected,
}) => {

    return (
        <div className={`${className}`}>
            <div className='w-full'>
                <HTKTitleBlock 
                    title={title}
                    description={description}
                    isRequired={true}
                />
            </div>
            <div className='w-fit flex-col flex-wrap gap-2 my-2'>
            {list.map((item, index) => {
                const isSelected = selectedItem === item
                return (
                    <div
                        key={index}
                        className={`rounded-[3px] border-[1px] border-solid border-HTKBorder
                        py-[6px] px-[14px] cursor-pointer drop-shadow-sm shadow-sm hover:bg-HTKMiddleGrey
                        transition-all duration-200 font-semibold my-2
                        ${isSelected ? 'bg-HTKRed text-HTKWhite hover:bg-HTKRed' : 'bg-HTKWhite text-HTKBlack'}`}
                        onClick={() => onSelected(item)}
                    >
                        {item}
                    </div>
                )
            })}
            </div>
        </div>
    )
}

export default HTKRecommendationTabs