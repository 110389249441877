import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialRecipeState } from '../../../assets/data/initialState'
import { NewRecipeData, RecipeData } from '../../../types/serviceTypes'
import { RecipeIngredient, RecipeStep, RecipeCategory } from '../../../types/stateTypes'
import { v1 as uuid } from 'uuid'
import moment from 'moment'
import { onChangeKcalToKJConverter, onChangeOzToGConverter } from '../../../utils/measurementConverter'

export type RecipeSliceType = {
    id:     string
    recipe: RecipeData
}

export type NewRecipeSliceType = {
    newRecipe: NewRecipeData
}

export type UpdateRecipeIngredient = {
    ingredient: RecipeIngredient
}

export type AmountConvertType = {
    measurement: string
    value:       string
}

export const recipeSlice = createSlice({
    name: 'recipe',
    initialState: InitialRecipeState,
    reducers: {
        // Select Existing Recipe
        selectRecipe: (state, action:PayloadAction<RecipeSliceType>) => {
            state.id = action.payload.id
        },
        storeRecipe: (state, action:PayloadAction<RecipeSliceType>) => {
            state.recipe = action.payload.recipe
        },
        updateRecipeTitle: (state, action:PayloadAction<string>) => {
            state.recipe.title = action.payload
        },
        updateRecipeImage: (state, action:PayloadAction<string>) => {
            state.recipe.image = action.payload
        },
        updateRecipeCategory: (state, action:PayloadAction<RecipeCategory[]>) => {
            state.recipe.recipeCategory = action.payload
        },
        removeRecipeCategory: (state, action: PayloadAction<string>) => {
            const filteredCategories = state.recipe.recipeCategory?.filter(category => category.id !== action.payload)
            state.recipe.recipeCategory = filteredCategories
        },
        updateRecipeIntakeLevel: (state, action:PayloadAction<AmountConvertType>) => {
            if (isNaN(Number(action.payload.value))) return
            if (action.payload.measurement === 'Metric') {
                const intakeKJ = action.payload.value === '' 
                ? 0 
                : parseInt(action.payload.value)
                state.recipe.intakeLevel = intakeKJ
            } else {
                const intakeCal = action.payload.value === '' 
                ? 0
                : onChangeKcalToKJConverter(action.payload.value)
                state.recipe.intakeLevel = intakeCal
            }
        },
        updateRecipeCarbs: (state, action:PayloadAction<AmountConvertType>) => {
            if (isNaN(Number(action.payload.value))) return
            if (action.payload.measurement === 'Metric') {
                const carbGrams = action.payload.value === ''
                    ? 0
                    : parseInt(action.payload.value)
                state.recipe.carbs = carbGrams
            } else {
                const carbOunces = action.payload.value === ''
                    ? 0
                    : onChangeOzToGConverter(action.payload.value)
                state.recipe.carbs = carbOunces
            }
        },
        updateRecipeFats: (state, action:PayloadAction<AmountConvertType>) => {
            if (isNaN(Number(action.payload.value))) return
            if (action.payload.measurement === 'Metric') {
                const fatGrams = action.payload.value === ''
                    ? 0
                    : parseInt(action.payload.value)
                state.recipe.fats = fatGrams
            } else {
                const fatOunces = action.payload.value === ''
                    ? 0
                    : onChangeOzToGConverter(action.payload.value)
                state.recipe.fats = fatOunces
            }
        },
        updateRecipeProtein: (state, action:PayloadAction<AmountConvertType>) => {
            if (isNaN(Number(action.payload.value))) return
            if (action.payload.measurement === 'Metric') {
                const proteinGrams = action.payload.value === ''
                    ? 0
                    : parseInt(action.payload.value)
                state.recipe.protein = proteinGrams
            } else {
                const proteinOunces = action.payload.value === ''
                    ? 0
                    : onChangeOzToGConverter(action.payload.value)
                state.recipe.protein = proteinOunces
            }
        },
        updateRecipeDescription: (state, action:PayloadAction<string>) => {
            state.recipe.description = action.payload
        },
        updateRecipeIngredientName: (state, action:PayloadAction<{ ingredientId: string, name: string }>) => {
            state.recipe.recipeIngredient?.forEach((ingredient, index) => {
                if (ingredient.id === action.payload.ingredientId &&
                    state.recipe.recipeIngredient
                ) {
                    state.recipe.recipeIngredient[index].name = action.payload.name
                }
            })
        },
        updateRecipeIngredientAmount: (state, action:PayloadAction<{ ingredientId: string, amount: string }>) => {
            state.recipe.recipeIngredient?.forEach((ingredient, index) => {
                if (ingredient.id === action.payload.ingredientId &&
                    state.recipe.recipeIngredient
                ) {
                    state.recipe.recipeIngredient[index].amount = action.payload.amount
                }
            })
        },
        deleteRecipeIngredient: (state, action:PayloadAction<string>) => {
            const filteredIngredients = state.recipe.recipeIngredient?.filter(ingredient => 
                ingredient.id !== action.payload
            )
            state.recipe.recipeIngredient = filteredIngredients
        },
        addNewRecipeIngredient: (state) => {
            const ingredientId = uuid()
            const date = moment().format()
            const newIngredientObj: RecipeIngredient = {
                id:        ingredientId,
                createdAt: date.toString(),
                updatedAt: date.toString(),
                name:      '',
                amount:    '0g',
                recipeId:  state.id,
                index: state.recipe.recipeIngredient ? state.recipe.recipeIngredient.length - 1 : 0
            }
            state.recipe.recipeIngredient?.push(newIngredientObj)
        },
        updateRecipeStepTitle: (state, action:PayloadAction<{ stepId: string, title: string }>) => {
            state.recipe.recipeStep?.forEach((step, index) => {
                if (step.id === action.payload.stepId &&
                    state.recipe.recipeStep    
                ) {
                    state.recipe.recipeStep[index].title = action.payload.title
                }
            })
        },
        updateRecipeStepDescription: (state, action:PayloadAction<{ stepId: string, description: string }>) => {
            state.recipe.recipeStep?.forEach((step, index) => {
                if (step.id === action.payload.stepId &&
                    state.recipe.recipeStep
                ) {
                    state.recipe.recipeStep[index].description = action.payload.description
                }
            })
        },
        deleteRecipeStep: (state, action: PayloadAction<string>) => {
            const filteredSteps = state.recipe.recipeStep?.filter(step => 
                step.id !== action.payload
            )
            const updatedSteps = filteredSteps?.map((step, index) => {
                return {
                    ...step,
                    stepNumber: index + 1
                }
            })
            state.recipe.recipeStep = updatedSteps
        },
        addNewRecipeStep: (state) => {
            const stepId = uuid()
            const date = moment().format()
            const newStepObj: RecipeStep = {
                id:          stepId,
                createdAt:   date.toString(),
                updatedAt:   date.toString(),
                stepNumber:  (state.recipe.recipeStep?.length ?? 0) + 1,
                title:       '',
                description: '',
                recipeId:    state.id,
            }
            state.recipe.recipeStep?.push(newStepObj)
        },
        updateRecipeActiveStatus: (state, action:PayloadAction<boolean>) => {
            state.recipe.isActiveOnApp = action.payload
        },
        clearRecipe: (state) => {
            state.id     = ''
            state.recipe = { id: '' }
        },

        // Add New Recipe
        clearNewRecipe: (state) => {
            const recipeId = uuid()
            state.newRecipe = {
                id:               recipeId,
                title:            '',
                image:            '',
                recipeCategory:   [],
                intakeLevel:      0,
                carbs:            0,
                fats:             0,
                protein:          0,
                description:      '',
                recipeIngredient: [],
                recipeStep:       []
            }
        },
        addNewRecipeTitle: (state, action:PayloadAction<string>) => {
            state.newRecipe.title = action.payload
        },
        addNewRecipeImage: (state, action:PayloadAction<string>) => {
            state.newRecipe.image = action.payload
        },
        addNewRecipeCategory: (state, action:PayloadAction<RecipeCategory[]>) => {
            state.newRecipe.recipeCategory = action.payload
        },
        removeNewRecipeCategory: (state, action: PayloadAction<string>) => {
            const filteredCategories = state.newRecipe.recipeCategory.filter(category => category.id !== action.payload)
            state.newRecipe.recipeCategory = filteredCategories
        },
        addNewRecipeIntakeLevel: (state, action:PayloadAction<AmountConvertType>) => {
            if (isNaN(Number(action.payload.value))) return
            if (action.payload.measurement === 'Metric') {
                const intakeKJ = action.payload.value === ''
                    ? 0
                    : parseInt(action.payload.value)
                state.newRecipe.intakeLevel = intakeKJ
            } else {
                const intakeCal = action.payload.value === ''
                    ? 0
                    : onChangeKcalToKJConverter(action.payload.value)
                state.newRecipe.intakeLevel = intakeCal
            }
        },
        addNewRecipeCarbs: (state, action:PayloadAction<AmountConvertType>) => {
            if (isNaN(Number(action.payload.value))) return
            if (action.payload.measurement === 'Metric') {
                const carbGrams = action.payload.value === ''
                    ? 0
                    : parseInt(action.payload.value)
                state.newRecipe.carbs = carbGrams
            } else {
                const carbOunces = action.payload.value === ''
                    ? 0
                    : onChangeOzToGConverter(action.payload.value)
                state.newRecipe.carbs = carbOunces
            }
        },
        addNewRecipeFats: (state, action:PayloadAction<AmountConvertType>) => {
            if (isNaN(Number(action.payload.value))) return
            if (action.payload.measurement === 'Metric') {
                const fatGrams = action.payload.value === ''
                    ? 0
                    : parseInt(action.payload.value)
                state.newRecipe.fats = fatGrams
            } else {
                const fatOunces = action.payload.value === ''
                    ? 0
                    : onChangeOzToGConverter(action.payload.value)
                state.newRecipe.fats = fatOunces
            }
        },
        addNewRecipeProtein: (state, action:PayloadAction<AmountConvertType>) => {
            if (isNaN(Number(action.payload.value))) return
            if (action.payload.measurement === 'Metric') {
                const proteinGrams = action.payload.value === ''
                    ? 0
                    : parseInt(action.payload.value)
                state.newRecipe.protein = proteinGrams
            } else {
                const proteinOunces = action.payload.value === ''
                    ? 0
                    : onChangeOzToGConverter(action.payload.value)
                state.newRecipe.protein = proteinOunces
            }
        },
        addNewRecipeDescription: (state, action:PayloadAction<string>) => {
            state.newRecipe.description = action.payload
        },
        addNewRecipeIngredientName: (state, action:PayloadAction<{ ingredientId: string, name: string }>) => {
            state.newRecipe.recipeIngredient.forEach((ingredient, index) => {
                if (ingredient.id === action.payload.ingredientId &&
                    state.newRecipe.recipeIngredient
                ) {
                    state.newRecipe.recipeIngredient[index].name = action.payload.name
                }
            })
        },
        addNewRecipeIngredientAmount: (state, action: PayloadAction<{ ingredientId: string, amount: string }>) => {
            state.newRecipe.recipeIngredient.forEach((ingredient, index) => {
                if (ingredient.id === action.payload.ingredientId &&
                    state.newRecipe.recipeIngredient
                ) {
                    state.newRecipe.recipeIngredient[index].amount = action.payload.amount
                }
            })
        },
        deleteNewRecipeIngredient: (state, action:PayloadAction<string>) => {
            const filteredIngredients = state.newRecipe.recipeIngredient.filter(ingredient => 
                ingredient.id !== action.payload    
            )
            state.newRecipe.recipeIngredient = filteredIngredients
        },
        addBrandNewRecipeIngredient: (state) => {
            const ingredientId = uuid()
            const date = moment().format()
            const newIngredientObj: RecipeIngredient = {
                id:        ingredientId,
                createdAt: date.toString(),
                updatedAt: date.toString(),
                name:      '',
                amount:    '0g',
                recipeId:  state.newRecipe.id,
                index : state.newRecipe.recipeIngredient.length
            }
            state.newRecipe.recipeIngredient.push(newIngredientObj)
        },
        addNewRecipeStepTitle: (state, action:PayloadAction<{ stepId: string, title: string }>) => {
            state.newRecipe.recipeStep.forEach((step, index) => {
                if (step.id === action.payload.stepId &&
                    state.newRecipe.recipeStep                    
                ) {
                    state.newRecipe.recipeStep[index].title = action.payload.title
                }
            })
        },
        addNewRecipeStepDescription: (state, action: PayloadAction<{ stepId: string, description: string }>) => {
            state.newRecipe.recipeStep.forEach((step, index) => {
                if (step.id === action.payload.stepId &&
                    state.newRecipe.recipeStep    
                ) {
                    state.newRecipe.recipeStep[index].description = action.payload.description
                }
            })
        },
        deleteNewRecipeStep: (state, action:PayloadAction<string>) => {
            const filteredSteps = state.newRecipe.recipeStep.filter(step =>
                step.id !== action.payload    
            )
            const updatedSteps = filteredSteps.map((step, index) => {
                return {
                    ...step,
                    stepNumber: index + 1
                }
            })
            state.newRecipe.recipeStep = updatedSteps
        },
        addBrandNewRecipeStep: (state) => {
            const stepId = uuid()
            const date = moment().format()
            const newStepObj: RecipeStep = {
                id:          stepId,
                createdAt:   date.toString(),
                updatedAt:   date.toString(),
                stepNumber:  (state.recipe.recipeStep?.length ?? 0) + 1,
                title:       '',
                description: '',
                recipeId:    state.newRecipe.id,
            }
            state.newRecipe.recipeStep.push(newStepObj)
        },
    }
})

export const recipeIdSelector  = (state: RootState) => state.recipe.id
export const recipeSelector    = (state: RootState) => state.recipe.recipe
export const newRecipeSelector = (state: RootState) => state.recipe.newRecipe
export const {
    selectRecipe,
    storeRecipe,
    updateRecipeTitle,
    updateRecipeImage,
    updateRecipeCategory,
    removeRecipeCategory,
    updateRecipeIntakeLevel,
    updateRecipeCarbs,
    updateRecipeFats,
    updateRecipeProtein,
    updateRecipeDescription,
    updateRecipeIngredientName,
    updateRecipeIngredientAmount,
    deleteRecipeIngredient,
    addNewRecipeIngredient,
    updateRecipeActiveStatus,
    deleteRecipeStep,
    addNewRecipeStep,
    updateRecipeStepTitle,
    updateRecipeStepDescription,
    clearRecipe,
    clearNewRecipe,
    addNewRecipeTitle,
    addNewRecipeImage,
    addNewRecipeCategory,
    removeNewRecipeCategory,
    addNewRecipeIntakeLevel,
    addNewRecipeCarbs,
    addNewRecipeFats,
    addNewRecipeProtein,
    addNewRecipeDescription,
    addNewRecipeIngredientName,
    addNewRecipeIngredientAmount,
    deleteNewRecipeIngredient,
    addBrandNewRecipeIngredient,
    addNewRecipeStepTitle,
    addNewRecipeStepDescription,
    deleteNewRecipeStep,
    addBrandNewRecipeStep,
} = recipeSlice.actions
export default recipeSlice.reducer