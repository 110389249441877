import { useHTKSelector } from '../../app/hooks'
import { HTKEmptyDetailBox } from '../../components/atoms/atoms'
import { UserDetail, UserList } from '../../components/organisms/organisms'
import { DashboardLayout } from '../../components/templates/templates'
import { userIdSelector } from '../../features/User/userSlice'

const Users = () => {
  const userId = useHTKSelector(userIdSelector)

  return (
    <DashboardLayout page='User'>
      <div className='w-full h-full flex justify-center'>
        <UserList />
        {userId ? (
          <UserDetail />
        ) : (
          <HTKEmptyDetailBox title='user'/>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Users