import { useHTKSelector } from '../../../app/hooks'
import { overlaySelector } from '../../../features/Cores/overlaySlice'
import { HTKLoading } from '../atoms'

const HTKOverlay = () => {
  const overlayState = useHTKSelector(overlaySelector)

  return (
    <div className='absolute inset-0 bg-HTKBlack/80 backdrop-blur-sm z-50'>
      <div 
        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
        h-[8em] flex flex-col justify-between text-center'
      >
        <HTKLoading shape='overlay'/>
        <p className='text-HTKWhite text-3xl font-Title font-semibold'>
          ... {overlayState.text}
        </p>
      </div>
    </div>
  )
}

export default HTKOverlay