import { RFC } from "../../../types/propTypes";
import { HTKDetailSectionTitleBlock } from "../atoms";

type HTKTabInput = {
	title: string;
	description: string;
	list: string[];
	selectedItem: string | undefined;
	className: string;
	type?: string;
	onSelect: (type: string) => void;
};

const HTKTabInput: RFC<HTKTabInput> = ({ title, description, list, selectedItem, className, type, onSelect }) => {
	const renderTabList = () => {
		if (type === "Circuit")
			return (
				<div
					className={`rounded-[3px] border-[1px] border-solid border-HTKBorder
                    py-[6px] px-[14px] cursor-pointer drop-shadow-sm shadow-sm
                    transition-all duration-200 font-semibold
                    bg-HTKRed text-HTKWhite hover:bg-HTKRed`}
				>
					Circuit
				</div>
			);

		return list.map((item, index) => {
			const isSelected = selectedItem === item;
			return (
				<div
					key={index}
					className={`rounded-[3px] border-[1px] border-solid border-HTKBorder
                            py-[6px] px-[14px] cursor-pointer drop-shadow-sm shadow-sm hover:bg-HTKMiddleGrey
                            transition-all duration-200 font-semibold
                            ${isSelected ? "bg-HTKRed text-HTKWhite hover:bg-HTKRed" : "bg-HTKWhite text-HTKBlack"}`}
					onClick={() => onSelect(item)}
				>
					{item}
				</div>
			);
		});
	};

	return (
		<div className={`${className}`}>
			<div className="w-full">
				<HTKDetailSectionTitleBlock title={title} description={description} />
			</div>
			<div className="w-full flex flex-wrap gap-2 my-2">{renderTabList()}</div>
		</div>
	);
};

export default HTKTabInput;
