import { useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useGetPresignedDownloadURLQuery } from '../../../services/FileService'
import { HTKLoading } from '../atoms'
import { TrainingIcon } from '../../../assets/icons/icons'

type HTKAsyncImageProps = {
  fileName: string | undefined
}

const HTKAsyncImage:RFC<HTKAsyncImageProps> = ({ fileName }) => {
  const [ isImageError, setIsImageError ] = useState(false)
  const { data, isLoading, isError } = useGetPresignedDownloadURLQuery({ imageKey: fileName }, { skip: !fileName })

  if (isLoading) return (
    <div className='h-full w-full flex justify-center items-center rounded-lg'>
      <HTKLoading shape='circle' />
    </div>
  )

  if (!data) return (
    <TrainingIcon 
      className='flex justify-center items-center h-full
      bg-HTKMiddleGrey rounded-lg hover:bg-HTKLightBlack
      cursor-pointer transition-all duration-200 '
      width={'24'}
      height={'24'}
    />
  )

  if (isError || isImageError) return (
    <img
      className='h-full aspect-square flex justify-center items-center rounded-[3px] object-contain'
      src='https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'
      alt='error'
    />
  )

  return (
    <img 
      className='h-full w-full rounded-[3px] drop-shadow-sm object-cover'
      src={data}
      alt='thumnail'
      onError={() => setIsImageError(true)}
      loading='lazy'
    />
  )
}

export default HTKAsyncImage