import { EXERCISE_TYPES } from "../assets/data/enums";
import { RecommendationOptionType } from "../features/Training/Programs/programSlice";
import { ProgramRecommendation } from "../types/stateTypes";

export const programActiveFilter = (active: string | undefined) => {
	switch (active) {
		case "ALL PROGRAMS":
			return "";
		case "ACTIVE PROGRAMS":
			return true;
		case "DEACTIVATED PROGRAMS":
			return false;
		default:
			return "";
	}
};

export const programRecommendationConverter = (option: string | undefined) => {
	switch (option) {
		case "Build muscle mass and gain strength":
			return "BuildMuscleMassAndGainStrength";
		case "Shred fat and build lean muscle":
			return "ShredFatAndBuildLeanMuscle";
		case "Improve performance or prepare for elite selection course":
			return "ImprovePerformanceOrPrepareForEliteSelectionCourse";
		case "Yes, I have had past experience":
			return "HaveHadPastExperience";
		case "No, I’m new to weight lifting":
			return "NewToWeightLifting";
		case "Yes, I have access to a standard gym":
			return "AccessToStandardGym";
		case "Yes, I have access to a home gym":
			return "AccessToHomeGym";
		case "No, I don’t have access":
			return "DontHaveAccess";
		case "Yes, I have a pull up bar":
			return "HavePullUpBar";
		case "No, I don’t have a pull up bar":
			return "DontHavePullUpBar";
		default:
			return "";
	}
};

export const assignRecommendationType = (option: string): RecommendationOptionType => {
	switch (option) {
		case "BuildMuscleMassAndGainStrength":
			return "Goal";
		case "ShredFatAndBuildLeanMuscle":
			return "Goal";
		case "ImprovePerformanceOrPrepareForEliteSelectionCourse":
			return "Goal";
		case "HaveHadPastExperience":
			return "Experience";
		case "NewToWeightLifting":
			return "Experience";
		case "AccessToStandardGym":
			return "Gym";
		case "AccessToHomeGym":
			return "Gym";
		case "DontHaveAccess":
			return "Gym";
		case "HavePullUpBar":
			return "Pullup";
		case "DontHavePullUpBar":
			return "Pullup";
		default:
			return "Goal";
	}
};

export const reverseProgramRecommendationConverter = (options: ProgramRecommendation[] | undefined, type: "Goal" | "Experience" | "Gym" | "Pullup") => {
	const goalOptions = ["BuildMuscleMassAndGainStrength", "ShredFatAndBuildLeanMuscle", "ImprovePerformanceOrPrepareForEliteSelectionCourse"];
	const experienceOptions = ["HaveHadPastExperience", "NewToWeightLifting"];
	const gymOptions = ["AccessToStandardGym", "AccessToHomeGym", "DontHaveAccess"];
	const pullupOptions = ["HavePullUpBar", "DontHavePullUpBar"];
	let selectedOption = "";

	switch (type) {
		case "Goal": {
			options?.forEach((option) => {
				const matchedOption = goalOptions.find((goal) => goal === option.title);
				if (matchedOption) selectedOption = matchedOption;
			});
			break;
		}
		case "Experience": {
			options?.forEach((option) => {
				const matchedOption = experienceOptions.find((experience) => experience === option.title);
				if (matchedOption) selectedOption = matchedOption;
			});
			break;
		}
		case "Gym": {
			options?.forEach((option) => {
				const matchedOption = gymOptions.find((gym) => gym === option.title);
				if (matchedOption) selectedOption = matchedOption;
			});
			break;
		}
		case "Pullup": {
			options?.forEach((option) => {
				const matchedOption = pullupOptions.find((pullup) => pullup === option.title);
				if (matchedOption) selectedOption = matchedOption;
			});
			break;
		}
		default:
			break;
	}

	switch (selectedOption) {
		case "BuildMuscleMassAndGainStrength":
			return "Build muscle mass and gain strength";
		case "ShredFatAndBuildLeanMuscle":
			return "Shred fat and build lean muscle";
		case "ImprovePerformanceOrPrepareForEliteSelectionCourse":
			return "Improve performance or prepare for elite selection course";
		case "HaveHadPastExperience":
			return "Yes, I have had past experience";
		case "NewToWeightLifting":
			return "No, I’m new to weight lifting";
		case "AccessToStandardGym":
			return "Yes, I have access to a standard gym";
		case "AccessToHomeGym":
			return "Yes, I have access to a home gym";
		case "DontHaveAccess":
			return "No, I don’t have access";
		case "HavePullUpBar":
			return "Yes, I have a pull up bar";
		case "DontHavePullUpBar":
			return "No, I don’t have a pull up bar";
		default:
			return;
	}
};

export const workoutTypeFilter = (type: string | undefined) => {
	switch (type) {
		case "ALL WORKOUTS":
			return "";
		case "STRENGTH":
			return "Strength";
		case "CIRCUIT":
			return "Circuit";
		default:
			return "";
	}
};

export const exerciseActiveFilter = (active: string | undefined) => {
	switch (active) {
		case "ALL EXERCISES":
			return "";
		case "ACTIVE":
			return true;
		case "INACTIVE":
			return false;
		default:
			return "";
	}
};

export const exerciseTypeConverter = (type: EXERCISE_TYPES) => {
	switch (type) {
		case EXERCISE_TYPES.CIRCUIT:
			return "Circuit";
		case EXERCISE_TYPES.WEIGHTED:
			return "Weighted";
		case EXERCISE_TYPES.ONE_RM:
			return "1RM Strength";
		case EXERCISE_TYPES.BODY_WEIGHT:
			return "Body Weight";
		default:
			return;
	}
};

export const exerciseTypeReverseConverter = (tab: string) => {
	switch (tab) {
		case "Circuit":
			return EXERCISE_TYPES.CIRCUIT;
		case "Weighted":
			return EXERCISE_TYPES.WEIGHTED;
		case "1RM Strength":
			return EXERCISE_TYPES.ONE_RM;
		case "Body Weight":
			return EXERCISE_TYPES.BODY_WEIGHT;
		default:
			return;
	}
};

export const exerciseNumberConverter = (index: number) => {
	switch (index) {
		case 1:
			return "ONE";
		case 2:
			return "TWO";
		case 3:
			return "THREE";
		case 4:
			return "FOUR";
		case 5:
			return "FIVE";
		case 6:
			return "SIX";
		case 7:
			return "SEVEN";
		case 8:
			return "EIGHT";
		case 9:
			return "NINE";
		case 10:
			return "TEN";
		case 11:
			return "ELEVEN";
		case 12:
			return "TWELVE";
		case 13:
			return "THIRTEEN";
		case 14:
			return "FOURTEEN";
		case 15:
			return "FIFTEEN";
		case 16:
			return "SIXTEEN";
		case 17:
			return "SEVENTEEN";
		case 18:
			return "EIGHTEEN";
		case 19:
			return "NINETEEN";
		case 20:
			return "TWENTY";
		default:
			return "";
	}
};
