import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import {
  addNewExerciseDescription,
  addNewExerciseImage,
  addNewExerciseTitle,
  addNewExerciseVideo,
  newExerciseSelector,
  removeNewExerciseCategory,
  selectExercise,
} from "../../../features/Training/Exercises/exerciseSlice";
import { useCreateExerciseMutation } from "../../../services/ExerciseService";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/Cores/overlaySlice";
import { Dialog } from "@headlessui/react";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { CloseIcon } from "../../../assets/icons/icons";
import { addExercise } from "../../../features/Training/Exercises/exercisesSlice";
import asyncTimeout from "../../../utils/asyncTimeout";
import {
  HTKButton,
  HTKTextArea,
  HTKTextInput,
  HTKTitleBlock,
  HTKUploadInput,
} from "../../atoms/atoms";
import { ExerciseCategoryField } from "../molecules";

const CreateExerciseModal = () => {
  const { title, video, exerciseCategory, description, image } =
    useHTKSelector(newExerciseSelector);
  const dispatch = useHTKDispatch();

  const [createExercise] = useCreateExerciseMutation();

  const createNewExercise = async () => {
    try {
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Creating New Exercise" }));
      const [data] = await Promise.all([
        createExercise({
          title,
          video,
          exerciseCategory,
          description,
          image,
        }).unwrap(),
        asyncTimeout(2000),
      ]);
      if (!data) throw Error;
      dispatch(addExercise(data));
      dispatch(selectExercise({ id: data.id, exercise: data }));
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "EXERCISE CREATED",
          body: "You have successfully created a new exercise!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to create exercise",
          body: "Please check your input details and try again.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  return (
    <>
      <div className="min-w-[500px] max-w-[600px] py-3 px-6">
        <Dialog.Title className="flex items-center justify-between">
          <div>
            <p className="font-Title font-extrabold text-xl">NEW EXERCISE</p>
            <p className="text-HTKBlack/80">Let’s create a new exercise.</p>
          </div>
          <CloseIcon
            width={"30"}
            height={"30"}
            className="cursor-pointer"
            onClick={() => dispatch(closeModal())}
          />
        </Dialog.Title>
        <hr className="htk-border my-2" />
        <div className="max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]">
          <HTKTitleBlock
            title="EXERCISE TITLE"
            description="Give this exercise an accurate title to what is involved."
            isRequired={!title}
          />
          <HTKTextInput
            name="title"
            value={title}
            placeholder="Title"
            onChange={(text) => dispatch(addNewExerciseTitle(text))}
          />
          <HTKTitleBlock
            title="EXERCISE VIDEO"
            description="Upload a video of this exercise. This video will be shown to users during a workout and exercise library."
            isRequired={!video}
          />
          <HTKUploadInput
            type="video"
            source={video ?? ""}
            onUpload={(video) => dispatch(addNewExerciseVideo(video ?? ""))}
          />
          <HTKTitleBlock
            title="EXERCISE IMAGE"
            description="Upload an image for this exercise’s thumbnail image. This image will be shown to users during a workout and viewing in the exercise library."
            isRequired={!image}
          />
          <HTKUploadInput
            type="image"
            source={image ?? ""}
            onUpload={(image) => dispatch(addNewExerciseImage(image ?? ""))}
          />
          <HTKTitleBlock
            title="EXERCISE CATEGORY"
            description="Add this exercise to a category. This allows user to find exercises by category."
            isRequired={!exerciseCategory?.length}
          />
          <ExerciseCategoryField
            categories={exerciseCategory}
            title="CREATE_EXERCISE"
            onDelete={(categoryId) =>
              dispatch(removeNewExerciseCategory(categoryId))
            }
          />
          <HTKTitleBlock
            title="EXERCISE DESCRIPTION"
            description="Add an exercise description. This description will be shown to user when they video this exercise in the exercise library."
            isRequired={
              !description || description === "<p><br></p>" ? true : false
            }
          />
          <HTKTextArea
            text={description ?? ""}
            onChange={(text) => dispatch(addNewExerciseDescription(text))}
          />
        </div>
      </div>
      <div className="sticky bottom-0 w-full h-12 flex items-center px-6">
        <HTKButton
          text="Create Exercise"
          isLoading={false}
          disabled={false}
          className="create-item-button"
          onSubmit={createNewExercise}
        />
        <HTKButton
          text="Cancel"
          className="cancel-item-button"
          onSubmit={() => dispatch(closeModal())}
        />
      </div>
    </>
  );
};

export default CreateExerciseModal;
