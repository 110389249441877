import { Dialog } from '@headlessui/react'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { closeModal, modalSelector } from '../../../features/Cores/modalSlice'
import { HTKButton } from '../../atoms/atoms'

const DeleteModal = () => {
    const { deleteId, deleteFn } = useHTKSelector(modalSelector)
    const dispatch = useHTKDispatch()

    return (
        <div className='w-[400px] py-3 px-6'>
            <Dialog.Title className='flex items-center justify-between'>
                <span className='font-Title font-extrabold text-xl'>
                CONFIRM DELETION
                </span>
            </Dialog.Title>
            <hr className='htk-border my-2'/>
            <div>
                <p className='text-HTKBlack/80'>
                    Are you sure you want to delete this item from the HTK database? This action is unrecoverable.
                </p>
                <div className='w-full flex items-center mt-3'>
                    <HTKButton 
                        text='Confirm Deletion'
                        className='main-detail-button'
                        onSubmit={() => deleteFn && deleteFn(deleteId ?? '')}
                    />
                    <HTKButton 
                        text='Go Back'
                        className='delete-goback-button'
                        onSubmit={() => dispatch(closeModal())}
                    />
                </div>
            </div>
        </div>
    )
}

export default DeleteModal