import { DashboardTitleType, RFC } from '../../types/propTypes'

type SubNavbarProps = {
    list:        DashboardTitleType[]
    currentPage: string
    onClick:     (pageTitle: string) => void
}

const SubNavbar:RFC<SubNavbarProps> = ({ 
    list,
    currentPage,
    onClick
}) => {
  return (
    <div className='w-full flex justify-center'>
        {list.map((tab, index) => (
            <div 
                key={index}
                className={`w-[9em] py-[6px] text-sm flex justify-center items-center
                cursor-pointer hover:bg-HTKMiddleGrey transition-all duration-300
                font-Title font-semibold
                ${index === 0 && 'border-l-[1px]'}
                ${list.length - 1 === index ? 'border-r-[1px]' : 'border-r-[0.5px]'}
                ${currentPage === tab.title 
                    ? 'bg-HTKDarkGrey text-white hover:bg-HTKDarkGrey' 
                    : 'bg-HTKWhite text-black'
                }`}
                onClick={() => onClick(tab.title)}
            >
               {tab.title} 
            </div>
        ))}
    </div>
  )
}

export default SubNavbar