import { RFC } from "../../../types/propTypes";
import { useHTKDispatch } from "../../../app/hooks";
import { HTKButton, HTKSectionTitleBlock } from "../../atoms/atoms";
import { BoxSkeleton, ActiveStatusField } from "../molecules";
import { openModal } from "../../../features/Cores/modalSlice";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { updateCourseActiveStatus } from "../../../features/Training/Courses/courseSlice";

type CourseManagementFieldProps = {
	isLoading: boolean;
	isActive: boolean | undefined;
	onSaveChangesPress: () => void;
	className?: string;
};

const CourseManagementField: RFC<CourseManagementFieldProps> = ({ className, isLoading, isActive, onSaveChangesPress }) => {
	const dispatch = useHTKDispatch();
	function renderStatusBox() {
		if (isLoading) return <BoxSkeleton />;
		return <ActiveStatusField width="w-full" size="text-xs" isActive={isActive} onChangeStatus={(isOpen) => dispatch(updateCourseActiveStatus(isOpen))} />;
	}

	function renderCourseRecommendation() {
		if (isLoading) return <BoxSkeleton />;
		return (
			<div className="m-3 rounded-[3px] htk-container p-2">
				<p className="font-Title font-bold text-sm mb-2">COURSE RECOMMENDATION</p>
				<p className="text-xs">When should this course be recommended to users? Select the questions a user must select to see this course.</p>
				<HTKButton
					text="Edit Recommendations"
					className="main-detail-button mt-3 text-sm"
					onSubmit={() =>
						dispatch(
							openModal({
								modalType: MODAL_TYPES.RECOMMENDATION,
							})
						)
					}
				/>
			</div>
		);
	}

	function renderSaveChages() {
		if (isLoading) return <BoxSkeleton />;
		return (
			<div className="m-3 rounded-[3px] htk-container p-2">
				<p className="font-Title font-bold text-sm mb-2">{`SAVE & APPLY CHANGES`}</p>
				<p className="text-xs">Saving and applying changes to this course will alter and forward changes made to this course to users on HTK.</p>
				<HTKButton text="Save Changes" className="main-detail-button mt-3 text-sm bg-HTKGreen" onSubmit={onSaveChangesPress} />
			</div>
		);
	}
	return (
		<div className={` border-l-[1px] w-full border-solid border-HTKBorder pb-[14em] ${className} `}>
			<HTKSectionTitleBlock title="COURSE MANAGEMENT" />
			{renderStatusBox()}
			<div className="h-full flex flex-col">
				{renderCourseRecommendation()}
				{renderSaveChages()}
			</div>
		</div>
	);
};

export default CourseManagementField;
