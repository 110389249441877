import { useMemo, useState } from 'react'
import { useHTKDispatch } from '../../../app/hooks'
import { debounce } from 'lodash'
import { DASHBOARD_TYPES, MODAL_TYPES } from '../../../assets/data/enums'
import { HTKSearchbar, HTKSearchTabs, HTKSearchTitleBlock } from '../../atoms/atoms'
import { InfiniteScrollLists } from '../organisms'
import { openModal } from '../../../features/Cores/modalSlice'
import { AnimatePresence } from 'framer-motion'
import { workoutSearchTabs } from '../../../assets/data/arrays'
import { clearNewWorkout } from '../../../features/Training/Workouts/workoutSlice'

const WorkoutsList = () => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
  const [ searchTab, setSearchTab ] = useState('ALL WORKOUTS')
  const dispatch = useHTKDispatch()

  return (
    <div className='flex flex-col'>
      <p className='min-h-[2.5em] pb-1 flex items-end font-Title text-2xl font-bold'>
        WORKOUTS
      </p>
      <div className='w-[20em] bg-HTKWhite htk-container'>
        <HTKSearchTitleBlock 
          title='ALL WORKOUTS'
          btnTitle='New'
          onClick={() => {
            dispatch(clearNewWorkout())
            dispatch(openModal({
              modalType: MODAL_TYPES.CREATE_WORKOUT
            }))
          }}
        />
        <HTKSearchbar onChange={onChangeText} />
        <HTKSearchTabs 
          tabs={workoutSearchTabs}
          selectedTab={searchTab}
          onClick={(tab) => setSearchTab(tab)}
        />
        <div className='w-full my-1'>
          <AnimatePresence>
            <InfiniteScrollLists 
              key='infinite_scroll'
              page={DASHBOARD_TYPES.WORKOUTS}
              searchText={searchText}
              searchTab={searchTab}
              pb={'pb-[12em]'}
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

export default WorkoutsList