import { useHTKDispatch, useHTKSelector } from '../../app/hooks'
import { HTKEmptyDetailBox } from '../../components/atoms/atoms'
import { ActiveStatusField } from '../../components/molecules/molecules'
import { ExerciseDetail, ExercisesList } from '../../components/organisms/organisms'
import { exerciseIdSelector, exerciseSelector, updateExerciseActiveStatus } from '../../features/Training/Exercises/exerciseSlice'

const Exercises = () => {
  const exerciseId = useHTKSelector(exerciseIdSelector)
  const { isActiveOnApp } = useHTKSelector(exerciseSelector)
  const dispatch = useHTKDispatch()

  return (
    <div className='w-full h-full flex justify-center'>
      <ExercisesList />
      {exerciseId ? (
        <>
          <ExerciseDetail />
          <ActiveStatusField 
            width='w-[17em]'
            isActive={isActiveOnApp}
            onChangeStatus={(isOpen) => dispatch(updateExerciseActiveStatus(isOpen))}
          />
        </>
      ) : (
        <HTKEmptyDetailBox title='exercise' />
      )}
    </div>
  )
}

export default Exercises