import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialExercisesState } from '../../../assets/data/initialState'
import { Exercise } from '../../../types/stateTypes'

export type WorkoutExercisesSliceType = {
    exercises: Exercise[]
}

export const workoutExercisesSlice = createSlice({
    name: 'workoutExercises',
    initialState: InitialExercisesState,
    reducers: {
        clearExercises: (state) => {
            state.exercises = []
        },
        setInitialExercises: (state, action: PayloadAction<WorkoutExercisesSliceType>) => {
            state.exercises = action.payload.exercises
        },
        setExercises: (state, action: PayloadAction<WorkoutExercisesSliceType>) => {
            const { exercises: storedExercises } = state
            const { exercises: newExercisese } = action.payload
            const exerciseIds = storedExercises.map(exercise => exercise.id)
            const filteredDuplicates = newExercisese.filter(exercise => !exerciseIds.includes(exercise.id))
            filteredDuplicates.map(exercise => state.exercises.push(exercise))
        },
        addExercise: (state, action: PayloadAction<Exercise>) => {
            state.exercises.unshift(action.payload)
        },
        updateExerciseInfo: (state, action: PayloadAction<Exercise>) => {
            const filteredExercises = state.exercises.filter(exercise => exercise.id !== action.payload.id)
            state.exercises = [action.payload, ...filteredExercises]
        },
        deleteExerciseWithId: (state, action: PayloadAction<string>) => {
            const filteredExercises = state.exercises.filter(exercise => exercise.id !== action.payload)
            state.exercises = filteredExercises
        }
    }
})

export const workoutExercisesSelector = (state: RootState) => state.workoutExercises.exercises

export const {
    clearExercises,
    setInitialExercises,
    setExercises,
    addExercise,
    updateExerciseInfo,
    deleteExerciseWithId,
} = workoutExercisesSlice.actions
export default workoutExercisesSlice.reducer