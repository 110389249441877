import { HTKApi } from "../app/api";
import { CreateAnnouncementArgs, PaginationPayload, UpdateAnnouncementArgs } from "../types/serviceTypes";
import { Announcement } from "../types/stateTypes";

export const announcementApi = HTKApi.injectEndpoints({
	endpoints: (builder) => ({
		getAnnouncements: builder.query<Announcement[], PaginationPayload>({
			query: ({ search, skip, take }) => ({
				url: "/announcements",
				params: { search, skip, take },
			}),
			providesTags: ["Announcement"],
		}),
		getActiveAnnouncements: builder.query<Announcement[], void>({
			query: () => "/announcements/app/active",
			providesTags: ["Announcement"],
		}),
		getAnnouncement: builder.query<Announcement, string>({
			query: (announcementId) => `/announcements/${announcementId}`,
			providesTags: ["Announcement"],
		}),
		getActiveAnnouncement: builder.query<Announcement, void>({
			query: () => "/announcements/app/active",
			providesTags: ["Announcement"],
		}),
		createAnnouncement: builder.mutation<Announcement, CreateAnnouncementArgs>({
			query: ({ title, link, image, body, courseId }) => ({
				url: `/announcements`,
				method: "POST",
				body: {
					title,
					link,
					image,
					body,
					courseId,
				},
			}),
			invalidatesTags: ["Announcement"],
		}),
		updateAnnouncement: builder.mutation<Announcement, UpdateAnnouncementArgs>({
			query: ({ id, title, link, image, body, courseId }) => ({
				url: `/announcements/${id}`,
				method: "PUT",
				body: {
					title,
					link,
					image,
					body,
					courseId,
				},
			}),
			invalidatesTags: ["Announcement"],
		}),
		activateAnnouncement: builder.mutation<Announcement, string>({
			query: (announcementId) => ({
				url: `/announcements/${announcementId}/active`,
				method: "PUT",
			}),
			invalidatesTags: ["Announcement"],
		}),
		deactivateAnnouncement: builder.mutation<Announcement, string>({
			query: (announcementId) => ({
				url: `/announcements/${announcementId}/hide-on-app`,
				method: "PUT",
			}),
			invalidatesTags: ["Announcement"],
		}),
		deleteAnnouncement: builder.mutation<Announcement, string>({
			query: (announcementId) => ({
				url: `/announcements/${announcementId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Announcement"],
		}),
	}),
});

export const { useLazyGetAnnouncementsQuery, useLazyGetActiveAnnouncementsQuery, useGetAnnouncementQuery, useGetActiveAnnouncementQuery, useCreateAnnouncementMutation, useUpdateAnnouncementMutation, useActivateAnnouncementMutation, useDeactivateAnnouncementMutation, useDeleteAnnouncementMutation } =
	announcementApi;
