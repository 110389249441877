import { useHTKSelector } from '../../app/hooks'
import { announcementIdSelector } from '../../features/Announcement/announcementSlice'
import { DashboardLayout } from '../../components/templates/templates'
import { AnnouncementsList, AnnouncementDetail, ActiveAnnouncement } from '../../components/organisms/organisms'
import { HTKEmptyDetailBox } from '../../components/atoms/atoms'

const Announcements = () => {
  const announcementId = useHTKSelector(announcementIdSelector)

  return (
    <DashboardLayout page='Announcements'>
      <div className='w-full h-full flex justify-center'>
        <AnnouncementsList />
        {announcementId ? (
          <AnnouncementDetail />
        ) : (
          <HTKEmptyDetailBox title='announcement'/>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Announcements