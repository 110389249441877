import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialProgramWorkoutsState } from '../../../assets/data/initialState'
import { Workout } from '../../../types/stateTypes'

export type ProgramWorkoutsSliceType = {
    workouts: Workout[]
}

export const programWorkoutsSlice = createSlice({
    name: 'programWorkouts',
    initialState: InitialProgramWorkoutsState,
    reducers: {
        clearProgramWorkouts: (state) => {
            state.workouts = []
        },
        setInitialProgramWorkouts: (state, action:PayloadAction<ProgramWorkoutsSliceType>) => {
            state.workouts = action.payload.workouts
        },
        setProgramWorkouts: (state, action:PayloadAction<ProgramWorkoutsSliceType>) => {
            const { workouts: storedWorkouts } = state
            const { workouts: newWorkouts } = action.payload
            const workoutIds = storedWorkouts.map(workout => workout.id)
            const filteredDuplicates = newWorkouts.filter(workout => !workoutIds.includes(workout.id))
            filteredDuplicates.map(workout => state.workouts.push(workout))
        },
    }
})

export const programWorkoutsSelector = (state: RootState) => state.programWorkouts.workouts
export const {
    clearProgramWorkouts,
    setInitialProgramWorkouts,
    setProgramWorkouts,
} = programWorkoutsSlice.actions
export default programWorkoutsSlice.reducer