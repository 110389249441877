import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useHTKDispatch, useHTKSelector } from "./app/hooks";
import { HTKModal, HTKOverlay, HTKSnackbar } from "./components/atoms/atoms";
import { clearAdmin, storeAdmin } from "./features/Cores/adminSlice";
import { overlaySelector } from "./features/Cores/overlaySlice";
import { Announcements, Library, Login, Training, Users } from "./pages/pages";
import { useLazyAdminMeQuery } from "./services/AuthService";
import { AuthRequiredRoutes, NotAuthRoutes } from "./utils/CustomRoutes";

function App() {
	const token = localStorage.getItem("HTKAdminToken");
	const overlayState = useHTKSelector(overlaySelector);
	const dispatch = useHTKDispatch();
	const [adminMe] = useLazyAdminMeQuery();

	useEffect(() => {
		if (!token) {
			dispatch(clearAdmin());
			localStorage.removeItem("HTKAdminToken");
		} else {
			getAdminData();
		}
	}, []);

	const getAdminData = async () => {
		try {
			const data = await adminMe().unwrap();
			if (!data) throw Error;
			dispatch(storeAdmin({ admin: data.adminDetails }));
			localStorage.setItem("HTKAdminToken", data.jwtToken);
		} catch (error) {
			console.log({ error });
		}
	};

	return (
		<Router>
			<HTKSnackbar position="bottom_left" />
			<HTKModal />
			{overlayState.isLoading && <HTKOverlay />}

			<Routes>
				<Route path="/" element={<Navigate to="/login" replace />} />

				<Route element={<NotAuthRoutes />}>
					<Route path="/login" element={<Login />} />
				</Route>

				<Route element={<AuthRequiredRoutes />}>
					<Route path="/announcements" element={<Announcements />} />
					<Route path="/training" element={<Training />} />
					<Route path="/library" element={<Library />} />
					<Route path="/users" element={<Users />} />
				</Route>

				<Route path="*" element={<Login />} />
			</Routes>
		</Router>
	);
}

export default App;
