const userIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "24"} 
            height={height ?? "24"} 
            viewBox="0 0 24 24"
        >
            <g id="supervisor_account_black_24dp">
                <path id="Path_979" data-name="Path 979" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_980" data-name="Path 980" d="M9,12A3.5,3.5,0,1,0,5.5,8.5,3.5,3.5,0,0,0,9,12ZM9,7A1.5,1.5,0,1,1,7.5,8.5,1.5,1.5,0,0,1,9,7Zm.05,10H4.77A10.374,10.374,0,0,1,9,16c.11,0,.23.01.34.01a4.58,4.58,0,0,1,1.64-1.81A11.726,11.726,0,0,0,9,14c-2.34,0-7,1.17-7,3.5V19H9V17.5A2.734,2.734,0,0,1,9.05,17Zm7.45-2.5c-1.84,0-5.5,1.01-5.5,3V19H22V17.5C22,15.51,18.34,14.5,16.5,14.5Zm1.21-1.82a2.5,2.5,0,1,0-2.42,0,2.448,2.448,0,0,0,2.42,0Z" transform="translate(0 0)"/>
            </g>
        </svg>
      </div>
    )
  }
  
  export default userIcon