import { RFC } from '../../../types/propTypes'
import { EXERCISE_TYPES } from '../../../assets/data/enums'
import { BinIcon } from '../../../assets/icons/icons'
import { Set } from '../../../types/stateTypes'
import { useHTKDispatch } from '../../../app/hooks'
import { deleteExerciseSet, updateExerciseOneRmWeight, updateExerciseRepetitions } from '../../../features/Training/Workouts/exerciseGroupSlice'

type HTKDoubleColsInputProps = {
  sets:         Set[]
  leftTitle:    string
  rightTitle:   string
  type:         EXERCISE_TYPES.WEIGHTED | EXERCISE_TYPES.ONE_RM
}

const HTKDoubleColsInput:RFC<HTKDoubleColsInputProps> = ({ 
  sets,
  leftTitle,
  rightTitle,
  type,
}) => {
  const dispatch = useHTKDispatch()

  return (
    <div className='w-full'>
      {sets.length ? (
      <div className='w-full gap-2 flex items-center justify-center'>
        <p className='w-[9em] font-semibold text-center'>
          {leftTitle}
        </p>
        <p className='w-[9em] font-semibold text-center'>
          {rightTitle}
        </p>
      </div>
      ) : null}
      {sets.map((set, index) => (
        <div
          key={index}
          className='flex justify-between w-full rounded-[4px] my-2
          border-solid border-[1px] border-HTKBorder px-3 py-2
          items-center'
        >
          <div className='w-[35px] text-2xl font-Title font-bold'>
            {set.title}
          </div>
          <div className='flex gap-2 mx-3 w-full items-center justify-center'>
            <input 
              type='text'
              value={set.repetitions}
              className='w-[9em] py-2 px-2 text-center rounded-[3px] 
              bg-HTKMiddleGrey font-medium outline-none'
              onChange={(e) => dispatch(updateExerciseRepetitions({
                repetitions: e.target.value,
                set
              }))}
            />
            {type === EXERCISE_TYPES.WEIGHTED ? (
              <div 
                className='w-[9em] py-2 px-2 text-center rounded-[3px] 
                bg-HTKMiddleGrey text-HTKRed font-medium'
              >
                Added By User
              </div>
            ) : (
                <div 
                  className='w-[9em] py-2 px-2 rounded-[3px] flex
                  bg-HTKMiddleGrey font-medium after:content-["%"]'
                >
                <input 
                  type='number'
                  value={set.oneRmWeight}
                  className='w-full text-center bg-HTKMiddleGrey outline-none'
                  onChange={(e) => dispatch(updateExerciseOneRmWeight({
                    oneRmWeight: parseInt(e.target.value), 
                    set
                  }))}
                />
              </div>
            )}
          </div>
          <BinIcon 
            className='cursor-pointer'
            onClick={() => dispatch(deleteExerciseSet(set))}
          />
        </div>
      ))}
    </div>
  )
}

export default HTKDoubleColsInput