import { useMemo, useState } from "react";
import { useHTKDispatch } from "../../../app/hooks";
import { debounce } from "lodash";
import { DASHBOARD_TYPES, MODAL_TYPES } from "../../../assets/data/enums";
import { HTKSearchbar, HTKSearchTabs, HTKSearchTitleBlock } from "../../atoms/atoms";
import { InfiniteScrollLists } from "../organisms";
import { openModal } from "../../../features/Cores/modalSlice";
import { AnimatePresence } from "framer-motion";
import { workoutSearchTabs } from "../../../assets/data/arrays";
import { clearNewWorkout } from "../../../features/Training/Workouts/workoutSlice";
import { clearNewCourseData } from "../../../features/Training/Courses/courseSlice";

const CoursesList = () => {
	const [searchText, setSearchText] = useState("");
	const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText]);
	const [searchTab, setSearchTab] = useState("ALL WORKOUTS");
	const dispatch = useHTKDispatch();

	return (
		<div className="flex flex-col">
			<p className="min-h-[2.5em] pb-1 flex items-end font-Title text-2xl font-bold">COURSES</p>
			<div className="w-[20em] bg-HTKWhite htk-container">
				<HTKSearchTitleBlock
					title="ALL COURSES"
					btnTitle="CREATE"
					onClick={() => {
						dispatch(clearNewCourseData());
						dispatch(
							openModal({
								modalType: MODAL_TYPES.CREATE_COURSE,
							})
						);
					}}
				/>
				<HTKSearchbar onChange={onChangeText} />

				<div className="w-full my-1">
					<AnimatePresence>
						<InfiniteScrollLists key="infinite_scroll" page={DASHBOARD_TYPES.COURSES} searchText={searchText} searchTab={searchTab} pb={"pb-[12em]"} />
					</AnimatePresence>
				</div>
			</div>
		</div>
	);
};

export default CoursesList;
