import { RFC } from '../../../types/propTypes'
import { DuplicateIcon } from '../../../assets/icons/icons'
import { HTKButton } from '../atoms'

type HTKDetailTitleblockProps = {
    title:        string
    subTitle?:    string
    mainBtnTitle: string
    onMainClick:  () => void
    subBtnTitle?: string
    onSubClick?:  () => void
    onDuplicate?: () => void
    leftIcon?:    JSX.Element
    onExtraBtnClick ?: () => void
    extraBtnTitle ?: string
}

const HTKDetailTitleblock:RFC<HTKDetailTitleblockProps> = ({
    title,
    subTitle,
    mainBtnTitle,
    onMainClick,
    subBtnTitle,
    onSubClick,
    onDuplicate,
    leftIcon,
    extraBtnTitle,
    onExtraBtnClick
}) => {
  return (
    <div
        className='w-full flex items-center justify-between px-3 py-2
        border-b-[1px] border-HTKBorder border-solid'
    >
        <div className='flex font-Title font-semibold text-HTKBlack/90 text-lg'>
            {leftIcon ? (leftIcon) : null}
            {title}
            {subTitle ? (
                <span className='font-Title uppercase font-bold text-lg pl-4 text-HTKBlack/70'>
                    {subTitle}
                </span>
            ) : null}
        </div>
        <div className='flex items-center'>
            {(onDuplicate) && (
            <DuplicateIcon 
                onClick={onDuplicate}
                className='cursor-pointer'
            />
            )}
            {(extraBtnTitle && onExtraBtnClick) && (
            <HTKButton 
                text={extraBtnTitle}
                onSubmit={onExtraBtnClick}
                className='mr-3 sub-detail-button'
            />
            )}
            {(subBtnTitle && onSubClick) && (
            <HTKButton 
                text={subBtnTitle}
                onSubmit={onSubClick}
                className='mr-3 sub-detail-button'
            />
            )}
            <HTKButton 
                text={mainBtnTitle}
                onSubmit={onMainClick}
                className='ml-3 main-detail-button'
            />
        </div>
    </div>
  )
}

export default HTKDetailTitleblock