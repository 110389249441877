import { RFC } from '../../../types/propTypes'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { articleIdSelector, selectArticle, updateArticleImage } from './articleSlice'
import { articlesSelector } from './articlesSlice'
import { Article } from '../../../types/stateTypes'
import { CardError, CardLogo, HTKAsyncImage, TitleCard } from '../../../components/atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import moment from 'moment'

type ArticleTitlesProps = {
    isLoading: boolean
    isError:   boolean
}

export const ArticleTitles:RFC<ArticleTitlesProps> = ({
    isLoading,
    isError,
}) => {
  const articleId = useHTKSelector(articleIdSelector)
  const articlesState = useHTKSelector(articlesSelector)
  const dispatch = useHTKDispatch()

  const handleClickCard = (item: Article) => {
    dispatch(updateArticleImage(''))
    dispatch(selectArticle({ id: item.id, article: item }))
  }
  
  if (isLoading) return <CardLogo />
  if (isError)   return <CardError />
  if (!articlesState.length) return (
    <div className='w-full h-[5em] text-HTKBlack font-Title text-lg flex justify-center items-center px-7'>
      No Article
    </div>
  )

  return (
    <LazyMotion features={domAnimation} key='article-card'>
      {articlesState.map((article, index) => (
        <TitleCard
          key={index}
          isSelected={articleId === article.id}
          onClick={() => handleClickCard(article)}
        >
          <div className='flex items-center justify-between'>
            <div className='min-w-[50px] h-[50px]'>
              <HTKAsyncImage fileName={article.image} />
            </div>
            <div className='w-full pl-3 truncate'>
              <p className='font-Title uppercase font-bold text-lg truncate'>
                {article.title}
              </p>
              <div className='flex items-center text-sm'>
                <p className='font-medium'>
                  Created
                </p>
                <p className='pl-2 text-inherit/80'>
                  {moment(article.createdAt).format('DD MMM YYYY')}
                </p>
              </div>
            </div>
          </div>
        </TitleCard>
      ))}
    </LazyMotion>
  )
}