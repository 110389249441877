import { RFC } from '../../../types/propTypes'
import { SearchIcon } from '../../../assets/icons/icons'

type HTKSearchbarProps = {
    onChange: (text: string) => void
}

const HTKSearchbar:RFC<HTKSearchbarProps> = ({ onChange }) => {
  return (
    <form className='w-full flex items-center'>
      <div 
        className='relative w-full flex justify-between items-center  
        bg-HTKMiddleGrey m-2 py-1 rounded-[3px] hover:shadow-focused 
        selection:shadow-focused transition-all duration-200'
      >
        <SearchIcon 
            className='flex items-center pl-2 pointer-events-none text-WHMDarkGrey'
        />
        <input 
          type='text'
          className={`w-full mx-1 pl-1 py-1 bg-HTKMiddleGrey outline-none`}
          placeholder='Search'
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </form>
  )
}

export default HTKSearchbar