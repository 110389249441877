import { useNavigate } from 'react-router-dom'
import { useHTKDispatch } from '../../app/hooks'
import { useAdminLoginMutation } from '../../services/AuthService'
import { useFormik } from 'formik'
import { InitialLoginState } from '../../assets/data/initialState'
import { LoginSchema } from '../../utils/validationSchema'
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../assets/data/enums'
import { closeSnackbar, openSnackbar } from '../../features/Cores/snackbarSlice'
import asyncTimeout from '../../utils/asyncTimeout'
import { HTKBlackLogo } from '../../assets/images/images'
import { LoginField } from '../../components/molecules/molecules'
import { storeAdmin } from '../../features/Cores/adminSlice'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useHTKDispatch()
  const [ adminLogin ] = useAdminLoginMutation()
  const formik = useFormik({
    initialValues: InitialLoginState,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        const [ data ] = await Promise.all([
          adminLogin(values).unwrap(),
          asyncTimeout(1500)
        ])
        if (!data.jwtToken) throw Error
        dispatch(storeAdmin({ admin: data.adminDetails }))
        localStorage.setItem('HTKAdminToken', data.jwtToken)
        navigate('/announcements')
      } catch (error) {
        dispatch(openSnackbar({
          snackbarType: SNACKBAR_TYPES.ERROR,
          snackbarMessage: ERROR_MESSAGES.LOGIN,
          snackbarTimeout: 10000,
          snackbarBtnText: SNACKBAR_BUTTON_TYPES.RETRY,
          snackbarBtnFunc: () => {
              formik.setFieldValue('email', '')
              formik.setFieldValue('password', '')
              dispatch(closeSnackbar())
          }
        }))
      }
    }
  })

  return (
    <div 
      className='min-h-screen w-full flex justify-center items-center
      bg-HTKLightGrey'
    >
      <div
        className='w-[400px] mx-auto flex justify-center items-center flex-col'
      >
        <img 
          src={HTKBlackLogo} 
          alt='HTK-Logo'
          className='w-[100px] h-[100px]'
        />
        <div 
          className='w-full htk-border rounded-container drop-shadow-md bg-HTKWhite'
        >
          <p className='font-Title font-semibold text-xl py-2 px-5
          border-b-[1px] border-solid border-HTKBorder'>
            SIGN IN
          </p>
          <LoginField formik={formik}/>
        </div>
      </div>
    </div>
  )
}

export default Login