import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { announcementIdSelector, announcementSelector, clearAnnouncement, removeAnnouncementProgram, selectAnnouncement, storeAnnouncement, updateAnnouncementBody, updateAnnouncementImage, updateAnnouncementLink, updateAnnouncementTitle } from "../../../features/Announcement/announcementSlice";
import { useActivateAnnouncementMutation, useDeactivateAnnouncementMutation, useGetAnnouncementQuery, useCreateAnnouncementMutation, useUpdateAnnouncementMutation, useDeleteAnnouncementMutation } from "../../../services/AnnouncementService";
import { closeOverlay, openOverlay } from "../../../features/Cores/overlaySlice";
import { addAnnouncement, updateAnnouncementInfo, deleteAnnouncementWithId, updateAnnouncementActiveStatus } from "../../../features/Announcement/announcementsSlice";
import { MODAL_TYPES } from "../../../assets/data/enums";
import asyncTimeout from "../../../utils/asyncTimeout";
import { closeModal, openModal } from "../../../features/Cores/modalSlice";
import { HTKDetailTitleBlockWithToggle, HTKTextArea, HTKTextInput, HTKTitleBlock, HTKUploadInput } from "../../atoms/atoms";
import { ImageSkeleton, InputSkeleton, TextAreaSkeleton } from "../../molecules/molecules";
import AnnouncementCourseField from "../../molecules/Fields/AnnouncementCourseField";

const AnnouncementDetail = () => {
	const announcementId = useHTKSelector(announcementIdSelector);
	const { id, title, link, image, body, courseId, isActiveOnApp, updatedAt, createdAt } = useHTKSelector(announcementSelector);
	const dispatch = useHTKDispatch();

	const { data: announcementData, isLoading: announcementLoading, isFetching: announcementFetching } = useGetAnnouncementQuery(announcementId ?? skipToken);
	const [createAnnouncement] = useCreateAnnouncementMutation();
	const [updateAnnouncement] = useUpdateAnnouncementMutation();
	const [deleteAnnouncement] = useDeleteAnnouncementMutation();
	const [activateAnnouncement] = useActivateAnnouncementMutation();
	const [deactivateAnnouncement] = useDeactivateAnnouncementMutation();

	useEffect(() => {
		announcementData && dispatch(storeAnnouncement({ id: announcementData.id, announcement: announcementData }));
	}, [announcementData]);

	const duplicateAnnouncement = async () => {
		if (!title || !link || !image || !body) throw Error;
		try {
			dispatch(openOverlay({ text: "Duplicating Announcement" }));
			const [data] = await Promise.all([createAnnouncement({ title, link, image, body, courseId }).unwrap(), asyncTimeout(2000)]);
			if (!data) throw Error;
			dispatch(addAnnouncement(data));
			dispatch(selectAnnouncement({ id: data.id, announcement: data }));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "ANNOUNCEMENT DUPLICATED",
					body: "You have successfully duplicated an announcement!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to duplicated announcement",
					body: "Please check your input details and try again.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	const saveAnnouncement = async () => {
		try {
			dispatch(openOverlay({ text: "Updating Announcement" }));
			const [data] = await Promise.all([updateAnnouncement({ id, title, link, image, body, courseId }).unwrap(), asyncTimeout(2000)]);
			if (!data) throw Error;
			dispatch(updateAnnouncementInfo(data));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "ANNOUNCE UPDATED",
					body: "You have successfully updated the announcement!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to update announcement",
					body: "Please check your input details and try again.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	const removeAnnouncement = async (announcementId: string) => {
		try {
			dispatch(closeModal());
			dispatch(openOverlay({ text: "Deleting this Announcement" }));
			const [data] = await Promise.all([deleteAnnouncement(announcementId).unwrap(), asyncTimeout(1000)]);
			if (!data) throw Error;
			dispatch(deleteAnnouncementWithId(announcementId));
			dispatch(clearAnnouncement());
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: "ANNOUNCEMENT DELETED",
					body: "You have successfully deleted the announcement!",
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: "Failed to delete announcement",
					body: "Something went wrong. Please try again later.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	const changeAnnouncementStatus = async () => {
		if (isActiveOnApp === undefined || !announcementId) return;
		try {
			dispatch(openOverlay({ text: `${isActiveOnApp ? "Deactivating" : "Activating"} Announcement` }));
			const [data] = await Promise.all([isActiveOnApp ? deactivateAnnouncement(announcementId).unwrap() : activateAnnouncement(announcementId).unwrap(), asyncTimeout(2000)]);
			if (!data) throw Error;
			dispatch(updateAnnouncementActiveStatus(data));
			dispatch(storeAnnouncement({ id: data.id, announcement: data }));
			dispatch(
				openModal({
					modalType: MODAL_TYPES.SUCCESS,
					title: `ANNOUNCEMENT ${isActiveOnApp ? "DEACTIVATED" : "ACTIVATED"}`,
					body: `You have successfully ${isActiveOnApp ? "deactivated" : "activated"} the announcement!`,
				})
			);
		} catch (error) {
			dispatch(
				openModal({
					modalType: MODAL_TYPES.FAIL,
					title: `Failed to ${isActiveOnApp ? "deactivate" : "activate"} announcement`,
					body: "Something went wrong. Please try again later.",
				})
			);
		} finally {
			dispatch(closeOverlay());
		}
	};

	return (
		<div className="flex flex-col mx-3">
			<p className="min-h-[2.5em] text-2xl font-bold"></p>
			<div className="w-[50em] bg-HTKWhite htk-container">
				<HTKDetailTitleBlockWithToggle
					title="VIEW ANNOUNCEMENT"
					updatedAt={updatedAt ?? createdAt}
					status={isActiveOnApp}
					onMainClick={changeAnnouncementStatus}
					onSave={saveAnnouncement}
					onDelete={() =>
						dispatch(
							openModal({
								modalType: MODAL_TYPES.DELETE,
								deleteId: id,
								deleteFn: removeAnnouncement,
							})
						)
					}
					onDuplicate={duplicateAnnouncement}
				/>

				<div className="py-3 px-2 h-screen overflow-y-auto overflow-x-hidden pb-[15em]">
					<HTKTitleBlock title="ANNOUNCEMENT TITLE" description="Give your announcement a unique title. This will be the main title shown to users on the app." isRequired={!title} />
					{announcementLoading || announcementFetching ? <InputSkeleton /> : <HTKTextInput name="title" value={title} placeholder="Title" onChange={(text) => dispatch(updateAnnouncementTitle(text))} />}
					<HTKTitleBlock title="ANNOUNCEMENT LINK" description="Add a link to this announcement that users can click on and follow through." className="mt-6" isRequired={!link} />
					{announcementLoading || announcementFetching ? <InputSkeleton /> : <HTKTextInput name="link" value={link} placeholder="Link" onChange={(text) => dispatch(updateAnnouncementLink(text))} />}
					<HTKTitleBlock title="ANNOUNCEMENT PROGRAM" description="Add a program to this announcement." className="mt-6" isRequired={!title} />
					<AnnouncementCourseField courseTitle={courseId ?? ""} title="UPDATE_ANNOUNCEMENT" onDelete={() => dispatch(removeAnnouncementProgram())} />
					<HTKTitleBlock title="ANNOUNCEMENT IMAGE" description="Add an image to this announcement." className="mt-6" isRequired={!image} />
					{announcementLoading || announcementFetching ? <ImageSkeleton /> : <HTKUploadInput type="image" source={image ?? ""} onUpload={(image) => dispatch(updateAnnouncementImage(image ?? ""))} />}
					<HTKTitleBlock title="ANNOUNCEMENT CONTENT" description="This is where the main content of the announcement is placed in." className="mt-6" isRequired={!body} />
					{announcementLoading || announcementFetching ? <TextAreaSkeleton /> : <HTKTextArea text={body ?? ""} onChange={(text) => dispatch(updateAnnouncementBody(text))} />}
				</div>
			</div>
		</div>
	);
};

export default AnnouncementDetail;
