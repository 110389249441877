const duplicateIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'} 
            height={height ?? '24'} 
            viewBox="0 0 24 24"
            onClick={onClick}
        >
        <path id="Path_1116" data-name="Path 1116" d="M0,0H24V24H0Z" fill="none"/>
        <path id="Path_1118" data-name="Path 1118" d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Zm0,16H8V7H19Z"/>
        </svg>
    </div>
  )
}

export default duplicateIcon