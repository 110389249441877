import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { CloseIcon } from '../../../assets/icons/icons'
import { closeModal, openModal } from '../../../features/Cores/modalSlice'
import { closeOverlay, openOverlay } from '../../../features/Cores/overlaySlice'
import {
  addBrandNewRecipeIngredient, addBrandNewRecipeStep, addNewRecipeCarbs,
  addNewRecipeDescription, addNewRecipeFats, addNewRecipeImage,
  addNewRecipeIngredientAmount, addNewRecipeIngredientName, addNewRecipeIntakeLevel,
  addNewRecipeProtein, addNewRecipeStepDescription, addNewRecipeStepTitle,
  addNewRecipeTitle, deleteNewRecipeIngredient, deleteNewRecipeStep,
  newRecipeSelector, removeNewRecipeCategory, selectRecipe
} from '../../../features/Library/Recipes/recipeSlice'
import { addRecipe } from '../../../features/Library/Recipes/recipesSlice'
import { useCreateRecipeMutation } from '../../../services/RecipeService'
import { Dialog } from '@headlessui/react'
import asyncTimeout from '../../../utils/asyncTimeout'
import { HTKButton, HTKPrefixTextInput, HTKTextArea, HTKTextInput, HTKTitleBlock, HTKUploadInput } from '../../atoms/atoms'
import { RecipeCategoryField, RecipeIngredientsField, RecipeStepsField } from '../molecules'
import { convertCalToKJ, convertOzToG, convertUnitNameForCalAndKJ, convertUnitNameForOzAndG } from '../../../utils/measurementConverter'
import { measurementSelector } from '../../../features/Cores/adminSlice'
import { createRecipeSchema } from '../../../utils/validationSchema'
import { ValidationError } from 'yup'
import { useEffect, useState } from 'react'
import { NewRecipeData } from '../../../types/serviceTypes'

const CreateRecipeModal = () => {
  const measurement = useHTKSelector(measurementSelector)
  const newRecipe = useHTKSelector(newRecipeSelector) as NewRecipeData
  const dispatch = useHTKDispatch()
  const [recipesErrors, setRecipesErrors] = useState<ValidationError[]>([])
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState<boolean>(false)
  const [createRecipe] = useCreateRecipeMutation()

  async function validateRecipe(): Promise<boolean> {
    try {
      const errors = await createRecipeSchema.validate(
        newRecipe, { abortEarly: false })
      return true
    } catch (error) {
      if (error instanceof ValidationError) {
        setRecipesErrors(error.inner)
      }
    }
    return false
  }

  useEffect(() => {
    if (shouldValidateOnChange) validateRecipe()
  }, [newRecipe])

  function getErrorMessageByField(fieldName: string) {
    const error = recipesErrors.find((error) => error.path === fieldName)
    if(!error) return ''
    return error ? error.errors[0] : ''
  }

  const createNewRecipe = async () => {
    setShouldValidateOnChange(true)
    const isValid = await validateRecipe()
    if (!isValid) return
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Creating New Recipe' }))
      const [data] = await Promise.all([
        createRecipe(newRecipe).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(addRecipe(data))
      dispatch(selectRecipe({ id: data.id, recipe: data }))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'RECIPE CREATED',
        body: 'You have successfully created a new recipe!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to create recipe',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <>
      <div
        className='min-w-[500px] max-w-[600px] py-3 px-6'
      >
        <Dialog.Title className='flex items-center justify-between'>
          <div>
            <p className='font-Title font-extrabold text-xl'>
              NEW RECIPE
            </p>
            <p className='text-HTKBlack/80'>
              Let’s create a new recipe.
            </p>
          </div>
          <CloseIcon
            width={'30'}
            height={'30'}
            className='cursor-pointer'
            onClick={() => dispatch(closeModal())}
          />
        </Dialog.Title>
        <hr className='htk-border my-2' />
        <div className='max-h-[480px] overflow-y-auto overflow-x-hidden pb-[2em]'>
          <HTKTitleBlock
            title='RECIPE NAME'
            description='Give this recipe a unique name for users to find it in our app.'
            isRequired={!newRecipe.title}
            error={getErrorMessageByField('title')}

          />
          <HTKTextInput
            name='title'
            value={newRecipe.title}
            placeholder='Title'
            onChange={(text) => dispatch(addNewRecipeTitle(text))}
          />
          <HTKTitleBlock
            title='RECIPE IMAGE'
            description='Upload a delicious looking image of this meal!'
            className='mt-6'
            isRequired={!newRecipe.image}
            error={getErrorMessageByField('image')}

          />
          <HTKUploadInput
            type='image'
            source={newRecipe.image ?? ''}
            onUpload={(image) => dispatch(addNewRecipeImage(image ?? ''))}
          />
          <HTKTitleBlock
            title='RECIPE CATEGORY'
            description='Add a category to this Recipe. This allows users to find a recipe by its allocated category.'
            className='mt-6'
            isRequired={!newRecipe.recipeCategory?.length}
            error={getErrorMessageByField('recipeCategory')}

          />
          <RecipeCategoryField
            categories={newRecipe.recipeCategory}
            title='CREATE_RECIPE'
            onDelete={(categoryId) => dispatch(removeNewRecipeCategory(categoryId))}
          />
          <HTKTitleBlock
            title='INTAKE LEVEL'
            description='What is the intake level for this meal? This is the overall total calories per serving for this meal.'
            className='mt-6'
            isRequired={!newRecipe.intakeLevel}
            error={getErrorMessageByField('intakeLevel')}
          />
          <HTKPrefixTextInput
            title='Intake'
            unit={`${convertUnitNameForCalAndKJ(measurement)}`}
            value={convertCalToKJ(measurement, newRecipe.intakeLevel)}
            placeholder='Intake Level'
            className='mr-4 w-[90px]'
            onChange={(intake) => dispatch(addNewRecipeIntakeLevel({ measurement, value: intake }))}
            error={getErrorMessageByField('intakeLevel')}

          />
          <HTKTitleBlock
            title='MICRO NUTRIENTS'
            description='What are the micro nutrients for this meal? This allows users to pick a meal that aligns with their goals.'
            className='mt-6'
            isRequired={!newRecipe.carbs || !newRecipe.fats || !newRecipe.protein}
          />
          <HTKPrefixTextInput
            title='Carbs'
            unit={`${convertUnitNameForOzAndG(measurement)}`}
            value={convertOzToG(measurement, newRecipe.carbs)}
            placeholder='Carbs'
            className='mr-4 w-[90px]'
            onChange={(carbs) => dispatch(addNewRecipeCarbs({ measurement, value: carbs }))}
            error={getErrorMessageByField('carbs')}
          />
          <HTKPrefixTextInput
            title='Fats'
            unit={`${convertUnitNameForOzAndG(measurement)}`}
            value={convertOzToG(measurement, newRecipe.fats)}
            placeholder='Fats'
            className='mr-4 w-[90px]'
            onChange={(fats) => dispatch(addNewRecipeFats({ measurement, value: fats }))}
            error={getErrorMessageByField('fats')}

          />
          <HTKPrefixTextInput
            title='Protein'
            unit={`${convertUnitNameForOzAndG(measurement)}`}
            value={convertOzToG(measurement, newRecipe.protein)}
            placeholder='Protein'
            className='mr-4 w-[90px]'
            onChange={(protein) => dispatch(addNewRecipeProtein({ measurement, value: protein }))}
            error={getErrorMessageByField('protein')}

          />
          <HTKTitleBlock
            title='DESCRIPTION'
            description='Create an enticing description for this recipe. Outline what it is and what a user will get out from making it.'
            className='mt-6'
            isRequired={!newRecipe.description}
            error={getErrorMessageByField('description')}

          />
          <HTKTextArea
            text={newRecipe.description ?? ''}
            onChange={(text) => dispatch(addNewRecipeDescription(text))}
          />
          <HTKTitleBlock
            title='INGREDIENTS'
            description='What does a user need to make this meal? Add the ingredients below so users know what to use for this meal. The amount should be based on a SINGLE SERVE of this meal.'
            className='mt-10'
            isRequired={!newRecipe.recipeIngredient?.length}
            error={getErrorMessageByField('recipeIngredient')}

          />
          <RecipeIngredientsField
            ingredients={newRecipe.recipeIngredient}
            onChangeName={(ingredientId, name) => dispatch(addNewRecipeIngredientName({ ingredientId, name }))}
            onChangeAmount={(ingredientId, amount) => dispatch(addNewRecipeIngredientAmount({ ingredientId, amount }))}
            onDelete={(ingredientId) => dispatch(deleteNewRecipeIngredient(ingredientId))}
            onAdd={() => dispatch(addBrandNewRecipeIngredient())}
            ingredientsErrors={recipesErrors.filter((error => error.path?.includes('recipeIngredient[')))
            }


          />
          <HTKTitleBlock
            title='STEPS'
            description='Add in the steps a user needs to complete in order to make their meal.'
            className='mt-6'
            isRequired={!newRecipe.recipeStep?.length}
            error={getErrorMessageByField('recipeStep')}
          />
          <RecipeStepsField
            steps={newRecipe.recipeStep}
            onChangeTitle={(stepId, title) => dispatch(addNewRecipeStepTitle({ stepId, title }))}
            onChangeDescription={(stepId, description) => dispatch(addNewRecipeStepDescription({ stepId, description }))}
            onDelete={(stepId) => dispatch(deleteNewRecipeStep(stepId))}
            onAdd={() => dispatch(addBrandNewRecipeStep())}
            stepsErrors={recipesErrors.filter((error => error.path?.includes('recipeStep[')))}
          />
        </div>
      </div>
      <div className='sticky bottom-0 w-full h-12 flex items-center px-6'>
        <HTKButton
          text='Create Recipe'

          isLoading={false}
          className='create-item-button'
          onSubmit={createNewRecipe}
        />
        <HTKButton
          text='Cancel'
          className='cancel-item-button'
          onSubmit={() => dispatch(closeModal())}
        />
      </div>
    </>
  )
}

export default CreateRecipeModal