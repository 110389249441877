import { RFC } from "../../../types/propTypes";
import { Week } from "../../../types/stateTypes";
import { useHTKDispatch, useHTKSelector } from "../../../app/hooks";
import { useAddWeekMutation } from "../../../services/ProgramService";
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from "../../../assets/data/enums";
import { ArrowRightIcon } from "../../../assets/icons/icons";
import { closeSnackbar, openSnackbar } from "../../../features/Cores/snackbarSlice";
import { HTKButton, HTKSectionTitleBlock, TitleCard } from "../../atoms/atoms";
import { CardListSkeleton } from "../molecules";
import { setWeek, weekSelector } from "../../../features/Training/Programs/weekSlice";

type ProgramWeeksListProps = {
	isLoading: boolean;
	programId: string;
	programWeeks: Week[] | undefined;
};

const ProgramWeeksList: RFC<ProgramWeeksListProps> = ({ isLoading, programId, programWeeks }) => {
	const weekState = useHTKSelector(weekSelector);
	const dispatch = useHTKDispatch();

	const [addNewWeek] = useAddWeekMutation();

	const handleClickCard = (week: Week) => {
		dispatch(setWeek(week));
	};

	const addProgramWeek = async () => {
		// const newWeekIndex = (programWeeks?.length ?? 0) + 1;
		const newWeekIndex = programWeeks?.length ?? 0;
		try {
			await addNewWeek({
				programId,
				weekIndex: newWeekIndex,
				weekName: newWeekIndex === 0 ? `Prep Week` : `Week ${newWeekIndex}`,
			});
		} catch (error) {
			dispatch(
				openSnackbar({
					snackbarType: SNACKBAR_TYPES.ERROR,
					snackbarMessage: ERROR_MESSAGES.ADD_WEEK,
					snackbarTimeout: 10000,
					snackbarBtnText: SNACKBAR_BUTTON_TYPES.CLOSE,
					snackbarBtnFunc: () => dispatch(closeSnackbar()),
				})
			);
		}
	};

	const renderProgramWeeks = () => {
		if (isLoading) return <CardListSkeleton />;
		return (
			<div className="flex flex-col h-screen w-full overflow-y-auto overflow-x-hidden pb-[15em]">
				{!programWeeks?.length ? (
					<div className="w-full text-center mt-4 font-Title text-lg">No Program Week</div>
				) : (
					programWeeks.map((week, index) => (
						<TitleCard key={index} isSelected={weekState.id === week.id} onClick={() => handleClickCard(week)}>
							<div className="flex justify-between">
								<span className="font-Title font-bold text-sm">{index === 0 ? `Prep Week` : `WEEK ${index}`}</span>
								<ArrowRightIcon />
							</div>
						</TitleCard>
					))
				)}
				<HTKButton text="Add Week" className="main-detail-button mx-5 my-6" onSubmit={addProgramWeek} />
			</div>
		);
	};

	return (
		<div className="border-r-[1px] border-solid border-HTKBorder">
			<HTKSectionTitleBlock title="PROGRAM WEEKS" />
			{renderProgramWeeks()}
		</div>
	);
};

export default ProgramWeeksList;
