const libraryIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "24"} 
            height={height ?? "24"} 
            viewBox="0 0 24 24"
        >
        <g id="category_black_24dp">
            <path id="Path_908" data-name="Path 908" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_909" data-name="Path 909" d="M12,2,6.5,11h11Zm0,3.84L13.93,9H10.06ZM17.5,13A4.5,4.5,0,1,0,22,17.5,4.494,4.494,0,0,0,17.5,13Zm0,7A2.5,2.5,0,1,1,20,17.5,2.5,2.5,0,0,1,17.5,20ZM3,21.5h8v-8H3Zm2-6H9v4H5Z" transform="translate(0 0)"/>
        </g>
        </svg>
      </div>
    )
  }
  
  export default libraryIcon