import { useState } from "react";
import { RFC } from "../../../types/propTypes";
import { AvatarPlaceholder, ErrorIcon } from "../../../assets/icons/icons";
import { useGetPresignedDownloadURLQuery } from "../../../services/FileService";
import { HTKLoading } from "../atoms";

type HTKAsyncAvatarProps = {
	fileName: string;
	width: string;
	height: string;
};

const HTKAsyncAvatar: RFC<HTKAsyncAvatarProps> = ({ fileName, width, height }) => {
	const [isAvatarError, setIsAvatarError] = useState(false);
	const { data, isLoading, isError } = useGetPresignedDownloadURLQuery({ imageKey: fileName }, { skip: !fileName });

	if (isLoading)
		return (
			<div className="h-full w-full flex justify-center items-center rounded-lg">
				<HTKLoading shape="circle" />
			</div>
		);

	if (!fileName || isAvatarError || isError)
		return (
			<AvatarPlaceholder
				width={48}
				height={48}
				className={`w-10 h-10
      overflow-hidden relative bg-gray-100 
      rounded-full dark:bg-gray-300 shadow-lg`}
			/>
		);

	return <img className={`${width} ${height} rounded-full`} src={data} alt="avatar" onError={() => setIsAvatarError(true)} loading="lazy" />;
};

export default HTKAsyncAvatar;
