import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { SuccessIcon } from '../../../assets/icons/icons'
import { closeModal, modalSelector } from '../../../features/Cores/modalSlice'
import { HTKButton } from '../../atoms/atoms'

const SuccessModal = () => {
    const { title, body } = useHTKSelector(modalSelector)
    const dispatch = useHTKDispatch()

    return (
        <div className='w-[25em] flex flex-col justify-center items-center py-3 px-6'>
            <SuccessIcon width={'65'} height={'65'}/>
            <p className='text-3xl font-Title font-bold text-HTKBlack my-3 text-center'>
                {title}
            </p>
            <p className='w-full text-center text-lg mb-7'>
                {body}
            </p>
            <HTKButton 
                text='CLOSE'
                className='modal-close-button'
                onSubmit={() => dispatch(closeModal())}
            />
        </div>
    )
}

export default SuccessModal