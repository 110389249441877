import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialRecipesState } from '../../../assets/data/initialState'
import { Recipe } from '../../../types/stateTypes'

export type RecipesSliceType = {
    recipes: Recipe[]
}

export const recipesSlice = createSlice({
    name: 'recipes',
    initialState: InitialRecipesState,
    reducers: {
        clearRecipes: (state) => {
            state.recipes = []
        },
        setInitialRecipes: (state, action:PayloadAction<RecipesSliceType>) => {
            state.recipes = action.payload.recipes
        },
        setRecipes: (state, action:PayloadAction<RecipesSliceType>) => {
            const { recipes: storedRecipes } = state
            const { recipes: newRecipes } = action.payload
            const recipeIds = storedRecipes.map(recipe => recipe.id)
            const filteredDuplicates = newRecipes.filter(recipe => !recipeIds.includes(recipe.id))
            filteredDuplicates.map(recipe => state.recipes.push(recipe))
        },
        addRecipe: (state, action:PayloadAction<Recipe>) => {
            state.recipes.unshift(action.payload)
        },
        updateRecipeInfo: (state, action:PayloadAction<Recipe>) => {
            const filteredRecipes = state.recipes.filter(recipe => recipe.id !== action.payload.id)
            state.recipes = [ action.payload, ...filteredRecipes ]
        },
        deleteRecipeWithId: (state, action:PayloadAction<string>) => {
            const filteredRecipes = state.recipes.filter(recipe => recipe.id !== action.payload)
            state.recipes = filteredRecipes
        }
    }
})

export const recipesSelector = (state: RootState) => state.recipes.recipes
export const {
    clearRecipes,
    setInitialRecipes,
    setRecipes,
    addRecipe,
    updateRecipeInfo,
    deleteRecipeWithId,
} = recipesSlice.actions
export default recipesSlice.reducer