import { RFC } from '../../../types/propTypes'
import { EyeCloseIcon, EyeOpenIcon } from '../../../assets/icons/icons'

type HTKLoginTextInputProps = {
    label: string
    name: string
    type: string
    value: string
    required?: boolean,
    isVisible?:   boolean
    touched?:     boolean | undefined
    error?:       string | undefined | null
    onIconPress?: (visible: boolean) => void
    onChange: (text: string) => void
}

const HTKLoginTextInput:RFC<HTKLoginTextInputProps> = ({
    label,
    name,
    type,
    value,
    required,
    isVisible,
    touched,
    error,
    onIconPress,
    onChange,
}) => {

    const renderPasswordIcons = () => {
        if (isVisible) return <EyeCloseIcon />
        else           return <EyeOpenIcon />
    }
    
    return (
        <>
            <div 
                className='relative w-full flex bg-HTKMiddleGrey rounded-container
                px-2 py-[6px] my-2'
            >
                <p className='text-[1.1em]'>
                    {label}
                </p>
                <input 
                    name={name}
                    type={type}
                    value={value}
                    required={required}
                    onChange={(e) => onChange(e.target.value)}
                    className={`w-full pl-2 outline-none text-[1.1em] bg-HTKMiddleGrey`}
                />
                {onIconPress && (
                    <div
                        className='absolute right-2 top-[0.5em] w-6 h-6 cursor-pointer'
                        onClick={() => onIconPress(!isVisible)}
                    >
                        { renderPasswordIcons() }
                    </div>
                )}
            </div>
        </>
    )
}

export default HTKLoginTextInput