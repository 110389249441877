const installIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '16'}
            height={height ?? '16'}
            viewBox="0 0 16 16"
            onClick={onClick}
        >
            <g id="logout_black_24dp" transform="translate(0 16) rotate(-90)">
                <g id="Group_309" data-name="Group 309">
                <path id="Path_925" data-name="Path 925" d="M0,0H16V16H0Z" fill="none"/>
                </g>
                <g id="Group_310" data-name="Group 310" transform="translate(3 3)">
                <path id="Path_926" data-name="Path 926" d="M10.778,5.778l-.783.783.878.883H6.333V8.556h4.539l-.878.878.783.789L13,8ZM4.111,4.111H8V3H4.111A1.114,1.114,0,0,0,3,4.111v7.778A1.114,1.114,0,0,0,4.111,13H8V11.889H4.111Z" transform="translate(-3 -3)"/>
                </g>
            </g>
        </svg>
      </div>
    )
  }
  
  export default installIcon