import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { InitialProgramState } from "../../../assets/data/initialState";
import { NewProgramData, ProgramData } from "../../../types/serviceTypes";

export type ProgramSliceType = {
	id: string;
	program: ProgramData;
};

export type NewProgramSliceType = {
	newProgram: NewProgramData;
};

export type RecommendationType = {
	option: string;
	type: RecommendationOptionType;
};

export type RecommendationOptionType = "Goal" | "Experience" | "Gym" | "Pullup";

export const programSlice = createSlice({
	name: "program",
	initialState: InitialProgramState,
	reducers: {
		// Select Existing Program
		selectProgram: (state, action: PayloadAction<ProgramSliceType>) => {
			state.id = action.payload.id;
		},
		storeProgram: (state, action: PayloadAction<ProgramSliceType>) => {
			state.program = action.payload.program;
		},
		updateProgramInternalTitle: (state, action: PayloadAction<string>) => {
			state.program.internalTitle = action.payload;
		},
		updateProgramTitle: (state, action: PayloadAction<string>) => {
			state.program.title = action.payload;
		},
		updateProgramUserFacingTitle: (state, action: PayloadAction<string>) => {
			state.program.userFacingTitle = action.payload;
		},
		updateProgramProgramPlannedFor: (state, action: PayloadAction<string>) => {
			state.program.programPlannedFor = [
				{
					id: action.payload === "Gym" ? "a0bbdb70-3bdb-4207-a03a-41c45e7afbc4" : "fe918260-2e31-4c40-bb7c-a44f3144536d",
					title: action.payload,
				},
			];
		},
		updateProgramDescription: (state, action: PayloadAction<string>) => {
			state.program.description = action.payload;
		},
		updateProgramImage: (state, action: PayloadAction<string>) => {
			state.program.image = action.payload;
		},
		updateProgramSkillRequired: (state, action: PayloadAction<string>) => {
			state.program.ProgramSkillRequired = [
				{
					id: action.payload === "Beginner" ? "ff89a478-4b5e-4e1b-ad1a-66a4ef6836bf" : action.payload === "Intermediate" ? "cf948b12-556f-4a26-adeb-4bb1a6f8624b" : "596edaac-df27-4895-ae03-497251bd77dc",
					title: action.payload,
				},
			];
		},
		clearProgram: (state) => {
			state.id = "";
			state.program = { id: "" };
		},
		updateProgramActiveStatus: (state, action: PayloadAction<boolean>) => {
			state.program.isActiveOnApp = action.payload;
		},
		addProgramRecommendation: (state, action: PayloadAction<RecommendationType>) => {
			const { option, type } = action.payload;
			const goalOptions = ["BuildMuscleMassAndGainStrength", "ShredFatAndBuildLeanMuscle", "ImprovePerformanceOrPrepareForEliteSelectionCourse"];
			const experienceOptions = ["HaveHadPastExperience", "NewToWeightLifting"];
			const gymOptions = ["AccessToStandardGym", "AccessToHomeGym", "DontHaveAccess"];
			const pullupOptions = ["HavePullUpBar", "DontHavePullUpBar"];

			const storeOption = (selectedOption: string | undefined, option: string) => {
				if (!selectedOption) {
					if (state.program.programRecommendation === undefined || !state.program.programRecommendation.length) {
						state.program.programRecommendation = [{ id: "", title: option }];
					} else {
						state.program.programRecommendation?.push({ id: "", title: option });
					}
				} else {
					state.program.programRecommendation?.forEach((recommendation, index) => {
						if (recommendation.title === selectedOption && state.program.programRecommendation) {
							state.program.programRecommendation[index] = { id: "", title: option };
						}
					});
				}
			};

			switch (type) {
				case "Goal": {
					const selectedOption = state.program.programRecommendation?.find((recommendation) => goalOptions.includes(recommendation.title));
					storeOption(selectedOption?.title, option);
					break;
				}
				case "Experience": {
					const selectedOption = state.program.programRecommendation?.find((recommendation) => experienceOptions.includes(recommendation.title));
					storeOption(selectedOption?.title, option);
					break;
				}
				case "Gym": {
					const selectedOption = state.program.programRecommendation?.find((recommendation) => gymOptions.includes(recommendation.title));
					storeOption(selectedOption?.title, option);
					break;
				}
				case "Pullup": {
					const selectedOption = state.program.programRecommendation?.find((recommendation) => pullupOptions.includes(recommendation.title));
					storeOption(selectedOption?.title, option);
					break;
				}
				default:
					break;
			}
		},
		clearRecommendationState: (state) => {
			state.program.programRecommendation = [];
		},

		// Add New Program
		clearNewProgram: (state) => {
			state.newProgram = {
				internalTitle: "",
				userFacingTitle: "",
				title: "",
				programPlannedFor: [],
				ProgramSkillRequired: [],
				description: "",
				image: "",
			};
		},
		addNewProgramInternalTitle: (state, action: PayloadAction<string>) => {
			state.newProgram.internalTitle = action.payload;
		},
		addNewProgramTitle: (state, action: PayloadAction<string>) => {
			state.newProgram.title = action.payload;
		},
		addNewProgramPlannedFor: (state, action: PayloadAction<string>) => {
			state.newProgram.programPlannedFor = [
				{
					id: action.payload === "Gym" ? "a0bbdb70-3bdb-4207-a03a-41c45e7afbc4" : "fe918260-2e31-4c40-bb7c-a44f3144536d",
					title: action.payload,
				},
			];
		},
		addNewProgramSkillRequired: (state, action: PayloadAction<string>) => {
			state.newProgram.ProgramSkillRequired = [
				{
					id: action.payload === "Beginner" ? "ff89a478-4b5e-4e1b-ad1a-66a4ef6836bf" : action.payload === "Intermediate" ? "cf948b12-556f-4a26-adeb-4bb1a6f8624b" : "596edaac-df27-4895-ae03-497251bd77dc",
					title: action.payload,
				},
			];
		},
		addNewProgramDescription: (state, action: PayloadAction<string>) => {
			state.newProgram.description = action.payload;
		},
		addNewProgramImage: (state, action: PayloadAction<string>) => {
			state.newProgram.image = action.payload;
		},
	},
});

export const programIdSelector = (state: RootState) => state.program.id;
export const programSelector = (state: RootState) => state.program.program;
export const newProgramSelector = (state: RootState) => state.program.newProgram;
export const recommendationSelector = (state: RootState) => state.program.program.programRecommendation;
export const {
	selectProgram,
	storeProgram,
	updateProgramInternalTitle,
	updateProgramTitle,
	updateProgramProgramPlannedFor,
	updateProgramSkillRequired,
	updateProgramDescription,
	updateProgramImage,
	clearProgram,
	updateProgramActiveStatus,
	addProgramRecommendation,
	clearRecommendationState,
	clearNewProgram,
	addNewProgramInternalTitle,
	addNewProgramTitle,
	addNewProgramPlannedFor,
	addNewProgramSkillRequired,
	addNewProgramDescription,
	addNewProgramImage,
	updateProgramUserFacingTitle,
} = programSlice.actions;
export default programSlice.reducer;
