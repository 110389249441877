// --- DASHBOARD ---
export enum DASHBOARD_TYPES {
	ANNOUNCEMENTS = "ANNOUNCEMENTS",
	PROGRAMS = "PROGRAMS",
	WORKOUTS = "WORKOUTS",
	EXERCISES = "EXERCISES",
	RECIPES = "RECIPES",
	ARTICLES = "ARTICLES",
	USERS = "USERS",
	COURSES = "COURSES",
}

export enum DASHBOARD_SUB_TYPES {
	COURSES = "COURSES",
	PROGRAMS = "PROGRAMS",
	WORKOUTS = "WORKOUTS",
	EXERCISES = "EXERCISES",
	RECIPES = "RECIPES",
	ARTICLES = "ARTICLES",
}

// --- SNACKBAR ---
export enum SNACKBAR_TYPES {
	ERROR = "error",
	WARNING = "warning",
	INFO = "info",
	SUCCESS = "success",
}

export enum SNACKBAR_BUTTON_TYPES {
	CLOSE = "CLOSE",
	RETRY = "RETRY",
	FIX = "FIX",
	DISMISS = "DISMISS",
	UNDO = "UNDO",
	RESEND = "RESEND",
}

export enum ERROR_MESSAGES {
	LOGIN = "Failed to Login. Please check your details and try again.",
	CHANGE_UNIT = "Failed to change the unit of measurement. Please try again later.",
	ADD_WEEK = "Failed to add a new week. Please try again later.",
	REMOVE_WORKOUT = "Failed to remove the workout. Please try again later.",
	USER_CSV = "Failed to install user data. Please try again later.",
}

// --- MODAL ---
export enum MODAL_TYPES {
	// ANNOUNCEMENT
	ANNOUNCEMENT = "ANNOUNCEMENT",
	ADD_PROGRAM = "ADD_PROGRAM",
	// COURSES
	CREATE_COURSE = "CREATE_COURSE",
	ADD_PROGRAM_TO_COURSE = "ADD_PROGRAM_TO_COURSE",
	EDIT_COURSE = "EDIT_COURSE",
	ADD_COURSE_TO_ANNOUNCEMENT = "ADD_COURSE_TO_ANNOUNCEMENT",
	// PROGRAM
	CREATE_PROGRAM = "CREATE_PROGRAM",
	EDIT_PROGRAM = "EDIT_PROGRAM",
	RECOMMENDATION = "RECOMMENDATION",
	ADD_WORKOUT = "ADD_WORKOUT",
	SWAP_WORKOUT = "SWAP_WORKOUT",
	// WORKOUT
	CREATE_WORKOUT = "CREATE_WORKOUT",
	EDIT_WORKOUT = "EDIT_WORKOUT",
	ADD_EXERCISES = "ADD_EXERCISES",
	ADD_SUPERSET = "ADD_SUPERSET",
	SWAP_EXERCISE = "SWAP_EXERCISE",
	// EXERCISE
	CREATE_EXERCISE = "CREATE_EXERCISE",
	EXERCISE_CATEGORY = "EXERCISE_CATEGORY",
	// RECIPE
	CREATE_RECIPE = "CREATE_RECIPE",
	RECIPE_CATEGORY = "RECIPE_CATEGORY",
	// ARTICLE
	CREATE_ARTICLE = "CREATE_ARTICLE",
	ARTICLE_CATEGORY = "ARTICLE_CATEGORY",
	// USER
	ACCESS_TO_USER = "ACCESS_TO_USER",
	RESTRICT_USER = "RESTRICT_USER",
	CONVERT_CSV = "CONVERT_CSV",

	// OTHERS
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
	DELETE = "DELETE",
}

// --- WORKOUT ---
export enum WORKOUT_TYPES {
	Circuit = "Circuit",
	Strength = "Strength",
}

// --- Exercise ---
export enum EXERCISE_TYPES {
	CIRCUIT = "Circuit",
	WEIGHTED = "Weighted",
	ONE_RM = "OneRm",
	BODY_WEIGHT = "BodyWeight",
}
