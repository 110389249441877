import { RFC } from '../../../types/propTypes'

type HTKTextInputProps = {
    name:         string
    value?:       string
    placeholder?: string
    error?:       string
    onChange:     (text: string) => void
}

const HTKTextInput:RFC<HTKTextInputProps> = ({
    name,
    value,
    placeholder,
    onChange,
    error
}) => {
    return (
        <div className='w-full drop-shadow-sm my-2'>
            <input
                name={name}
                type='text'
                value={value ?? ''}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                className='w-full outline-none rounded-[3px] bg-HTKMiddleGrey
                border-[1px] border-HTKBorder border-solid px-3 py-1 font-medium flex items-center justify-between'

            />

        </div>
    )
}

export default HTKTextInput