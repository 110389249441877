import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { useHTKDispatch, useHTKSelector } from '../../../app/hooks'
import { AddIcon, CloseIcon } from '../../../assets/icons/icons'
import { closeModal, modalSelector, openModal } from '../../../features/Cores/modalSlice'
import { articleCategoryTypes } from '../../../assets/data/arrays'
import { domAnimation, LazyMotion } from 'framer-motion'
import { addNewArticleCategory, articleSelector, updateArticleCategory } from '../../../features/Library/Articles/articleSlice'
import { HTKButton } from '../../atoms/atoms'
import { MODAL_TYPES } from '../../../assets/data/enums'

const AddArticleCategoryModal = () => {
    const { title } = useHTKSelector(modalSelector)
    const { category } = useHTKSelector(articleSelector)
    const [ selectedCategory, setSelectedCategory ] = useState(category ?? '')
    const dispatch = useHTKDispatch()

    const saveCategories = () => {
        switch (title) {
            case 'CREATE_ARTICLE': {
                dispatch(addNewArticleCategory(selectedCategory))
                dispatch(openModal({
                    modalType: MODAL_TYPES.CREATE_ARTICLE
                }))
                return
            }
            case 'UPDATE_ARTICLE': {
                dispatch(updateArticleCategory(selectedCategory))
                dispatch(closeModal())
                return
            }
            default:
                return
        }
    }

    const cancelCategories = () => {
        switch (title) {
            case 'CREATE_ARTICLE': {
                dispatch(openModal({
                    modalType: MODAL_TYPES.CREATE_ARTICLE,
                }))
                return
            }
            case 'UPDATE_ARTICLE': {
                dispatch(closeModal())
                return
            }
            default:
                return
        }
    }

    return (
        <>
            <div
                className={`py-3 px-6 transition-all duration-300 relative
                ${!selectedCategory ? 'w-[400px]' : 'w-[600px]'}`}
            >
                <Dialog.Title className='flex items-center justify-between'>
                    <div>
                        <p className='font-Title font-extrabold text-xl'>
                            ADD CATEGORY TO ARTICLE
                        </p>
                        <p className='text-HTKBlack/80'>
                            Select what category you would like to add to this Article.
                        </p>
                    </div>
                    <CloseIcon
                        width={'30'}
                        height={'30'}
                        className='cursor-pointer'
                        onClick={() => dispatch(closeModal())}
                    />
                </Dialog.Title>
                <hr className='htk-border my-2' />
                <div
                    className={`grid transition-all duration-200
                    ${!selectedCategory ? 'grid-cols-1 w-[90%] mx-auto' : 'grid-cols-2'}`}
                >
                    <div className='w-full px-4 max-h-[28em]'>
                        <div className='font-Title font-bold'>
                            CATEGORIES
                        </div>
                        <div className='w-full max-h-[25em] overflow-y-auto overflow-x-hidden pb-[1em]'>
                            <LazyMotion
                                key='article-category-card'
                                features={domAnimation}
                            >
                            {articleCategoryTypes.map((category, index) => {
                                const isSelected = selectedCategory === category
                                return (
                                    <div
                                        key={index}
                                        className='flex items-center justify-between cursor-pointer drop-shadow-md my-2'
                                        onClick={() => setSelectedCategory(category)}
                                    >
                                        <p
                                            className={`${isSelected
                                            ? 'bg-HTKBlack text-HTKWhite'
                                            : 'bg-HTKMiddleGrey text-HTKBlack'}
                                            w-full px-2 py-1 font-semibold font-Title rounded-l-[3px]
                                            border-solid border-[1px] border-HTKBorder `}
                                        >
                                            {category}
                                        </p>
                                        <AddIcon 
                                            width={'30'}
                                            height={'30'}
                                            className={`${isSelected
                                            ? 'bg-HTKBlack fill-HTKWhite'
                                            : 'bg-HTKMiddleGrey fill-HTKBlack'}
                                            py-[1px] border-solid border-[1px] 
                                            border-HTKBorder rounded-r-[3px]`}
                                        />
                                    </div>
                                )
                            })}
                            </LazyMotion>
                        </div>
                    </div>

                    {selectedCategory ? (
                        <div className='w-full px-4 max-h-[28em]'>
                            <div className='font-Title font-bold'>
                                SELECTED CATEGORY
                            </div>
                            <div className='w-full'>
                                <div className='flex items-center justify-between cursor-pointer drop-shadow-md my-2'>
                                    <p
                                        className='w-full px-2 py-1 font-semibold font-Title
                                        bg-HTKRed text-HTKWhite rounded-l-[3px]
                                        border-solid border-[1px] border-HTKBorder'
                                    >
                                        {selectedCategory}
                                    </p>
                                    <CloseIcon
                                        width={'24'}
                                        height={'24'}
                                        className='py-[4px] border-solid border-[1px] border-HTKBorder 
                                        rounded-r-[3px] bg-HTKRed fill-HTKWhite hover:bg-HTKWhite
                                        hover:fill-HTKRed hover:border-HTKRed transition-all duration-300'
                                        onClick={() => setSelectedCategory('')}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className='sticky bottom-0 w-full flex items-center h-12 pl-6 pr-16'>
                <div className='flex items-center'>
                    <HTKButton
                        text='Save Category'
                        className='create-item-button'
                        onSubmit={saveCategories}
                    />
                    <HTKButton
                        text='Cancel'
                        className='cancel-item-button'
                        onSubmit={cancelCategories}
                    />
                </div>
            </div>
        </>
    )
}

export default AddArticleCategoryModal