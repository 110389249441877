const calculateIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "24"} 
            height={height ?? "24"} 
            viewBox="0 0 24 24"
        >
          <g id="Group_1971" data-name="Group 1971">
              <rect id="Rectangle_961" data-name="Rectangle 961" width="24" height="24" fill="none"/>
          </g>
          <g id="Group_1973" data-name="Group 1973">
              <g id="Group_1972" data-name="Group 1972">
              <path id="Path_1126" data-name="Path 1126" d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V5H19Z"/>
              <rect id="Rectangle_962" data-name="Rectangle 962" width="5" height="1.5" transform="translate(6.25 7.72)"/>
              <rect id="Rectangle_963" data-name="Rectangle 963" width="5" height="1.5" transform="translate(13 15.75)"/>
              <rect id="Rectangle_964" data-name="Rectangle 964" width="5" height="1.5" transform="translate(13 13.25)"/>
              <path id="Path_1127" data-name="Path 1127" d="M8,18H9.5V16h2V14.5h-2v-2H8v2H6V16H8Z"/>
              <path id="Path_1128" data-name="Path 1128" d="M14.09,10.95,15.5,9.54l1.41,1.41,1.06-1.06L16.56,8.47l1.41-1.41L16.91,6,15.5,7.41,14.09,6,13.03,7.06l1.41,1.41L13.03,9.89Z"/>
              </g>
          </g>
        </svg>
      </div>
    )
  }
  
  export default calculateIcon