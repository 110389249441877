import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialArticlesState } from '../../../assets/data/initialState'
import { Article } from '../../../types/stateTypes'

export type ArticlesSliceType = {
    articles: Article[]
}

export const articlesSlice = createSlice({
    name: 'articles',
    initialState: InitialArticlesState,
    reducers: {
        clearArticles: (state) => {
            state.articles = []
        },
        setInitialArticles: (state, action:PayloadAction<ArticlesSliceType>) => {
            state.articles = action.payload.articles
        },
        setArticles: (state, action:PayloadAction<ArticlesSliceType>) => {
            const { articles: storedArticles } = state
            const { articles: newArticles } = action.payload
            const articleIds = storedArticles.map(article => article.id)
            const filteredDuplicates = newArticles.filter(article => !articleIds.includes(article.id))
            filteredDuplicates.map(article => state.articles.push(article))
        },
        addArticle: (state, action:PayloadAction<Article>) => {
            state.articles.unshift(action.payload)
        },
        updateArticleInfo: (state, action:PayloadAction<Article>) => {
            const filteredArticles = state.articles.filter(article => article.id !== action.payload.id)
            state.articles = [ action.payload, ...filteredArticles ]
        },
        deleteArticleWithId: (state, action:PayloadAction<string>) => {
            const filteredArticles = state.articles.filter(article => article.id !== action.payload)
            state.articles = filteredArticles
        }
    }
})

export const articlesSelector = (state: RootState) => state.articles.articles
export const {
    clearArticles,
    setInitialArticles,
    setArticles,
    addArticle,
    updateArticleInfo,
    deleteArticleWithId,
} = articlesSlice.actions
export default articlesSlice.reducer